import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

export const StyledPrimaryBrandColorContainer = styled.div``;
export const StyledLabel = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px",
  border: `1px solid ${APP_COMMON_COLORS.dark[100]}`,
  width: 50,
  fontSize: 14,
});

export const ColorPickerContainer = styled.div({
  width: "fit-content",
  padding: "15px",
  borderRadius: "5px",
  border: `1px dashed ${APP_COMMON_COLORS.dark[500]}`,
  ".react-colorful__pointer": {
    width: "16px",
    height: "16px",
    borderWidth: "2px",
  },
  ".react-colorful__saturation": {
    marginBottom: "10px",
    height: "400px",
    borderRadius: "5px",
  },
  ".react-colorful__last-control": {
    borderRadius: "5px",
  },
  display: "flex",
  flexDirection: "column",
});

export const ColorPickerRow = styled.div({
  display: "flex",
  justifyContent: "space-between",
  paddingBlock: "10px",
});

export const ColorInput = styled.input({
  width: "100px",
  borderRadius: "5px",
  border: `1px solid ${APP_COMMON_COLORS.dark[100]}`,
  padding: "10px",
});

export const StyledCancelLabel = styled.div({
  display: "flex",
  justifyContent: "center",
  fontSize: 12,
  marginTop: 10,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
});
