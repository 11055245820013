import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

export const StyledAppModalDesktopStepsContainer = styled.div({
  display: "none",
  "@media only screen and (min-width: 1200px)": {
    display: "block",
    paddingLeft: 40,
    paddingTop: 78,
    marginRight: 50,
    minWidth: 150,
  },
});

export const StyledAppModalDesktopStepTitle = styled.div(({ theme }) => ({
  display: "none",
  marginBottom: 12,
  "@media only screen and (min-width: 1200px)": {
    display: "block",
    color: APP_COMMON_COLORS.dark[700],
    fontWeight: 600,
    fontSize: 30,
  },
}));
