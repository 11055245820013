import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  FORM_FIELD_ATOMS,
  TEMPLATES_ATOMS,
} from "../../AddEditTemplateModal.atoms";
import { StyledAppModalSubmitButtonContainer } from "../../../../../components/shared/StyledComponents/StyledAppModalComponents";
import React, { useState } from "react";
import { AddIconWithLabel } from "../../../../../components/shared/AddIconWithLabel/AddIconWithLabel";
import { StyledClose } from "../../PerformancePostFields/PerformanceSideInfo/PerformanceSideInfo.styles";
import {
  StyledFieldsRowsContainer,
  StyledFieldRow,
  StyledLabelContainer,
  StyledFieldLabel,
} from "../FormPostFields.styles";
import styled from "styled-components";
import { FORM_FIELDS_DISPLAY_STATES } from "../FormPostFields.types";
import { AppButton } from "../../../../../components/core/AppButton/AppButton";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../../redux/client/clientSlice";
import { getUser } from "../../../../../redux/user/userSlice";
import { capitalizeFirstChar } from "../../../../../helpers/common";
import {
  ADD_FORM_POST_TEMPLATE_DTO,
  FORM_POST_TEMPLATE,
  TEMPLATE_TYPE,
} from "../../../../../types/templates/templates.types";
import { firebaseHttpsCallable } from "../../../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../../../constants/firebase.constants";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../../../../styles/styles.constants";
import { AppSpinner } from "../../../../../components/core/AppSpinner/AppSpinner";

const StyledFormPostContainer = styled.div({
  "@media only screen and (min-width: 1200px)": {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const FormPostFieldsRows = () => {
  const client = useSelector(getClientSelector);
  const user = useSelector(getUser);
  const templateName = useAtomValue(TEMPLATES_ATOMS.templateName);
  const templateId = useAtomValue(TEMPLATES_ATOMS.templateId);
  const setIsPublished = useSetAtom(TEMPLATES_ATOMS.isPublished);
  const [isLoading, setIsLoading] = useState(false);

  const [formPostAdditionalFields, setFormPostAdditionalFields] = useAtom(
    TEMPLATES_ATOMS.formPostAdditionalFields
  );

  const [displayState, setDisplayState] = useAtom(
    FORM_FIELD_ATOMS.displayState
  );

  const [editingFieldIndex, setEditingFieldIndex] = useAtom(
    FORM_FIELD_ATOMS.editingFieldIndex
  );

  const [fieldSelectType, setFieldSelectType] = useAtom(
    FORM_FIELD_ATOMS.fieldSelectType
  );

  const isEditMode = useAtomValue(TEMPLATES_ATOMS.isEditMode);

  const handleEditField = (index: number) => {
    setDisplayState(FORM_FIELDS_DISPLAY_STATES.ADD_NEW_FIELD);
    setEditingFieldIndex(index);
    const type =
      formPostAdditionalFields[index]?.type ||
      (formPostAdditionalFields[index] as any)?.value;
    setFieldSelectType(type);
  };

  const handleRemoveField = (index: number) => {
    const newArray = [...formPostAdditionalFields];
    newArray.splice(index, 1);
    setFormPostAdditionalFields(newArray);
  };

  const handleEditFormPost = async () => {
    const payload: FORM_POST_TEMPLATE = {
      clientId: String(client?.clientId),
      templateName: capitalizeFirstChar(templateName)?.trim(),
      templateId: templateId,
      templateType: TEMPLATE_TYPE.formPost,
      additionalFields: [...formPostAdditionalFields],
      updatedBy: String(user.uid),
      updatedAt: Date.now(),
    };

    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.editGeneralPostTemplate)(
      payload
    )
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          toast("Form post updated", APP_TOASTIFY.SUCCESS);
          setIsPublished(true);
        } else {
          toast(
            "Something went wrong while updating form post[1]",
            APP_TOASTIFY.ERROR
          );
        }
      })
      .catch((err: any) => {
        toast(
          "Something went wrong while updating form post[2]",
          APP_TOASTIFY.ERROR
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddFormPost = async () => {
    const payload: ADD_FORM_POST_TEMPLATE_DTO = {
      clientId: String(client?.clientId),
      templateName: capitalizeFirstChar(templateName)?.trim(),
      templateType: TEMPLATE_TYPE.formPost,
      additionalFields: [...formPostAdditionalFields],
      updatedBy: String(user.uid),
    };

    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.addGeneralPostTemplate)(
      payload
    )
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          toast("Form post added", APP_TOASTIFY.SUCCESS);
          setIsPublished(true);
        } else {
          toast(
            "Something went wrong while adding form post[1]",
            APP_TOASTIFY.ERROR
          );
        }
      })
      .catch((err: any) => {
        toast(
          "Something went wrong while adding form post[2]",
          APP_TOASTIFY.ERROR
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnSubmitFormPost = () => {
    setIsLoading(true);

    if (isEditMode) {
      handleEditFormPost();
    } else {
      handleAddFormPost();
    }
  };

  if (isLoading) {
    return <AppSpinner />;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "55vh",
      }}
    >
      <StyledFieldsRowsContainer>
        {formPostAdditionalFields?.map((formPost, index) => {
          return (
            <React.Fragment key={index}>
              <StyledFieldRow>
                <StyledLabelContainer>
                  <StyledClose onClick={() => handleRemoveField(index)} />
                  <StyledFieldLabel onClick={() => handleEditField(index)}>
                    {formPost.label}
                  </StyledFieldLabel>
                </StyledLabelContainer>
              </StyledFieldRow>
            </React.Fragment>
          );
        })}
      </StyledFieldsRowsContainer>
      <div>
        <AddIconWithLabel
          label="ADD CUSTOM FIELD"
          onClick={() =>
            setDisplayState(FORM_FIELDS_DISPLAY_STATES.ADD_NEW_FIELD)
          }
        />

        <StyledAppModalSubmitButtonContainer style={{ bottom: 0, margin: 0 }}>
          <AppButton onClick={handleOnSubmitFormPost}>Publish</AppButton>
        </StyledAppModalSubmitButtonContainer>
      </div>
    </div>
  );
};
