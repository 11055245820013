import React from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../../../redux/user/userSlice";
import styled from "styled-components";

const StyledGreetingMessageContainer = styled.div({
  display: "flex",
  fontSize: 20,
  gap: 5,
  alignItems: "center",
});

export const GreetingMessage = () => {
  const user = useSelector(getUser);
  return (
    <StyledGreetingMessageContainer>
      <div>Welcome back,</div>
      {user.firstName && (
        <div style={{ fontWeight: "bold" }}>{`${user.firstName}`}</div>
      )}
    </StyledGreetingMessageContainer>
  );
};
