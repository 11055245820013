import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { getFirebaseDataByColKeyVal } from "../firebase/getFirebaseDataByColKeyVal";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../styles/styles.constants";
import { getClientSelector, setClient } from "../../redux/client/clientSlice";
import { CLIENT_STATE } from "../../types/client/client.types";
import { getUser } from "../../redux/user/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES, EXTERNAL_URLS } from "../../constants/routes.constant";

export const useSetClientData = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const client = useSelector(getClientSelector);
  const location = useLocation();
  const navigate = useNavigate();

  const fetchFirebaseColors = async (clientId: string) => {
    try {
      const res = await getFirebaseDataByColKeyVal(
        COLLECTIONS_TYPE.clients,
        "clientId",
        clientId
      );

      const clientData = Object.values(res)[0] as CLIENT_STATE;
      dispatch(setClient({ ...clientData }));
    } catch (err) {
      console.log({ err });
      toast("Something went wrong [1]", APP_TOASTIFY.ERROR);
    }
  };

  useEffect(() => {
    const selectedClientId = localStorage.getItem("selectedClientId");
    const clientAdmins = Object.entries(user.isClientAdmin || {})?.filter(
      ([_clientId, isClientAdmin]) => isClientAdmin
    );

    const clientId = user.isClientAdmin?.[String(selectedClientId)]
      ? selectedClientId
      : clientAdmins?.[0]?.[0];
    if (clientId && user.isLoggedIn) {
      fetchFirebaseColors(clientId);
    }

    if (
      clientAdmins.length > 1 &&
      !user.isClientAdmin?.[String(selectedClientId)]
    )
      navigate(APP_ROUTES.clientSelector);
  }, [user]);

  useEffect(() => {
    const faviconLink = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement | null;

    if (
      !user.isLoggedIn ||
      Object.values(user.yourzownPermissions || {}).length > 0
    ) {
      document.title = "YourZown";
      faviconLink!.href = String(EXTERNAL_URLS.yourzownLogo);
      return;
    }

    if (user.isLoggedIn) {
      if (client?.clientName) {
        document.title = String(client?.clientName);
      }
      if (client?.branding?.logoUrl) {
        faviconLink!.href = String(client?.branding?.logoUrl);
      }
      return;
    }
  }, [user, client, location.pathname]);

  return null;
};
