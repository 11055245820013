import { useEffect, useState } from "react";
import {
  StyledMainSubGroupName,
  StyledMemberSubGroupsTreeWithCheckboxesContainer,
  StyledSubGroupLabelInDepth,
  StyledSubGroupLabelRow,
  StyledSubGroupsLabelContainer,
  StyledTreeNodeContainer,
} from "./MemberSubGroupsTreeWithCheckboxes.styles";
import { Node } from "../../../GroupsPage/SubGroupsTree/SubGroupsTree.types";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";
import { SUB_GROUPS } from "../../../../types/groups/groups.types";
import { mapFlatTreeToNestedTree } from "../../../GroupsPage/SubGroupsTree/SubGroupsTree.helpers";
import { Checkbox } from "@mui/material";
import { CheckBoxIconNotChecked } from "../../../../components/core/AppBurgerMenu/icons/CheckBoxIconNotChecked";
import { CheckBoxIcon } from "../../../../components/core/AppBurgerMenu/icons/CheckBoxIcon";
import { addOrRemoveStringFromArray } from "../../../../helpers/common";
import { DISABLED_ELEMENT_STYLES } from "../../../../styles/styles.constants";
import { compareAlphanumeric } from "../../../../helpers/compareAlphanumeric";

export interface TreeNodeWithCheckBoxesProps {
  node: Node;
  depth: number;
  checkedSubGroups: string[];
  onChangeCheckedSubGroups: (
    checkedSubGroups: string[],
    groupId: string
  ) => void;
  isDisabled?: boolean;
}

export interface MemberSubGroupsTreeWithCheckboxesProps {
  flatTree: SUB_GROUPS;
  checkedSubGroups: string[];
  onChangeCheckedSubGroups: (
    checkedSubGroups: string[],
    groupId: string
  ) => void;
  isDisabled?: boolean;
}

const TreeNode = (props: TreeNodeWithCheckBoxesProps) => {
  return (
    <StyledTreeNodeContainer>
      <StyledSubGroupLabelRow>
        <span style={{ color: APP_COMMON_COLORS.dark[300] }}>
          {"—".repeat(props.depth)}
        </span>
        <Checkbox
          style={{
            padding: 0,
            paddingInline: 5,
            ...(props.isDisabled ? DISABLED_ELEMENT_STYLES : {}),
          }}
          checked={props?.checkedSubGroups?.includes(props.node.subGroupId)}
          icon={<CheckBoxIconNotChecked width={20} height={20} />}
          checkedIcon={<CheckBoxIcon width={20} height={20} />}
          disableRipple
          disabled={Boolean(props.isDisabled)}
          onChange={(_e) => {
            const arr = addOrRemoveStringFromArray(
              props.checkedSubGroups,
              props.node.subGroupId
            );
            const uniqueArray = Array.from(new Set(arr));

            props.onChangeCheckedSubGroups(uniqueArray, props.node.groupId);
          }}
        />
        {props.node.subGroupId?.startsWith("group") ? (
          <StyledMainSubGroupName>
            {props.node.subGroupName}
          </StyledMainSubGroupName>
        ) : (
          <StyledSubGroupsLabelContainer>
            <StyledSubGroupLabelInDepth depth={props.depth}>
              {props.node.subGroupName}
            </StyledSubGroupLabelInDepth>
          </StyledSubGroupsLabelContainer>
        )}
      </StyledSubGroupLabelRow>

      {props.node.children && (
        <div>
          {props.node.children
            .sort((a, b) => compareAlphanumeric(a.subGroupName, b.subGroupName))
            .map((child) => {
              if (child.subGroupId === props.node.subGroupId) {
                return null;
              }
              return (
                <TreeNode
                  key={child.subGroupId}
                  node={child}
                  depth={props.depth + 1}
                  checkedSubGroups={props.checkedSubGroups}
                  onChangeCheckedSubGroups={props.onChangeCheckedSubGroups}
                  isDisabled={props.isDisabled}
                />
              );
            })}
        </div>
      )}
    </StyledTreeNodeContainer>
  );
};

export const MemberSubGroupsTreeWithCheckboxes = (
  props: MemberSubGroupsTreeWithCheckboxesProps
) => {
  const [tree, setTree] = useState<Node | null>(null);

  useEffect(() => {
    if (props.flatTree) {
      const nestedTree = mapFlatTreeToNestedTree(props.flatTree);
      setTree(nestedTree as any);
    }
  }, [props.flatTree, Object.values(tree || {}).length]);

  if (!tree) {
    return <div>Loading...</div>;
  }

  return (
    <StyledMemberSubGroupsTreeWithCheckboxesContainer>
      <TreeNode
        node={tree as any}
        depth={0}
        checkedSubGroups={props.checkedSubGroups}
        onChangeCheckedSubGroups={props.onChangeCheckedSubGroups}
        isDisabled={props.isDisabled}
      />
    </StyledMemberSubGroupsTreeWithCheckboxesContainer>
  );
};
