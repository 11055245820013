import { StyledAppModalDesktopStepTitle } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { StyleAppModalSingleStepContainer } from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { FieldsRows } from "./FieldsRows/FieldsRows";
import { NewFieldForm } from "./NewFieldForm/NewFieldForm";
import { RolesSideInfoFieldsInTemplate } from "./RolesSideInfoFieldsInTemplate/RolesSideInfoFieldsInTemplate";
import { AppSideMenuContainer } from "../../../../components/shared/AppSideMenuContainer/AppSideMenuContainer";
import { StyledRoleCustomFieldStepContainer } from "./RoleCustomFieldStep.styles";
import { useAtom } from "jotai";
import { ROLES_ATOMS } from "../../RolesPage.atoms";
import { DISPLAY_STATES } from "../../RolesPage.types";

export interface RoleCustomFieldStepValues {
  roleName?: string;
}

export const RoleCustomFieldStep = () => {
  const [displayState, setDisplayState] = useAtom(
    ROLES_ATOMS.customStepDisplayState
  );

  return (
    <StyledRoleCustomFieldStepContainer>
      <StyleAppModalSingleStepContainer
        $customStylesDesktop={{ paddingBottom: "20px" }}
      >
        <StyledAppModalDesktopStepTitle style={{ marginBottom: 35 }}>
          Custom fields
        </StyledAppModalDesktopStepTitle>
        <FieldsRows
          onClick={() => setDisplayState(DISPLAY_STATES.ADD_NEW_FIELD)}
          hideSection={displayState !== DISPLAY_STATES.FIELDS_ROWS}
        />
        <NewFieldForm
          onClickAdd={() => setDisplayState(DISPLAY_STATES.FIELDS_ROWS)}
          hideSection={displayState !== DISPLAY_STATES.ADD_NEW_FIELD}
        />
      </StyleAppModalSingleStepContainer>

      <AppSideMenuContainer hideInMobile={true}>
        <RolesSideInfoFieldsInTemplate />
      </AppSideMenuContainer>
    </StyledRoleCustomFieldStepContainer>
  );
};
