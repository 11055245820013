import { Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import { StyledAppModalSubmitButtonContainer } from "../../../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppButton } from "../../../../../../components/core/AppButton/AppButton";
import { AppFormikField } from "../../../../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { useAtom, useSetAtom } from "jotai";
import { StyleForm } from "./EventAttachment.styles";
import { TEMPLATES_ATOMS } from "../../../AddEditTemplateModal.atoms";
import {
  StyledAddAndCancelContainer,
  StyledCancel,
} from "../EventCustomFields.styles";
import {
  EVENT_CUSTOM_FIELD,
  EVENT_CUSTOM_FIELDS_TYPE,
} from "../../../../../../types/templates/templates.types";

interface EventAttachmentLabelValue {
  label: string;
}

const schema = Yup.object({
  label: Yup.string().required("Required").max(255, "Maximum length is 255"),
});

export const EventAttachment = () => {
  const setCustomFieldType = useSetAtom(TEMPLATES_ATOMS.customFieldsType);
  const additionalFieldsComponent = useSetAtom(
    TEMPLATES_ATOMS.additionalFieldsComponent
  );
  const [eventCustomFields, setEventCustomFields] = useAtom(
    TEMPLATES_ATOMS.eventCustomFields
  );

  const initialValues = {
    label: "",
  };

  const resetForm = () => {
    setCustomFieldType(undefined);
    additionalFieldsComponent("DEFINED_FIELDS");
  };

  const handleOnSubmit = (values: EventAttachmentLabelValue) => {
    const attachmentField: EVENT_CUSTOM_FIELD = {
      value: EVENT_CUSTOM_FIELDS_TYPE.attachment,
      label: values.label,
      required: false,
    };

    setEventCustomFields([...eventCustomFields, attachmentField]);

    resetForm();
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values: EventAttachmentLabelValue) => handleOnSubmit(values)}
    >
      {({
        errors,
        touched,
        values,
        submitCount,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => {
        const _touched: FormikTouched<EventAttachmentLabelValue> = touched;
        const _errors: FormikErrors<EventAttachmentLabelValue> = errors;

        return (
          <StyleForm>
            <AppFormikField
              containerstyle={{ marginBottom: 40 }}
              name="label"
              isRequired={true}
              value={values.label || ""}
              label="Attachment label"
              errorMessage={
                (_touched.label && _errors.label) ||
                (submitCount > 0 && errors.label)
                  ? String(errors.label)
                  : ""
              }
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
            />

            <StyledAddAndCancelContainer>
              <StyledAppModalSubmitButtonContainer style={{ margin: 0 }}>
                <AppButton onClick={handleSubmit}>Add</AppButton>
              </StyledAppModalSubmitButtonContainer>
              <StyledCancel onClick={resetForm}>CANCEL</StyledCancel>
            </StyledAddAndCancelContainer>
          </StyleForm>
        );
      }}
    </Formik>
  );
};
