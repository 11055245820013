import React from "react";
import { StyledAppModalDesktopStepTitle } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import {
  AppRadioCustomContainerStyles,
  CustomRadioAndLabelContainerStyles,
  StyleAppRadioButtonGroupsContainer,
  StyledAdditionalFieldRow,
  StyledAdditionalFieldsRowsContainer,
  StyledAppButtonContainer,
  StyledCloseIconAndLabel,
  StyledEventAdditionalFieldsContainer,
  StyledInputsContainer,
  StyledLabelSwitch,
  StyledVerticalDivider,
} from "./EventAdditionalFieldsStep.styles";
import { AppTopTags } from "../../../../components/core/AppTopTags/AppTopTags";
import { AppButton } from "../../../../components/core/AppButton/AppButton";
import { AppSwitch } from "../../../../components/core/AppSwitch/AppSwitch";
import { AppRadioButtonGroup } from "../../../../components/core/AppFormik/AppFormikField/AppRadioButton";
import { useState } from "react";
import {
  PRE_DEFINED_ADDITIONAL_FIELDS,
  getEnabledFieldsArray,
} from "./EventAdditionalFieldsStep.helper";
import { AppModal } from "../../../../components/core/AppModal/AppModal";
import { useModal } from "../../../../components/core/AppModal/useModal";
import { useScreenWidth } from "../../../../hooks/useScreenWidth/useScreenWidth";
import { AppModalMobileSteps } from "../../../../components/shared/AppModalMobileSteps/AppModalMobileSteps";
import { StyleAppModalSingleStepContainer } from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppSideMenuContainer } from "../../../../components/shared/AppSideMenuContainer/AppSideMenuContainer";
import { EventSideInfo } from "./EventSideInfo/EventSideInfo";
import { AddIconWithLabel } from "../../../../components/shared/AddIconWithLabel/AddIconWithLabel";
import { TEMPLATES_ATOMS } from "../AddEditTemplateModal.atoms";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  ADD_EVENT_TEMPLATE_DTO,
  EVENT_TEMPLATE,
  TEMPLATE_TYPE,
} from "../../../../types/templates/templates.types";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { getUser } from "../../../../redux/user/userSlice";
import { firebaseHttpsCallable } from "../../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../../constants/firebase.constants";
import { GENERAL_REPONSE } from "../../../../types/firbase.types";
import { toast } from "react-toastify";
import {
  APP_TOASTIFY,
  HIDDEN_ELEMENT_STYLES,
} from "../../../../styles/styles.constants";
import { EventCustomFields } from "./EventCustomFields/EventCustomFields";
import { StyledClose } from "../../../../components/shared/StyledComponents/StyledClose";
import { capitalizeFirstChar } from "../../../../helpers/common";

export interface EventAdditionalFieldsStepValuesKeys {
  enabled?: boolean;
  required: boolean;
  label: string;
  value: string;
}

export interface EventAdditionalFieldsStepValues {
  [key: string]: EventAdditionalFieldsStepValuesKeys;
}

export const EventAdditionalFieldsStep: React.FC = () => {
  const { isOpen, openModal, closeModal } = useModal();
  const [values, setValues] = useAtom(TEMPLATES_ATOMS.eventAdditionalFields);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const templateName = useAtomValue(TEMPLATES_ATOMS.templateName);
  const templateType = useAtomValue(TEMPLATES_ATOMS.templateType);
  const isPublished = useSetAtom(TEMPLATES_ATOMS.isPublished);
  const isEditMode = useAtomValue(TEMPLATES_ATOMS.isEditMode);
  const templateId = useAtomValue(TEMPLATES_ATOMS.templateId);
  const clientData = useSelector(getClientSelector);
  const userData = useSelector(getUser);
  const [additionalFieldsComponent, setAdditionalFieldsComponent] = useAtom(
    TEMPLATES_ATOMS.additionalFieldsComponent
  );
  const [eventCustomFields, setEventCustomFields] = useAtom(
    TEMPLATES_ATOMS.eventCustomFields
  );

  const handleOnSwitchChange = (e: boolean, additionalFieldValue: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      [additionalFieldValue]: {
        ...PRE_DEFINED_ADDITIONAL_FIELDS[additionalFieldValue],
        enabled: e,
        required: PRE_DEFINED_ADDITIONAL_FIELDS[additionalFieldValue].required,
        value: PRE_DEFINED_ADDITIONAL_FIELDS[additionalFieldValue].value,
        label: PRE_DEFINED_ADDITIONAL_FIELDS[additionalFieldValue].label,
      },
    }));
  };

  const handleUpdateEventCustomFieldRequired = (
    required: boolean,
    index: number
  ) => {
    const updatedFields = [...eventCustomFields];
    updatedFields[index] = {
      ...updatedFields[index],
      required: required,
    };
    setEventCustomFields([...updatedFields]);
  };

  const handleRemoveEventCustomField = (index: number) => {
    const updatedFields = [...eventCustomFields];
    updatedFields.splice(index, 1);
    setEventCustomFields([...updatedFields]);
  };

  const handleAddEventPostTemplate = (
    mappedAdditionalFields: EventAdditionalFieldsStepValuesKeys[]
  ) => {
    const payload: ADD_EVENT_TEMPLATE_DTO = {
      templateName: capitalizeFirstChar(templateName),
      templateType: templateType as TEMPLATE_TYPE.event,
      additionalFields: mappedAdditionalFields,
      customFields: [...eventCustomFields],
      clientId: String(clientData.clientId),
      updatedBy: String(userData.uid),
    };

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.addEventTemplate)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_REPONSE;
        if (data.result === "ok") {
          toast("Event Template Added", APP_TOASTIFY.SUCCESS);
          isPublished(true);
        } else {
          toast("Error while adding event template [1]", APP_TOASTIFY.ERROR);
        }
      })
      .catch((e) =>
        toast("Error while event adding template [2]", APP_TOASTIFY.ERROR)
      )
      .finally(() => setIsSubmitting(false));
    return;
  };

  const handleEditEventPostTemplate = (
    mappedAdditionalFields: EventAdditionalFieldsStepValuesKeys[]
  ) => {
    const payload: Partial<EVENT_TEMPLATE> = {
      templateName: capitalizeFirstChar(templateName),
      templateType: templateType as TEMPLATE_TYPE.event,
      additionalFields: mappedAdditionalFields,
      customFields: [...eventCustomFields],
      clientId: String(clientData.clientId),
      templateId: templateId,
      updatedBy: String(userData.uid),
    };

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.editEventTemplate)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_REPONSE;
        if (data.result === "ok") {
          toast("Event template updated", APP_TOASTIFY.SUCCESS);
          isPublished(true);
        } else {
          toast("Error while updating event template [1]", APP_TOASTIFY.ERROR);
        }
      })
      .catch((e) =>
        toast("Error while event updating template [2]", APP_TOASTIFY.ERROR)
      )
      .finally(() => setIsSubmitting(false));
    return;
  };

  const handleOnSubmit = () => {
    setIsSubmitting(true);
    const mappedAdditionalFields = getEnabledFieldsArray(values);

    if (isEditMode) {
      handleEditEventPostTemplate(mappedAdditionalFields);
    } else {
      handleAddEventPostTemplate(mappedAdditionalFields);
    }
  };

  const screenWidth = useScreenWidth();

  return (
    <>
      <StyledEventAdditionalFieldsContainer>
        <StyleAppModalSingleStepContainer style={{ paddingBottom: 0 }}>
          <StyledAppModalDesktopStepTitle style={{ marginBottom: 27 }}>
            Select additional fields
          </StyledAppModalDesktopStepTitle>

          {/* {screenWidth < 1200 &&
            Object.values(values)?.filter((value) => value.enabled)?.length >
              0 && (
              <StyledPrimaryBackgroundContainer onClick={openModal}>
                <div>Included fields:</div>
                <div>
                  {
                    Object.values(values)?.filter((value) => value.enabled)
                      ?.length
                  }
                </div>
              </StyledPrimaryBackgroundContainer>
            )} */}

          <div
            style={{
              ...(additionalFieldsComponent !== "DEFINED_FIELDS" &&
                HIDDEN_ELEMENT_STYLES),
            }}
          >
            <StyledInputsContainer>
              <AppTopTags tags={["MANDATORY", "OPTIONAL"]} />
              <StyledAdditionalFieldsRowsContainer>
                {Object.values(PRE_DEFINED_ADDITIONAL_FIELDS).map(
                  (additionalField, index) => {
                    return (
                      <React.Fragment key={additionalField.label + index}>
                        <StyledAdditionalFieldRow>
                          <StyledLabelSwitch>
                            <AppSwitch
                              label={additionalField.label}
                              checked={Boolean(
                                values[additionalField.value]?.enabled
                              )}
                              onChange={(e) => {
                                handleOnSwitchChange(e, additionalField.value);
                              }}
                            />
                          </StyledLabelSwitch>

                          <StyleAppRadioButtonGroupsContainer
                            $isDisabled={
                              !values[additionalField.value]?.enabled
                            }
                          >
                            <AppRadioButtonGroup
                              options={[
                                { label: "", value: true },
                                { label: "", value: false },
                              ]}
                              value={Boolean(
                                values[additionalField.value]?.required
                              )}
                              onChangeValue={(e) =>
                                setValues((prevValues) => ({
                                  ...prevValues,
                                  [additionalField.value]: {
                                    ...prevValues[additionalField.value],
                                    required: e,
                                    label: additionalField.label,
                                    value: additionalField.value,
                                  },
                                }))
                              }
                              customContainerStyles={
                                AppRadioCustomContainerStyles
                              }
                              customRadioAndLabelContainerStyles={{
                                ...CustomRadioAndLabelContainerStyles,
                              }}
                              customErrorStyles={{ display: "none" }}
                              rowDirection={true}
                            />
                          </StyleAppRadioButtonGroupsContainer>
                        </StyledAdditionalFieldRow>
                      </React.Fragment>
                    );
                  }
                )}
                {Object.values(eventCustomFields).map((field, index) => {
                  return (
                    <React.Fragment key={(field.label, index)}>
                      <StyledAdditionalFieldRow>
                        <StyledCloseIconAndLabel>
                          <StyledClose
                            onClick={() => handleRemoveEventCustomField(index)}
                          />
                          <StyledVerticalDivider />
                          <div>{field.label}</div>
                        </StyledCloseIconAndLabel>

                        <StyleAppRadioButtonGroupsContainer>
                          <AppRadioButtonGroup
                            options={[
                              { label: "", value: true },
                              { label: "", value: false },
                            ]}
                            value={Boolean(field.required)}
                            onChangeValue={(e) => {
                              handleUpdateEventCustomFieldRequired(e, index);
                            }}
                            customContainerStyles={
                              AppRadioCustomContainerStyles
                            }
                            customRadioAndLabelContainerStyles={{
                              ...CustomRadioAndLabelContainerStyles,
                            }}
                            customErrorStyles={{ display: "none" }}
                            rowDirection={true}
                          />
                        </StyleAppRadioButtonGroupsContainer>
                      </StyledAdditionalFieldRow>
                    </React.Fragment>
                  );
                })}
              </StyledAdditionalFieldsRowsContainer>
            </StyledInputsContainer>

            <AddIconWithLabel
              label={"ADD CUSTOM FIELD"}
              onClick={() => setAdditionalFieldsComponent("CUSTOM_FIELDS")}
            />

            <StyledAppButtonContainer>
              <AppButton
                onClick={() => {
                  handleOnSubmit();
                }}
                isLoading={isSubmitting}
              >
                Publish
              </AppButton>
            </StyledAppButtonContainer>
          </div>

          <div
            style={{
              ...(additionalFieldsComponent !== "CUSTOM_FIELDS" &&
                HIDDEN_ELEMENT_STYLES),
            }}
          >
            <EventCustomFields />
          </div>
        </StyleAppModalSingleStepContainer>
        <AppSideMenuContainer hideInMobile={true}>
          <EventSideInfo addedFields={Object.values(values)} />
        </AppSideMenuContainer>
      </StyledEventAdditionalFieldsContainer>

      <AppModal isOpen={isOpen} closeModal={closeModal}>
        <AppModalMobileSteps
          tabValue={0}
          titlesOfSteps={["Included fields"]}
          hideStepsTitle={true}
        />
        <EventSideInfo addedFields={Object.values(values)} />
        <AppButton onClick={closeModal}>Close</AppButton>
      </AppModal>
    </>
  );
};
