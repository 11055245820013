import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../../../providers/AppThemeProvider";
import { AppFormikSelect } from "../../../../../components/core/AppFormik/AppFormikSelect/AppFormikSelect";
import { useAtom } from "jotai";
import { TEMPLATES_ATOMS } from "../../AddEditTemplateModal.atoms";
import { EventTextInput } from "./EventTextInput/EventTextInput";
import { EventAttachment } from "./EventAttachment/EventAttachment";
import { EventRedirection } from "./EventRedirection/EventRedirection";
import { EVENT_CUSTOM_FIELDS_TYPE } from "../../../../../types/templates/templates.types";

const EVENT_CUSTOM_FIELDS = [
  { label: "Text Input", value: EVENT_CUSTOM_FIELDS_TYPE.textInput },
  { label: "Attachment", value: EVENT_CUSTOM_FIELDS_TYPE.attachment },
  { label: "Redirection", value: EVENT_CUSTOM_FIELDS_TYPE.redirection },
];

export const StyledAddNewField = styled.div({
  fontWeight: 500,
  size: 14,
  color: APP_COMMON_COLORS.dark[700],
  paddingBottom: 20,
  borderBottom: `1px solid ${APP_COMMON_COLORS.dark[300]}`,
  marginBottom: 30,
});

export const StyledRoleAddCustomFieldFormContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const StyledInputsSection = styled.div({
  width: "100%",
  "@media (min-width: 1200px)": {
    width: 600,
  },
});
export const StyledSelectContainer = styled.div({
  "@media only screen and (min-width: 1200px)": {
    width: 300,
  },
});

export const EventCustomFields = () => {
  const [customFieldType, setCustomFieldType] = useAtom(
    TEMPLATES_ATOMS.customFieldsType
  );

  return (
    <StyledRoleAddCustomFieldFormContainer>
      <StyledInputsSection>
        <StyledAddNewField>Add new field</StyledAddNewField>
        <StyledSelectContainer>
          <AppFormikSelect
            value={customFieldType}
            options={EVENT_CUSTOM_FIELDS}
            onChange={(e) => setCustomFieldType(e.value)}
            label="Select"
          />
        </StyledSelectContainer>

        {customFieldType === EVENT_CUSTOM_FIELDS_TYPE.textInput && (
          <EventTextInput />
        )}
        {customFieldType === EVENT_CUSTOM_FIELDS_TYPE.attachment && (
          <EventAttachment />
        )}
        {customFieldType === EVENT_CUSTOM_FIELDS_TYPE.redirection && (
          <EventRedirection />
        )}
      </StyledInputsSection>
    </StyledRoleAddCustomFieldFormContainer>
  );
};
