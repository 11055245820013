import { SUBSCRIPTION_DATA } from "../../types/payments/payments.types";
import { MemberFormValues, SubGroup } from "./MembersPage.types";

export const addProductIdToMemberAndChildren = (
  member: MemberFormValues,
  productIds: { [key: string]: { productId: string } }
) => {
  const _member = { ...member };
  _member.children?.map((child) => {
    if (productIds[String(child.childId)]) {
      child.productId = productIds[String(child.childId)].productId;
    }
  });

  if (productIds[String(_member.uid)]) {
    _member.productId = productIds[String(_member.uid)].productId;
  }

  return _member;
};

export const getPaymentStatus = (data?: SUBSCRIPTION_DATA | undefined) => {
  if (!data?.productId) {
    return "-";
  }

  if (data?.status === "active") {
    return "Paid";
  }

  if (data?.status === "inActive") {
    return "Unpaid";
  }

  return "-";
};

export const getNextPaymentDate = (
  memberSubscription: { [id: string]: SUBSCRIPTION_DATA } | undefined,
  id?: string
) => {
  if (!memberSubscription) {
    return 0;
  }

  if (!id) {
    return 0;
  }

  return (
    (memberSubscription[id] && memberSubscription[id].nextPaymentDate) || 0
  );
};

export const mapValidProductId = (productId?: string | null): string | null => {
  if (!productId) return null;

  if (
    productId?.toLocaleLowerCase() === "none" ||
    productId?.toLocaleLowerCase() === "undefined" ||
    productId?.toLocaleLowerCase() === "null"
  ) {
    return null;
  }

  return String(productId);
};
