import { useEffect, useState } from "react";
import { AppTable } from "../../components/core/AppTable/AppTable";
import { StyledPageContainer } from "../../components/shared/StyledComponents/StyledPageContainer";
import { SetupPaymentCard } from "./cards/SetupPaymentCard/SetupPaymentCard";
import { useFirebaseStreamPayments } from "./useFirebaseStreamPayments";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../redux/client/clientSlice";
import { PageAddButton } from "../../components/shared/PageAddButton/PageAddButton";
import styled from "styled-components";
import { SubscriptionFormModal } from "./SubscriptionFormModal/SubscriptionFormModal";
import { useAtom } from "jotai";
import { SUBSCRIPTION_ATOMS } from "./SubscriptionFormModal/SubscriptionFormModal.Atom";
import { StripeDashboardCard } from "./cards/StripeDashboardCard/StripeDashboardCard";
import { StyledCardsContainer } from "../../components/shared/StyledComponents/StyledCardsContainer";
import { AppSpinner } from "../../components/core/AppSpinner/AppSpinner";
import { DeletePaymentProductModal } from "./DeletePaymentProductModal/DeletePaymentProductModal";
import { PAYMENTS_ATOMS } from "./PaymentsPage.Atoms";

const columns = [
  {
    cell: "Subscription Name",
  },
  {
    cell: "Price",
  },
  {
    cell: "Frequency",
  },
  {
    cell: "Users",
  },
  {
    cell: "",
  },
  {
    cell: <div style={{ display: "flex", justifyContent: "end" }}>Actions</div>,
  },
];

const StyledPageAddButton = styled.div({
  position: "absolute",
  top: -160,
  right: -23,
  width: "100%",
  "@media only screen and (min-width: 1200px)": {
    top: -465,
    right: -40,
  },
});

const StyledTableContainer = styled.div({
  position: "relative",
  marginTop: 60,
  "@media only screen and (min-width: 768px)": {
    marginTop: 45,
  },
});

export const PaymentsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const client = useSelector(getClientSelector);
  const rows: any[] = useFirebaseStreamPayments(String(client?.clientId));
  const [isModalOpen, setIsModalOpen] = useAtom(SUBSCRIPTION_ATOMS.isModalOpen);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useAtom(
    PAYMENTS_ATOMS.isDeleteModalOpen
  );
  const isActiveAccount = client.paymentAccountStatus === "active";

  useEffect(() => {
    rows && setIsLoading(false);
  }, [rows]);

  if (isLoading) {
    return (
      <StyledPageContainer>
        <AppSpinner />
      </StyledPageContainer>
    );
  }

  return (
    <StyledPageContainer>
      <StyledCardsContainer style={{ position: "relative", marginBottom: 30 }}>
        <SetupPaymentCard />
        {isActiveAccount && <StripeDashboardCard />}
      </StyledCardsContainer>

      {isActiveAccount && (
        <StyledTableContainer>
          <StyledPageAddButton>
            <PageAddButton
              text={"Add subscription"}
              onClick={() => setIsModalOpen(true)}
            />
          </StyledPageAddButton>

          <AppTable
            columns={columns}
            rows={rows}
            showPagination={false}
            showTotalCount={false}
            showSearchInput={false}
          />
        </StyledTableContainer>
      )}

      {isModalOpen && <SubscriptionFormModal />}
      {isDeleteModalOpen && <DeletePaymentProductModal />}
    </StyledPageContainer>
  );
};
