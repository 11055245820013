import styled from "styled-components";

export const StyledGroupsTabContainer = styled.div({
  width: "100%",
  marginTop: -50,
  "@media only screen and (min-width: 1200px)": {
    width: 650,
    marginTop: 0,
  },
});

export const StyledMemberTreeContainer = styled.div({
  height: 280,
  overflow: "auto",
});
