import { Form } from "formik";
import styled from "styled-components";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import { APP_COMMON_COLORS } from "../../../../../../providers/AppThemeProvider";

export const StyleForm = styled(Form)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "45vh",
  "@media only screen and (min-width: 1200px)": {
    height: "38vh",
  },
});

export const StyleInputs = styled.div({
  overflowY: "auto",
  paddingTop: 8,
  paddingLeft: 18,
  paddingRight: 15,
  marginBottom: 20,
  minHeight: 280,
});
