import styled from "styled-components";

export const StyledTemplatesPageTools = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const StyledFirstHeader = styled.div({
  "@media only screen and (min-width: 1200px)": {
    paddingRight: 420,
  },
});
