import { useState } from "react";
import { useAtom } from "jotai";
import { GROUP_MODAL_ATOMS } from "../../GroupFormModal/GroupFormModal.Atoms";
import { StyledEdit, StyledMainSubGroupName } from "../SubGroupsTree.styles";
import { AddIconWithLabel } from "../../../../components/shared/AddIconWithLabel/AddIconWithLabel";
import { Node, TreeNodeProps } from "../SubGroupsTree.types";
import { RecycleBinIcon } from "../../../../assets/icons/RecycleBinIcon";
import { Divider } from "@mui/material";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";
import {
  DELETE_SUB_GROUP_DTO,
  SUB_GROUPS,
} from "../../../../types/groups/groups.types";
import { useTheme } from "styled-components";
import { mapNestedTreeToFlatTree } from "../SubGroupsTree.helpers";
import { GROUPS_PAGE_ATOMS } from "../../GroupsPage.Atoms";
import { firebaseHttpsCallable } from "../../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../../constants/firebase.constants";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { toast } from "react-toastify";
import {
  APP_TOASTIFY,
  DISABLED_ELEMENT_STYLES,
} from "../../../../styles/styles.constants";
import { GENERAL_REPONSE } from "../../../../types/firbase.types";
import { compareAlphanumeric } from "../../../../helpers/compareAlphanumeric";

const TreeNode = (props: TreeNodeProps) => {
  const [isEditingNameInput, setIsEditingNameInput] = useState(true);
  const [nodeName, setNodeName] = useState(props.node.subGroupName);
  const [groupName, setGroupName] = useAtom(GROUP_MODAL_ATOMS.groupName);
  const [groupId, setGroupId] = useAtom(GROUP_MODAL_ATOMS.groupId);
  const [isEditMode, setIsEditMode] = useAtom(GROUP_MODAL_ATOMS.isEditMode);
  const [groupMembersCount, setGroupMembersCount] = useAtom(
    GROUPS_PAGE_ATOMS.groupMembersCount
  );

  const theme = useTheme();

  const handleAddNode = () => {
    props.addNode?.(props.node.subGroupId, "");
  };

  const handleEditNode = () => {
    setIsEditingNameInput(false);
    props.editNode?.(props.node.subGroupId, nodeName || "(no name)");
    props.onChange?.();
  };

  const canDeleteNode = () => {
    if (props.node.subGroupId?.startsWith("group")) {
      return false;
    }
    if (props.node.children?.length > 0) {
      return false;
    }
    if (!isEditMode) {
      return true;
    }
    const hasNoChildren: boolean =
      !groupMembersCount?.childrenIds[props.node.subGroupId]?.length;
    const hasNoMembers: boolean =
      !groupMembersCount?.membersIds[props.node.subGroupId]?.length;
    return hasNoChildren && hasNoMembers;
  };

  return (
    <div style={{ margin: "12px 0 12px 0px" }}>
      <div style={{ display: "flex" }}>
        {props.node.subGroupId?.startsWith("group") ? (
          <StyledMainSubGroupName>{groupName}</StyledMainSubGroupName>
        ) : (
          <>
            {isEditingNameInput ? (
              <>
                <div style={{ marginRight: 15 }}>{"—".repeat(props.depth)}</div>
                <input
                  type="text"
                  value={nodeName}
                  onChange={(e) => {
                    setNodeName(e.target.value);
                  }}
                  onBlur={handleEditNode}
                  onKeyDown={(e) => e.key === "Enter" && handleEditNode()}
                  autoFocus
                />
              </>
            ) : (
              <span
                style={{ display: "flex" }}
                onDoubleClick={() => setIsEditingNameInput(true)}
              >
                <div style={{ display: "flex", gap: 5, marginRight: 15 }}>
                  <span style={{ color: APP_COMMON_COLORS.dark[300] }}>
                    {"—".repeat(props.depth)}
                  </span>
                  <span>{props.node.subGroupName}</span>
                </div>
                <StyledEdit onClick={() => setIsEditingNameInput(true)} />
                <Divider
                  orientation="vertical"
                  style={{ height: 22, marginLeft: 10 }}
                />
              </span>
            )}
          </>
        )}

        <AddIconWithLabel
          $customstyles={{ marginBottom: 0, marginLeft: 5 }}
          onClick={handleAddNode}
        />

        {canDeleteNode() && (
          <>
            <Divider
              orientation="vertical"
              style={{ height: 22, marginLeft: 5, marginRight: 8 }}
            />
            <RecycleBinIcon
              onClick={() =>
                props.deleteNode?.(props.node.subGroupId, props.node)
              }
            />
          </>
        )}
      </div>

      {props.node.children && (
        <div>
          {props.node.children
            .sort((a, b) => compareAlphanumeric(a.subGroupName, b.subGroupName))
            .map((child) => {
              if (child.subGroupId === props.node.subGroupId) {
                return null;
              }
              return (
                <TreeNode
                  key={child.subGroupId}
                  node={child}
                  addNode={props.addNode}
                  editNode={props.editNode}
                  deleteNode={props.deleteNode}
                  depth={props.depth + 1}
                  onChange={props.onChange}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export const SubGroupsTree = () => {
  const [tree, setTree] = useAtom(GROUP_MODAL_ATOMS.tree);
  const [groupName, setGroupName] = useAtom(GROUP_MODAL_ATOMS.groupName);
  const [groupId, setGroupId] = useAtom(GROUP_MODAL_ATOMS.groupId);
  const [newGroupData, setNewGroupData] = useAtom(
    GROUP_MODAL_ATOMS.newGroupData
  );
  const [isEditMode, setIsEditMode] = useAtom(GROUP_MODAL_ATOMS.isEditMode);
  const client = useSelector(getClientSelector);
  const [isDeleting, setIsDeleting] = useState(false);

  const addNode = (parentId: string, subGroupName: string) => {
    const newTree = [...tree];
    const addNodeToTree = (nodes: Node[]) => {
      nodes.forEach((node) => {
        if (node.subGroupId === parentId) {
          node.children.push({
            subGroupId: `subGroup_${Date.now()}`,
            subGroupName,
            parentId,
            groupId,
            children: [],
          });
        } else {
          addNodeToTree(node.children);
        }
      });
    };
    addNodeToTree(newTree);
    setTree(newTree);
  };

  const editNode = (subGroupId: string, newName: string) => {
    const newTree = [...tree];
    const editNodeInTree = (nodes: Node[]) => {
      nodes.forEach((node) => {
        if (node.subGroupId === subGroupId) {
          node.subGroupName = newName;
        } else {
          editNodeInTree(node.children);
        }
      });
    };
    editNodeInTree(newTree);
    setTree(newTree);
  };

  const handleDeleteNodeFromTree = (subGroupId: string) => {
    const newTree = tree.filter((node: Node) => node.subGroupId !== subGroupId);
    const deleteNodeFromTree = (nodes: Node[]) => {
      nodes.forEach((node) => {
        node.children = node.children.filter(
          (child) => child.subGroupId !== subGroupId
        );
        deleteNodeFromTree(node.children);
      });
    };
    deleteNodeFromTree(newTree);
    setTree(newTree);
    saveTree();
  };

  const deleteNode = async (subGroupId: string, currentNode: Node) => {
    setIsDeleting(true);
    const payload: DELETE_SUB_GROUP_DTO = {
      clientId: String(client.clientId),
      groupId,
      subGroupId,
    };
    if (isEditMode && currentNode?.children?.length < 1) {
      firebaseHttpsCallable(FIRESTORE_FUNCTIONS.deleteSubGroup)({
        ...payload,
      })
        .then((res) => {
          const data = res.data as GENERAL_REPONSE;
          if (data.result === "ok") {
            handleDeleteNodeFromTree(subGroupId);
          } else {
            toast("Error while deleting sub group [1]", APP_TOASTIFY.ERROR);
          }
        })
        .catch((e) => {
          toast("Error while deleting sub group [2]", APP_TOASTIFY.ERROR);
        })
        .finally(() => {
          setIsDeleting(false);
        });
      return;
    } else {
      handleDeleteNodeFromTree(subGroupId);
      setIsDeleting(false);
    }
  };

  const saveTree = () => {
    const flatTree = mapNestedTreeToFlatTree(tree, groupId);
    const savedTree = {
      createdAt: Date.now(),
      groupId,
      groupName,
      subGroups: flatTree,
    };
    setNewGroupData(savedTree);
  };

  const buildTree = (flatNodes: SUB_GROUPS, parentId?: string) => {
    let nodes: Node[] = [];
    Object.keys(flatNodes).forEach((key) => {
      const node = flatNodes[key];
      if (node.parentId === parentId) {
        nodes.push({
          subGroupId: node.subGroupId,
          subGroupName: node.subGroupName,
          parentId: node.parentId,
          groupId,
          children: buildTree(flatNodes, node.subGroupId),
        });
      }
    });
    return nodes;
  };

  const loadNewTree = () => {
    setTree([
      {
        subGroupId: groupId,
        subGroupName: groupName,
        parentId: groupId,
        groupId,
        children: [],
      },
    ]);
  };

  return (
    <div style={{ ...(isDeleting && DISABLED_ELEMENT_STYLES) }}>
      {groupName && tree?.length < 1 && (
        <AddIconWithLabel label="Expand subgroups" onClick={loadNewTree} />
      )}

      {tree.map((node, index) => {
        return (
          <TreeNode
            key={`${node.subGroupId}${index}`}
            node={node}
            addNode={addNode}
            editNode={editNode}
            deleteNode={deleteNode}
            depth={0}
            onChange={saveTree}
          />
        );
      })}
    </div>
  );
};
