import styled from "styled-components";

export const StyledAddAndCancelContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 25,
  alignItems: "center",
  width: "100%",
  "@media only screen and (min-width: 1200px)": {
    flexDirection: "row",
    gap: 40,
  },
});

export const StyledCancel = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 5,
  color: theme.colorPrimary,
  cursor: "pointer",
  fontWeight: 600,
  "&:hover": {
    textDecoration: "underline",
  },
}));
