import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { APP_ROUTES } from "../../constants/routes.constant";
import { AppHeader } from "../core/AppHeader/AppHeader";
import { DashboardPage } from "../../pages/DashboardPage/DashboardPage";
import { StyledAppLoggedInContainer } from "../../App.styles";
import { BrandingPage } from "../../pages/BrandingPage/BrandingPage";
import { MembersPage } from "../../pages/MembersPage/MembersPage";
import { TemplatesPage } from "../../pages/TemplatesPage/TemplatesPage";
import { getClientSelector } from "../../redux/client/clientSlice";
import { RolesPage } from "../../pages/RolesPage/RolesPage";
import { ContactsPage } from "../../pages/ContactsPage/ContactsPage";
import { PaymentsPage } from "../../pages/PaymentsPage/PaymentsPage";
import { ClubDocsPage } from "../../pages/ClubDocsPage/ClubDocsPage";
import { LocationsPage } from "../../pages/LocationsPage/LocationsPage";
import { GroupsPage } from "../../pages/GroupsPage/GroupsPage";
import { ClientSelectorPage } from "../../pages/ClientSelector/ClientSelectorPage";

export const ClientRoutes = () => {
  const client = useSelector(getClientSelector);
  const location = useLocation();
  const isClientSelectorRoute = location.pathname.includes(
    APP_ROUTES.clientSelector
  );

  if (!client?.branding?.colorPrimary) {
    return <></>;
  }

  return (
    <StyledAppLoggedInContainer isFullWidthRoute={isClientSelectorRoute}>
      {!isClientSelectorRoute && <AppHeader />}
      <Routes>
        <Route
          path={APP_ROUTES.home}
          element={<Navigate to={APP_ROUTES.dashboard} />}
        />
        <Route path={APP_ROUTES.dashboard} element={<DashboardPage />} />
        <Route path={APP_ROUTES.members} element={<MembersPage />} />
        <Route path={APP_ROUTES.roles} element={<RolesPage />} />
        <Route path={APP_ROUTES.templates} element={<TemplatesPage />} />
        <Route path={APP_ROUTES.groups} element={<GroupsPage />} />
        <Route path={APP_ROUTES.branding} element={<BrandingPage />} />
        <Route path={APP_ROUTES.contacts} element={<ContactsPage />} />
        <Route path={APP_ROUTES.payments} element={<PaymentsPage />} />
        <Route path={APP_ROUTES.clubDocs} element={<ClubDocsPage />} />
        <Route path={APP_ROUTES.locations} element={<LocationsPage />} />
        <Route
          path={APP_ROUTES.clientSelector}
          element={<ClientSelectorPage />}
        />
        <Route path={APP_ROUTES.pageNotFound} element={<DashboardPage />} />
      </Routes>
    </StyledAppLoggedInContainer>
  );
};
