import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";

export const StyledFieldsRowsContainer = styled.div({
  overflowY: "auto",
  maxHeight: 380,
  marginBottom: 30,
  // borderBottom: `1px solid ${APP_COMMON_COLORS.dark[300]}`,
  "@media only screen and (min-width: 1200px)": {
    maxHeight: 300,
  },
});

export const StyledFieldRow = styled.div({
  display: "flex",
  borderBottom: `1px dashed ${APP_COMMON_COLORS.dark[100]}`,
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: 40,
  "@media only screen and (min-width: 1200px)": {
    paddingRight: 20,
  },
});

export const StyledLabelContainer = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: APP_COMMON_COLORS.dark[700],
  fontWeight: 500,
  gap: 15,
  lineHeight: "22px",
}));

export const StyledFormAddNewField = styled.div({
  marginBlock: 30,
});

export const StyledFieldLabel = styled.span({
  "&:hover": {
    textDecoration: "underline",
    cursor: "pointer",
  },
});
