import { useState } from "react";
import { AppModalDesktopSteps } from "../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps";
import { StyledModalBackButton } from "../../../components/core/AppModal/AppModal.styles";
import { BackButtonIcon } from "../../../components/core/AppBurgerMenu/icons/BackButtonIcon";
import { StyledAppModalStepsContainer } from "../../../components/shared/StyledComponents/StyledAppModalComponents";
import { HIDDEN_ELEMENT_STYLES } from "../../../styles/styles.constants";
import { RoleNameStep, RoleNameStepValues } from "./RoleNameStep/RoleNameStep";
import { RolesPermissions } from "./RolesPermissionsStep/RolesPermissionsStep";
import { AppModalMobileSteps } from "../../../components/shared/AppModalMobileSteps/AppModalMobileSteps";
import { RoleCustomFieldStep } from "./RoleCustomFieldStep/RoleCustomFieldStep";
import { useAtom } from "jotai";
import { ROLES_ATOMS } from "../RolesPage.atoms";

const STEPPER_DESKTOP_TABS = ["Role", "Custom fields", "Permissions"];
const STEPPER_MOBILE_TITLES = ["Role name", "Custom fields", "Set permissions"];

export const CreateRoleModal = () => {
  const [modalCurrentStep, setModalCurrentStep] = useAtom(
    ROLES_ATOMS.modalCurrentStep
  );

  return (
    <StyledAppModalStepsContainer>
      <AppModalMobileSteps
        tabsLength={STEPPER_MOBILE_TITLES.length}
        tabValue={modalCurrentStep}
        titlesOfSteps={STEPPER_MOBILE_TITLES}
      />
      <AppModalDesktopSteps
        tabs={STEPPER_DESKTOP_TABS}
        tabsTitle="Create role"
        OnChangeTabChange={setModalCurrentStep}
        tabValue={modalCurrentStep}
      />
      {modalCurrentStep > 0 && (
        <StyledModalBackButton
          onClick={() => setModalCurrentStep(modalCurrentStep - 1)}
        >
          <BackButtonIcon />
        </StyledModalBackButton>
      )}

      <div style={{ ...(modalCurrentStep !== 0 && HIDDEN_ELEMENT_STYLES) }}>
        <RoleNameStep />
      </div>

      <div
        style={{
          width: "100%",
          ...(modalCurrentStep !== 1 && HIDDEN_ELEMENT_STYLES),
        }}
      >
        <RoleCustomFieldStep />
      </div>

      <div
        style={{
          width: "100%",
          ...(modalCurrentStep !== 2 && HIDDEN_ELEMENT_STYLES),
        }}
      >
        <RolesPermissions />
      </div>
    </StyledAppModalStepsContainer>
  );
};
