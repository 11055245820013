import { Formik, FormikErrors, FormikTouched } from "formik";
import { StyledAppModalDesktopStepTitle } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import * as Yup from "yup";
import { AppFormikField } from "../../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { AppButton } from "../../../../components/core/AppButton/AppButton";
import {
  StyleAppModalSingleStepContainer,
  StyledAppModalFooterButtonContainer,
} from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { StyledForm } from "./RoleNameStep.styles";
import { ROLES_ATOMS } from "../../RolesPage.atoms";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

export interface RoleNameStepValues {
  roleName: string;
}

export const RoleNameStep = () => {
  const roleNameStepSchema = Yup.object().shape({
    roleName: Yup.string().required("Required"),
  });

  const isEditMode = useAtomValue(ROLES_ATOMS.isEditMode);

  const [roleName, setRoleName] = useAtom(ROLES_ATOMS.roleName);
  const setModalCurrentStep = useSetAtom(ROLES_ATOMS.modalCurrentStep);

  const handleSubmit = (values: RoleNameStepValues) => {
    setRoleName(values.roleName);
    setModalCurrentStep(1);
  };

  return (
    <StyleAppModalSingleStepContainer>
      <StyledAppModalDesktopStepTitle>Role name</StyledAppModalDesktopStepTitle>

      <Formik
        enableReinitialize={true}
        initialValues={{ roleName: roleName }}
        validationSchema={roleNameStepSchema}
        onSubmit={(values: RoleNameStepValues) => {
          handleSubmit(values);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => {
          const _touched: FormikTouched<RoleNameStepValues> = touched;
          const _errors: FormikErrors<RoleNameStepValues> = errors;

          return (
            <StyledForm>
              <div>
                <AppFormikField
                  isRequired={true}
                  name="roleName"
                  value={values.roleName}
                  label="ROLE NAME"
                  errorMessage={
                    _touched.roleName && _errors.roleName
                      ? String(errors.roleName)
                      : undefined
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  containerstyle={{ marginBottom: 25 }}
                  isDisabled={isEditMode}
                />
              </div>

              <StyledAppModalFooterButtonContainer>
                <AppButton onClick={handleSubmit}>CONTINUE</AppButton>
              </StyledAppModalFooterButtonContainer>
            </StyledForm>
          );
        }}
      </Formik>
    </StyleAppModalSingleStepContainer>
  );
};
