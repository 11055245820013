import { Form } from "formik";
import styled from "styled-components";

export const StyledRoleNameStepContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  paddingInline: 20,
  "@media only screen and (min-width: 1200px)": {
    width: 600,
    padding: "78px 40px",
  },
});

export const StyledForm = styled(Form)({
  height: "58vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "@media only screen and (min-width: 1200px)": {
    height: "50vh",
  },
});
