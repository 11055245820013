import { useAtom } from "jotai";
import { PAYMENTS_ATOMS } from "../PaymentsPage.Atoms";
import { DeleteModal } from "../../../components/shared/DeleteModal/DeleteModal";
import { useEffect, useState } from "react";
import { PaymentsIcon } from "../../../components/core/AppBurgerMenu/icons/PaymentsIcon";
import {
  AppFormikSelect,
  SelectOption,
} from "../../../components/core/AppFormik/AppFormikSelect/AppFormikSelect";
import { getFirebaseDataByColKeyVal } from "../../../hooks/firebase/getFirebaseDataByColKeyVal";
import {
  COLLECTIONS_TYPE,
  FIRESTORE_FUNCTIONS,
} from "../../../constants/firebase.constants";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../redux/client/clientSlice";
import {
  DELETE_SUBSCRIPTION_PRODUCT_DTO,
  PAYMENT_DATA,
  SUBSCRIPTION_DATA,
} from "../../../types/payments/payments.types";
import { getFirebaseDataByColKeyValKeyVal } from "../../../hooks/firebase/getFirebaseDataByColKeyValKeyVal";
import { firebaseHttpsCallable } from "../../../hooks/firebase/firebase.helper";
import { GENERAL_REPONSE } from "../../../types/firbase.types";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../../styles/styles.constants";

export const DeletePaymentProductModal = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useAtom(
    PAYMENTS_ATOMS.isDeleteModalOpen
  );
  const [selectedProductRow, setSelectedProductRow] = useAtom(
    PAYMENTS_ATOMS.selectedProductRow
  );
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [clientProducts, setClientProducts] = useState(false);
  const client = useSelector(getClientSelector);
  const [hideProceedBtn, setHideProceedBtn] = useState<boolean>(true);
  const [hideProductSelect, setHideProductSelect] = useState<boolean>(true);
  const [productOptions, setProductOptions] = useState<SelectOption[] | []>([]);
  const [newProductId, setNewProductId] = useState<string>("");
  const [subscriptionsUnderProduct, setSubscriptionsUnderProduct] = useState<
    SUBSCRIPTION_DATA[] | undefined
  >(undefined);

  const handleResetDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedProductRow(undefined);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 100);
  };

  const onDeleteProduct = () => {
    setIsDeleteLoading(true);
    const payload: DELETE_SUBSCRIPTION_PRODUCT_DTO = {
      clientId: String(client?.clientId),
      productId: String(selectedProductRow && selectedProductRow.productId),
    };
    if (newProductId) {
      payload.newSubscriptionsUsersProductId = newProductId;
    }
    if (subscriptionsUnderProduct && subscriptionsUnderProduct.length > 0) {
      payload.currentSubscriptionsUsersIds = subscriptionsUnderProduct;
    }
    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.deleteSubscriptionProduct)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_REPONSE;
        if (data.result === "ok") {
          toast("Product deleted", APP_TOASTIFY.SUCCESS);
          handleResetDeleteModal();
        } else {
          toast("Error while deleting product [2]", APP_TOASTIFY.ERROR);
        }
      })
      .catch((e) =>
        toast("Error while deleting product [3]", APP_TOASTIFY.ERROR)
      )
      .finally(() => setIsDeleteLoading(false));
  };

  useEffect(() => {
    getFirebaseDataByColKeyVal(
      COLLECTIONS_TYPE.products,
      "clientId",
      String(client?.clientId)
    ).then((response: PAYMENT_DATA) => {
      const products = Object.values(response || {});
      const productOptions = products.map((product: PAYMENT_DATA) => {
        return {
          value: product.productId,
          label: product.name,
        };
      });

      const removedCurrentProduct = productOptions.filter(
        (productOption) => productOption.value !== selectedProductRow?.productId
      );
      setProductOptions(removedCurrentProduct);
    });
  }, [isDeleteModalOpen]);

  useEffect(() => {
    getFirebaseDataByColKeyValKeyVal(
      COLLECTIONS_TYPE.subscriptions,
      `clientId`,
      String(client?.clientId),
      "productId",
      String(selectedProductRow?.productId)
    ).then((response: { [key: string]: SUBSCRIPTION_DATA }) => {
      const _subscriptionsUnderProduct = Object.values(response || {});

      if (_subscriptionsUnderProduct.length > 0) {
        setHideProceedBtn(true);
        setHideProductSelect(false);
      } else {
        setHideProceedBtn(false);
        setHideProductSelect(true);
      }

      setSubscriptionsUnderProduct(_subscriptionsUnderProduct);
    });
  }, [isDeleteModalOpen]);

  if (!isDeleteModalOpen) {
    return <></>;
  }

  return (
    <DeleteModal
      isOpen={isDeleteModalOpen}
      title={"Delete Subscription"}
      deleteBtnLabel={"DELETE SUBSCRIPTION"}
      onDeleteButton={onDeleteProduct}
      onCloseDeleteModal={() => {
        setIsDeleteLoading(false);
        setSelectedProductRow(undefined);
        setIsDeleteModalOpen(false);
      }}
      deleteContent={{
        title: "YES, DELETE THIS SUBSCRIPTION",
        subTitle: String(selectedProductRow?.name),
        icon: <PaymentsIcon isActive={false} />,
      }}
      isLoading={isDeleteLoading}
      hideProceedBtn={hideProceedBtn}
    >
      {subscriptionsUnderProduct &&
      subscriptionsUnderProduct?.length > 0 &&
      productOptions?.length === 0 ? (
        <div>
          This action can not be completed because you have{" "}
          {subscriptionsUnderProduct?.length} subscription
          {subscriptionsUnderProduct?.length > 1 && "s"} on this subscription.
          Please add another product from payments page to and move current
          members to another subscription to delete this one.
        </div>
      ) : (
        !hideProductSelect && (
          <div>
            <div style={{ marginBottom: 20 }}>
              You have {subscriptionsUnderProduct?.length}{" "}
              {` user${
                subscriptionsUnderProduct &&
                subscriptionsUnderProduct?.length > 1
                  ? "s"
                  : ""
              } `}
              on this subscription. Please move them to another subscription to
              proceed deleting this subscription
            </div>
            <AppFormikSelect
              label={"Select another subscription"}
              options={productOptions}
              value={newProductId}
              onChange={(e) => {
                setHideProceedBtn(false);
                setNewProductId(e.value);
              }}
            />
          </div>
        )
      )}
    </DeleteModal>
  );
};
