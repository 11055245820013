import { atom } from "jotai";
import { LOCATION_DATA } from "../../../types/locations/locations.types";

export const initialLocationFormData = {
  title: "",
  lat: 0,
  lng: 0,
  locationDetails: "",
  addressLineOne: "",
  postCode: "",
};
export const LOCATION_MODAL_ATOMS = {
  isEditMode: atom(false),
  isOpen: atom(false),
  currentStep: atom(0),
  locationFormData: atom(initialLocationFormData),
  isPublished: atom(false),
  isDeleteModalOpen: atom(false),
  deletingLocationData: atom<LOCATION_DATA | null>(null),
};
