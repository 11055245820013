import styled from "styled-components";
import { AppModal } from "../../../components/core/AppModal/AppModal";
import * as Yup from "yup";
import { Formik, Form, FormikTouched, FormikErrors } from "formik";
import { AppFormikField } from "../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { AppButton } from "../../../components/core/AppButton/AppButton";
import { StyledAppModalDesktopStepTitle } from "../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { useEffect, useState } from "react";
import { firebaseHttpsCallable } from "../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../constants/firebase.constants";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../../styles/styles.constants";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../types/global";
import {
  getAddEditClientModalData,
  setAddEditClientModalData,
} from "../../../redux/client/clientSlice";
import {
  ADD_EDIT_CLIENT_MODAL,
  ADD_EDIT_CLIENT_MODAL_MODE,
  CLIENT_STATE,
  EDIT_CLIENT_DTO,
} from "../../../types/client/client.types";
import { emailRegex } from "../../../helpers/emailRegex";
import { StyledAppModalSubmitButtonContainer } from "../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppModalMobileSteps } from "../../../components/shared/AppModalMobileSteps/AppModalMobileSteps";

export const StyledAddEditClientContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  "@media only screen and (min-width: 1200px)": {
    width: 600,
  },
});

export const StyledAddEditTitle = styled.div({
  display: "flex",
  flexDirection: "column",
});
export const StyledForm = styled(Form)({
  height: "58vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "@media only screen and (min-width: 1200px)": {
    height: "50vh",
  },
});
export const StyledFormInputs = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 10,
});

const StyledAddEditClientModalContainer = styled.div({
  display: "flex",
  justifyContent: "center",
});

const clientSchema = Yup.object({
  clientName: Yup.string()
    .required("Required")
    .max(255, "Maximum length is 255"),
  clientAdmin: Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Required")
      .min(5, "Min length is 5")
      .max(255, "Max Length is 255"),
  }),
});

export const AddEditClientModal = () => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState<CLIENT_STATE>(
    {} as CLIENT_STATE
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const modalData = useSelector((state: GlobalState) =>
    getAddEditClientModalData(state)
  ) as ADD_EDIT_CLIENT_MODAL;

  const editClientMode: boolean = Boolean(
    modalData?.mode === ADD_EDIT_CLIENT_MODAL_MODE.EDIT_CLIENT
  );
  const addClientMode: boolean = Boolean(
    modalData?.mode === ADD_EDIT_CLIENT_MODAL_MODE.ADD_CLIENT
  );

  const handleOnSubmit = async (values: CLIENT_STATE) => {
    setIsLoading(true);

    if (addClientMode) {
      const requestBody = {
        clientName: values.clientName,
        clientAdmin: {
          firstName: values.clientAdmin.firstName,
          lastName: values.clientAdmin.lastName,
          email: values.clientAdmin.email,
        },
      } as CLIENT_STATE;
      AddClientSubmission(requestBody);
    }
    if (editClientMode) {
      const requestBody: EDIT_CLIENT_DTO = {
        clientName: String(values.clientName),
        clientId: String(values.clientId),
        clientAdmin: {
          firstName: values.clientAdmin.firstName,
          lastName: values.clientAdmin.lastName,
        },
      };
      EditClientSubmission(requestBody);
    }
  };

  const EditClientSubmission = async (requestBody: EDIT_CLIENT_DTO) => {
    setIsLoading(true);

    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.editClientData)(requestBody)
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          toast("Client's data edited successfully", APP_TOASTIFY.SUCCESS);
          dispatch(setAddEditClientModalData(null));
        } else {
          toast("Something went wrong [edit client-1]", APP_TOASTIFY.ERROR);
        }
      })
      .catch((err: any) => {
        console.log({ err });
        toast("Something went wrong [edit client-2]", APP_TOASTIFY.ERROR);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const AddClientSubmission = async (requestBody: CLIENT_STATE) => {
    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.addClient)(requestBody)
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          toast("Success", APP_TOASTIFY.SUCCESS);
          dispatch(setAddEditClientModalData(null));
        } else {
          toast("Something went wrong [4]", APP_TOASTIFY.ERROR);
        }
      })
      .catch((err: any) => {
        console.log({ err });
        toast("Something went wrong [5]", APP_TOASTIFY.ERROR);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = (e?: any, reason?: any) => {
    if (reason === "backdropClick") return;
    dispatch(setAddEditClientModalData(null));
  };

  useEffect(() => {
    if (modalData?.clientData) {
      setInitialValues({ ...modalData?.clientData });
    } else {
      setInitialValues({} as CLIENT_STATE);
    }
  }, [modalData?.isOpen]);

  return (
    <AppModal isOpen={Boolean(modalData?.isOpen)} closeModal={handleClose}>
      <AppModalMobileSteps
        tabValue={0}
        titlesOfSteps={["Add Client"]}
        tabsLength={1}
      />
      <StyledAddEditClientModalContainer>
        <StyledAddEditClientContainer>
          <StyledAppModalDesktopStepTitle>
            {addClientMode ? "Add Client" : "Edit Client"}
          </StyledAppModalDesktopStepTitle>

          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={clientSchema}
            onSubmit={(values: CLIENT_STATE) => handleOnSubmit(values)}
          >
            {({
              errors,
              touched,
              values,
              submitCount,
              handleSubmit,
              handleChange,
              handleBlur,
              getFieldProps,
            }) => {
              const _touched: FormikTouched<CLIENT_STATE> = touched;
              const _errors: FormikErrors<CLIENT_STATE> = errors;

              return (
                <StyledForm>
                  <StyledFormInputs>
                    <AppFormikField
                      name="clientName"
                      isRequired={true}
                      value={values.clientName || ""}
                      label="CLIENT NAME/IDENTITY"
                      errorMessage={
                        (_touched.clientName && _errors.clientName) ||
                        (submitCount > 0 && errors.clientName)
                          ? String(errors.clientName)
                          : ""
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                    />
                    <AppFormikField
                      name="clientAdmin.firstName"
                      isRequired={true}
                      value={getFieldProps("clientAdmin.firstName").value || ""}
                      label="ADMIN FIRST NAME"
                      errorMessage={
                        (_touched.clientAdmin?.firstName &&
                          _errors.clientAdmin?.firstName) ||
                        (submitCount > 0 && errors.clientAdmin?.firstName)
                          ? String(errors.clientAdmin?.firstName)
                          : ""
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                    />
                    <AppFormikField
                      name="clientAdmin.lastName"
                      isRequired={true}
                      value={getFieldProps("clientAdmin.lastName").value || ""}
                      label="ADMIN LAST NAME"
                      errorMessage={
                        (_touched.clientAdmin?.lastName &&
                          _errors.clientAdmin?.lastName) ||
                        (submitCount > 0 && errors.clientAdmin?.lastName)
                          ? String(errors.clientAdmin?.lastName)
                          : ""
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                    />
                    {addClientMode && (
                      <AppFormikField
                        name="clientAdmin.email"
                        isRequired={true}
                        value={getFieldProps("clientAdmin.email").value || ""}
                        label="ADMIN EMAIL"
                        errorMessage={
                          (_touched.clientAdmin?.email &&
                            _errors.clientAdmin?.email) ||
                          (submitCount > 0 && errors.clientAdmin?.email)
                            ? String(errors.clientAdmin?.email)
                            : ""
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        containerstyle={{ marginBottom: 30 }}
                      />
                    )}
                  </StyledFormInputs>

                  <StyledAppModalSubmitButtonContainer>
                    <AppButton isLoading={isLoading} onClick={handleSubmit}>
                      {addClientMode ? "CREATE CLIENT" : "EDIT CLIENT"}
                    </AppButton>
                  </StyledAppModalSubmitButtonContainer>
                </StyledForm>
              );
            }}
          </Formik>
        </StyledAddEditClientContainer>
      </StyledAddEditClientModalContainer>
    </AppModal>
  );
};
