import { Close } from "@mui/icons-material";
import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

export const StyledClose = styled(Close)(({ theme }) => ({
  color: theme.colorPrimary,
  cursor: "pointer",
  "&:hover": {
    color: APP_COMMON_COLORS.error.main,
  },
}));
