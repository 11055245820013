import { useTheme } from "styled-components";

export const NoLogoIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20"
        cy="20"
        r="20"
        fill={theme.colorPrimary}
        fillOpacity="0.1"
      />
      <path
        d="M29.2235 21.5491C29.1506 21.4219 29.0453 21.3162 28.9184 21.2426C28.7915 21.1691 28.6474 21.1304 28.5007 21.1304C28.354 21.1304 28.2099 21.1691 28.083 21.2426C27.9561 21.3162 27.8508 21.4219 27.7778 21.5491C26.9662 22.9649 26.0005 24.838 26.0005 25.7221C26.0005 26.3851 26.2639 27.0209 26.7327 27.4897C27.2015 27.9585 27.8373 28.2219 28.5003 28.2219C29.1633 28.2219 29.7991 27.9585 30.2679 27.4897C30.7367 27.0209 31.0001 26.3851 31.0001 25.7221C31.0001 24.8397 30.0343 22.9657 29.2235 21.5491Z"
        fill={theme.colorPrimary}
      />
      <path
        d="M26.4532 18.5441C26.5069 18.3901 26.5106 18.2231 26.4638 18.0669C26.417 17.9107 26.322 17.7732 26.1924 17.6742L19.8713 11.3531C19.6329 11.1264 19.3166 11 18.9876 11C18.6587 11 18.3423 11.1264 18.1039 11.3531L16.5008 12.9538C16.4771 12.9775 16.4481 12.9952 16.4162 13.0054C16.3843 13.0155 16.3504 13.0178 16.3174 13.0121L12.8111 12.393C12.7014 12.369 12.588 12.3675 12.4777 12.3884C12.3674 12.4094 12.2625 12.4524 12.1692 12.5149C12.076 12.5775 11.9963 12.6582 11.9351 12.7522C11.8738 12.8463 11.8321 12.9518 11.8126 13.0624C11.7931 13.1729 11.7962 13.2863 11.8216 13.3956C11.847 13.505 11.8943 13.6081 11.9605 13.6987C12.0268 13.7894 12.1106 13.8657 12.2071 13.9231C12.3036 13.9805 12.4107 14.0178 12.5219 14.0328L19.138 15.1994C19.1921 15.2095 19.2399 15.2406 19.2711 15.2859C19.3024 15.3312 19.3144 15.3869 19.3047 15.441L19.1855 16.1143C19.1754 16.1684 19.1443 16.2162 19.099 16.2474C19.0538 16.2787 18.998 16.2907 18.9439 16.281L14.1443 15.4377C14.1113 15.4319 14.0774 15.4342 14.0455 15.4444C14.0135 15.4545 13.9845 15.4722 13.961 15.496L11.6103 17.8467C11.4168 18.0401 11.2633 18.2698 11.1586 18.5226C11.0539 18.7753 11 19.0463 11 19.3199C11 19.5935 11.0539 19.8644 11.1586 20.1172C11.2633 20.37 11.4168 20.5996 11.6103 20.7931L15.5008 24.6853C15.8915 25.0758 16.4212 25.2952 16.9736 25.2952C17.526 25.2952 18.0558 25.0758 18.4464 24.6853L23.6926 19.444C24.0079 19.1702 24.4035 19.0062 24.82 18.9766H25.6533C25.8152 18.9977 25.9796 18.9673 26.1232 18.8897C26.2668 18.812 26.3823 18.6911 26.4532 18.5441Z"
        fill={theme.colorPrimary}
      />
    </svg>
  );
};
