import { useEffect } from "react";
import { _fs } from "../../services/firebase/config";
import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { useSetAtom } from "jotai";
import { BURGER_MENU_ATOMS } from "../../components/core/AppBurgerMenu/AppBurgerMenu";

export const useFirebaseStreamAppVersions = () => {
  const setVersion = useSetAtom(BURGER_MENU_ATOMS.version);

  useEffect(() => {
    const unsubscribe = _fs
      .collection(COLLECTIONS_TYPE.buildVersions)
      .doc(COLLECTIONS_TYPE.web)
      .onSnapshot(async (doc) => {
        setVersion(doc.data()?.version || "");
      });

    return () => unsubscribe();
  }, []);
};
