import { Formik, FormikErrors, FormikTouched } from "formik";
import { StyledAppModalDesktopStepTitle } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import * as Yup from "yup";
import { AppFormikField } from "../../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { AppButton } from "../../../../components/core/AppButton/AppButton";
import { StyledForm, StyledFormInputs } from "./NameAndTypeStep.styles";
import { StyledDivider } from "../../../../components/shared/StyledComponents/StyledDivider";
import { AppRadioButtonGroup } from "../../../../components/core/AppFormik/AppFormikField/AppRadioButton";
import {
  StyleAppModalSingleStepContainer,
  StyledAppModalSubmitButtonContainer,
} from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import {
  ADD_QUICK_POST_TEMPLATE_DTO,
  QUICK_POST_TEMPLATE,
  TEMPLATE_TYPE,
} from "../../../../types/templates/templates.types";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  TEMPLATES_ATOMS,
  TEMPLATE_STEPPER_TITLES,
} from "../AddEditTemplateModal.atoms";
import { firebaseHttpsCallable } from "../../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../../constants/firebase.constants";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { GENERAL_REPONSE } from "../../../../types/firbase.types";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../../../styles/styles.constants";
import { useState } from "react";
import { getUser } from "../../../../redux/user/userSlice";
import { capitalizeFirstChar } from "../../../../helpers/common";

export interface NameAndTypeStepValues {
  templateName: string;
  templateType: TEMPLATE_TYPE | undefined;
}

const nameAndTypeStepSchema = Yup.object().shape({
  templateName: Yup.string().required("Required"),
  templateType: Yup.string().required("Required"),
});

const getActionButtonLabel = (templateType: TEMPLATE_TYPE) => {
  if (templateType === TEMPLATE_TYPE.quickPost) {
    return "Publish";
  }
  return "Continue";
};

export const NameAndTypeStep: React.FC = () => {
  const [templateName, setTemplateName] = useAtom(TEMPLATES_ATOMS.templateName);
  const [templateType, setTemplateType] = useAtom(TEMPLATES_ATOMS.templateType);
  const setCurrentModalStep = useSetAtom(TEMPLATES_ATOMS.currentModalStep);
  const setIsPublished = useSetAtom(TEMPLATES_ATOMS.isPublished);
  const setTemplateStepperTitles = useSetAtom(
    TEMPLATES_ATOMS.templateStepperTitles
  );
  const isEditMode = useAtomValue(TEMPLATES_ATOMS.isEditMode);
  const templateId = useAtomValue(TEMPLATES_ATOMS.templateId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const client = useSelector(getClientSelector);
  const user = useSelector(getUser);

  const handleAddGeneralPostTemplate = async () => {
    setIsSubmitting(true);
    const payload: ADD_QUICK_POST_TEMPLATE_DTO = {
      clientId: String(client.clientId),
      templateName: capitalizeFirstChar(templateName),
      templateType: templateType as TEMPLATE_TYPE.quickPost,
      updatedBy: String(user.uid),
    };

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.addQuickPostTemplate)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_REPONSE;
        if (data.result === "ok") {
          toast("Quick post template added", APP_TOASTIFY.SUCCESS);
          setIsPublished(true);
        } else {
          toast(
            "Error while adding quick post template [1]",
            APP_TOASTIFY.ERROR
          );
        }
      })
      .catch((e) =>
        toast("Error while adding quick post template [2]", APP_TOASTIFY.ERROR)
      )
      .finally(() => setIsSubmitting(false));
  };

  const handleEditQuickPostTemplate = async () => {
    setIsSubmitting(true);
    const payload: QUICK_POST_TEMPLATE = {
      clientId: String(client.clientId),
      templateName: capitalizeFirstChar(templateName)?.trim(),
      templateType: templateType as TEMPLATE_TYPE.quickPost,
      updatedBy: String(user.uid),
      templateId: templateId,
    };

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.editQuickPostTemplate)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_REPONSE;
        if (data.result === "ok") {
          toast("Quick post template updated", APP_TOASTIFY.SUCCESS);
          setIsPublished(true);
        } else {
          toast(
            "Error while updating quick post template [1]",
            APP_TOASTIFY.ERROR
          );
        }
      })
      .catch((e) =>
        toast(
          "Error while updating quick post template [2]",
          APP_TOASTIFY.ERROR
        )
      )
      .finally(() => setIsSubmitting(false));
  };

  const handleOnSubmit = (values: NameAndTypeStepValues) => {
    if (
      templateType === TEMPLATE_TYPE.event ||
      templateType === TEMPLATE_TYPE.performancePost ||
      templateType === TEMPLATE_TYPE.formPost
    ) {
      setCurrentModalStep(1);
      return;
    }

    if (templateType === TEMPLATE_TYPE.quickPost) {
      if (isEditMode) {
        handleEditQuickPostTemplate();
      } else {
        handleAddGeneralPostTemplate();
      }
      return;
    }
  };

  return (
    <StyleAppModalSingleStepContainer>
      <StyledAppModalDesktopStepTitle style={{ marginBottom: 60 }}>
        Template type & name
      </StyledAppModalDesktopStepTitle>

      <Formik
        enableReinitialize={true}
        initialValues={{
          templateName: templateName,
          templateType: templateType,
        }}
        validationSchema={nameAndTypeStepSchema}
        onSubmit={(values: NameAndTypeStepValues) => {
          handleOnSubmit(values);
        }}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          submitCount,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => {
          const _touched: FormikTouched<NameAndTypeStepValues> = touched;
          const _errors: FormikErrors<NameAndTypeStepValues> = errors;

          return (
            <StyledForm>
              <StyledFormInputs>
                <StyledDivider />
                <AppRadioButtonGroup
                  options={[
                    { label: "Event", value: TEMPLATE_TYPE.event },
                    {
                      label: "Performance Post",
                      value: TEMPLATE_TYPE.performancePost,
                    },
                    { label: "Quick Post", value: TEMPLATE_TYPE.quickPost },
                    {
                      label: "Form Post",
                      value: TEMPLATE_TYPE.formPost,
                    },
                  ]}
                  value={values.templateType}
                  onChangeValue={(e) => {
                    setFieldValue("templateType", e);
                    setTemplateType(e);
                    setTemplateStepperTitles(TEMPLATE_STEPPER_TITLES[e]);
                  }}
                  errorMessage={
                    submitCount > 0 && _errors.templateType
                      ? String(errors.templateType)
                      : undefined
                  }
                  customContainerStyles={{ marginBottom: 30 }}
                  isDisabled={isEditMode}
                />

                <AppFormikField
                  isRequired={true}
                  name="templateName"
                  value={values.templateName}
                  label="TEMPLATE NAME"
                  errorMessage={
                    _touched.templateName && _errors.templateName
                      ? String(errors.templateName)
                      : undefined
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("templateName", e.target.value);
                    setTemplateName(e.target.value);
                  }}
                  type="text"
                  containerstyle={{ marginBottom: 25 }}
                />
              </StyledFormInputs>

              <StyledAppModalSubmitButtonContainer>
                <AppButton isLoading={isSubmitting} onClick={handleSubmit}>
                  {getActionButtonLabel(templateType as TEMPLATE_TYPE)}
                </AppButton>
              </StyledAppModalSubmitButtonContainer>
            </StyledForm>
          );
        }}
      </Formik>
    </StyleAppModalSingleStepContainer>
  );
};
