import React from "react";
import {
  InputAdornment,
  IconButton,
  Typography,
  useTheme as useMuiTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, Form, FormikTouched, FormikErrors } from "formik";
import * as Yup from "yup";
import styled, { useTheme } from "styled-components";
import { AppFormikField } from "../../components/core/AppFormik/AppFormikField/AppFormikField";
import { useAutoFill } from "../../hooks/useAutoFill/useAutoFill";
import { Link, useNavigate } from "react-router-dom";
import { AppButton } from "../../components/core/AppButton/AppButton";
import { toast } from "react-toastify";
import { VisibilityOffIcon } from "../../assets/icons/VisibilityOffIcon";
import { VisibilityOnIcon } from "../../assets/icons/VisibilityOnIcon";
import { AppCheckBox } from "../../components/core/AppCheckBox/AppCheckBox";
import { APP_TOASTIFY } from "../../styles/styles.constants";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { _fs } from "../../services/firebase/config";
import { APP_ROUTES } from "../../constants/routes.constant";
import { APP_COMMON_COLORS } from "../../providers/AppThemeProvider";
import { emailRegex } from "../../helpers/emailRegex";
import { AuthLayout } from "../../components/shared/AuthLayout/AuthLayout";

const StyledForm = styled(Form)({
  width: "100%",
  "@media (min-width: 1200px)": {
    width: 400,
  },
});

const StyledSignInBtnContainer = styled.div({
  marginTop: 75,
  "@media (min-width: 1200px)": {
    marginTop: 30,
  },
});

const schema = Yup.object({
  email: Yup.string()
    .matches(emailRegex, "Invalid email address")
    .required("Required")
    .min(5, "Minimum length is 5")
    .max(255, "Maximum length is 255"),
  password: Yup.string()
    .min(8, "Must be 8 characters or more")
    .required("Required"),
});

const signIn = async (values: { email: string; password: string }) => {
  const auth = getAuth(_fs.app);
  const userCredential = await signInWithEmailAndPassword(
    auth,
    values.email?.trim(),
    values.password
  );
  const user = userCredential.user;
  return user;
};

export const SignInPage: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAutoFill, autofillInputProps } = useAutoFill();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const isSmallScreen = useMediaQuery(useMuiTheme().breakpoints.down("lg"));

  return (
    <AuthLayout
      title="Welcome To"
      subtitle="YourZown"
      iconSubtitle="Sign in"
      children={
        <Formik
          enableReinitialize={true}
          initialValues={{ email: "", password: "" }}
          validationSchema={schema}
          onSubmit={async (values: { email: string; password: string }) => {
            try {
              const user = await signIn(values);
              if (user) {
                navigate(APP_ROUTES.dashboard);
              }
            } catch (error) {
              toast("Invalid Credentials", APP_TOASTIFY.ERROR);
            }
          }}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            isValid,
            dirty,
          }) => {
            const _touched: FormikTouched<{
              email: string;
              password: string;
            }> = touched;

            const _errors: FormikErrors<{ email: string; password: string }> =
              errors;

            return (
              <StyledForm>
                <AppFormikField
                  dataTestId="email-address"
                  name="email"
                  value={values.email}
                  label="EMAIL ADDRESS"
                  InputLabelProps={{ shrink: isAutoFill || undefined }}
                  InputProps={autofillInputProps}
                  errorMessage={
                    _touched.email && _errors.email
                      ? String(errors.email)
                      : undefined
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  sx={{
                    marginTop: 3.375,
                  }}
                />

                <AppFormikField
                  dataTestId="password"
                  name="password"
                  value={values.password}
                  label="PASSWORD"
                  errorMessage={
                    _touched.password && _errors.password
                      ? String(errors.password)
                      : undefined
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  InputLabelProps={{ shrink: isAutoFill || undefined }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                          sx={{
                            marginRight: "2px",
                          }}
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityOnIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginTop: 2,
                  }}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={"space-between"}
                  width="100%"
                  marginTop={1.25}
                  marginBottom={2}
                >
                  <Box display="flex" alignItems="center">
                    <AppCheckBox
                      sx={{
                        width: 24,
                        height: 24,
                        marginRight: 1,
                      }}
                      size="medium"
                    />
                    <Typography
                      fontWeight={300}
                      color={theme.dark[400]}
                      variant="body2"
                    >
                      Stay signed in
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Link
                      style={{ color: theme.dark[700] }}
                      to={APP_ROUTES.forgetPassword}
                    >
                      <Typography
                        color={theme.dark[700]}
                        fontWeight={400}
                        variant="body2"
                      >
                        Forgot password
                      </Typography>
                    </Link>
                  </Box>
                </Box>
                <StyledSignInBtnContainer></StyledSignInBtnContainer>
                <AppButton
                  dataTestId="submit-button"
                  isLoading={isSubmitting}
                  type="submit"
                  customStyles={{
                    background: APP_COMMON_COLORS.yourzown.colorPrimary,
                    borderColor: APP_COMMON_COLORS.yourzown.colorPrimary,
                    "&:hover": {
                      background: APP_COMMON_COLORS.yourzown.colorPrimary,
                      border: `1px solid ${theme.white}`,
                    },
                  }}
                >
                  <Typography
                    color={theme.common.white}
                    fontWeight={600}
                    variant="body1"
                  >
                    SIGN IN
                  </Typography>
                </AppButton>
              </StyledForm>
            );
          }}
        </Formik>
      }
    />
  );
};
