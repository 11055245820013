import { atom } from "jotai";
import { Node } from "../SubGroupsTree/SubGroupsTree.types";
import { GROUP } from "../../../types/groups/groups.types";

export const GROUP_MODAL_ATOMS = {
  isEditMode: atom(false),
  isOpen: atom(false),
  groupName: atom(""),
  groupId: atom(""),
  newGroupData: atom<GROUP | undefined>(undefined),
  tree: atom<Node[]>([]),
  isPublished: atom(false),
};
