import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

export const StyledAppTopTagsContainer = styled.div({
  display: "flex",
  justifyContent: "end",
  borderBottom: `1px solid ${APP_COMMON_COLORS.dark[300]}`,
  "@media only screen and (min-width: 1200px)": {
    paddingInline: 20,
  },
});

export const StyledAppTopTags = styled.div({
  display: "flex",
  borderRadius: "5px 5px 0 0 ",
  background: "#F7F7F7",
  border: `1px solid ${APP_COMMON_COLORS.dark[300]}`,
  borderBottom: "none",
});

export const StyledTopTag = styled.div({
  padding: "10px 5px",
  borderRadius: "5px 5px 0 0 ",
  width: "auto",
  fontSize: "12px",
  textAlign: "center",
  "@media only screen and (min-width: 1200px)": {
    padding: "10px",
    width: 75,
  },
});

export const StyledTopTagsDivider = styled.div({
  border: `0.5px solid ${APP_COMMON_COLORS.dark[300]}`,
});
