import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";

export const StyledTreeNodeContainer = styled.div({
  margin: "12px 0",
});

export const StyledSubGroupLabelRow = styled.div({
  display: "flex",
});

export const StyledSubGroupsLabelContainer = styled.div({
  display: "flex",
  gap: 5,
  marginRight: 15,
  alignItems: "center",
});

export const StyledSubGroupLabelInDepth = styled.div<{
  depth: number;
}>(({ depth, theme }) => ({
  fontSize: Math.max(20 - depth * 2, 12),
  fontWeight: Math.max(600 - depth * 100, 300),
  opacity: Math.max(1 - (depth - 1) / 10, 0.5),
}));

export const StyledSubGroupCountLabel = styled.span({
  fontSize: 12,
  fontWeight: 300,
  opacity: 0.7,
});

export const StyledSubGroupCountValue = styled.span(({ theme }) => ({
  color: theme.colorPrimary,
}));

export const StyledMainSubGroupName = styled.span(({ theme }) => ({
  color: theme.colorPrimary,
  display: "flex",
  alignItems: "center",
  fontWeight: 600,
  fontSize: 18,
}));

export const StyledGroupLabel = styled.div({
  fontSize: 18,
  fontWeight: 300,
  gap: 10,
  display: "flex",
});

export const StyledGroupAsterisks = styled.div({
  fontSize: 25,
  color: APP_COMMON_COLORS.red[500],
  marginLeft: -20,
});

export const StyledMemberSubGroupsTreeWithCheckboxesContainer = styled.div({
  borderBottom: `1px solid ${APP_COMMON_COLORS.dark[100]}`,
  paddingBottom: 5,
  marginBottom: 10,
});
