import styled from "styled-components";
import { HIDDEN_ELEMENT_STYLES } from "../../../../../styles/styles.constants";
import { APP_COMMON_COLORS } from "../../../../../providers/AppThemeProvider";
import { AppFormikSelect } from "../../../../../components/core/AppFormik/AppFormikSelect/AppFormikSelect";

import { RoleTextInput } from "./RoleTextInput/RoleTextInput";
import { useAtom } from "jotai";
import { ROLES_ATOMS } from "../../../RolesPage.atoms";
import { StyledSelectContainer } from "./NewFieldForm.styles";
import { RoleAttachmentInput } from "./RoleAttachment/RoleAttachment";
import { RoleRedirection } from "./RoleRedirection/RoleRedirection";
import { RoleSingleChoice } from "./RoleSingleChoice/RoleSingleChoice";
import { ROLE_FIELDS_TYPES } from "../../../../../types/roles/roles.types";

interface RoleAddCustomFieldFormProps {
  onClickAdd: () => void;
  hideSection: boolean;
}

const ROLES_CUSTOM_FIELDS = [
  { label: "Text Input", value: ROLE_FIELDS_TYPES.textInput },
  { label: "Attachment", value: ROLE_FIELDS_TYPES.attachment },
  { label: "Redirection", value: ROLE_FIELDS_TYPES.redirection },
  { label: "Single Choice", value: ROLE_FIELDS_TYPES.singleChoice },
];

export const StyledAddNewField = styled.div({
  fontWeight: 500,
  size: 14,
  color: APP_COMMON_COLORS.dark[700],
  paddingBottom: 20,
  borderBottom: `1px solid ${APP_COMMON_COLORS.dark[300]}`,
  marginBottom: 30,
});

export const StyledRoleAddCustomFieldFormContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const StyledInputsSection = styled.div({
  width: "100%",
  "@media (min-width: 1200px)": {
    width: 600,
  },
});

export const NewFieldForm = (props: RoleAddCustomFieldFormProps) => {
  const [fieldType, setFieldType] = useAtom(ROLES_ATOMS.customFieldSelectType);

  return (
    <StyledRoleAddCustomFieldFormContainer
      style={{ ...(props.hideSection && HIDDEN_ELEMENT_STYLES) }}
    >
      <StyledInputsSection>
        <StyledAddNewField>Add new field</StyledAddNewField>
        <StyledSelectContainer>
          <AppFormikSelect
            value={fieldType}
            options={ROLES_CUSTOM_FIELDS}
            onChange={(e) => setFieldType(e.value)}
            label="Select"
          />
        </StyledSelectContainer>

        {fieldType === ROLE_FIELDS_TYPES.textInput && <RoleTextInput />}
        {fieldType === ROLE_FIELDS_TYPES.attachment && <RoleAttachmentInput />}
        {fieldType === ROLE_FIELDS_TYPES.redirection && <RoleRedirection />}
        {fieldType === ROLE_FIELDS_TYPES.singleChoice && <RoleSingleChoice />}
      </StyledInputsSection>
    </StyledRoleAddCustomFieldFormContainer>
  );
};
