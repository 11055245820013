import React from "react";
import {
  StyledAppTopTags,
  StyledAppTopTagsContainer,
  StyledTopTag,
  StyledTopTagsDivider,
} from "./AppTopTags.styles";

interface AppTopTagsProps {
  tags: string[];
}

export const AppTopTags: React.FC<AppTopTagsProps> = (props) => {
  return (
    <StyledAppTopTagsContainer>
      <StyledAppTopTags>
        {props.tags.map((tag, index) => (
          <React.Fragment key={index}>
            <StyledTopTag>{tag}</StyledTopTag>
            {index + 1 != props?.tags.length && <StyledTopTagsDivider />}
          </React.Fragment>
        ))}
      </StyledAppTopTags>
    </StyledAppTopTagsContainer>
  );
};
