import { useTheme } from "styled-components";

export const RecycleBinIcon = (props: { onClick: () => void }) => {
  const theme = useTheme();

  return (
    <svg
      style={{ cursor: "pointer" }}
      onClick={props.onClick}
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4167 6.25H2.91667C2.80616 6.25 2.70018 6.2939 2.62204 6.37204C2.5439 6.45018 2.5 6.55616 2.5 6.66667V18.3333C2.5 18.7754 2.67559 19.1993 2.98816 19.5118C3.30072 19.8244 3.72464 20 4.16667 20H14.1667C14.6087 20 15.0326 19.8244 15.3452 19.5118C15.6577 19.1993 15.8333 18.7754 15.8333 18.3333V6.66667C15.8333 6.55616 15.7894 6.45018 15.7113 6.37204C15.6332 6.2939 15.5272 6.25 15.4167 6.25ZM7.70833 17.0833C7.70833 17.2491 7.64248 17.4081 7.52527 17.5253C7.40806 17.6425 7.24909 17.7083 7.08333 17.7083C6.91757 17.7083 6.7586 17.6425 6.64139 17.5253C6.52418 17.4081 6.45833 17.2491 6.45833 17.0833V9.58333C6.45833 9.41757 6.52418 9.2586 6.64139 9.14139C6.7586 9.02418 6.91757 8.95833 7.08333 8.95833C7.24909 8.95833 7.40806 9.02418 7.52527 9.14139C7.64248 9.2586 7.70833 9.41757 7.70833 9.58333V17.0833ZM11.875 17.0833C11.875 17.2491 11.8092 17.4081 11.6919 17.5253C11.5747 17.6425 11.4158 17.7083 11.25 17.7083C11.0842 17.7083 10.9253 17.6425 10.8081 17.5253C10.6908 17.4081 10.625 17.2491 10.625 17.0833V9.58333C10.625 9.41757 10.6908 9.2586 10.8081 9.14139C10.9253 9.02418 11.0842 8.95833 11.25 8.95833C11.4158 8.95833 11.5747 9.02418 11.6919 9.14139C11.8092 9.2586 11.875 9.41757 11.875 9.58333V17.0833Z"
        fill={theme.colorPrimary}
      />
      <path
        d="M17.5 3.33333H13.5417C13.4864 3.33333 13.4334 3.31138 13.3944 3.27231C13.3553 3.23324 13.3333 3.18025 13.3333 3.125V2.08333C13.3333 1.5308 13.1138 1.0009 12.7231 0.610194C12.3324 0.219493 11.8025 0 11.25 0L7.08333 0C6.5308 0 6.0009 0.219493 5.61019 0.610194C5.21949 1.0009 5 1.5308 5 2.08333V3.125C5 3.18025 4.97805 3.23324 4.93898 3.27231C4.89991 3.31138 4.84692 3.33333 4.79167 3.33333H0.833333C0.61232 3.33333 0.400358 3.42113 0.244078 3.57741C0.0877973 3.73369 0 3.94565 0 4.16667C0 4.38768 0.0877973 4.59964 0.244078 4.75592C0.400358 4.9122 0.61232 5 0.833333 5H17.5C17.721 5 17.933 4.9122 18.0893 4.75592C18.2455 4.59964 18.3333 4.38768 18.3333 4.16667C18.3333 3.94565 18.2455 3.73369 18.0893 3.57741C17.933 3.42113 17.721 3.33333 17.5 3.33333ZM6.66667 3.125V2.08333C6.66667 1.97283 6.71057 1.86685 6.78871 1.78871C6.86685 1.71057 6.97283 1.66667 7.08333 1.66667H11.25C11.3605 1.66667 11.4665 1.71057 11.5446 1.78871C11.6228 1.86685 11.6667 1.97283 11.6667 2.08333V3.125C11.6667 3.18025 11.6447 3.23324 11.6056 3.27231C11.5666 3.31138 11.5136 3.33333 11.4583 3.33333H6.875C6.81975 3.33333 6.76676 3.31138 6.72769 3.27231C6.68862 3.23324 6.66667 3.18025 6.66667 3.125Z"
        fill={theme.colorPrimary}
      />
    </svg>
  );
};
