import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";
import { DeleteUploadedImageIcon } from "./DeleteUploadedImageIcon";
import { isValidURL } from "../../../../helpers/isValidUrl";

interface UploadedImagePreviewProps {
  imagePreview: string;
  imageName?: string;
  onClickDelete: () => void;
  hidePreview?: boolean;
}

const StyledUploadedImagePreviewContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StyledDeleteIconContainer = styled.div({
  margin: "10px -50px 0 -25px",
  zIndex: 2,
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

const StyledImageContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 90,
  padding: 15,
  width: 200,
  border: `1px solid ${APP_COMMON_COLORS.dark[100]}`,
  overflow: "hidden",
});

const StyledImageName = styled.a({
  marginLeft: 20,
  color: APP_COMMON_COLORS.dark[700],
  fontWeight: 500,
  fontSize: 14,
});

export const UploadedImagePreview = (props: UploadedImagePreviewProps) => {
  console.log(">>is valid", isValidURL(props.imagePreview));

  return (
    <StyledUploadedImagePreviewContainer>
      <StyledDeleteIconContainer onClick={props.onClickDelete}>
        <DeleteUploadedImageIcon />
      </StyledDeleteIconContainer>
      {!props.hidePreview && (
        <StyledImageContainer>
          <img
            src={props.imagePreview}
            alt="image"
            style={{
              width: "auto",
              height: "80%",
              display: "block",
              objectFit: "cover",
            }}
          />
        </StyledImageContainer>
      )}
      <StyledImageName
        style={{ marginInlineStart: props.hidePreview ? "40px" : 0 }}
        href={isValidURL(props.imagePreview) ? props.imagePreview : undefined}
        target="_blank"
      >
        {props.imageName}
      </StyledImageName>
    </StyledUploadedImagePreviewContainer>
  );
};
