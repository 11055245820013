import styled from "styled-components";

export const StyledCardsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 20,
  "@media only screen and (min-width: 768px)": {
    flexDirection: "row",
  },
});
