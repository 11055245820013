import { useTheme } from "styled-components";

export const TemplateIconDeleteModal = () => {
  const theme = useTheme();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8001 10.9602C23.4453 10.492 23.2533 9.92065 23.2533 9.33321C23.2533 8.74576 23.4453 8.17442 23.8001 7.70621C23.9455 7.50714 24.0141 7.26225 23.9934 7.01659C23.9726 6.77094 23.8638 6.54104 23.6871 6.36921L17.6291 0.308207C17.4564 0.132631 17.2264 0.0249338 16.981 0.00474265C16.7356 -0.0154485 16.4911 0.0532103 16.2921 0.198207C15.8235 0.552047 15.2523 0.743474 14.6651 0.743474C14.0779 0.743474 13.5067 0.552047 13.0381 0.198207C12.8391 0.0522539 12.5942 -0.0168938 12.3483 0.00350388C12.1024 0.0239016 11.8722 0.13247 11.7001 0.309207L0.146062 11.8632C0.0633869 11.9461 0.0124002 12.0553 0.00198437 12.1719C-0.00843141 12.2885 0.022384 12.405 0.0890621 12.5012C0.461701 13.0376 0.661303 13.6751 0.661062 14.3282C0.661635 14.8968 0.507088 15.4549 0.214062 15.9422C0.0928513 16.1406 0.0411135 16.3737 0.0670051 16.6047C0.0928968 16.8357 0.194945 17.0516 0.357062 17.2182L6.78006 23.6412C6.94732 23.8026 7.16334 23.9041 7.39436 23.9298C7.62539 23.9555 7.85843 23.9039 8.05706 23.7832C8.58286 23.4742 9.186 23.3219 9.79545 23.3443C10.4049 23.3666 10.9953 23.5626 11.4971 23.9092C11.5934 23.9758 11.71 24.0064 11.8266 23.9958C11.9432 23.9852 12.0523 23.934 12.1351 23.8512L23.6881 12.2982C23.8651 12.1263 23.974 11.8961 23.9946 11.6502C24.0152 11.4042 23.9461 11.1592 23.8001 10.9602ZM19.4411 11.3232L11.1701 19.5982C10.9726 19.7958 10.7382 19.9526 10.4801 20.0596C10.222 20.1665 9.94542 20.2216 9.66606 20.2216C9.38671 20.2216 9.11009 20.1665 8.85203 20.0596C8.59396 19.9526 8.3595 19.7958 8.16206 19.5982L4.40006 15.8362C4.00112 15.4371 3.77701 14.896 3.77701 14.3317C3.77701 13.7674 4.00112 13.2263 4.40006 12.8272L12.6731 4.55421C13.0721 4.15557 13.613 3.93165 14.1771 3.93165C14.7411 3.93165 15.282 4.15557 15.6811 4.55421L19.4411 8.31521C19.6388 8.51252 19.7957 8.74687 19.9029 9.00486C20.01 9.26286 20.0653 9.53944 20.0654 9.81879C20.0656 10.0981 20.0108 10.3748 19.904 10.6329C19.7972 10.8911 19.6406 11.1256 19.4431 11.3232H19.4411Z"
        fill={theme.colorPrimary}
      />
      <path
        d="M14.708 7.03234C14.6384 6.96264 14.5557 6.90736 14.4646 6.86964C14.3736 6.83191 14.276 6.8125 14.1775 6.8125C14.079 6.8125 13.9814 6.83191 13.8904 6.86964C13.7994 6.90736 13.7167 6.96264 13.647 7.03234L6.87901 13.7983C6.80914 13.868 6.75368 13.9508 6.7158 14.0418C6.67791 14.1329 6.65834 14.2306 6.6582 14.3293C6.65806 14.4279 6.67736 14.5257 6.71499 14.6169C6.75262 14.7081 6.80784 14.791 6.87751 14.8608C6.94717 14.9307 7.02992 14.9862 7.12101 15.024C7.21211 15.0619 7.30978 15.0815 7.40844 15.0816C7.50711 15.0818 7.60483 15.0625 7.69604 15.0249C7.78724 14.9872 7.87014 14.932 7.94001 14.8623L14.708 8.09834C14.7785 8.0286 14.8344 7.94559 14.8725 7.8541C14.9107 7.76261 14.9304 7.66447 14.9304 7.56534C14.9304 7.46621 14.9107 7.36807 14.8725 7.27658C14.8344 7.18509 14.7785 7.10208 14.708 7.03234Z"
        fill={theme.colorPrimary}
      />
      <path
        d="M14.1998 11.3431L11.1918 14.3521C11.1181 14.4208 11.059 14.5036 11.0181 14.5956C10.9771 14.6876 10.955 14.7869 10.9532 14.8876C10.9515 14.9883 10.97 15.0883 11.0077 15.1817C11.0454 15.2751 11.1016 15.3599 11.1728 15.4311C11.244 15.5024 11.3288 15.5585 11.4222 15.5962C11.5156 15.6339 11.6157 15.6525 11.7164 15.6507C11.8171 15.6489 11.9164 15.6269 12.0084 15.5859C12.1004 15.5449 12.1832 15.4858 12.2518 15.4121L15.2598 12.4041C15.3965 12.2627 15.4722 12.0733 15.4706 11.8767C15.469 11.68 15.3902 11.4919 15.2512 11.3527C15.1122 11.2136 14.9241 11.1346 14.7275 11.1328C14.5308 11.131 14.3413 11.2066 14.1998 11.3431Z"
        fill={theme.colorPrimary}
      />
    </svg>
  );
};
