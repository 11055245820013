import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { AppFormikField } from "../AppFormik/AppFormikField/AppFormikField";
import TodayIcon from "@mui/icons-material/Today";
import { useState } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import styled from "styled-components";

interface AppDatePickerProps {
  label?: string;
  dateValue: any;
  onShouldDisableDate: (dayJs: Dayjs | null) => boolean;
  onChangeDate: (dayJs: Dayjs | null) => void;
  onOpen?: (value: boolean) => void;
  textFieldValue?: number | null;
  className?: string;
  error?: boolean;
  isOpen?: boolean;
}

const StyledTodayIcon = styled(TodayIcon)(({ theme }) => ({
  color: theme.colorPrimary,
  cursor: "pointer",
}));

export const AppDatePicker: React.FC<AppDatePickerProps> = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        value={dayjs(props.dateValue)}
        shouldDisableDate={(dayJs: any) => props.onShouldDisableDate(dayJs)}
        onChange={(dayJs: Dayjs | null) => props.onChangeDate(dayJs)}
        onOpen={() => props.onOpen && props.onOpen(true)}
        onClose={() => {
          props.onOpen && props.onOpen(false);
          setIsOpen(false);
        }}
        open={isOpen}
        format="DD/MM/YYYY"
        closeOnSelect={true}
        slots={{
          textField: (textFieldProps) => (
            <AppFormikField
              {...textFieldProps}
              label={props?.label ? props.label : ""}
              onFocus={() => !isOpen && setIsOpen(true)}
              onBlur={() => setIsOpen(false)}
              InputProps={{
                endAdornment: (
                  <StyledTodayIcon onClick={() => setIsOpen(true)} />
                ),
              }}
            />
          ),
        }}
      />
    </LocalizationProvider>
  );
};
