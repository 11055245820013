import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import { TabPanel } from "../../../../components/shared/AppModalDesktopSteps/TabPanel/TabPanel";
import { MemberFormValues } from "../../MembersPage.types";
import { AppFormikField } from "../../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { AppButton } from "../../../../components/core/AppButton/AppButton";
import { MEMBER_INITIAL_VALUES } from "../../constants";
import { StyledAppModalDesktopStepTitle } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { StyledTabContainer } from "../../MembersPage.styles";
import { StyledAppModalSubmitButtonContainer } from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import {
  AppFormikSelect,
  SelectOption,
} from "../../../../components/core/AppFormik/AppFormikSelect/AppFormikSelect";
import { COLLECTIONS_TYPE } from "../../../../constants/firebase.constants";
import { useSelector } from "react-redux";
import { getFirebaseDataByColKeyVal } from "../../../../hooks/firebase/getFirebaseDataByColKeyVal";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { PAYMENT_DATA } from "../../../../types/payments/payments.types";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { MEMBERS_ATOMS } from "../../MembersPage.Atoms";
import styled from "styled-components";
import { AppDatePicker } from "../../../../components/core/AppDatePicker/AppDatePicker";
import { dayJsToTs } from "../../../../helpers/date-time";
import { Dayjs } from "dayjs";
import { _fs } from "../../../../services/firebase/config";
import * as Yup from "yup";

interface AddMemberTabProps {
  outerTabValue: number;
  handleOuterTabChange: (newValue: number) => void;
  isAutoFill: boolean;
  autofillInputProps: {
    onAnimationStart: (e: React.AnimationEvent<HTMLDivElement>) => void;
    onAnimationEnd: (e: React.AnimationEvent<HTMLDivElement>) => void;
    onFocus: () => void;
  };
}

const StyledForm = styled(Form)({
  height: "100%",
  display: "flex",
  paddingBottom: 35,
  "@media only screen and (min-width: 1200px)": {
    paddingBottom: 10,
  },
});

export const AddMemberTab = ({
  autofillInputProps,
  handleOuterTabChange,
  isAutoFill,
  outerTabValue,
}: AddMemberTabProps) => {
  const client = useSelector(getClientSelector);
  const [products, setProducts] = useAtom(MEMBERS_ATOMS.products);
  const isEditMode = useAtomValue(MEMBERS_ATOMS.isEditMode);
  const setDefaultPaymentProductId = useSetAtom(
    MEMBERS_ATOMS.defaultPaymentProductId
  );
  const [initialValues, setInitialValues] = useState(MEMBER_INITIAL_VALUES);
  const [addMemberFormValues, setAddMemebrsformValues] = useAtom(
    MEMBERS_ATOMS.addMemberFormValues
  );

  const [isMemberFound, setIsMemberFound] = useState(false);

  const checkEmailExistence = async (email: string) => {
    if (email) {
      const res = await _fs
        .collection(COLLECTIONS_TYPE.members)
        .where(`clientsIds.${client.clientId}`, "==", true)
        .where("email", "==", email)
        .get();

      return res.docs.length === 0;
    }
    return true;
  };

  const getMemberDataByEmail = async (email: string) => {
    if (email) {
      const res = await _fs
        .collection(COLLECTIONS_TYPE.members)
        .where("email", "==", email)
        .get();

      return res.docs?.[0]?.data();
    }
  };

  const handleEmailChange = async (e: any, setFieldValue: any) => {
    const email = e.target.value;
    setFieldValue("email", email);
    setAddMemebrsformValues((prev) => ({ ...prev, email }));

    const memberData = await getMemberDataByEmail(email);
    if (memberData) {
      setFieldValue("firstName", memberData.firstName);
      setFieldValue("lastName", memberData.lastName);
      setIsMemberFound(true); // Disable fields if member is found
    } else {
      setIsMemberFound(false); // Enable fields if no member is found
    }
  };

  const addMemberYupSchema = Yup.object({
    email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
      .required("Required")
      .min(5, "Minimum length is 5")
      .max(255, "Maximum length is 255")
      .test(
        "checkEmailExistence",
        "Email already exists",
        async function (value) {
          const { path, createError } = this;

          if (
            value &&
            !isEditMode &&
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ) {
            const isEmailExists = await checkEmailExistence(value);
            if (!isEmailExists) {
              return createError({ path, message: "Email already exists" });
            }
          }
          return true;
        }
      ),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
  });

  useEffect(() => {
    const getProducts = async () => {
      const products: { [key: string]: PAYMENT_DATA } =
        await getFirebaseDataByColKeyVal(
          COLLECTIONS_TYPE.products,
          "clientId",
          String(client.clientId)
        );

      if (Object.values(products || {}).length > 0) {
        let mappedProducts: SelectOption[] = [];
        Object.values(products).map((p) => {
          mappedProducts.push({
            label: p.name,
            value: p.productId,
          });
          if (p.isDefaultPayment) {
            setDefaultPaymentProductId(p.productId);
            setInitialValues({ ...initialValues, productId: p.productId });
          }
        });
        setProducts([{ label: "None", value: "none" }, ...mappedProducts]);
      } else {
        setProducts([{ label: "None", value: "none" }]);
      }
    };
    getProducts();
  }, []);

  return (
    <TabPanel value={outerTabValue} index={0}>
      <StyledTabContainer>
        <StyledAppModalDesktopStepTitle style={{ marginBottom: 20 }}>
          Member profile
        </StyledAppModalDesktopStepTitle>

        <Formik
          enableReinitialize={true}
          validateOnMount
          initialValues={isEditMode ? addMemberFormValues : initialValues}
          validationSchema={addMemberYupSchema}
          onSubmit={async (values: MemberFormValues) => {
            return handleOuterTabChange(1);
          }}
        >
          {({
            errors,
            values,
            touched,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }) => {
            const _touched: FormikTouched<MemberFormValues> = touched;

            const _errors: FormikErrors<MemberFormValues> = errors;

            return (
              <StyledForm>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  height={"100%"}
                  flexGrow={1}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 12,
                    }}
                  >
                    <AppFormikField
                      isRequired={true}
                      name="email"
                      value={values.email}
                      isDisabled={isEditMode}
                      label="Email Address"
                      InputLabelProps={{
                        shrink: isAutoFill || undefined,
                      }}
                      InputProps={autofillInputProps}
                      errorMessage={
                        _touched.email && _errors.email
                          ? String(errors.email)
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={(e: any) => handleEmailChange(e, setFieldValue)}
                      type="text"
                      tooltip={
                        isMemberFound && !_errors.email
                          ? "This member already exists in another club, the first name and last name will be pre-filled"
                          : ""
                      }
                    />
                    <AppFormikField
                      isRequired={true}
                      name="firstName"
                      value={values.firstName}
                      label="First Name"
                      isDisabled={isMemberFound}
                      errorMessage={
                        _touched.firstName && _errors.firstName
                          ? String(errors.firstName)
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={(e: any) => {
                        setFieldValue("firstName", e.target.value);
                        setAddMemebrsformValues({
                          ...values,
                          firstName: e.target.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: isAutoFill || undefined,
                      }}
                    />
                    <AppFormikField
                      isRequired={true}
                      isDisabled={isMemberFound}
                      name="lastName"
                      value={values.lastName}
                      label="Last Name"
                      errorMessage={
                        _touched.lastName && _errors.lastName
                          ? String(errors.lastName)
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={(e: any) => {
                        setFieldValue("lastName", e.target.value);
                        setAddMemebrsformValues({
                          ...values,
                          lastName: e.target.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: isAutoFill || undefined,
                      }}
                    />
                    <AppFormikSelect
                      name="productId"
                      label="Subscription"
                      value={
                        values.productId && values.productId !== "null"
                          ? values.productId
                          : "none"
                      }
                      errorMessage={
                        _touched.productId && _errors.productId
                          ? String(errors.productId)
                          : undefined
                      }
                      onChange={(e) => {
                        setFieldValue("productId", e.value);
                        setAddMemebrsformValues({
                          ...values,
                          productId: e.value,
                        });
                      }}
                      options={products as SelectOption[]}
                    />
                    {!isEditMode &&
                      values.productId &&
                      values.productId !== "none" &&
                      values.productId !== "null" && (
                        <AppDatePicker
                          dateValue={addMemberFormValues.nextPaymentDate}
                          onShouldDisableDate={(dayJs) => {
                            return false;
                          }}
                          onChangeDate={(dayJs: Dayjs | null) => {
                            const value = dayJsToTs(dayJs as Dayjs);
                            setFieldValue("nextPaymentDate", value);
                            setAddMemebrsformValues({
                              ...values,
                              nextPaymentDate: value,
                            });
                          }}
                          label="Subscription Start Date"
                        />
                      )}
                  </div>

                  <StyledAppModalSubmitButtonContainer>
                    <AppButton onClick={handleSubmit}>Continue</AppButton>
                  </StyledAppModalSubmitButtonContainer>
                </Box>
              </StyledForm>
            );
          }}
        </Formik>
      </StyledTabContainer>
    </TabPanel>
  );
};
