import { CSSObject } from "styled-components";
import {
  StyledAppFormikFieldError,
  StyledAppFormikFieldTooltip,
} from "../AppFormik.styles";
import {
  StyledAppFormikFieldContainer,
  StyledFieldContainer,
  StyledTextField,
} from "./AppFormikField.styles";
import { ChangeEventHandler } from "react";
import {
  FilledInputProps,
  InputBaseComponentProps,
  InputLabelProps,
  SxProps,
  Theme,
} from "@mui/material";
import { StyledRequiredAsterisks } from "../../../shared/StyledComponents/StyledRequiredAsterisks";

export interface AppFormikFieldProps {
  name?: string;
  type?: "text" | "number" | "email" | "password";
  label?: string;
  placeholder?: string;
  fieldStyle?: CSSObject;
  fieldEndContent?: string | React.ReactNode;
  containerstyle?: CSSObject;
  errorMessage?: string;
  isRequired?: boolean;
  value?: string | number;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  isDisabled?: boolean;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onFocus?: () => void;
  InputProps?: Partial<FilledInputProps>;
  sx?: SxProps<Theme>;
  InputLabelProps?: Partial<InputLabelProps>;
  inputProps?: InputBaseComponentProps;
  multiline?: boolean;
  rows?: number;
  tooltip?: string;
  dataTestId?: string;
}

export const AppFormikField = (props: AppFormikFieldProps) => {
  return (
    <StyledAppFormikFieldContainer
      $containerstyle={{ ...props.containerstyle }}
    >
      <StyledFieldContainer>
        {props.isRequired && (
          <StyledRequiredAsterisks>*</StyledRequiredAsterisks>
        )}
        <StyledTextField
          $styledErrorProp={Boolean(props.errorMessage)}
          name={props.name}
          label={props.label}
          type={props.type}
          placeholder={props.placeholder}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onChange={props.onChange}
          value={props.value}
          InputProps={props.InputProps}
          inputProps={props.inputProps}
          InputLabelProps={props.InputLabelProps}
          sx={props.sx}
          disabled={props.isDisabled}
          multiline={props.multiline}
          rows={props.rows}
          data-testid={props.dataTestId}
        />
      </StyledFieldContainer>
      {props.tooltip && (
        <StyledAppFormikFieldTooltip>
          {props.tooltip}
        </StyledAppFormikFieldTooltip>
      )}
      <StyledAppFormikFieldError>
        {props.errorMessage}
      </StyledAppFormikFieldError>
    </StyledAppFormikFieldContainer>
  );
};
