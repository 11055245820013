import React, { useState } from "react";
import {
  InputAdornment,
  IconButton,
  Typography,
  useTheme as useMuiTheme,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, Form, FormikTouched, FormikErrors } from "formik";
import * as Yup from "yup";
import styled, { useTheme } from "styled-components";
import { AppFormikField } from "../../components/core/AppFormik/AppFormikField/AppFormikField";
import { useAutoFill } from "../../hooks/useAutoFill/useAutoFill";
import { useLocation, useNavigate } from "react-router-dom";
import { AppButton } from "../../components/core/AppButton/AppButton";
import { VisibilityOffIcon } from "../../assets/icons/VisibilityOffIcon";
import { VisibilityOnIcon } from "../../assets/icons/VisibilityOnIcon";
import { _fs } from "../../services/firebase/config";
import { AppPasswordStrengthBar } from "../../components/core/AppPasswordStrengthBar/AppPasswordStrengthBar";
import { firebaseHttpsCallable } from "../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../constants/firebase.constants";
import { APP_ROUTES } from "../../constants/routes.constant";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../styles/styles.constants";
// import { YourZownIcon } from "../../assets/icons/YourZownIcon";
import { AuthLayout } from "../../components/shared/AuthLayout/AuthLayout";

const schema = Yup.object({
  password: Yup.string()
    .min(8, "Must be 8 characters or more")
    .required("Required"),
});

const StyledFormikContainer = styled.div({
  paddingTop: 50,
  "@media (min-width: 1200px)": {
    paddingTop: 25,
  },
});

const StyledButtonContainer = styled.div({
  marginTop: 80,
  "@media (min-width: 1200px)": {
    marginTop: 40,
  },
});

export const SignUpPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const { isAutoFill, autofillInputProps } = useAutoFill();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const isSmallScreen = useMediaQuery(useMuiTheme().breakpoints.down("lg"));

  const handleOnSubmit = async (values: {
    email: string;
    password: string;
  }) => {
    setIsLoading(true);
    const params = new URLSearchParams(location.search);
    const createdAt = params.get("createdAt");
    const encryptedUID = params.get("c");
    const shouldNavigateToMobileAppsPage = Boolean(
      params.get("app") === "true"
    );

    const requestBody = {
      createdAt: createdAt,
      c: encryptedUID,
      p: values.password,
    };

    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.updatePassword)(requestBody)
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          toast("Your password has been created.", APP_TOASTIFY.SUCCESS);

          navigate(
            shouldNavigateToMobileAppsPage
              ? APP_ROUTES.downloadMobileApps
              : APP_ROUTES.signIn
          );
          return;
        }
        if (res?.data?.result === "nok") {
          console.log(res);
          toast(res?.data?.message, APP_TOASTIFY.ERROR);
          return;
        }
      })
      .catch((err: any) => {
        console.log(err);
        toast("Something went wrong [8]", APP_TOASTIFY.ERROR);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AuthLayout
      title="Welcome To"
      subtitle="YourZown Admin"
      iconSubtitle="Create a new password"
      children={
        <StyledFormikContainer>
          <Formik
            enableReinitialize={true}
            initialValues={{ email: "", password: "" }}
            validationSchema={schema}
            onSubmit={(values: { email: string; password: string }) =>
              handleOnSubmit(values)
            }
          >
            {({ errors, values, touched, handleChange, handleBlur }) => {
              const _touched: FormikTouched<{
                email: string;
                password: string;
              }> = touched;

              const _errors: FormikErrors<{ email: string; password: string }> =
                errors;

              return (
                <Form
                  style={{
                    width: isSmallScreen ? "100%" : 400,
                  }}
                >
                  <Box paddingX={2.5}>
                    <AppFormikField
                      name="password"
                      value={values.password}
                      label="Choose password"
                      errorMessage={
                        _touched.password && _errors.password
                          ? String(errors.password)
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type={showPassword ? "text" : "password"}
                      InputLabelProps={{ shrink: isAutoFill || undefined }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                              sx={{
                                marginRight: "2px",
                              }}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityOnIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        marginTop: 2,
                      }}
                    />
                    <Box marginTop={1.25} marginBottom={2} marginLeft={2}>
                      <AppPasswordStrengthBar password={values.password} />
                    </Box>

                    <StyledButtonContainer>
                      <AppButton isLoading={isLoading} type="submit">
                        <Typography
                          color={theme.common.white}
                          fontWeight={600}
                          variant="body1"
                        >
                          SIGN UP
                        </Typography>
                      </AppButton>
                    </StyledButtonContainer>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      my={2.75}
                    ></Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </StyledFormikContainer>
      }
    />
  );
};
