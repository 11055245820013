import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";
import { Form } from "formik";

export const StyledChildrenTabContainer = styled.div({
  minHeight: "100%",
  "@media only screen and (min-width: 1200px)": {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const StyledTitle = styled.div({
  display: "none",
  "@media only screen and (min-width: 1200px)": {
    display: "block",
    color: APP_COMMON_COLORS.dark[700],
    fontWeight: 400,
    fontSize: 16,
    marginBottom: 12,
  },
});

export const StyledDivider = styled.div({
  height: 1,
  borderBottom: `1px dashed ${APP_COMMON_COLORS.dark[100]}`,
  marginBlock: 18,
});

export const StyledChildListItem = styled.ul(({ theme }) => ({
  paddingInlineStart: 20,
  marginBlock: 0,
  listStyleType: "disc",
  li: {
    color: APP_COMMON_COLORS.dark[600],
  },
  "li::marker": {
    color: APP_COMMON_COLORS.dark[300],
  },
}));

export const StyledCancel = styled.div(({ theme }) => ({
  color: theme.colorPrimary,
  cursor: "pointer",
  marginBottom: 25,
  fontWeight: 600,
  height: "fit-content",
  "&:hover": {
    textDecoration: "underline",
  },
}));

export const StyledFormFieldsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  paddingLeft: 20,
  overflow: "auto",
  height: 430,
  "@media only screen and (min-width: 1200px)": {
    height: 390,
  },
});

export const StyledChildrenButtonsContainer = styled.div({
  display: "flex",
  gap: 25,
  alignItems: "center",
  marginTop: "auto",
});

export const StyledForm = styled(Form)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
});
