import styled, { CSSObject } from "styled-components";
import { APP_COMMON_COLORS } from "../../../../../providers/AppThemeProvider";
import { Close } from "@mui/icons-material";

export const StyledFieldsRowsContainer = styled.div({
  overflowY: "auto",
  maxHeight: 380,
  borderBottom: `1px solid ${APP_COMMON_COLORS.dark[300]}`,
  "@media only screen and (min-width: 1200px)": {
    maxHeight: 300,
  },
});

export const StyledAddFieldsWrapper = styled.div({
  borderTop: `1px solid ${APP_COMMON_COLORS.dark[300]}`,
  paddingTop: 10,
  height: 348,
});

export const StyledFieldRow = styled.div({
  display: "flex",
  borderBottom: `1px dashed ${APP_COMMON_COLORS.dark[100]}`,
  justifyContent: "space-between",
  alignItems: "center",
  "@media only screen and (min-width: 1200px)": {
    paddingRight: 20,
  },
  "&:last-child": {
    borderBottom: "none",
  },
});

export const StyledLabelContainer = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: APP_COMMON_COLORS.dark[700],
  fontWeight: 500,
  gap: 15,
}));

export const AppRadioCustomContainerStyles: CSSObject = {
  display: "flex",
  justifyContent: "space-around",
  flexDirection: "row",
  width: 165,
};

export const CustomRadioAndLabelContainerStyles: CSSObject = {
  border: "none",
  paddingBottom: 0,
  display: "flex",
};
