import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

export const StyledAppModalMobileStepsContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  paddingBlock: 40,
  marginBottom: 40,
  borderBottom: `1px solid ${APP_COMMON_COLORS.dark[300]}`,
  "@media only screen and (min-width: 1200px)": {
    display: "none",
  },
});
