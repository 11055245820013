import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";
import { AppButton } from "../../core/AppButton/AppButton";

interface TitleBodyButtonWidgetProps {
  title?: string | React.ReactNode;
  body?: string | React.ReactNode;
  buttonLabel?: string | React.ReactNode;
  onButtonClick?: () => void;
  isLoading?: boolean;
}

const StyledContainer = styled.div({
  height: 220,
  borderRadius: 4,
  border: `1px solid ${APP_COMMON_COLORS.dark[100]}`,
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "24px 18px",
  "@media only screen and (min-width: 768px)": {
    width: 200,
  },
});

const StyledTitle = styled.div({
  fontSize: 16,
  fontWeight: 400,
  textAlign: "center",
  borderBottom: `1px dashed ${APP_COMMON_COLORS.dark[100]}`,
  paddingBottom: 16,
  marginBottom: 16,
});

const StyledBody = styled.div({
  fontSize: 14,
  fontWeight: 400,
  textAlign: "center",
});

export const TitleBodyButtonWidget = (props: TitleBodyButtonWidgetProps) => {
  return (
    <StyledContainer>
      <div>
        {props.title && <StyledTitle>{props.title}</StyledTitle>}
        {props.body && <StyledBody>{props.body}</StyledBody>}
      </div>
      {props.buttonLabel && (
        <AppButton
          containerCustomStyles={{ width: "100%" }}
          onClick={props.onButtonClick}
          isLoading={props.isLoading}
        >
          {props.buttonLabel}
        </AppButton>
      )}
    </StyledContainer>
  );
};
