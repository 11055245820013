import { useEffect, useState } from "react";
import { StyledMainSubGroupName } from "../SubGroupsTree.styles";
import { Node, TreeNodeProps } from "../SubGroupsTree.types";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";
import { SUB_GROUPS } from "../../../../types/groups/groups.types";
import { mapFlatTreeToNestedTree } from "../SubGroupsTree.helpers";
import {
  StyledSubGroupCountLabel,
  StyledSubGroupCountValue,
  StyledSubGroupLabelInDepth,
  StyledSubGroupLabelRow,
  StyledSubGroupsLabelContainer,
  StyledTreeNodeContainer,
} from "./ViewSubGroupsTree.styles";
import { GROUPS_PAGE_ATOMS } from "../../GroupsPage.Atoms";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../../constants/routes.constant";
import { Tooltip } from "@mui/material";
import { compareAlphanumeric } from "../../../../helpers/compareAlphanumeric";

const TreeNode = (props: TreeNodeProps) => {
  const [groupMembersCount, setGroupMembersCount] = useAtom(
    GROUPS_PAGE_ATOMS.groupMembersCount
  );

  const navigate = useNavigate();

  return (
    <StyledTreeNodeContainer>
      <StyledSubGroupLabelRow>
        {props.node.subGroupId?.startsWith("group") ? (
          <StyledMainSubGroupName>
            {props.node.subGroupName}
          </StyledMainSubGroupName>
        ) : (
          <StyledSubGroupsLabelContainer>
            <span style={{ color: APP_COMMON_COLORS.dark[300] }}>
              {"—".repeat(props.depth)}
            </span>
            <StyledSubGroupLabelInDepth depth={props.depth}>
              <Tooltip placement="top" title={"Click to view members"}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(
                      APP_ROUTES.members +
                        `?subGroupId=${props.node.subGroupId}` +
                        `&subGroupName=${props.node.subGroupName}`
                    );
                  }}
                >
                  {props.node.subGroupName}
                </span>
              </Tooltip>

              <StyledSubGroupCountLabel
                style={{ marginLeft: 8, marginRight: 4 }}
              >
                (children:
              </StyledSubGroupCountLabel>

              <StyledSubGroupCountValue>
                {groupMembersCount?.childrenIds[props.node.subGroupId]
                  ?.length || 0}
              </StyledSubGroupCountValue>

              <StyledSubGroupCountLabel style={{ marginRight: 4 }}>
                , members:
              </StyledSubGroupCountLabel>

              <StyledSubGroupCountValue>
                {groupMembersCount?.membersIds[props.node.subGroupId]?.length ||
                  0}
              </StyledSubGroupCountValue>

              <StyledSubGroupCountLabel>)</StyledSubGroupCountLabel>
            </StyledSubGroupLabelInDepth>
          </StyledSubGroupsLabelContainer>
        )}
      </StyledSubGroupLabelRow>

      {props.node.children && (
        <div>
          {props.node.children
            .sort((a, b) => compareAlphanumeric(a.subGroupName, b.subGroupName))
            .map((child) => {
              if (child.subGroupId === props.node.subGroupId) {
                return null;
              }
              return (
                <TreeNode
                  key={child.subGroupId}
                  node={child}
                  depth={props.depth + 1}
                />
              );
            })}
        </div>
      )}
    </StyledTreeNodeContainer>
  );
};

export const ViewSubGroupsTree = ({ flatTree }: { flatTree: SUB_GROUPS }) => {
  const [tree, setTree] = useState<Node | null>(null);

  useEffect(() => {
    if (flatTree) {
      const nestedTree = mapFlatTreeToNestedTree(flatTree);
      setTree(nestedTree);
    }
  }, [flatTree, Object.values(tree || {}).length]);

  if (!tree) {
    return <div>Loading...</div>;
  }

  return <TreeNode node={tree} depth={0} />;
};
