import React from "react";

export const AppleIcon = () => {
  return (
    <svg
      width="18"
      height="23"
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3476_11398"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="6"
        width="18"
        height="17"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.823 12.73C14.8215 11.2058 15.5409 9.77067 16.763 8.85996C16.8706 8.77562 16.9389 8.65095 16.9521 8.51494C16.9653 8.37893 16.9223 8.24343 16.833 8.13996C15.9112 7.11695 14.6186 6.50487 13.243 6.43996C11.553 6.27996 9.94304 7.43996 9.08304 7.43996C8.22304 7.43996 6.82304 6.43996 5.42304 6.43996C3.53777 6.49888 1.82343 7.54801 0.91304 9.19996C-1.00696 12.54 0.42304 17.44 2.24304 20.18C3.15304 21.5 4.24304 22.99 5.68304 22.94C7.12304 22.89 7.58304 22.05 9.25304 22.05C10.923 22.05 11.393 22.94 12.853 22.91C14.313 22.88 15.323 21.56 16.243 20.24C16.8076 19.4096 17.2677 18.5128 17.613 17.57C17.6977 17.3377 17.5999 17.0785 17.383 16.96C15.8069 16.1385 14.8197 14.5073 14.823 12.73Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3476_11398)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5 1.4248H22.6434V27.9412H-5L-5 1.4248Z"
          fill="white"
        />
      </g>
      <mask
        id="mask1_3476_11398"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="0"
        width="6"
        height="6"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2432 3.61019C12.9742 2.73978 13.4024 1.65525 13.4632 0.520192C13.4694 0.366562 13.4045 0.218622 13.2874 0.119032C13.1702 0.0194524 13.0138 -0.0206876 12.8632 0.0101924C11.7752 0.260072 10.7927 0.844002 10.0532 1.68019C9.31859 2.51593 8.87934 3.57012 8.80316 4.68019C8.79957 4.82366 8.8578 4.96176 8.96303 5.05935C9.06827 5.15693 9.21036 5.20459 9.35316 5.19019C10.4822 5.0496 11.5154 4.48472 12.2432 3.61019Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_3476_11398)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.803 -5H18.4636V10.1927H3.803L3.803 -5Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
