import React, { useState } from "react";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import { APP_TOASTIFY } from "../../../styles/styles.constants";
import { PreUploadMessage } from "./PreUploadMessage/PreUploadMessage";
import { UploadedImagePreview } from "./UploadedImagePreview/UploadedImagePreview";
import {
  StyledAppUploadImageWithPreviewContainer,
  StyledBody,
  StyledBrowseMessage,
  StyledClearIconStyles,
  StyledDragDropMessage,
  StyledExtensionMessage,
  StyledUploadActionContainer,
} from "./AppUploadImageWithPreview.styles";

interface AppUploadImageWithPreviewProps {
  onChange: (file: File) => void;
  onDelete: (e?: boolean) => void;
  isUploading?: boolean;
  id: string;
  currentImage?: string;
  initialMessages?: {
    primary?: string;
    secondary?: string;
  };
  extensions?: string;
  hidePreview?: boolean;
  defaultName?: string;
  errorMessages?: string[];
}

export const AppUploadImageWithPreview: React.FC<
  AppUploadImageWithPreviewProps
> = ({ isUploading = false, ...props }) => {
  const [showUploadContainer, setShowUploadContainer] = useState<boolean>(
    Boolean(props.currentImage)
  );
  const [imagePreview, setImagePreview] = useState<any>(props.currentImage);
  const [imageName, setImageName] = useState<string>(
    props.defaultName as string
  );
  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);

  const handleChange = (file: File) => {
    if (file) {
      const isValidFileType = props.extensions
        ?.replace(/\./g, "")
        .split(",")
        .some((ext) => file.type.includes(ext.trim()));
      const isValidFileSize = file.size <= 10 * 1024 * 1024;
      if (isValidFileSize && isValidFileType) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        setImageName(file.name);
        reader.readAsDataURL(file);
        props.onChange(file);
      } else {
        toast(
          !isValidFileType
            ? props.errorMessages?.[0] || "Only images are accepted"
            : props.errorMessages?.[1] ||
                "Please upload an image file within 10MB size limit.",
          APP_TOASTIFY.ERROR
        );
      }
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const file = e.dataTransfer.files[0];
    handleChange(file);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);
  };

  const handleShowUploadContainer = () => {
    props.onDelete?.(true);
    setShowUploadContainer(true);
    setImagePreview(undefined);
    setImageName("");
  };

  const handleOnClickDelete = () => {
    props.onDelete?.(true);
    setShowUploadContainer(true);
  };

  if (!showUploadContainer) {
    return (
      <StyledAppUploadImageWithPreviewContainer>
        <StyledBody>
          {props.currentImage ? (
            <UploadedImagePreview
              imagePreview={props.currentImage}
              imageName={imageName}
              onClickDelete={handleOnClickDelete}
            />
          ) : (
            <PreUploadMessage
              primaryMessage={props.initialMessages?.primary}
              secondayMessage={props.initialMessages?.secondary}
              onClick={handleShowUploadContainer}
            />
          )}
        </StyledBody>
      </StyledAppUploadImageWithPreviewContainer>
    );
  }

  return (
    <StyledAppUploadImageWithPreviewContainer
      onDrop={showUploadContainer && handleDrop}
      onDragOver={showUploadContainer && handleDragOver}
      onDragLeave={showUploadContainer && handleDragLeave}
      style={{ background: isDraggingOver ? "#f0f0f0" : "transparent" }}
    >
      <StyledBody>
        {imagePreview ? (
          <UploadedImagePreview
            imagePreview={imagePreview}
            imageName={imageName}
            onClickDelete={handleShowUploadContainer}
            hidePreview={props.hidePreview}
          />
        ) : (
          <div>
            <IconButton
              component="label"
              htmlFor={`image-upload${props.id}`}
              style={{ width: "100%", height: "100%" }}
              disableRipple
            >
              <StyledUploadActionContainer>
                <div style={{ display: "flex" }}>
                  <StyledDragDropMessage>
                    Drag & drop the logo file here, or
                  </StyledDragDropMessage>
                  <StyledBrowseMessage id="browse">browse</StyledBrowseMessage>
                </div>
                <StyledExtensionMessage>
                  {props.initialMessages?.secondary}
                </StyledExtensionMessage>
              </StyledUploadActionContainer>

              <input
                id={`image-upload${props.id}`}
                type="file"
                accept={props?.extensions || "image/*"}
                onChange={(e: any) => handleChange(e?.target?.files[0])}
                style={{ display: "none" }}
              />
              <StyledClearIconStyles
                onClick={() => {
                  setShowUploadContainer(false);
                  props.onDelete(false);
                }}
              />
            </IconButton>
          </div>
        )}
      </StyledBody>
    </StyledAppUploadImageWithPreviewContainer>
  );
};
