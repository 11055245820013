import React from "react";
import styled, { useTheme } from "styled-components";
import { Avatar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

interface PageAddButtonProps {
  text: string;
  onClick: () => void;
}

const PageAddButtonContainer = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  transform: "scale(0.8)",
  gap: 10,
  "-webkit-tap-highlight-color": "transparent",
  position: "absolute",
  right: 23,
  top: 110,
  color: theme.colorPrimary,
  "@media only screen and (min-width: 1200px)": {
    right: 40,
    top: 145,
    transform: "scale(1)",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const StyledText = styled.div(({ theme }) => ({
  display: "none",
  "@media only screen and (min-width: 1200px)": {
    display: "block",
    color: theme.colorPrimary,
    fontWeight: 500,
    fontSize: 16,
  },
}));

export const PageAddButton: React.FC<PageAddButtonProps> = (props) => {
  const theme = useTheme();

  return (
    <PageAddButtonContainer onClick={props?.onClick}>
      <StyledText>{props?.text}</StyledText>
      <Avatar
        sx={{
          background: theme.colorPrimary,
          border: `3px solid ${APP_COMMON_COLORS.dark[200]}`,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
        }}
      >
        <AddIcon />
      </Avatar>
    </PageAddButtonContainer>
  );
};
