import styled from "styled-components";
import { AppUploadIcon } from "../icons/AppUploadIcon";

interface PreUploadMessageProps {
  primaryMessage?: string;
  secondayMessage?: string;
  onClick: () => void;
}

const StyledPreUploadMessageContianer = styled.div({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
});

const StyledUploadMessagesContainer = styled.div(({ theme }) => ({
  display: "flex",
  gap: 5,
}));

const StyledUploadMessage = styled.div(({ theme }) => ({
  color: theme.colorPrimary,
  fontWeight: 500,
  fontSize: 14,
}));

export const PreUploadMessage = (props: PreUploadMessageProps) => {
  return (
    <StyledPreUploadMessageContianer onClick={props.onClick}>
      <AppUploadIcon
        containerStyles={{ width: 100, height: 85, marginLeft: -25 }}
      />
      <StyledUploadMessagesContainer>
        <StyledUploadMessage>{props.primaryMessage}</StyledUploadMessage>
        <StyledUploadMessage style={{ fontWeight: 200 }}>
          {props.secondayMessage}
        </StyledUploadMessage>
      </StyledUploadMessagesContainer>
    </StyledPreUploadMessageContianer>
  );
};
