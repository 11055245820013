import styled, { CSSObject } from "styled-components";
import { APP_COMMON_COLORS } from "../../providers/AppThemeProvider";

export const StyledSubTitle = styled.div({
  fontWeight: 600,
  color: APP_COMMON_COLORS.dark[400],
  textAlign: "center",
});

export const StyledBody = styled.div({
  fontWeight: 500,
  color: APP_COMMON_COLORS.dark[400],
  marginBlock: 24,
  textAlign: "center",
});

export const StyledRadioButtonsContainer: CSSObject = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: 60,
};
