import {
  COLLECTIONS_TYPE,
  FIRESTORE_FUNCTIONS,
} from "../../constants/firebase.constants";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { generateRandomString } from "../../helpers/generateRandomString";
import { getFirebaseStorageReference } from "../../services/firebase/config";
import { getDownloadURL, uploadBytes } from "firebase/storage";

export const firebaseHttpsCallable = <Req, Res>(
  functionName: FIRESTORE_FUNCTIONS
) => httpsCallable<Req, Res>(getFunctions(), functionName);

export const uploadImageToStorage = async (
  file: any,
  storageFolder: COLLECTIONS_TYPE | string,
  fileName?: string
) => {
  const fileExtention = file?.type?.split("/") && file?.type?.split("/")[1];
  const randomFileName =
    fileName || `${generateRandomString(52, true)}.${fileExtention}`;
  const fileRef = getFirebaseStorageReference(
    `${storageFolder}/${randomFileName}`
  );

  return new Promise((resolve, reject) => {
    uploadBytes(fileRef, file)
      .then((snapshot: any) => {
        getDownloadURL(snapshot.ref)
          .then((url: string) => {
            resolve(url);
          })
          .catch((error: any) => {
            console.log(error);
            reject(error);
          });
      })
      .catch((error: any) => {
        console.log(error);
        reject(error);
      });
  });
};

export const setFirebaseFunctionsPort = () => {
  if (localStorage.getItem("devfunc") === "true") {
    const defaultPort = 5001;
    const functions = getFunctions();
    connectFunctionsEmulator(functions, "localhost", defaultPort);
  }
};
