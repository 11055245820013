import { useAtomValue } from "jotai";
import { ROLES_ATOMS } from "../../RolesPage.atoms";
import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";
import { Circle } from "@mui/icons-material";

const StyledDivider = styled.div({
  borderBottom: `1px dashed ${APP_COMMON_COLORS.dark[300]}`,
  height: 1,
  marginBlock: 20,
});

const StyledPermissionContainer = styled.div({
  display: "flex",
  gap: 20,
  alignItems: "center",
  marginBottom: 5,
});

export const SideInfoPermissionsInRole = () => {
  const selectedPermissions = useAtomValue(ROLES_ATOMS.selectedPermissions);

  return (
    <div>
      <div>Permissions</div>
      <StyledDivider />
      {Object.values(selectedPermissions).map((permission) => {
        return (
          permission.isChecked && (
            <StyledPermissionContainer key={permission.value}>
              <Circle
                style={{ fontSize: 10, color: APP_COMMON_COLORS.dark[300] }}
              />
              <div
                key={permission.value}
                style={{ color: APP_COMMON_COLORS.dark[600] }}
              >
                {permission.label}
              </div>
            </StyledPermissionContainer>
          )
        );
      })}
    </div>
  );
};
