import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { _fs } from "../../services/firebase/config";

/** get data by key and value
 * note: you can use this function inside useEffect
 */
export const getFirebaseDataByColDocColKeyVal = async (
  collection: COLLECTIONS_TYPE,
  doc: string,
  innerCollection: COLLECTIONS_TYPE,
  fbKey: string,
  fbValue: string,
  orderBy?: { orderKey: string; direction: "desc" | "asc" }
) => {
  let _data: any;

  let ref = _fs
    .collection(collection)
    .doc(doc)
    .collection(innerCollection)
    .where(fbKey || "", "==", fbValue || "");

  if (orderBy?.direction && orderBy.orderKey) {
    ref = ref.orderBy(orderBy?.orderKey, orderBy?.direction);
  }

  return await ref
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((_doc) => {
        if (_doc?.data()) {
          _data = {
            ..._data,
            [_doc.id]: { ..._doc.data(), docId: _doc.id },
          };
        }
      });
      return _data;
    })
    .catch((error) => {
      return { status: "nok", error };
    });
};
