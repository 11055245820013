import { useEffect, useState } from "react";
import { _fs } from "../../services/firebase/config";
import { FB_QUERYSNAPSHOT } from "../../types/firbase.types";
import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { RowProps } from "../../components/core/AppTable/types";
import styled, { useTheme } from "styled-components";
import { APP_COMMON_COLORS } from "../../providers/AppThemeProvider";
import { GROUP } from "../../types/groups/groups.types";
import { TableData } from "../../components/core/AppTable/TableBody/TableBody";
import GroupIcon from "@mui/icons-material/Group";
import AddIcon from "@mui/icons-material/Add";
import AppSelectButton from "../../components/core/AppSelectButton/AppSelectButton";
import { ThreeDotsIcon } from "../../assets/icons/ThreeDotsIcon";
import { useNavigate } from "react-router-dom";
import { ViewSubGroupsTree } from "./SubGroupsTree/ViewSubGroupsTree/ViewSubGroupsTree";
import { useAtom } from "jotai";
import { GROUPS_PAGE_ATOMS } from "./GroupsPage.Atoms";
import Skeleton from "@mui/material/Skeleton";
import { GROUP_MODAL_ATOMS } from "./GroupFormModal/GroupFormModal.Atoms";
import { mapFlatTreeToNestedTree } from "./SubGroupsTree/SubGroupsTree.helpers";
import { APP_ROUTES } from "../../constants/routes.constant";
import firebase from "firebase/compat/app";
import { compareAlphanumeric } from "../../helpers/compareAlphanumeric";

export const StyledLogoImage = styled.img({
  display: "block",
  width: 35,
});

export const StyledActions = styled.div({
  display: "flex",
  justifyContent: "end",
  gap: 15,
});

export const StyledClientName = styled.div({
  alignSelf: "center",
  fontSize: 14,
  color: APP_COMMON_COLORS.yourzown.colorPrimary,
});

const StyledGroupNameContainer = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 20,
});

const sortByName = (
  a: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
  b: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
) => {
  const nameA = a?.data()?.groupName?.toLowerCase();
  const nameB = b?.data()?.groupName?.toLowerCase();

  return compareAlphanumeric(nameA, nameB);
};

export const useFirebaseStreamGroups = (clientId: string) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [rows, setRows] = useState<any>([]);
  const [fetched, setFetched] = useState<boolean>(false);
  const [groupMembersCount, setGroupMembersCount] = useAtom(
    GROUPS_PAGE_ATOMS.groupMembersCount
  );
  const [isOpen, setIsOpen] = useAtom(GROUP_MODAL_ATOMS.isOpen);
  const [isEditMode, setIsEditMode] = useAtom(GROUP_MODAL_ATOMS.isEditMode);
  const [tree, setTree] = useAtom(GROUP_MODAL_ATOMS.tree);
  const [groupName, setGroupName] = useAtom(GROUP_MODAL_ATOMS.groupName);
  const [groupId, setGroupId] = useAtom(GROUP_MODAL_ATOMS.groupId);

  const handleEditGroup = (group: GROUP) => {
    setIsOpen(true);
    setIsEditMode(true);
    setTree([mapFlatTreeToNestedTree(group.subGroups || {})]);
    setGroupName(group.groupName);
    setGroupId(group.groupId);
  };

  useEffect(() => {
    const unsubscribe = _fs
      .collection(COLLECTIONS_TYPE.groups)
      .doc(clientId)
      .collection(COLLECTIONS_TYPE.clientGroups)
      .onSnapshot(async (querySnapshot: FB_QUERYSNAPSHOT) => {
        let _rows: RowProps[] = [];
        let _row: RowProps = {} as RowProps;
        querySnapshot?.docs?.sort?.(sortByName)?.forEach((doc) => {
          const { groupName, subGroups, groupId } = doc?.data() as GROUP;
          const subGroupsCount: number = Object.keys(subGroups || {})?.length;

          _row = {
            id: [
              groupId,
              groupName,
              ...Object.values(subGroups || {}).map?.(
                (subgroup) => subgroup.subGroupName
              ),
            ].join(" "),
            cells: [
              <StyledGroupNameContainer>
                {subGroupsCount > 1 ? (
                  <AddIcon
                    style={{ color: theme.colorPrimary, cursor: "pointer" }}
                  />
                ) : (
                  <GroupIcon style={{ color: theme.colorPrimary }} />
                )}
                <div>{groupName}</div>
              </StyledGroupNameContainer>,
              String(subGroupsCount - 1),
              groupMembersCount ? (
                String(groupMembersCount?.childrenIds?.[groupId]?.length || 0)
              ) : (
                <Skeleton style={{ width: 20 }} />
              ),
              groupMembersCount ? (
                String(groupMembersCount?.membersIds?.[groupId]?.length || 0)
              ) : (
                <Skeleton style={{ width: 20 }} />
              ),
              <AppSelectButton
                containerStyle={{ justifyContent: "end" }}
                title={<ThreeDotsIcon />}
                buttonsList={["View members", "Edit group"]}
                buttonActions={[
                  () =>
                    navigate(
                      APP_ROUTES.members +
                        `?subGroupId=${groupId}` +
                        `&subGroupName=${groupName}`
                    ),

                  () => handleEditGroup(doc?.data() as GROUP),
                ]}
              />,
            ],
            masterDetails:
              subGroupsCount > 1 ? (
                <table
                  style={{
                    width: "100%",
                    borderRadius: 10,
                  }}
                >
                  <TableData
                    rows={[
                      {
                        cells: [
                          <ViewSubGroupsTree flatTree={subGroups || {}} />,
                        ],
                      },
                    ]}
                    customstylesTableTr={{
                      ":last-child": { paddingInlineEnd: 0 },
                    }}
                    customstylesTableTd={{
                      height: "10px",
                      borderBottom: `1px dashed ${APP_COMMON_COLORS.dark[300]}`,
                    }}
                  />
                </table>
              ) : null,
          };
          _rows.push(_row);
        });

        setRows(_rows);
        setFetched(true);
      });
    return () => unsubscribe();
  }, [fetched, groupMembersCount?.childrenIds, groupMembersCount?.membersIds]);

  return fetched ? rows : null;
};
