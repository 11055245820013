import { SUB_GROUPS } from "../../../types/groups/groups.types";
import { Node, SubGroup } from "./SubGroupsTree.types";

export const mapNestedTreeToFlatTree = (nodes: Node[], groupId: string) => {
  let flatNodes: SUB_GROUPS = {};
  nodes.forEach((node: Node) => {
    flatNodes[node.subGroupId] = {
      groupId,
      subGroupId: node.subGroupId,
      subGroupName: node.subGroupName,
      parentId: node.parentId,
    };
    if (node.children && node.children.length > 0) {
      Object.assign(flatNodes, mapNestedTreeToFlatTree(node.children, groupId));
    }
  });
  return flatNodes;
};

export const mapFlatTreeToNestedTree = (flatNodes: {
  [key: string]: SubGroup;
}): Node => {
  const nodeMap: { [key: string]: Node } = {};

  for (const subGroupId in flatNodes) {
    const subGroup = flatNodes[subGroupId];
    nodeMap[subGroupId] = {
      ...subGroup,
      children: [],
    };
  }

  let rootNode: Node | null = null;

  for (const subGroupId in nodeMap) {
    const node = nodeMap[subGroupId];
    if (node.parentId === node.subGroupId) {
      rootNode = node;
    } else {
      const parent = nodeMap[node.parentId!];
      if (parent) {
        parent.children.push(node);
      }
    }
  }

  if (!rootNode) {
    return undefined as any;
    // throw new Error("Root node not found");
  }

  const sortChildren = (node: Node) => {
    node.children.sort((a, b) => a.subGroupId.localeCompare(b.subGroupId));
    node.children.forEach(sortChildren);
  };

  sortChildren(rootNode);

  return rootNode;
};
