import { StyledPageContainer } from "../../components/shared/StyledComponents/StyledPageContainer";
import { PageAddButton } from "../../components/shared/PageAddButton/PageAddButton";
import { AppModal } from "../../components/core/AppModal/AppModal";
import { useModal } from "../../components/core/AppModal/useModal";
import { AddEditTemplateModal } from "./AddEditTemplateModal/AddEditTemplateModal";
import { AppTable } from "../../components/core/AppTable/AppTable";
import { useEffect, useState } from "react";
import { RowProps } from "../../components/core/AppTable/types";
import { useFirebaseStreamTemplates } from "./useFirebaseStreamTemplates";
import { AppSpinner } from "../../components/core/AppSpinner/AppSpinner";
import {
  StyledFirstHeader,
  StyledTemplatesPageTools,
} from "./TemplatesPages.styles";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../redux/client/clientSlice";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  FORM_FIELD_ATOMS,
  TEMPLATES_ATOMS,
  TEMPLATE_STEPPER_TITLES,
} from "./AddEditTemplateModal/AddEditTemplateModal.atoms";
import {
  DELETE_TEMPLATE_DTO,
  TEMPLATE_TYPE,
} from "../../types/templates/templates.types";
import { DeleteModal } from "../../components/shared/DeleteModal/DeleteModal";
import { TemplateIconDeleteModal } from "./DeleteTemplate/TemplateIconDeleteModal";
import { DELETE_TEMPLATE_ATOM } from "./DeleteTemplate/DeleteTemplate.atoms";
import { firebaseHttpsCallable } from "../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../constants/firebase.constants";
import { GENERAL_REPONSE } from "../../types/firbase.types";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../styles/styles.constants";
import { FORM_FIELDS_DISPLAY_STATES } from "./AddEditTemplateModal/FormPostFieldsV2/FormPostFields.types";

export const TemplatesPage = () => {
  const isPublished = useAtomValue(TEMPLATES_ATOMS.isPublished);
  const setTemplateName = useSetAtom(TEMPLATES_ATOMS.templateName);
  const setTemplateType = useSetAtom(TEMPLATES_ATOMS.templateType);
  const setCurrentModalStep = useSetAtom(TEMPLATES_ATOMS.currentModalStep);
  const setIsPublished = useSetAtom(TEMPLATES_ATOMS.isPublished);
  const setAdditionalFieldsComponent = useSetAtom(
    TEMPLATES_ATOMS.additionalFieldsComponent
  );
  const setEventAdditionalFields = useSetAtom(
    TEMPLATES_ATOMS.eventAdditionalFields
  );
  const setEventCustomFields = useSetAtom(TEMPLATES_ATOMS.eventCustomFields);
  const setPerformancePostFields = useSetAtom(
    TEMPLATES_ATOMS.performancePostFields
  );
  const [isEditMode, setIsEditMode] = useAtom(TEMPLATES_ATOMS.isEditMode);
  const [isModalOpen, setIsModalOpen] = useAtom(TEMPLATES_ATOMS.isModalOpen);
  const [isLoading, setIsLoading] = useState(true);
  const setTemplateStepperTitles = useSetAtom(
    TEMPLATES_ATOMS.templateStepperTitles
  );

  const [deleteTemplateName, setDeleteTemplateName] = useAtom(
    DELETE_TEMPLATE_ATOM.templateName
  );
  const [deleteTemplateId, setDeleteTemplateId] = useAtom(
    DELETE_TEMPLATE_ATOM.templateId
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useAtom(
    DELETE_TEMPLATE_ATOM.isModalOpen
  );
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const setAccidentPostAdditionalFields = useSetAtom(
    TEMPLATES_ATOMS.formPostAdditionalFields
  );
  const [displayState, setDisplayState] = useAtom(
    FORM_FIELD_ATOMS.displayState
  );
  const [fieldSelectType, setFieldSelectType] = useAtom(
    FORM_FIELD_ATOMS.fieldSelectType
  );

  const handleResetDeleteModal = () => {
    setDeleteTemplateName("");
    setDeleteTemplateId("");
    setIsDeleteModalOpen(false);
    setIsDeleteLoading(false);
  };

  const handleDeleteTemplate = () => {
    setIsDeleteLoading(true);
    const payload: DELETE_TEMPLATE_DTO = {
      clientId: String(client?.clientId),
      templateId: deleteTemplateId,
    };
    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.deleteTemplate)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_REPONSE;
        if (data.result === "ok") {
          toast("Template deleted", APP_TOASTIFY.SUCCESS);
        } else {
          toast("Error while deleting template [2]", APP_TOASTIFY.ERROR);
        }
      })
      .catch((e) =>
        toast("Error while deleting template [3]", APP_TOASTIFY.ERROR)
      )
      .finally(() => handleResetDeleteModal());
  };

  const client = useSelector(getClientSelector);

  const rows: RowProps[] = useFirebaseStreamTemplates(String(client?.clientId));

  let columns = [
    {
      cell: <StyledFirstHeader>Name</StyledFirstHeader>,
    },

    {
      cell: <div>Type</div>,
    },
    {
      cell: (
        <div style={{ display: "flex", justifyContent: "end" }}>Actions</div>
      ),
    },
  ];

  useEffect(() => {
    rows && setIsLoading(false);
  }, [rows]);

  const handleCloseModal = () => {
    setTemplateName("");
    setTemplateType(undefined);
    setCurrentModalStep(0);
    setAdditionalFieldsComponent("DEFINED_FIELDS");
    setEventAdditionalFields({});
    setIsPublished(false);
    setEventCustomFields([]);
    setPerformancePostFields([]);
    setIsModalOpen(false);
    setIsEditMode(false);
    setTemplateStepperTitles(TEMPLATE_STEPPER_TITLES[TEMPLATE_TYPE.quickPost]);
    setAccidentPostAdditionalFields([]);
    setDisplayState(FORM_FIELDS_DISPLAY_STATES.FIELDS_ROWS);
    setFieldSelectType(undefined);
  };

  useEffect(() => {
    if (isPublished) {
      handleCloseModal();
    }
  }, [isPublished]);

  if (isLoading) {
    return (
      <StyledPageContainer>
        <AppSpinner />
      </StyledPageContainer>
    );
  }

  return (
    <StyledPageContainer>
      <StyledTemplatesPageTools>
        <PageAddButton
          text={"Create template"}
          onClick={() => setIsModalOpen(true)}
        />
        <AppModal
          isOpen={isModalOpen}
          closeModal={handleCloseModal}
          $customContentStyle={{ padding: 0 }}
        >
          <AddEditTemplateModal />
        </AppModal>
      </StyledTemplatesPageTools>
      <AppTable
        columns={columns}
        rows={rows}
        showPagination={false}
        showTotalCount={false}
      />

      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          title={"Delete template"}
          deleteBtnLabel={"DELETE TEMPLATE"}
          onDeleteButton={handleDeleteTemplate}
          onCloseDeleteModal={handleResetDeleteModal}
          deleteContent={{
            title: "YES, DELETE THIS TEMPLATE",
            subTitle: deleteTemplateName,
            icon: <TemplateIconDeleteModal />,
          }}
          isLoading={isDeleteLoading}
        />
      )}
    </StyledPageContainer>
  );
};
