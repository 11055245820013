import { createSlice } from "@reduxjs/toolkit";
import { GlobalState } from "../../types/global";
import {
  ADD_EDIT_CLIENT_MODAL,
  CLIENT_STATE,
} from "../../types/client/client.types";

const initialState: CLIENT_STATE = {
  branding: {
    colorPrimary: "",
    colorSecondary: "",
    welcomeMessage: "",
  },
  clientName: "",
  clientId: "",
  modals: {
    addEditClientModalData: null,
  },
} as CLIENT_STATE;

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClient(state, action: { payload: CLIENT_STATE }) {
      state!.branding!.logoUrl = action?.payload?.branding?.logoUrl;
      state!.branding!.backgroundImageUrl =
        action?.payload?.branding?.backgroundImageUrl;
      state!.branding!.brandMarkUrl = action?.payload?.branding?.brandMarkUrl;
      state!.branding!.colorPrimary = String(
        action?.payload?.branding?.colorPrimary
      );
      state!.branding!.colorSecondary = String(
        action?.payload?.branding?.colorSecondary
      );
      state!.branding!.welcomeMessage = String(
        action?.payload?.branding?.welcomeMessage
      );
      state.clientId = action.payload.clientId;
      state.domain = action.payload.domain;
      state.clientName = action.payload.clientName;
      state.paymentAccountStatus = action.payload.paymentAccountStatus;
      state.paymentAccountId = action.payload.paymentAccountId;
    },
    setClientId(state, action: { payload: { clientId: string } }) {
      state.clientId = action.payload.clientId;
    },
    removeClient(state) {
      Object.assign(state, initialState);
    },
    setAddEditClientModalData(
      state,
      action: { payload: ADD_EDIT_CLIENT_MODAL | null }
    ) {
      state!.modals!.addEditClientModalData = action.payload;
    },
  },
});

export const {
  setClient,
  setClientId,
  setAddEditClientModalData,
  removeClient,
} = clientSlice.actions;

export const getClientSelector = (state: GlobalState) => state.client || {};

export const getAddEditClientModalData = (
  state: GlobalState
): CLIENT_STATE | {} => state!.client!.modals!.addEditClientModalData || {};

export default clientSlice.reducer;
