export enum FORM_FIELDS_TYPES {
  textInput = "freeTextField",
  singleChoice = "singleChoice",
}

export type FORM_FIELD = FORM_TEXT_INPUT_FIELD | FORM_SINGLE_CHOICE_FIELD;

export interface FORM_TEXT_INPUT_FIELD {
  label: string;
  required: boolean;
  type: FORM_FIELDS_TYPES.textInput;
}

export interface FORM_SINGLE_CHOICE_FIELD {
  label: string;
  required: boolean;
  options: string[];
  type: FORM_FIELDS_TYPES.singleChoice;
}

export enum FORM_FIELDS_DISPLAY_STATES {
  FIELDS_ROWS = "FIELDS_ROWS",
  ADD_NEW_FIELD = "ADD_NEW_FIELD",
}

export const FORM_FIELD_CUSTOM_FIELDS = [
  { label: "Text Input", value: FORM_FIELDS_TYPES.textInput },
  { label: "Single Choice", value: FORM_FIELDS_TYPES.singleChoice },
];
