import React from "react";
import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../providers/AppThemeProvider";
import { PlayStoreIcon } from "../../assets/icons/PlayStoreIcon";
import { AppleIcon } from "../../assets/icons/AppleIcon";
import { YourZownIcon } from "../../assets/icons/YourZownIcon";
import { useLocation } from "react-router-dom";

const StyledPageContainer = styled.div({
  width: "100%",
  height: "100vh",
  background: APP_COMMON_COLORS.yourzown.colorPrimary,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: APP_COMMON_COLORS.common.white,
});

const StyledPlatformContainer = styled.a({
  display: "flex",
  justifyContent: "start",
  alignItems: "start",
  gap: 20,
  backgroundColor: APP_COMMON_COLORS.common.black,
  marginBottom: 25,
  padding: 20,
  borderRadius: 15,
  cursor: "pointer",
  color: "white",
  textDecoration: "none",
});

const Divider = styled.div({
  width: "50%",
  minWidth: 300,
  marginBlock: 25,
  border: `1px dashed #D2CECE50`,
});
const APP_STORE_APP_LINK =
  "https://apps.apple.com/gb/app/yourzown/id6480378140";
const PLAY_STORE_APP_LINK =
  "https://play.google.com/store/apps/details?id=com.yourzownapp.yourzownapp";
export const DownloadMobileAppsPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isRedirectedFromResetPassword =
    params.get("redirectedFrom") === "reset-password";
  return (
    <StyledPageContainer>
      <YourZownIcon width="90" height="90" />
      <h2>
        {isRedirectedFromResetPassword
          ? "Your password has been reset!"
          : "Your account has been created!"}
      </h2>
      <h4 style={{ fontWeight: "400", textAlign: "center", paddingInline: 10 }}>
        If you haven't already, download the app on your mobile device and sign
        in to continue.
      </h4>

      <Divider />
      <div>
        <StyledPlatformContainer href={APP_STORE_APP_LINK}>
          <AppleIcon />
          <span>Download on App store</span>
        </StyledPlatformContainer>
        <StyledPlatformContainer href={PLAY_STORE_APP_LINK}>
          <PlayStoreIcon />
          <span>Download on Google Play</span>
        </StyledPlatformContainer>
      </div>
    </StyledPageContainer>
  );
};
