import { Dayjs } from "dayjs";

export const tsToDayMonthYear = (ts?: number) => {
  if (!ts) return "";
  const date = new Date(ts);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const dayJsToTs = (dayjs: Dayjs): number => {
  const day = Number(dayjs.toDate().getDate());
  const month = Number(dayjs.toDate().getMonth());
  const year = Number(dayjs.toDate().getFullYear());
  const newDate = new Date(year, month, day);
  return newDate.getTime();
};
