import { Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import { StyledAppModalSubmitButtonContainer } from "../../../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppButton } from "../../../../../../components/core/AppButton/AppButton";
import { AppFormikField } from "../../../../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { useAtom, useSetAtom } from "jotai";
import { StyleForm, StyleInputs } from "./RoleSingleChoice.styles";
import { ROLES_ATOMS } from "../../../../RolesPage.atoms";
import { DISPLAY_STATES } from "../../../../RolesPage.types";
import {
  StyledAddAndCancelContainer,
  StyledCancel,
} from "../../../CreateRoleModal.styles";
import { InputAdornment } from "@mui/material";
import { AddIconWithLabel } from "../../../../../../components/shared/AddIconWithLabel/AddIconWithLabel";
import {
  ROLE_FIELDS_TYPES,
  SINGLE_CHOICE_FIELD,
} from "../../../../../../types/roles/roles.types";
import { StyledDoDisturbOnIcon } from "../../../../../../components/shared/StyledComponents/shared-styled-components";

interface RoleSingleChoiceValue {
  label: string;
  options: string[];
}
const schema = Yup.object({
  label: Yup.string().required("Required"),
});

const initialValues = {
  label: "",
  options: ["", ""],
};

export const RoleSingleChoice = () => {
  const setDisplayState = useSetAtom(ROLES_ATOMS.customStepDisplayState);
  const setFieldSelectType = useSetAtom(ROLES_ATOMS.customFieldSelectType);
  const [addedFields, setAddedFields] = useAtom(ROLES_ATOMS.addedFields);

  const resetForm = () => {
    setFieldSelectType("");
    setDisplayState(DISPLAY_STATES.FIELDS_ROWS);
  };

  const handleOnSubmit = (values: RoleSingleChoiceValue) => {
    const singleChoiceField: SINGLE_CHOICE_FIELD = {
      type: ROLE_FIELDS_TYPES.singleChoice,
      label: values.label,
      options: values.options,
      required: false,
    };

    const dynamicValidationSchema = Yup.object().shape({
      label: Yup.string().required("Required"),
      options: Yup.array()
        .of(Yup.string().required("Option is required"))
        .min(1, "At least one option is required"),
    });

    dynamicValidationSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        setAddedFields([...addedFields, singleChoiceField]);
        resetForm();
      })
      .catch((errors) => {
        return;
      });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values: RoleSingleChoiceValue) => handleOnSubmit(values)}
    >
      {({
        errors,
        touched,
        values,
        submitCount,
        handleSubmit,
        handleBlur,
        setFieldValue,
        setErrors,
      }) => {
        const _touched: FormikTouched<RoleSingleChoiceValue> = touched;
        const _errors: FormikErrors<RoleSingleChoiceValue> = errors;

        const handleOptionValueOnChange = (e: string, index: number) => {
          const newOptions = [...values.options];
          newOptions[index] = e;
          setFieldValue("options", newOptions);
        };

        const customHandleChange = (name: string, value: string) => {
          setFieldValue(name, value);
        };

        return (
          <StyleForm>
            <StyleInputs>
              <AppFormikField
                containerstyle={{ marginBottom: 8 }}
                name="label"
                isRequired={true}
                value={values.label || ""}
                label="Single Choice label"
                errorMessage={
                  (_touched.label && _errors.label) ||
                  (submitCount > 0 && errors.label)
                    ? String(errors.label)
                    : ""
                }
                onBlur={handleBlur}
                onChange={(e) => customHandleChange("label", e.target.value)}
                type="text"
              />

              {values.options.map((option, index) => {
                return (
                  <AppFormikField
                    key={index}
                    containerstyle={{ marginBottom: 8 }}
                    isRequired={true}
                    name={`options[${index}]`}
                    value={values.options[index] || ""}
                    label={`Option ${index + 1}`}
                    errorMessage={
                      submitCount > 0 && !values.options[index]
                        ? "Required"
                        : ""
                    }
                    onChange={(e) =>
                      handleOptionValueOnChange(e.target.value, index)
                    }
                    type="text"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {index > 1 && (
                            <StyledDoDisturbOnIcon
                              onClick={() => {
                                const newOptions = [...values.options];
                                newOptions.splice(index, 1);
                                setFieldValue("options", newOptions);
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                );
              })}

              <AddIconWithLabel
                label="Add Option"
                onClick={() =>
                  setFieldValue("options", [...values.options, ""])
                }
              />
            </StyleInputs>

            <StyledAddAndCancelContainer>
              <StyledAppModalSubmitButtonContainer style={{ margin: 0 }}>
                <AppButton onClick={handleSubmit}>Add</AppButton>
              </StyledAppModalSubmitButtonContainer>
              <StyledCancel onClick={resetForm}>CANCEL</StyledCancel>
            </StyledAddAndCancelContainer>
          </StyleForm>
        );
      }}
    </Formik>
  );
};
