import { useAtom } from "jotai";
import { TEMPLATES_ATOMS } from "../../AddEditTemplateModal.atoms";
import {
  StyledTitle,
  StyledLabel2,
  StyledDivider,
  StyledDataContainer,
  StyledLabel3,
  StyledClose,
  StyledFlexDiv,
} from "./PerformanceSideInfo.styles";

export const PerformanceSideInfo: React.FC = () => {
  const [performancePostFields, setPerformancePostFields] = useAtom(
    TEMPLATES_ATOMS.performancePostFields
  );

  const handleRemoveField = (index: number) => {
    const newArray = [...performancePostFields];
    newArray.splice(index, 1);
    setPerformancePostFields(newArray);
  };

  return (
    <div>
      <StyledTitle>Metrics in player development</StyledTitle>

      <StyledDivider />

      {performancePostFields.length > 0 && (
        <StyledDataContainer>
          <StyledLabel2>Metric</StyledLabel2>
          <StyledLabel2>Rating</StyledLabel2>
        </StyledDataContainer>
      )}

      {performancePostFields.length > 0 &&
        performancePostFields.map((field, index) => {
          return (
            <StyledDataContainer style={{ marginBottom: 5 }} key={index}>
              <StyledFlexDiv title="Remove metric">
                <StyledClose onClick={() => handleRemoveField(index)} />
                <StyledLabel3>{field.metricTitle}</StyledLabel3>
              </StyledFlexDiv>
              <StyledLabel3>{field.starRating}</StyledLabel3>
            </StyledDataContainer>
          );
        })}
    </div>
  );
};
