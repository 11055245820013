import firebase from "firebase/compat/app";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutAndRemoveUserData } from "../../redux/user/userSlice";
import { removeClient } from "../../redux/client/clientSlice";

export const useFirebaseLogoutUser = () => {
  const dispatch = useDispatch();

  return () => {
    firebase
      .auth()
      .signOut()
      .then((_user: any) => {
        dispatch(logoutAndRemoveUserData());
        dispatch(removeClient());
        localStorage.removeItem("selectedClientId");
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
