import React from "react";
import styled from "styled-components";
import {
  StyledSectionSubtitle,
  StyledSectionTitle,
} from "../BrandingPage.styles";
import { AppUploadImageWithPreview } from "../../../components/core/AppUploadImageWithPreview/AppUploadImageWithPreview";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../redux/client/clientSlice";

interface UploadLogoProps {
  setLogoFile: (file: File | undefined) => void;
  setLogoFileDeleted: (e: boolean) => void;
}

const StyledUploadLogoContainer = styled.div({});

export const UploadLogo = (props: UploadLogoProps) => {
  const client = useSelector(getClientSelector);

  return (
    <StyledUploadLogoContainer>
      <StyledSectionTitle>Logo</StyledSectionTitle>
      <StyledSectionSubtitle>
        Displayed across the app and email templates
      </StyledSectionSubtitle>
      <AppUploadImageWithPreview
        onChange={(e: File) => {
          props.setLogoFile(e);
          props.setLogoFileDeleted(false);
        }}
        onDelete={(e: boolean | undefined) => {
          props.setLogoFileDeleted(Boolean(e));
          props.setLogoFile(undefined);
        }}
        id={"logo"}
        initialMessages={{ primary: "upload logo", secondary: "(.PNG .JPEG)" }}
        currentImage={client.branding?.logoUrl}
        extensions=".png, .PNG, .jpeg, .JPEG"
      />
    </StyledUploadLogoContainer>
  );
};
