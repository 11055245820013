import styled from "styled-components";

export const StyledGroupsPageTools = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const StyledSubmitButtonContainer = styled.div({
  marginTop: "auto",
  marginBottom: 25,
  "@media only screen and (min-width: 1200px)": {
    width: 200,
  },
});

export const StyledAddEditGroupModalContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  h2: {
    marginBottom: 40,
  },
});

export const StyledGroupForm = styled.div({
  display: "flex",
  flexWrap: "wrap",
  height: "-webkit-fill-available",
  overflow: "auto",
  paddingTop: 10,
  "@media only screen and (min-width: 1200px)": {
    paddingTop: 0,
  },
});

export const StyledGroupFormContainer = styled.div({
  maxWidth: "700px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  height: "100%",
});
