import { CSSObject, useTheme } from "styled-components";

export const SelectColorIcon = (props: { containerStyles?: CSSObject }) => {
  const theme = useTheme();
  return (
    <div style={{ ...props?.containerStyles }}>
      <svg
        width="98"
        height="98"
        viewBox="0 0 98 98"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_1204_2161)">
          <rect
            x="24"
            y="19.0039"
            width="50"
            height="50"
            rx="25"
            fill={theme.colorPrimary}
          />
          <rect
            x="22"
            y="17.0039"
            width="54"
            height="54"
            rx="27"
            stroke="white"
            strokeOpacity="0.5"
            strokeWidth="4"
          />
        </g>
        <path
          d="M54 41.0039C54.2652 41.0039 54.5196 40.8985 54.7071 40.711C54.8946 40.5235 55 40.2691 55 40.0039C55 39.7387 54.8946 39.4843 54.7071 39.2968C54.5196 39.1093 54.2652 39.0039 54 39.0039H53.25C53.1837 39.0039 53.1201 38.9776 53.0732 38.9307C53.0263 38.8838 53 38.8202 53 38.7539V36.5039C53.0611 35.378 52.675 34.2736 51.9259 33.4308C51.1768 32.5881 50.1253 32.0753 49 32.0039C47.8747 32.0753 46.8232 32.5881 46.0741 33.4308C45.325 34.2736 44.9389 35.378 45 36.5039V38.7539C45 38.8202 44.9737 38.8838 44.9268 38.9307C44.8799 38.9776 44.8163 39.0039 44.75 39.0039H44C43.7348 39.0039 43.4804 39.1093 43.2929 39.2968C43.1054 39.4843 43 39.7387 43 40.0039C43 40.2691 43.1054 40.5235 43.2929 40.711C43.4804 40.8985 43.7348 41.0039 44 41.0039H54Z"
          fill="white"
        />
        <path
          d="M45.2501 42.5039C45.1838 42.5039 45.1202 42.5302 45.0733 42.5771C45.0264 42.624 45.0001 42.6876 45.0001 42.7539V49.5039C44.9955 50.2765 45.1637 51.0404 45.4925 51.7396C45.8212 52.4388 46.3022 53.0556 46.9001 53.5449C46.9311 53.5682 46.9562 53.5985 46.9736 53.6332C46.9909 53.6679 47 53.7061 47.0001 53.7449V55.0039C47.0001 55.2691 47.1054 55.5235 47.293 55.711C47.4805 55.8986 47.7349 56.0039 48.0001 56.0039H50.0001C50.2653 56.0039 50.5197 55.8986 50.7072 55.711C50.8947 55.5235 51.0001 55.2691 51.0001 55.0039V53.7469C51.0002 53.7081 51.0092 53.6699 51.0266 53.6352C51.0439 53.6005 51.0691 53.5702 51.1001 53.5469C51.6983 53.0574 52.1794 52.4402 52.5081 51.7407C52.8369 51.0411 53.005 50.2769 53.0001 49.5039V42.7539C53.0001 42.6876 52.9738 42.624 52.9269 42.5771C52.88 42.5302 52.8164 42.5039 52.7501 42.5039H45.2501Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_1204_2161"
            x="0"
            y="0.00390625"
            width="98"
            height="98"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="5" />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1204_2161"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1204_2161"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
