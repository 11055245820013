import { TextField, MenuItem } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import styled from "styled-components";
import { countriesList } from "../../../../constants/countriesList.constant";

export const AppPhoneNumberSelect = (props: any) => {
  const countryOptions = countriesList.map((country) => (
    <MenuItem value={country.dialCode}>
      <ReactCountryFlag
        countryCode={country.code}
        svg
        style={{ marginRight: "1rem", width: "1.5rem", height: "1.5rem" }}
      />
      {country.dialCode}
    </MenuItem>
  ));

  return (
    <StyledSelectField select {...props} variant="filled">
      {countryOptions}
    </StyledSelectField>
  );
};

const StyledSelectField = styled(TextField)({
  width: 250,
  "@media (min-width:1200px)": {
    width: 190,
  },
  ".MuiFilledInput-input": {
    padding: 0,
    background: "transparent",
    backgroundColor: "transparent !important",
  },
  ".MuiInputBase-formControl": {
    background: "transparent !important",
  },
  ".MuiInputBase-formControl::before": {
    border: "none !important",
  },
});
