export const YourZownIcon: React.FC<{
  width?: string;
  height?: string;
}> = ({ width = "30", height = "30" }) => {
  return (
    <svg
      version="1.1"
      id="White_Lettering_On_Blue"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 308.558 308.558"
      enable-background="new 0 0 308.558 308.558"
    >
      <g>
        <path
          fill="#21409A"
          d="M0.516,269.115c0,22.092,17.909,40,40,40h228c22.092,0,40-17.908,40-40V40.508c0-22.091-17.908-40-40-40
		h-228c-22.091,0-40,17.909-40,40V269.115z"
        />
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M92.824,74.989c-2.828-4.718-9.641-8.578-15.141-8.578H53.918c-5.5,0-7.717,3.878-4.927,8.618
		l50.244,85.346c2.79,4.739,3.233,12.724,0.983,17.743L73.823,237.02c-2.249,5.02,0.411,9.126,5.911,9.126h23.864
		c5.5,0,11.849-4.103,14.109-9.117l55.081-122.21c2.261-5.015-0.391-9.117-5.891-9.117H143.05c-5.5,0-11.903,4.078-14.229,9.062
		l-1.095,2.346c-2.325,4.983-6.542,5.202-9.369,0.484L92.824,74.989z"
        />
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M210.249,213.029c-5.5,0-11.718,4.159-13.816,9.243l-6.042,14.631c-2.099,5.084,0.684,9.237,6.184,9.229
		l35.192-0.048c5.5-0.008,11.474-4.266,13.273-9.463l4.901-14.144c1.8-5.197-1.227-9.449-6.727-9.449H210.249z"
        />
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M120.909,66.412c-5.5,0-7.812,3.932-5.136,8.737l6.289,11.296c2.676,4.806,9.364,8.758,14.864,8.782
		l50.311,0.228c5.5,0.024,8.16,4.151,5.91,9.171l-59.33,132.395c-2.25,5.02,0.41,9.126,5.91,9.126h23.632
		c5.5,0,11.894-4.082,14.207-9.072l74.941-161.59c2.313-4.989-0.293-9.072-5.793-9.072H120.909z"
        />
      </g>
    </svg>
  );
};
