import React, { useEffect } from "react";
import { StyledAppModalDesktopStepTitle } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { StyleAppModalSingleStepContainer } from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import styled from "styled-components";
import {
  FORM_FIELD_ATOMS,
  TEMPLATES_ATOMS,
} from "../AddEditTemplateModal.atoms";
import { useAtom } from "jotai";
import { AppFormikSelect } from "../../../../components/core/AppFormik/AppFormikSelect/AppFormikSelect";
import { StyledFormAddNewField } from "./FormPostFields.styles";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";
import { FormPostFieldsRows } from "./FormPostFieldsRows/FormPostFieldsRows";
import {
  FORM_FIELD_CUSTOM_FIELDS,
  FORM_FIELDS_DISPLAY_STATES,
  FORM_FIELDS_TYPES,
} from "./FormPostFields.types";
import { FormPostTextInput } from "./FormPostTextInput/FormPostTextInput";
import { FormPostSingleChoice } from "./FormPostSingleChoice/FormPostSingleChoice";

const StyledFormPostContainer = styled.div({
  "@media only screen and (min-width: 1200px)": {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const FormPostFieldsV2: React.FC = () => {
  const [displayState, setDisplayState] = useAtom(
    FORM_FIELD_ATOMS.displayState
  );

  const [fieldSelectType, setFieldSelectType] = useAtom(
    FORM_FIELD_ATOMS.fieldSelectType
  );

  const [formPostAdditionalFields, setFormPostAdditionalFields] = useAtom(
    TEMPLATES_ATOMS.formPostAdditionalFields
  );

  useEffect(() => {
    if (
      !formPostAdditionalFields ||
      (formPostAdditionalFields && formPostAdditionalFields.length < 1)
    ) {
      setDisplayState(FORM_FIELDS_DISPLAY_STATES.ADD_NEW_FIELD);
    }
  }, [displayState, formPostAdditionalFields]);

  return (
    <StyledFormPostContainer>
      <StyleAppModalSingleStepContainer style={{ paddingBottom: 0 }}>
        <StyledAppModalDesktopStepTitle
          style={{ borderBottom: `1px solid ${APP_COMMON_COLORS.dark[300]}` }}
        >
          Form Post Fields
        </StyledAppModalDesktopStepTitle>

        {displayState === FORM_FIELDS_DISPLAY_STATES.FIELDS_ROWS && (
          <FormPostFieldsRows />
        )}

        {displayState === FORM_FIELDS_DISPLAY_STATES.ADD_NEW_FIELD && (
          <StyledFormAddNewField>
            <AppFormikSelect
              value={fieldSelectType}
              options={FORM_FIELD_CUSTOM_FIELDS}
              onChange={(e) => {
                setFieldSelectType(e.value);
              }}
              label="Select"
            />
            {fieldSelectType === FORM_FIELDS_TYPES.textInput && (
              <FormPostTextInput />
            )}
            {fieldSelectType === FORM_FIELDS_TYPES.singleChoice && (
              <FormPostSingleChoice />
            )}
          </StyledFormAddNewField>
        )}
      </StyleAppModalSingleStepContainer>
    </StyledFormPostContainer>
  );
};
