import styled from "styled-components";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Close } from "@mui/icons-material";

export const StyledDoDisturbOnIcon = styled(DoDisturbOnIcon)({
  cursor: "pointer",
  "&:hover": {
    color: APP_COMMON_COLORS.error.main,
  },
});

export const StyledAddCircleRoundedIcon = styled(AddCircleRoundedIcon)(
  ({ theme }) => ({
    cursor: "pointer",
    "&:hover": {
      color: theme.colorPrimary,
    },
  })
);

export const StyledPrimaryColorText = styled.div(({ theme }) => ({
  color: theme.colorPrimary,
}));

export const StyledSwitchAndTextContainer = styled.div({
  display: "flex",
  alignItems: "center",
  marginLeft: -5,
});

export const StyledClose = styled(Close)(({ theme }) => ({
  color: theme.colorPrimary,
  cursor: "pointer",
  "&:hover": {
    color: APP_COMMON_COLORS.error.main,
  },
}));
