export const DeleteContactIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0ZM12 2.5C13.8051 2.49561 15.574 3.00659 17.0988 3.97288C18.6235 4.93917 19.8407 6.32062 20.6074 7.95488C21.3741 9.58913 21.6583 11.4083 21.4267 13.1985C21.1951 14.9887 20.4573 16.6756 19.3 18.061C19.2301 18.1449 19.1347 18.2035 19.0284 18.2281C18.922 18.2526 18.8106 18.2417 18.711 18.197C17.831 17.797 16.766 17.407 15.551 16.96L14.807 16.686C14.7179 16.6213 14.6431 16.5388 14.5874 16.4438C14.5316 16.3488 14.4961 16.2434 14.483 16.134C14.4103 15.8788 14.3894 15.6116 14.4215 15.3481C14.4536 15.0847 14.5381 14.8303 14.67 14.6C15.738 13.424 16.4 12.16 16.4 9.5C16.453 8.89643 16.3777 8.28848 16.179 7.7161C15.9803 7.14371 15.6628 6.61986 15.2472 6.17896C14.8316 5.73806 14.3274 5.3901 13.7677 5.15795C13.2081 4.9258 12.6056 4.81472 12 4.832C11.3944 4.81472 10.7919 4.9258 10.2323 5.15795C9.67262 5.3901 9.16843 5.73806 8.75285 6.17896C8.33726 6.61986 8.01968 7.14371 7.82099 7.7161C7.6223 8.28848 7.54699 8.89643 7.60001 9.5C7.60001 12.163 8.26201 13.427 9.33001 14.6C9.46259 14.8298 9.54788 15.0838 9.58085 15.3471C9.61382 15.6104 9.59382 15.8776 9.52201 16.133C9.50891 16.2424 9.47337 16.3478 9.41762 16.4428C9.36187 16.5378 9.2871 16.6202 9.19801 16.685L8.45401 16.959C7.23901 17.406 6.17401 17.799 5.29401 18.196C5.19444 18.2407 5.08298 18.2516 4.97663 18.2271C4.87027 18.2025 4.77488 18.1439 4.70501 18.06C3.54829 16.675 2.81081 14.9886 2.57921 13.199C2.3476 11.4094 2.63147 9.59082 3.39749 7.95694C4.1635 6.32305 5.37985 4.94168 6.90366 3.97506C8.42747 3.00844 10.1955 2.49672 12 2.5Z"
      fill="#666666"
    />
  </svg>
);
