import { useEffect, useState } from "react";
import { _fs } from "../../services/firebase/config";
import { FB_QUERYSNAPSHOT } from "../../types/firbase.types";
import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { RowProps } from "../../components/core/AppTable/types";
import styled, { useTheme } from "styled-components";
import { Contact } from "../../types/contacts/contacts.types";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppSelectButton from "../../components/core/AppSelectButton/AppSelectButton";
import { ThreeDotsIcon } from "../../assets/icons/ThreeDotsIcon";
import { RecycleBinIcon } from "../../assets/icons/RecycleBinIcon";
import { Divider } from "@mui/material";

const StyledContactNameContainer = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 20,
});

const StyledActions = styled.div({
  display: "flex",
  justifyContent: "end",
  gap: 15,
});

export const useFirebaseStreamContacts = (
  clientId: string,
  onEditStart: (rowData: Contact) => void,
  onDeleteStart: (rowData: Contact) => void
) => {
  const [rows, setRows] = useState<any>([]);
  const [fetched, setFetched] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    const unsubscribe = _fs
      .collection(COLLECTIONS_TYPE.contacts)
      .where(`clientId`, "==", clientId)
      .onSnapshot((querySnapshot: FB_QUERYSNAPSHOT) => {
        let _rows: RowProps[] = [];
        let _row: RowProps = {} as RowProps;

        querySnapshot.forEach((doc) => {
          const { contactId, firstName, lastName, email, phoneNumber, title } =
            doc?.data() as Contact;

          _row = {
            id: [
              contactId,
              firstName,
              lastName,
              email,
              phoneNumber,
              title,
            ].join(" "),
            cells: [
              <StyledContactNameContainer>
                <AccountCircleIcon style={{ color: theme.colorPrimary }} />
                <div>
                  <div>{`${firstName} ${lastName}`}</div>
                  <div style={{ color: theme.colorPrimary }}>{email}</div>
                </div>
              </StyledContactNameContainer>,
              <div>{phoneNumber}</div>,
              <div>{title}</div>,
              <StyledActions>
                <RecycleBinIcon
                  onClick={() => onDeleteStart(doc.data() as Contact)}
                />
                <Divider orientation="vertical" style={{ height: 22 }} />
                <AppSelectButton
                  containerStyle={{ justifyContent: "end" }}
                  title={<ThreeDotsIcon />}
                  buttonsList={["Edit contact"]}
                  buttonActions={[
                    () => {
                      onEditStart(doc?.data() as Contact);
                    },
                  ]}
                />
              </StyledActions>,
            ],
          };
          _rows.push(_row);
        });
        setRows(_rows);
        setFetched(true);
      });
    return () => unsubscribe();
  }, [fetched]);

  return fetched ? rows : null;
};
