import { useEffect, useState } from "react";
import { _fs } from "../../../services/firebase/config";
import { FB_QUERYSNAPSHOT } from "../../../types/firbase.types";
import { RowProps } from "../../../components/core/AppTable/types";
import styled, { useTheme } from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";
import { ThreeDotsIcon } from "../../../assets/icons/ThreeDotsIcon";
import { MEMBERS, MEMBER_STATUS } from "../../../types/members/members.types";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  COLLECTIONS_TYPE,
  FIRESTORE_FUNCTIONS,
} from "../../../constants/firebase.constants";
import { useLocation } from "react-router-dom";
import AppSelectButton from "../../../components/core/AppSelectButton/AppSelectButton";
import { ChildrenDTO, MemberFormValues } from "../MembersPage.types";
import { MemberStatusCell } from "./MemberStatusCell/MemberStatusCell";
import firebase from "firebase/compat/app";
import { useAtom, useSetAtom } from "jotai";
import { MEMBERS_ATOMS } from "../MembersPage.Atoms";
import { VIEW_MEMBER_MODAL_ATOMS } from "../ViewModals/ViewMemberModal/ViewMemberModal.Atoms";
import { VIEW_CHILD_MODAL_ATOMS } from "../ViewModals/ViewChildModal/ViewChildModal.Atoms";
import { firebaseHttpsCallable } from "../../../hooks/firebase/firebase.helper";
import { APP_TOASTIFY } from "../../../styles/styles.constants";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";
import { getPaymentStatus } from "../MemberPage.helper";

export const StyledLogoImage = styled.img({
  display: "block",
  width: 35,
});

export const StyledActions = styled.div({
  display: "flex",
  justifyContent: "end",
  gap: 15,
});

export const StyledClientName = styled.div({
  alignSelf: "center",
  fontSize: 14,
  color: APP_COMMON_COLORS.yourzown.colorPrimary,
});

const StyledGroupNameContainer = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 20,
});

const getRolesDisplay = (rolesString: string, isClientAdmin: boolean) => {
  const admin = isClientAdmin ? "Admin" : "";
  const rolesDisplay = rolesString
    ?.split(",")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(", ");
  const comma = admin && rolesDisplay ? ", " : "";
  return rolesDisplay ? `${admin}${comma}${rolesDisplay}` : admin;
};

const sortMembers = (
  a: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
  b: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
) => {
  const NameA = `${a.data().firstName.toLowerCase()} ${a
    .data()
    .lastName.toLowerCase()}`;
  const NameB = `${b.data().firstName.toLowerCase()} ${b
    .data()
    .lastName.toLowerCase()}`;

  if (NameA < NameB) {
    return -1; // a should come before b
  } else if (NameA > NameB) {
    return 1; // b should come before a
  } else {
    return 0; // a and b are equal in terms of sorting
  }
};

export const useFirebaseStreamMembers = (
  clientId: string,
  setSelectedEditRow: React.Dispatch<
    React.SetStateAction<MemberFormValues | null>
  >,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setTabValue: React.Dispatch<React.SetStateAction<number>>
) => {
  const [rows, setRows] = useState<any>([]);
  const [fetched, setFetched] = useState<boolean>(false);
  const theme = useTheme();
  const { state }: { state: { filterBy: { value: string } } } = useLocation();
  const setIsEditMode = useSetAtom(MEMBERS_ATOMS.isEditMode);

  const location = useLocation();
  const [subGroupId, setSubGroupId] = useState("");
  const [isViewMemberModalOpen, setIsViewMemberModalOpen] = useAtom(
    VIEW_MEMBER_MODAL_ATOMS.isViewMemberModalOpen
  );
  const [viewMemberId, setViewMemberId] = useAtom(
    VIEW_MEMBER_MODAL_ATOMS.viewMemberId
  );
  const [isViewChildModalOpen, setIsViewChildModalOpen] = useAtom(
    VIEW_CHILD_MODAL_ATOMS.isViewChildModalOpen
  );
  const [viewChildId, setViewChildId] = useAtom(
    VIEW_CHILD_MODAL_ATOMS.viewChildId
  );

  const [memberSubscription, setMemberSubscription] = useAtom(
    MEMBERS_ATOMS.memberSubscription
  );

  const handleDeactivateMember = async (
    memberUid: string,
    clientId: string
  ) => {
    toast("Deactivating member...", APP_TOASTIFY.SUCCESS);

    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.deactivateMember)({
      memberId: String(memberUid),
      clientId: String(clientId),
    })
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          toast("Member deactivated", APP_TOASTIFY.SUCCESS);
        } else {
          toast("Something while deactivating member", APP_TOASTIFY.ERROR);
        }
      })
      .catch((err: any) => {
        console.log({ err });
        toast("Something while deactivating member [2]", APP_TOASTIFY.ERROR);
      });
  };

  const handleActivateMember = async (memberUid: string, clientId: string) => {
    toast("Activating member...", APP_TOASTIFY.SUCCESS);

    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.reactivateMember)({
      memberId: memberUid,
      clientId: clientId,
    })
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          toast("Member activated", APP_TOASTIFY.SUCCESS);
        } else {
          toast("Something while activating member", APP_TOASTIFY.ERROR);
        }
      })
      .catch((err: any) => {
        console.log({ err });
        toast("Something while activating member [2]", APP_TOASTIFY.ERROR);
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSubGroupId(params.get("subGroupId") || "");
  }, [location.search]);

  const membersRef = _fs
    .collection(COLLECTIONS_TYPE.members)
    .where(`clientsIds.${clientId}`, "==", true);

  const childrenRef = _fs
    .collection(COLLECTIONS_TYPE.children)
    .where(`clientId`, "==", clientId);

  useEffect(() => {
    let childrenData: { [key: string]: ChildrenDTO[] } = {};
    let membersData: FB_QUERYSNAPSHOT;

    const mergeAndProcessData = async () => {
      let _rows: RowProps[] = [];

      membersData?.docs?.sort?.(sortMembers)?.forEach((doc) => {
        const {
          uid,
          firstName,
          lastName,
          email,
          roles,
          isClientAdmin,
          status,
          isDeactivated,
          ...rest
        } = doc?.data() as MEMBERS;
        const children = childrenData[uid] || [];

        if (
          state?.filterBy?.value &&
          !(rest as { [key: string]: any })[state?.filterBy?.value]
        ) {
          return;
        }

        const rolesString = new Set();

        Object.entries(
          (roles?.[clientId] || {}) as {
            [key: string]: { [key: string]: boolean };
          }
        ).forEach(([roleName, role]: [string, { [key: string]: boolean }]) => {
          Object.values(role).forEach((value) => {
            if (value === true) {
              rolesString.add(roleName);
            }
          });
        });

        const _row = {
          id: [
            uid,
            firstName,
            lastName,
            email,
            isClientAdmin?.[String(clientId)] ? "admin" : "",
            isDeactivated && isDeactivated[clientId] ? "deactivated" : status,
            getPaymentStatus(memberSubscription && memberSubscription[uid]),
            ...Array.from(rolesString),
          ].join(" "),
          cells: [
            <StyledGroupNameContainer>
              <AccountCircleIcon style={{ color: theme.colorPrimary }} />
              <div>
                <div>{`${firstName} ${lastName}`}</div>
                <div style={{ color: theme.colorPrimary }}>{email}</div>
              </div>
            </StyledGroupNameContainer>,
            <div>
              {getRolesDisplay(
                Array.from(rolesString)?.toString(),
                isClientAdmin?.[String(clientId)]
              )}
            </div>,
            memberSubscription ? (
              getPaymentStatus(memberSubscription[uid])
            ) : (
              <Skeleton width={30} />
            ),
            <MemberStatusCell
              status={status}
              email={email}
              isDeactivated={isDeactivated}
              clientId={clientId}
            />,
            <AppSelectButton
              containerStyle={{ justifyContent: "end" }}
              title={<ThreeDotsIcon />}
              buttonsList={[
                "View Profile",
                "Edit Profile",
                isDeactivated && isDeactivated[clientId]
                  ? "Activate"
                  : "Deactivate",
              ]}
              buttonActions={[
                () => {
                  setIsViewMemberModalOpen(true);
                  setViewMemberId(uid);
                  setIsViewChildModalOpen(false);
                  setViewChildId(undefined);
                },
                () => {
                  setIsModalOpen(true);
                  setIsEditMode(true);
                  setTabValue(0);
                  setSelectedEditRow({
                    ...doc?.data(),
                    children,
                    hasChildren: !!children?.length,
                  } as MemberFormValues);
                },
                () => {
                  isDeactivated && isDeactivated[clientId]
                    ? handleActivateMember(uid, clientId)
                    : handleDeactivateMember(uid, clientId);
                },
              ]}
            />,
          ],
        };

        if (subGroupId) {
          if (roles && roles[clientId]) {
            const subGroups = Object.values(roles[clientId]);
            let combinedSubGroups: string[] = [];

            if (subGroups && subGroups.length > 0) {
              subGroups.forEach((_subGroupObj) => {
                if (_subGroupObj) {
                  const _subGroupsIds = Object.keys(_subGroupObj);
                  if (_subGroupsIds && _subGroupsIds?.length > 0) {
                    _subGroupsIds?.forEach((_subGroupsId: string) => {
                      combinedSubGroups.push(_subGroupsId);
                    });
                  }
                }
              });
            }
            if (combinedSubGroups?.includes(subGroupId)) {
              _rows.push(_row);
            }
          }
        } else {
          _rows.push(_row);
        }

        if (children.length) {
          _rows.push(
            ...children
              .filter((child) =>
                state?.filterBy?.value
                  ? child[state?.filterBy?.value as string] === true
                  : true
              )
              .map((child, index) => ({
                id: [
                  child.childId,
                  child.firstName,
                  child.lastName,
                  status === MEMBER_STATUS.active
                    ? MEMBER_STATUS.active
                    : "Not signed in Resend invite",
                  "child",
                  getPaymentStatus(
                    memberSubscription &&
                      memberSubscription[String(child.childId)]
                  ),
                ].join(" "),
                cells: [
                  <StyledGroupNameContainer>
                    <AccountCircleIcon style={{ color: theme.colorPrimary }} />
                    <div>
                      <div>{`${child.firstName} ${child.lastName}`}</div>
                      <div style={{ color: theme.colorPrimary }}>{email}</div>
                    </div>
                  </StyledGroupNameContainer>,
                  <div>Child</div>,
                  memberSubscription ? (
                    getPaymentStatus(memberSubscription[String(child.childId)])
                  ) : (
                    <Skeleton width={30} />
                  ),
                  <MemberStatusCell
                    status={status}
                    email={email}
                    isDeactivated={isDeactivated}
                    clientId={clientId}
                  />,
                  <AppSelectButton
                    containerStyle={{ justifyContent: "end" }}
                    title={<ThreeDotsIcon />}
                    buttonsList={["View Child", "Edit Child"]}
                    buttonActions={[
                      () => {
                        setIsViewChildModalOpen(true);
                        setViewChildId(child.childId);
                        setIsViewMemberModalOpen(false);
                        setViewMemberId(undefined);
                      },
                      () => {
                        setTabValue(1);
                        setIsModalOpen(true);
                        setIsEditMode(true);
                        setSelectedEditRow({
                          ...doc?.data(),
                          children,
                          hasChildren: !!children?.length,
                          selectedChild: { ...child, index },
                        } as MemberFormValues);
                      },
                    ]}
                  />,
                ],
              }))
          );
        }
      });
      setRows(_rows);
      setFetched(true);
    };
    const unsubscribeChildren = childrenRef.onSnapshot(
      async (querySnapshot: FB_QUERYSNAPSHOT) => {
        childrenData = {};
        querySnapshot.forEach((doc) => {
          const childData = doc.data() as ChildrenDTO;
          if (subGroupId) {
            if (childData.subGroups?.includes(String(subGroupId))) {
              if (Array.isArray(childrenData[String(childData.memberId)])) {
                childrenData[String(childData.memberId)].push(childData);
              } else {
                childrenData[String(childData.memberId)] = [childData];
              }
            }
          } else {
            if (Array.isArray(childrenData[String(childData.memberId)])) {
              childrenData[String(childData.memberId)].push(childData);
            } else {
              childrenData[String(childData.memberId)] = [childData];
            }
          }
        });

        mergeAndProcessData();
      }
    );

    const unsubscribeMembers = membersRef.onSnapshot(
      async (querySnapshot: FB_QUERYSNAPSHOT) => {
        membersData = querySnapshot;

        mergeAndProcessData();
      }
    );
    return () => {
      unsubscribeChildren();
      unsubscribeMembers();
    };
  }, [
    fetched,
    state?.filterBy?.value,
    subGroupId,
    Object.values(memberSubscription || {}).length,
  ]);

  return fetched ? rows : null;
};
