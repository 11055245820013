import { CircularProgress } from "@mui/material";
import styled from "styled-components";

export const StyledAppSpinner = styled.div({
  height: "70vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const AppSpinner = () => (
  <StyledAppSpinner>
    <CircularProgress />
  </StyledAppSpinner>
);
