import styled, { CSSObject } from "styled-components";

export const StyledAppModalStepsContainer = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  height: "100%",
  "@media only screen and (min-width: 1200px)": {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    width: "100%",
  },
}));

export const StyleAppModalSingleStepContainer = styled.div<{
  $customStylesMobile?: CSSObject;
  $customStylesDesktop?: CSSObject;
}>(({ $customStylesMobile, $customStylesDesktop }) => ({
  display: "flex",
  flexDirection: "column",
  paddingInline: 20,
  ...$customStylesMobile,
  "@media only screen and (min-width: 1200px)": {
    width: 600,
    padding: "78px 40px",
    ...$customStylesDesktop,
  },
}));

export const StyledAppModalSubmitButtonContainer = styled.div({
  // marginTop: "auto",
  marginBlockStart: "auto",
  marginBottom: 25,
  width: "100%",
  "@media only screen and (min-width: 1200px)": {
    width: 200,
  },
});

export const StyledAppModalFooterButtonContainer = styled.div({
  zIndex: 1,
  position: "absolute",
  bottom: 80,
  width: "calc(100% - 40px)",
  "@media only screen and (min-width: 1200px)": {
    width: 200,
  },
});
