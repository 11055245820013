import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import { ChangeEvent, useRef, useState } from "react";
import {
  AppFormikField,
  AppFormikFieldProps,
} from "../AppFormik/AppFormikField/AppFormikField";

interface AppGooglePlacesAutoComplete extends AppFormikFieldProps {
  onPlaceSelect: (place: any) => void;
}

export const AppGooglePlacesAutoComplete = ({
  onPlaceSelect,
  value,
  ...rest
}: AppGooglePlacesAutoComplete) => {
  const [address, setAddress] = useState(value);
  const [selected, setSelected] = useState<any>(null);
  const searchBox: any = useRef(null);

  const onPlacesChanged = () => {
    const places = searchBox?.current?.getPlaces();
    const place = places[0];
    setSelected(place);
    setAddress(place.formatted_address);
    onPlaceSelect(place);
  };

  return (
    <StandaloneSearchBox
      onLoad={(ref) => (searchBox.current = ref)}
      onPlacesChanged={onPlacesChanged}
    >
      <AppFormikField
        {...rest}
        onChange={(e) => setAddress(e.target.value)}
        value={address}
      />
    </StandaloneSearchBox>
  );
};
