import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

export const StyledRequiredAsterisks = styled.div({
  fontSize: 25,
  marginTop: 11,
  color: APP_COMMON_COLORS.red[500],
  position: "absolute",
  left: -15,
  "@media only screen and (min-width: 1200px)": {
    left: -20,
  },
});
