import { CSSObject } from "styled-components";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import {
  StyledSelectField,
  StyledSelectInputAndErrorContainer,
} from "./AppFormikSelect.styles";
import { StyledAppFormikFieldError } from "../AppFormik.styles";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";
import { StyledRequiredAsterisks } from "../../../shared/StyledComponents/StyledRequiredAsterisks";

export interface SelectOption {
  value: any;
  label: string;
}

interface AppFormikSelectProps {
  name?: string;
  label?: string;
  placeholder?: string;
  options: SelectOption[];
  fieldStyle?: CSSObject;
  errorMessage?: string;
  isRequired?: boolean;
  value?: string;
  onChange: (value: SelectOption) => void;
  isDisabled?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  containerStyles?: CSSObject;
}

export const AppFormikSelect = (props: AppFormikSelectProps) => {
  return (
    <FormControl style={{ width: "100%", ...props.containerStyles }}>
      {props.isRequired && <StyledRequiredAsterisks>*</StyledRequiredAsterisks>}
      <InputLabel
        style={{
          fontSize: 12,
          fontWeight: 500,
          paddingLeft: 15,
          color: props.errorMessage
            ? APP_COMMON_COLORS.error.main
            : APP_COMMON_COLORS.dark[700],
        }}
        id="app-select-label"
      >
        {props.label}
      </InputLabel>

      <StyledSelectInputAndErrorContainer>
        <StyledSelectField
          onBlur={props.onBlur}
          name={props.name}
          labelId="app-select-label"
          value={props.value || ""}
          label={props.label}
          $styledErrorProp={Boolean(props.errorMessage)}
          placeholder={props.placeholder}
        >
          {props.options?.map((element: SelectOption, index: number) => {
            return (
              <MenuItem
                // style={{ ...MENU_ITEMS_STYLES }}
                onClick={() => props.onChange(element)}
                key={index}
                value={element?.value}
              >
                {element.label}
              </MenuItem>
            );
          })}
        </StyledSelectField>
        <StyledAppFormikFieldError>
          {props.errorMessage}
        </StyledAppFormikFieldError>
      </StyledSelectInputAndErrorContainer>
    </FormControl>
  );
};
