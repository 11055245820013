import React, { useEffect, useState } from "react";
import { StyledPageContainer } from "../../components/shared/StyledComponents/StyledPageContainer";
import { StyledAddButtonContainer } from "../MembersPage/MembersPage.styles";
import { PageAddButton } from "../../components/shared/PageAddButton/PageAddButton";
import { ContactFormModal } from "./ContactFormModal/ContactFormModal";
import { AppTable } from "../../components/core/AppTable/AppTable";
import { AppSpinner } from "../../components/core/AppSpinner/AppSpinner";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../redux/client/clientSlice";
import { RowProps } from "../../components/core/AppTable/types";
import { useFirebaseStreamContacts } from "./useFirebaseStreamContacts";
import { Contact } from "../../types/contacts/contacts.types";
import { DeleteModal } from "../../components/shared/DeleteModal/DeleteModal";
import { firebaseHttpsCallable } from "../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../constants/firebase.constants";
import { GENERAL_REPONSE } from "../../types/firbase.types";
import { APP_TOASTIFY } from "../../styles/styles.constants";
import { toast } from "react-toastify";
import { DeleteContactIcon } from "./ContactFormModal/DeleteContactIcon";

export const ContactsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState<Contact | null>(null);
  const client = useSelector(getClientSelector);
  const rows: RowProps[] = useFirebaseStreamContacts(
    String(client?.clientId),
    (rowData: Contact) => {
      setIsEditMode(true);
      setSelectedRow(rowData);
      setIsModalOpen(true);
    },
    (rowData: Contact) => {
      setSelectedRow(rowData);
      setIsDeleteModalOpen(true);
    }
  );

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
    setSelectedRow(null);
  };

  const onDeleteContact = () => {
    setIsDeleting(true);
    const payload: { contactId: string } = {
      contactId: String(selectedRow?.contactId),
    };

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.deleteContact)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_REPONSE;
        if (data.result === "ok") {
          toast("Contact Deleted Successfully", APP_TOASTIFY.SUCCESS);
        } else {
          toast("Something Went Wrong", APP_TOASTIFY.ERROR);
        }
      })
      .catch((e) => toast("Something Went Wrong", APP_TOASTIFY.ERROR))
      .finally(() => {
        setIsDeleting(false);
        setSelectedRow(null);
        setIsDeleteModalOpen(false);
      });
  };
  let columns = [
    {
      cell: <div>NAME</div>,
    },
    {
      cell: <div>MOBILE</div>,
    },
    {
      cell: <div>TITLE</div>,
    },
    {
      cell: <div style={{ textAlign: "end" }}>Actions</div>,
    },
  ];

  useEffect(() => {
    rows && setIsLoading(false);
  }, [rows]);

  if (isLoading) {
    return (
      <StyledPageContainer>
        <AppSpinner />
      </StyledPageContainer>
    );
  }

  return (
    <StyledPageContainer>
      <StyledAddButtonContainer>
        <PageAddButton
          text={isEditMode ? "Edit contact" : "Add contact"}
          onClick={handleOpenModal}
        />
      </StyledAddButtonContainer>

      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          title={"Delete contact"}
          deleteBtnLabel={"DELETE CONTACT"}
          onDeleteButton={onDeleteContact}
          onCloseDeleteModal={() => {
            setIsDeleting(false);
            setSelectedRow(null);
            setIsDeleteModalOpen(false);
          }}
          deleteContent={{
            title: "YES, DELETE THIS CONTACT",
            subTitle: `${selectedRow?.firstName} ${selectedRow?.lastName}`,
            icon: <DeleteContactIcon />,
          }}
          isLoading={isDeleting}
        />
      )}

      {isModalOpen && (
        <ContactFormModal
          handleCloseModal={handleCloseModal}
          isModalOpen={isModalOpen}
          isEditMode={isEditMode}
          contactData={selectedRow}
        />
      )}

      <AppTable
        columns={columns}
        rows={rows}
        showPagination={false}
        showTotalCount={false}
      />
    </StyledPageContainer>
  );
};
