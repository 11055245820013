import { useEffect, useState } from "react";
import { StyledPageContainer } from "../../components/shared/StyledComponents/StyledPageContainer";
import { PageAddButton } from "../../components/shared/PageAddButton/PageAddButton";
import { CSSObject } from "styled-components";
import { AppTable } from "../../components/core/AppTable/AppTable";
import { RowProps } from "../../components/core/AppTable/types";
import { MEMBERS } from "../../types/members/members.types";
import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { AppSpinner } from "../../components/core/AppSpinner/AppSpinner";
import { streamFirebaseDataCollection } from "../../hooks/firebase/streamFirebaseDataCollection";
import { useDispatch } from "react-redux";
import { setAddEditClientModalData } from "../../redux/client/clientSlice";
import { ADD_EDIT_CLIENT_MODAL_MODE } from "../../types/client/client.types";
import { AddEditClientModal } from "./AddEditClientModal/AddEditClientModal";
import { useFirebaseStreamClients } from "./useFirebaseStreamClients";
import { StyledFirstHeader } from "../TemplatesPage/TemplatesPages.styles";

const StyledSearchContainerStyles: CSSObject = {
  width: 165,
  marginBottom: 21,
  "@media only screen and (min-width: 1200px)": {
    width: 400,
    marginBottom: 40,
  },
};

export const ClientsPage = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const [membersData, setMembersData] = useState<{
    [x: string]: MEMBERS;
  }>();

  const rows: RowProps[] = useFirebaseStreamClients(
    membersData as { [x: string]: MEMBERS }
  );

  const handleAddClient = () => {
    dispatch(
      setAddEditClientModalData({
        isOpen: true,
        mode: ADD_EDIT_CLIENT_MODAL_MODE.ADD_CLIENT,
      })
    );
  };

  useEffect(() => {
    rows && setIsLoading(false);
  }, [rows]);

  useEffect(() => {
    const unsubscribe = streamFirebaseDataCollection(
      COLLECTIONS_TYPE.members,
      (data) => setMembersData(data),
      (error) => console.log("Error:", error)
    );

    return () => unsubscribe();
  }, []);

  let columns = [
    {
      cell: <StyledFirstHeader>Client</StyledFirstHeader>,
    },

    {
      cell: "Members",
    },
    {
      cell: (
        <div style={{ display: "flex", justifyContent: "end" }}>Actions</div>
      ),
    },
  ];

  return (
    <StyledPageContainer>
      {isLoading ? (
        <AppSpinner />
      ) : (
        <>
          <PageAddButton text="Add Client" onClick={handleAddClient} />
          <AppTable
            columns={columns}
            rows={rows}
            searchContainerStyles={StyledSearchContainerStyles}
            showPagination={false}
            showTotalCount={false}
          />

          <AddEditClientModal />
        </>
      )}
    </StyledPageContainer>
  );
};
