import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";

export const StyledSwitchContainer = styled.div({
  display: "flex",
  alignItems: "center",
  marginBottom: 20,
});

export const StyledSwitchLabel = styled.div({
  fontWeight: 500,
  fontSize: 14,
  color: APP_COMMON_COLORS.dark[600],
});
