import { useScreenWidth } from "../../../hooks/useScreenWidth/useScreenWidth";
import { StyledAppSideInfoContainer } from "../StyledComponents/StyledAppSideInfoContainer";

interface AppSideMenuContainerProps {
  children: React.ReactNode;
  hideInMobile?: boolean;
  hideInDesktop?: boolean;
}

export const AppSideMenuContainer = (props: AppSideMenuContainerProps) => {
  const screenWidth = useScreenWidth();

  if (props.hideInMobile && screenWidth < 1200) {
    return null;
  }

  if (props.hideInDesktop && screenWidth >= 1200) {
    return null;
  }

  return (
    <StyledAppSideInfoContainer>{props.children}</StyledAppSideInfoContainer>
  );
};
