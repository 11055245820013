interface CLIENT_ADMIN {
  email: string;
  firstName: string;
  lastName: string;
  uid: string;
}
export interface BRANDING {
  logoUrl?: string;
  backgroundImageUrl?: string;
  brandMarkUrl?: string;
  colorPrimary: string;
  colorSecondary: string;
  welcomeMessage: string;
}
interface EMAIL_SIGNATURE {
  email: string;
  verified: boolean;
}
export interface CLIENT_STATE {
  branding?: BRANDING;
  clientId?: string;
  domain?: string;
  clientName?: string;
  clientAdmin: CLIENT_ADMIN;
  emailSignature?: EMAIL_SIGNATURE;
  modals?: {
    addEditClientModalData: ADD_EDIT_CLIENT_MODAL | null;
  };
  paymentAccountId?: string;
  paymentAccountStatus?: "active" | undefined;
}

export enum ADD_EDIT_CLIENT_MODAL_MODE {
  ADD_CLIENT = "ADD_CLIENT",
  EDIT_CLIENT = "EDIT_CLIENT",
}
export interface ADD_EDIT_CLIENT_MODAL {
  isOpen: boolean;
  mode: ADD_EDIT_CLIENT_MODAL_MODE;
  docId?: string;
  clientData?: CLIENT_STATE;
}

export interface EDIT_CLIENT_DTO {
  clientAdmin: {
    firstName: string;
    lastName: string;
  };
  clientId: string;
  clientName: string;
}

export interface EDIT_CLIENT_BRANDING_DTO {
  logoUrl?: string | null;
  backgroundImageUrl?: string | null;
  brandMarkUrl?: string | null;
  colorPrimary?: string;
  clientId: string;
}
