import { useEffect } from "react";
import { COLLECTIONS_TYPE } from "../../../constants/firebase.constants";
import { getFirebaseDataByColKeyVal } from "../../../hooks/firebase/getFirebaseDataByColKeyVal";
import { SUBSCRIPTION_DATA } from "../../../types/payments/payments.types";
import { useAtom } from "jotai";
import { MEMBERS_ATOMS } from "../MembersPage.Atoms";
import { useFirebaseStreamDataByColKeyVal } from "../../../hooks/firebase/useFirebaseStreamDataByColKeyVal";

export const useGetMembersSubscriptions = (clientId: string) => {
  const [memberSubscription, setMemberSubscription] = useAtom(
    MEMBERS_ATOMS.memberSubscription
  );

  const res = useFirebaseStreamDataByColKeyVal({
    collection: COLLECTIONS_TYPE.subscriptions,
    fbKey: "clientId",
    fbValue: clientId,
  });

  useEffect(() => {
    setMemberSubscription(undefined);

    if (res) {
      try {
        const paymentStatus: SUBSCRIPTION_DATA[] = Object.values(res);

        const mappedData = paymentStatus.reduce((acc: any, item) => {
          const key = item.childId || item.memberId;
          acc[key] = item;
          return acc;
        }, {});

        setMemberSubscription(mappedData);
      } catch (err) {
        console.error("Error processing subscription data", err);
      }
    }
  }, [clientId, res]);
};
