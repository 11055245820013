export const isValidURL = (string: string) => {
  try {
    // Attempt to create a new URL object
    new URL(string);
    return true;
  } catch (_) {
    // If an error is thrown, the URL is invalid
    return false;
  }
};
