import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";

export const StyledInputsContainer = styled.div({
  height: "48vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  marginBottom: 30,
  "@media only screen and (min-width: 1200px)": {
    width: 600,
    height: "50vh",
  },
});

export const StyledRolesTogglesContainer = styled.div({
  overflowY: "auto",
  height: 380,
  borderBottom: `1px solid ${APP_COMMON_COLORS.dark[300]}`,
  "@media only screen and (min-width: 1200px)": {
    height: 340,
  },
});

export const StyledRolesPermissionsToggleRow = styled.div({
  display: "flex",
  borderBottom: `1px dashed ${APP_COMMON_COLORS.dark[100]}`,
  justifyContent: "space-between",
  "@media only screen and (min-width: 1200px)": {
    paddingRight: 20,
  },
  "&:last-child": {
    borderBottom: "none",
  },
});
