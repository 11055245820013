import { useTheme } from "styled-components";

export const DeleteUploadedImageIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="98"
      height="98"
      viewBox="0 0 98 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1204_2161)">
        <rect x="24" y="19.0039" width="50" height="50" rx="25" fill="white" />
        <rect
          x="22"
          y="17.0039"
          width="54"
          height="54"
          rx="27"
          stroke={theme.colorPrimary}
          strokeWidth="4"
        />
      </g>
      <path
        d="M55.4167 40.2539H42.9167C42.8062 40.2539 42.7002 40.2978 42.622 40.3759C42.5439 40.4541 42.5 40.5601 42.5 40.6706V52.3372C42.5 52.7793 42.6756 53.2032 42.9882 53.5157C43.3007 53.8283 43.7246 54.0039 44.1667 54.0039H54.1667C54.6087 54.0039 55.0326 53.8283 55.3452 53.5157C55.6577 53.2032 55.8333 52.7793 55.8333 52.3372V40.6706C55.8333 40.5601 55.7894 40.4541 55.7113 40.3759C55.6332 40.2978 55.5272 40.2539 55.4167 40.2539ZM47.7083 51.0872C47.7083 51.253 47.6425 51.412 47.5253 51.5292C47.4081 51.6464 47.2491 51.7122 47.0833 51.7122C46.9176 51.7122 46.7586 51.6464 46.6414 51.5292C46.5242 51.412 46.4583 51.253 46.4583 51.0872V43.5872C46.4583 43.4215 46.5242 43.2625 46.6414 43.1453C46.7586 43.0281 46.9176 42.9622 47.0833 42.9622C47.2491 42.9622 47.4081 43.0281 47.5253 43.1453C47.6425 43.2625 47.7083 43.4215 47.7083 43.5872V51.0872ZM51.875 51.0872C51.875 51.253 51.8092 51.412 51.6919 51.5292C51.5747 51.6464 51.4158 51.7122 51.25 51.7122C51.0842 51.7122 50.9253 51.6464 50.8081 51.5292C50.6908 51.412 50.625 51.253 50.625 51.0872V43.5872C50.625 43.4215 50.6908 43.2625 50.8081 43.1453C50.9253 43.0281 51.0842 42.9622 51.25 42.9622C51.4158 42.9622 51.5747 43.0281 51.6919 43.1453C51.8092 43.2625 51.875 43.4215 51.875 43.5872V51.0872Z"
        fill={theme.colorPrimary}
      />
      <path
        d="M57.5 37.3372H53.5417C53.4864 37.3372 53.4334 37.3153 53.3944 37.2762C53.3553 37.2371 53.3333 37.1842 53.3333 37.1289V36.0872C53.3333 35.5347 53.1138 35.0048 52.7231 34.6141C52.3324 34.2234 51.8025 34.0039 51.25 34.0039H47.0833C46.5308 34.0039 46.0009 34.2234 45.6102 34.6141C45.2195 35.0048 45 35.5347 45 36.0872V37.1289C45 37.1842 44.9781 37.2371 44.939 37.2762C44.8999 37.3153 44.8469 37.3372 44.7917 37.3372H40.8333C40.6123 37.3372 40.4004 37.425 40.2441 37.5813C40.0878 37.7376 40 37.9496 40 38.1706C40 38.3916 40.0878 38.6035 40.2441 38.7598C40.4004 38.9161 40.6123 39.0039 40.8333 39.0039H57.5C57.721 39.0039 57.933 38.9161 58.0893 38.7598C58.2455 38.6035 58.3333 38.3916 58.3333 38.1706C58.3333 37.9496 58.2455 37.7376 58.0893 37.5813C57.933 37.425 57.721 37.3372 57.5 37.3372ZM46.6667 37.1289V36.0872C46.6667 35.9767 46.7106 35.8708 46.7887 35.7926C46.8668 35.7145 46.9728 35.6706 47.0833 35.6706H51.25C51.3605 35.6706 51.4665 35.7145 51.5446 35.7926C51.6228 35.8708 51.6667 35.9767 51.6667 36.0872V37.1289C51.6667 37.1842 51.6447 37.2371 51.6056 37.2762C51.5666 37.3153 51.5136 37.3372 51.4583 37.3372H46.875C46.8197 37.3372 46.7668 37.3153 46.7277 37.2762C46.6886 37.2371 46.6667 37.1842 46.6667 37.1289Z"
        fill={theme.colorPrimary}
      />
      <defs>
        <filter
          id="filter0_d_1204_2161"
          x="0"
          y="0.00390625"
          width="98"
          height="98"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1204_2161"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1204_2161"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
