import { useEffect, useState } from "react";
import { AppTable } from "../../components/core/AppTable/AppTable";
import { StyledPageContainer } from "../../components/shared/StyledComponents/StyledPageContainer";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../redux/client/clientSlice";
import { AppSpinner } from "../../components/core/AppSpinner/AppSpinner";
import { useFirebaseStreamLocations } from "./useFirebaseStreamLocations";
import { PageAddButton } from "../../components/shared/PageAddButton/PageAddButton";
import { AppModal } from "../../components/core/AppModal/AppModal";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  initialLocationFormData,
  LOCATION_MODAL_ATOMS,
} from "./LocationFormModal/LocationFormModal.Atoms";
import { LocationFormModal } from "./LocationFormModal/LocationFormModal";
import { DeleteModal } from "../../components/shared/DeleteModal/DeleteModal";
import { LocationsIcon } from "../../components/core/AppBurgerMenu/icons/LocationsIcon";
import { DELETE_LOCATION_DTO } from "../../types/locations/locations.types";
import { firebaseHttpsCallable } from "../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../constants/firebase.constants";
import { GENERAL_REPONSE } from "../../types/firbase.types";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../styles/styles.constants";

const columns = [
  {
    cell: "Title",
  },
  {
    cell: "Address",
  },
  {
    cell: "",
  },
  {
    cell: <div style={{ display: "flex", justifyContent: "end" }}>Actions</div>,
  },
];

export const LocationsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const client = useSelector(getClientSelector);
  const rows: any[] = useFirebaseStreamLocations(String(client?.clientId));
  const [isOpen, setIsOpen] = useAtom(LOCATION_MODAL_ATOMS.isOpen);
  const setIsEditMode = useSetAtom(LOCATION_MODAL_ATOMS.isEditMode);
  const [currentStep, setCurrentStep] = useAtom(
    LOCATION_MODAL_ATOMS.currentStep
  );
  const setLocationFormData = useSetAtom(LOCATION_MODAL_ATOMS.locationFormData);

  const [isPublished, setIsPublished] = useAtom(
    LOCATION_MODAL_ATOMS.isPublished
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useAtom(
    LOCATION_MODAL_ATOMS.isDeleteModalOpen
  );
  const deletingLocationData = useAtomValue(
    LOCATION_MODAL_ATOMS.deletingLocationData
  );
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const handleCloseModal = () => {
    setIsOpen(false);
    setCurrentStep(0);
    setLocationFormData(initialLocationFormData);
    setIsPublished(false);
    setIsEditMode(false);
  };

  const handleResetDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setIsDeleteLoading(false);
  };

  const handleDeleteLocation = () => {
    setIsDeleteLoading(true);
    const payload: DELETE_LOCATION_DTO = {
      id: String(deletingLocationData && deletingLocationData.id),
      clientId: String(client?.clientId),
    };
    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.deleteLocation)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_REPONSE;
        if (data.result === "ok") {
          toast("Location deleted", APP_TOASTIFY.SUCCESS);
        } else {
          toast("Error while deleting location [1]", APP_TOASTIFY.ERROR);
        }
      })
      .catch((e) =>
        toast("Error while deleting Location [2]", APP_TOASTIFY.ERROR)
      )
      .finally(() => handleResetDeleteModal());
  };

  useEffect(() => {
    if (isPublished) {
      handleCloseModal();
    }
  }, [isPublished]);

  useEffect(() => {
    rows && setIsLoading(false);
  }, [rows]);

  if (isLoading) {
    return (
      <StyledPageContainer>
        <AppSpinner />
      </StyledPageContainer>
    );
  }

  return (
    <StyledPageContainer>
      <PageAddButton text={"Add location"} onClick={() => setIsOpen(true)} />
      <AppTable
        columns={columns}
        rows={rows}
        showPagination={false}
        showTotalCount={false}
      />

      <AppModal
        isOpen={isOpen}
        closeModal={handleCloseModal}
        $customContentStyle={{ padding: 0 }}
        currentTab={currentStep}
        setCurrentTab={setCurrentStep}
        showBackArrow={true}
      >
        <LocationFormModal />
      </AppModal>

      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          title={"Delete Location"}
          deleteBtnLabel={"DELETE LOCATION"}
          onDeleteButton={handleDeleteLocation}
          onCloseDeleteModal={handleResetDeleteModal}
          deleteContent={{
            title: "YES, DELETE THIS LOCATION",
            subTitle: String(deletingLocationData?.title),
            icon: <LocationsIcon isActive={false} />,
          }}
          isLoading={isDeleteLoading}
        />
      )}
    </StyledPageContainer>
  );
};
