import styled from "styled-components";

export const StyledTreeNodeContainer = styled.div({
  margin: "12px 0",
});

export const StyledSubGroupLabelRow = styled.div({
  display: "flex",
});

export const StyledSubGroupsLabelContainer = styled.div({
  display: "flex",
  gap: 5,
  marginRight: 15,
  alignItems: "center",
});

export const StyledSubGroupLabelInDepth = styled.div<{
  depth: number;
}>(({ depth, theme }) => ({
  fontSize: Math.max(20 - depth * 2, 12),
  fontWeight: Math.max(600 - depth * 100, 300),
  opacity: Math.max(1 - (depth - 1) / 10, 0.5),
  "&:hover": { opacity: 1, transform: "scale(1.05)" },
}));

export const StyledSubGroupCountLabel = styled.span({
  fontSize: 12,
  fontWeight: 300,
  opacity: 0.7,
});

export const StyledSubGroupCountValue = styled.span(({ theme }) => ({
  color: theme.colorPrimary,
}));
