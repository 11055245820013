import React from "react";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";

export const RolesIcon = (props: { isActive: boolean }) => {
  const color = props.isActive
    ? APP_COMMON_COLORS.common.white
    : APP_COMMON_COLORS.dark[500];

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.37 -0.00390625H1.63C1.19761 -0.00324415 0.783147 0.168929 0.477558 0.47483C0.17197 0.780731 0.000220212 1.19537 0 1.62776L0 18.3661C0.00066121 18.7982 0.172605 19.2124 0.478146 19.5179C0.783687 19.8235 1.1979 19.9954 1.63 19.9961H18.37C18.8021 19.9954 19.2163 19.8235 19.5219 19.5179C19.8274 19.2124 19.9993 18.7982 20 18.3661V1.62776C19.9998 1.19537 19.828 0.780731 19.5224 0.47483C19.2169 0.168929 18.8024 -0.00324415 18.37 -0.00390625ZM17.9167 18.2953L2.12 18.3294C2.00949 18.3294 1.90351 18.2855 1.82537 18.2074C1.74723 18.1292 1.70333 18.0233 1.70333 17.9128L1.66667 2.07943C1.66667 1.96892 1.71057 1.86294 1.78871 1.7848C1.86685 1.70666 1.97283 1.66276 2.08333 1.66276L17.9167 1.62859C18.0272 1.62859 18.1332 1.67249 18.2113 1.75063C18.2894 1.82877 18.3333 1.93475 18.3333 2.04526V17.8786C18.3333 17.9891 18.2894 18.0951 18.2113 18.1732C18.1332 18.2514 18.0272 18.2953 17.9167 18.2953Z"
        fill={color}
      />
      <path
        d="M6.63878 8.98068H13.3604C14.1168 8.98068 14.8421 8.68023 15.3769 8.14544C15.9117 7.61065 16.2121 6.88532 16.2121 6.12901C16.2121 5.3727 15.9117 4.64737 15.3769 4.11258C14.8421 3.57779 14.1168 3.27734 13.3604 3.27734H6.63878C5.88247 3.27734 5.15713 3.57779 4.62234 4.11258C4.08755 4.64737 3.78711 5.3727 3.78711 6.12901C3.78711 6.88532 4.08755 7.61065 4.62234 8.14544C5.15713 8.68023 5.88247 8.98068 6.63878 8.98068ZM6.63878 4.67901C6.92078 4.67901 7.19645 4.76263 7.43093 4.91931C7.6654 5.07598 7.84816 5.29866 7.95607 5.5592C8.06399 5.81974 8.09223 6.10643 8.03721 6.38301C7.9822 6.65959 7.8464 6.91365 7.64699 7.11306C7.44759 7.31247 7.19353 7.44826 6.91694 7.50328C6.64036 7.5583 6.35367 7.53006 6.09313 7.42214C5.8326 7.31422 5.60991 7.13147 5.45324 6.89699C5.29657 6.66252 5.21294 6.38685 5.21294 6.10484C5.21316 5.72676 5.36346 5.36422 5.6308 5.09687C5.89815 4.82952 6.26069 4.67923 6.63878 4.67901Z"
        fill={color}
      />
      <path
        d="M6.63878 16.719H13.3604C13.7349 16.719 14.1057 16.6452 14.4517 16.5019C14.7977 16.3586 15.1121 16.1485 15.3769 15.8837C15.6417 15.6189 15.8517 15.3046 15.995 14.9586C16.1383 14.6126 16.2121 14.2418 16.2121 13.8673C16.2121 13.4928 16.1383 13.122 15.995 12.776C15.8517 12.43 15.6417 12.1157 15.3769 11.8509C15.1121 11.5861 14.7977 11.376 14.4517 11.2327C14.1057 11.0894 13.7349 11.0156 13.3604 11.0156H6.63878C6.26429 11.0156 5.89347 11.0894 5.54749 11.2327C5.20151 11.376 4.88714 11.5861 4.62234 11.8509C4.35754 12.1157 4.14749 12.43 4.00418 12.776C3.86087 13.122 3.78711 13.4928 3.78711 13.8673C3.78711 14.2418 3.86087 14.6126 4.00418 14.9586C4.14749 15.3046 4.35754 15.6189 4.62234 15.8837C4.88714 16.1485 5.20151 16.3586 5.54749 16.5019C5.89347 16.6452 6.26429 16.719 6.63878 16.719ZM13.3604 12.469C13.6424 12.469 13.9181 12.5526 14.1526 12.7093C14.3871 12.8659 14.5698 13.0886 14.6777 13.3491C14.7857 13.6097 14.8139 13.8964 14.7589 14.173C14.7039 14.4495 14.5681 14.7036 14.3687 14.903C14.1693 15.1024 13.9152 15.2382 13.6386 15.2932C13.362 15.3482 13.0753 15.32 12.8148 15.2121C12.5543 15.1042 12.3316 14.9214 12.1749 14.6869C12.0182 14.4525 11.9346 14.1768 11.9346 13.8948C11.9343 13.7073 11.9709 13.5216 12.0424 13.3483C12.1139 13.1749 12.2189 13.0174 12.3513 12.8847C12.4837 12.7519 12.641 12.6466 12.8142 12.5747C12.9873 12.5028 13.1729 12.4657 13.3604 12.4656V12.469Z"
        fill={color}
      />
    </svg>
  );
};
