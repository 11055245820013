import { removeUndefinedValuesFromObject } from "../../../../helpers/common";
import { SUB_GROUPS } from "../../../../types/groups/groups.types";
import { SubGroup } from "../../../GroupsPage/SubGroupsTree/SubGroupsTree.types";

export const mapCheckedRolesSubGroupsObj = (data: any) => {
  const result: any = {};

  Object.keys(data)?.forEach?.((key) => {
    result[key] = {};

    data[key]?.forEach?.((entry: any) => {
      if (entry.isChecked === true) {
        result[key][entry.subGroupId] = true;
      }
    });
  });
  removeUndefinedValuesFromObject(result);
  return result;
};

export const mapFlatGroupAndSubGroupsIds = (memberRoles: any) => {
  const flatRoles: { [key: string]: boolean } = {};

  for (const role in memberRoles) {
    if (memberRoles.hasOwnProperty(role)) {
      for (const subGroupId in memberRoles[role]) {
        if (memberRoles[role].hasOwnProperty(subGroupId)) {
          const prefix = subGroupId.startsWith("group_")
            ? "groupId_"
            : "subGroupId_";
          flatRoles[`${prefix}${subGroupId}`] = memberRoles[role][subGroupId];
        }
      }
    }
  }

  return flatRoles;
};

export const mapSubGroupsContactData = (
  memberRoleGroupsAndSubGroups: { [key: string]: boolean },
  role: string,
  subGroupsContact: { [key: string]: string[] },
  isKeyContactChecked: boolean
) => {
  let flatRoles: any = mapFlatGroupAndSubGroupsNamesAndIdsForRole(
    memberRoleGroupsAndSubGroups
  );

  let newSubGroupsContact: any = {};

  if (!isKeyContactChecked && subGroupsContact) {
    Object.keys(subGroupsContact || {}).forEach((key) => {
      const filteredArray = subGroupsContact[key].filter((r) => r !== role);
      if (filteredArray.length > 0) {
        newSubGroupsContact[key] = filteredArray;
      }
    });
  }

  if (isKeyContactChecked) {
    newSubGroupsContact = { ...subGroupsContact };
    Object.keys(flatRoles || {}).forEach((key) => {
      console.log(key, 8888);
      if (subGroupsContact && subGroupsContact[key]) {
        newSubGroupsContact[key].push(role);
      } else {
        newSubGroupsContact[key] = [role];
      }
    });
  }
  return newSubGroupsContact;
};

export const getIsKeyContactToggleChecked = (
  role: string,
  subGroupsContact: { [key: string]: string[] } | undefined
) => {
  /** return true if any subGroupsContact has a role in the array  */
  if (subGroupsContact) {
    for (const key in subGroupsContact) {
      if (subGroupsContact[key].includes(role)) {
        return true;
      }
    }
  }
  return false;
};

export const mapFlatGroupAndSubGroupsNamesAndIdsForRole = (
  memberRoleGroupsAndSubGroups: any
) => {
  let flatRoles: any = {};

  for (const subGroupId in memberRoleGroupsAndSubGroups) {
    if (memberRoleGroupsAndSubGroups.hasOwnProperty(subGroupId)) {
      flatRoles[`${subGroupId}`] = memberRoleGroupsAndSubGroups[subGroupId];
    }
  }

  return flatRoles;
};

export const mapMemberGroupsFromArrayToObject = (arrayOfStrings: string[]) => {
  const obj = arrayOfStrings.reduce((acc: { [key: string]: boolean }, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  return obj;
};

export function getNamePathFromGroups(
  groups: SUB_GROUPS[],
  subGroupId: string
): string {
  let foundGroup: SUB_GROUPS | undefined;
  let foundSubGroup: SubGroup | undefined;

  for (const group of groups) {
    if (subGroupId in group) {
      foundGroup = group;
      foundSubGroup = group[subGroupId];
      break;
    }
  }

  if (!foundGroup || !foundSubGroup) {
    return "";
  }

  const paths: string[] = [];
  let currentSubGroup = foundSubGroup;

  while (currentSubGroup) {
    paths.unshift(currentSubGroup.subGroupName);

    if (currentSubGroup.parentId === currentSubGroup.subGroupId) {
      break;
    }

    const parentSubGroupId = currentSubGroup.parentId;

    if (parentSubGroupId && parentSubGroupId in foundGroup) {
      currentSubGroup = foundGroup[parentSubGroupId];
    } else {
      break;
    }
  }

  const topLevelGroup = Object.values(foundGroup).find(
    (sg) => sg.subGroupId === sg.groupId && sg.groupId === sg.parentId
  );
  if (topLevelGroup && paths[0] !== topLevelGroup.subGroupName) {
    paths.unshift(topLevelGroup.subGroupName);
  }

  return paths.join(" - ");
}
