import styled from "styled-components";

export const StyledAddButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const StyledAddButton = styled.div(({ theme }) => ({
  width: 50,
  height: 50,
  background: theme.palette.primary.main,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
}));

export const StyledSearchContainer = styled.div(({ theme }) => ({
  fontWeight: "600",
  fontSize: "20px",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  cursor: "pointer",
  marginBottom: "25px",
  color: theme.colorPrimary,
}));

/* temp styles => TODO: to be removed */
export const TempStyledAppSideContainer = styled.div({
  padding: 20,

  "@media only screen and (min-width: 1200px)": {
    marginTop: -80,
    marginLeft: -30,
    padding: "78px 40px",
  },
});

export const StyledTabContainer = styled.div({
  padding: "20px 20px 0",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  "@media only screen and (min-width: 1200px)": {
    padding: "78px 40px 0",
  },
});
