import { useEffect, useState } from "react";
import { _fs } from "../../services/firebase/config";
import { FB_QUERYSNAPSHOT } from "../../types/firbase.types";
import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { RowProps } from "../../components/core/AppTable/types";
import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../providers/AppThemeProvider";
import { RecycleBinIcon } from "../../assets/icons/RecycleBinIcon";
import { Divider } from "@mui/material";
import { ThreeDotsIcon } from "../../assets/icons/ThreeDotsIcon";
import AppSelectButton from "../../components/core/AppSelectButton/AppSelectButton";
import {
  TEMPLATE_DATA,
  TEMPLATE_TYPE,
} from "../../types/templates/templates.types";
import { useSetAtom } from "jotai";
import {
  TEMPLATES_ATOMS,
  TEMPLATE_STEPPER_TITLES,
} from "./AddEditTemplateModal/AddEditTemplateModal.atoms";
import { DELETE_TEMPLATE_ATOM } from "./DeleteTemplate/DeleteTemplate.atoms";
import { mapEnabledArrayFieldsToObj } from "./AddEditTemplateModal/EventAdditionalFieldsStep/EventAdditionalFieldsStep.helper";

export const StyledLogoImage = styled.img({
  display: "block",
  width: 35,
});

export const StyledActions = styled.div({
  display: "flex",
  justifyContent: "end",
  gap: 15,
});

export const StyledClientName = styled.div({
  alignSelf: "center",
  fontSize: 14,
  color: APP_COMMON_COLORS.yourzown.colorPrimary,
});

const TEMPLATE_TYPE_DISPLAY_VALUE = {
  [TEMPLATE_TYPE.event]: "Event",
  [TEMPLATE_TYPE.performancePost]: "Performance Post",
  [TEMPLATE_TYPE.quickPost]: "Quick Post",
  [TEMPLATE_TYPE.formPost]: "Form Post",
};

export const useFirebaseStreamTemplates = (clientId: string) => {
  const [rows, setRows] = useState<any>([]);
  const [fetched, setFetched] = useState<boolean>(false);
  const setIsEditModalOpen = useSetAtom(TEMPLATES_ATOMS.isModalOpen);
  const setIsEditMode = useSetAtom(TEMPLATES_ATOMS.isEditMode);
  const setTemplateType = useSetAtom(TEMPLATES_ATOMS.templateType);
  const setTemplateName = useSetAtom(TEMPLATES_ATOMS.templateName);
  const setTemplateId = useSetAtom(TEMPLATES_ATOMS.templateId);
  const setPerformancePostFields = useSetAtom(
    TEMPLATES_ATOMS.performancePostFields
  );
  const setTemplateStepperTitles = useSetAtom(
    TEMPLATES_ATOMS.templateStepperTitles
  );
  const setFormPostAdditionalFields = useSetAtom(
    TEMPLATES_ATOMS.formPostAdditionalFields
  );
  const setEventCustomFields = useSetAtom(TEMPLATES_ATOMS.eventCustomFields);
  const setEventAdditionalFields = useSetAtom(
    TEMPLATES_ATOMS.eventAdditionalFields
  );
  const setDeleteTemplateName = useSetAtom(DELETE_TEMPLATE_ATOM.templateName);
  const setDeleteTemplateId = useSetAtom(DELETE_TEMPLATE_ATOM.templateId);
  const setIsDeleteModalOpen = useSetAtom(DELETE_TEMPLATE_ATOM.isModalOpen);

  const handleEditTemplate = (templateData: TEMPLATE_DATA) => {
    setIsEditModalOpen(true);
    setIsEditMode(true);
    setTemplateType(templateData.templateType);
    setTemplateName(templateData.templateName);
    setTemplateId(templateData.templateId);

    if (templateData.templateType === TEMPLATE_TYPE.performancePost) {
      setPerformancePostFields(templateData.additionalFields);
      setTemplateStepperTitles(
        TEMPLATE_STEPPER_TITLES[TEMPLATE_TYPE.performancePost]
      );
    }

    if (templateData.templateType === TEMPLATE_TYPE.formPost) {
      setFormPostAdditionalFields(templateData.additionalFields || []);
      setTemplateStepperTitles(TEMPLATE_STEPPER_TITLES[TEMPLATE_TYPE.formPost]);
    }
    if (templateData.templateType === TEMPLATE_TYPE.event) {
      setEventAdditionalFields(
        mapEnabledArrayFieldsToObj(templateData.additionalFields || [])
      );
      setEventCustomFields(templateData.customFields || []);
      setTemplateStepperTitles(TEMPLATE_STEPPER_TITLES[TEMPLATE_TYPE.event]);
    }
  };

  const handleDeleteTemplate = (templateName: string, templateId: string) => {
    setDeleteTemplateName(templateName);
    setDeleteTemplateId(templateId);
    setIsDeleteModalOpen(true);
  };

  useEffect(() => {
    const unsubscribe = _fs
      .collection(COLLECTIONS_TYPE.templates)
      .doc(String(clientId))
      .collection(COLLECTIONS_TYPE.clientTemplates)
      .orderBy("templateName", "asc")
      .onSnapshot((querySnapshot: FB_QUERYSNAPSHOT) => {
        let _rows: RowProps[] = [];
        let _row: RowProps = {} as RowProps;

        querySnapshot.forEach((doc) => {
          const docId = doc.id;
          const { templateName, templateType, templateId } =
            doc?.data() as TEMPLATE_DATA;

          _row = {
            id: [
              docId,
              templateName,
              TEMPLATE_TYPE_DISPLAY_VALUE[templateType],
            ].join(" "),
            cells: [
              templateName,
              <div>{TEMPLATE_TYPE_DISPLAY_VALUE[templateType]}</div>,
              <StyledActions>
                <RecycleBinIcon
                  onClick={() => handleDeleteTemplate(templateName, templateId)}
                />
                <Divider orientation="vertical" style={{ height: 22 }} />
                <AppSelectButton
                  title={<ThreeDotsIcon />}
                  buttonsList={["Edit"]}
                  buttonActions={[
                    () => handleEditTemplate(doc?.data() as TEMPLATE_DATA),
                  ]}
                />
              </StyledActions>,
            ],
          };
          _rows.push(_row);
        });
        setRows(_rows);
        setFetched(true);
      });
    return () => unsubscribe();
  }, [fetched]);

  return fetched ? rows : null;
};
