import { useSelector } from "react-redux";
import { TitleBodyButtonWidget } from "../../../../components/widgets/TitleBodyButtonWidget/TitleBodyButtonWidget";
import { FIRESTORE_FUNCTIONS } from "../../../../constants/firebase.constants";
import { firebaseHttpsCallable } from "../../../../hooks/firebase/firebase.helper";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../../../styles/styles.constants";
import { GENERAL_REPONSE } from "../../../../types/firbase.types";
import { useState } from "react";

export const StripeDashboardCard = () => {
  const client = useSelector(getClientSelector);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <TitleBodyButtonWidget
      title="Payment Dashboard"
      body="Access your dashboard to monitor your balance, view upcoming payouts, and track earnings in real time."
      buttonLabel={"STRIPE DASHBOARD"}
      isLoading={isLoading}
      onButtonClick={() => {
        setIsLoading(true);
        firebaseHttpsCallable(
          FIRESTORE_FUNCTIONS.createClientAccountDashboardLink
        )({
          clientId: client.clientId,
        })
          .then((res) => {
            const data = res.data as GENERAL_REPONSE;
            const newWindow = window.open(
              data.data?.url || "https://dashboard.stripe.com/login",
              "_blank"
            );

            if (
              !newWindow ||
              newWindow?.closed ||
              typeof newWindow?.closed === "undefined"
            ) {
              alert(
                "Redirection to Stripe was blocked by your browser. Please allow pop-ups and redirects for this site in your browser settings."
              );
            }
          })
          .catch(() => {
            toast("Something went wrong [3]", APP_TOASTIFY.ERROR);
          })
          .finally(() => setIsLoading(false));
      }}
    />
  );
};
