import React from "react";
import { StyledPageContainer } from "../../components/shared/StyledComponents/StyledPageContainer";
import { SetupPaymentCard } from "../PaymentsPage/cards/SetupPaymentCard/SetupPaymentCard";
import { useUserIdentity } from "../../hooks/useUserIdentity/useUserIdentity";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../redux/client/clientSlice";
import { StripeDashboardCard } from "../PaymentsPage/cards/StripeDashboardCard/StripeDashboardCard";
import { StyledCardsContainer } from "../../components/shared/StyledComponents/StyledCardsContainer";

export const DashboardPage = () => {
  const { user } = useUserIdentity();
  const isYourzownAdmin = user.yourzownPermissions;
  const client = useSelector(getClientSelector);
  const isActiveAccount = client.paymentAccountStatus === "active";

  if (isYourzownAdmin) {
    return <StyledPageContainer>Dashboard Page</StyledPageContainer>;
  }
  return (
    <StyledPageContainer>
      <StyledCardsContainer>
        <SetupPaymentCard />
        {isActiveAccount && <StripeDashboardCard />}
      </StyledCardsContainer>
    </StyledPageContainer>
  );
};
