import { Form, Formik, FormikErrors } from "formik";
import * as Yup from "yup";
import { StyledAppModalSubmitButtonContainer } from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppButton } from "../../../../components/core/AppButton/AppButton";
import { useSetAtom, useAtomValue, useAtom } from "jotai";
import { LOCATION_MODAL_ATOMS } from "../LocationFormModal.Atoms";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { getUser } from "../../../../redux/user/userSlice";
import { firebaseHttpsCallable } from "../../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../../constants/firebase.constants";
import { LOCATION_DATA } from "../../../../types/locations/locations.types";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../../../styles/styles.constants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { StyledAppFormikFieldError } from "../../../../components/core/AppFormik/AppFormik.styles";
interface LOCATION_STEP_TWO_VALUES {
  locationDetails: string;
}

export const LOCATION_STEP_TWO_INITIAL_VALUES: LOCATION_STEP_TWO_VALUES = {
  locationDetails: "",
};

const schema = Yup.object().shape({
  locationDetails: Yup.string()
    .required("Required")
    .notOneOf(["<p><br></p>"], "Required"),
});

const QUILL_TOOLBAR_CONFIG = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["clean"],
  ],
};
export const LocationFormStepTwo = () => {
  const locationFormData = useAtomValue(LOCATION_MODAL_ATOMS.locationFormData);

  const clientData = useSelector(getClientSelector);
  const userData = useSelector(getUser);
  const setIsPublished = useSetAtom(LOCATION_MODAL_ATOMS.isPublished);
  const isEditMode = useAtomValue(LOCATION_MODAL_ATOMS.isEditMode);

  const handleOnSubmit = async (values: any) => {
    const payload: LOCATION_DATA = {
      ...values,
      clientId: String(clientData.clientId),
      createdBy: String(userData.uid),
      id: isEditMode ? values.id : String(Date.now()),
    };

    await firebaseHttpsCallable(
      isEditMode
        ? FIRESTORE_FUNCTIONS.updateLocation
        : FIRESTORE_FUNCTIONS.addLocation
    )(payload)
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          toast(
            isEditMode ? "Location updated" : "Location added",
            APP_TOASTIFY.SUCCESS
          );
        } else {
          toast(
            isEditMode
              ? "Something went wrong while updating location [1]"
              : "Something went wrong while adding location [1]",
            APP_TOASTIFY.ERROR
          );
        }
        setIsPublished(true);
      })
      .catch((err: any) => {
        console.log({ err });
        toast(
          isEditMode
            ? "Something went wrong while updating location [2]"
            : "Something went wrong while adding location [2]",
          APP_TOASTIFY.ERROR
        );
      });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...LOCATION_STEP_TWO_INITIAL_VALUES,
        ...locationFormData,
      }}
      validationSchema={schema}
      onSubmit={(values: LOCATION_STEP_TWO_VALUES) => {
        handleOnSubmit(values);
      }}
    >
      {({ errors, values, setFieldValue, handleSubmit, isSubmitting }) => {
        const _errors: FormikErrors<LOCATION_STEP_TWO_VALUES> = errors;

        return (
          <Form>
            <div>
              <ReactQuill
                theme="snow"
                style={{ height: "45vh" }}
                value={values.locationDetails}
                onChange={(value) => setFieldValue("locationDetails", value)}
                modules={QUILL_TOOLBAR_CONFIG}
              />
              {_errors.locationDetails && (
                <StyledAppFormikFieldError>
                  {String(errors.locationDetails)}
                </StyledAppFormikFieldError>
              )}
            </div>

            <StyledAppModalSubmitButtonContainer style={{ marginTop: 80 }}>
              <AppButton onClick={handleSubmit} isLoading={isSubmitting}>
                Submit
              </AppButton>
            </StyledAppModalSubmitButtonContainer>
          </Form>
        );
      }}
    </Formik>
  );
};
