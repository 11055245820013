import { atom } from "jotai";
import { MemberFormValues, RolesAndSubGroups } from "./MembersPage.types";
import { SelectOption } from "../../components/core/AppFormik/AppFormikSelect/AppFormikSelect";
import { SUB_GROUPS } from "../../types/groups/groups.types";
import { MEMBER_INITIAL_VALUES } from "./constants";
import { SUBSCRIPTION_DATA } from "../../types/payments/payments.types";

export const MEMBERS_ATOMS = {
  tabValue: atom(0),
  addMemberFormValues: atom<MemberFormValues>(MEMBER_INITIAL_VALUES),
  isGroupsLoading: atom(true),
  products: atom<SelectOption[] | undefined>(undefined),
  defaultPaymentProductId: atom<string | undefined>(undefined),
  isEditMode: atom(false),
  subGroupsContact: atom<{ [key: string]: string[] } | {}>({}),
  selectedEditRow: atom<MemberFormValues | null>(null),
  clientGroupsAndSubGroups: atom<SUB_GROUPS[] | []>([]),
  memberSubscription: atom<{ [id: string]: SUBSCRIPTION_DATA } | undefined>(
    undefined
  ),

  /** to be removed  ad use subGroups and roles separately */
  rolesAndSubGroups: atom<RolesAndSubGroups>({}),
};
