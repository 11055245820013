import { useEffect } from "react";
import { _fs } from "../../services/firebase/config";
import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { getFirebaseDataByColKeyVal } from "../../hooks/firebase/getFirebaseDataByColKeyVal";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../redux/client/clientSlice";
import { MEMBERS } from "../../types/members/members.types";
import { useAtom } from "jotai";
import { GROUPS_PAGE_ATOMS } from "./GroupsPage.Atoms";

export interface GROUP_MEMBER_COUNT {
  membersIds: { [key: string]: string[] };
  childrenIds: { [key: string]: string[] };
}

export const useGetGroupsMembersCounts = () => {
  const client = useSelector(getClientSelector);

  const [groupMembersCount, setGroupMembersCount] = useAtom(
    GROUPS_PAGE_ATOMS.groupMembersCount
  );

  useEffect(() => {
    const fetchGroupMembersCounts = async () => {
      const res = await getFirebaseDataByColKeyVal(
        COLLECTIONS_TYPE.members,
        `clientsIds.${String(client.clientId)}`,
        true
      );

      let _groupsMemberCount: GROUP_MEMBER_COUNT = {
        membersIds: {},
        childrenIds: {},
      };

      let members = [];
      if (res) {
        members = [...(Object.values(res) as unknown as MEMBERS[])];

        if (members?.length > 1) {
          members.forEach((m) => {
            if (m.roles && m.roles[String(client.clientId)]) {
              const groupsOfRoles = Object.values(
                m.roles[String(client.clientId)]
              );
              if (groupsOfRoles && groupsOfRoles.length > 0) {
                groupsOfRoles.forEach((subGroupIdsObj) => {
                  if (subGroupIdsObj) {
                    const subGroupsIdsKeysArr = Object.keys(
                      subGroupIdsObj as []
                    );
                    if (subGroupsIdsKeysArr && subGroupsIdsKeysArr.length > 0) {
                      const flattenedArray = subGroupsIdsKeysArr.flat();

                      const uniqueSubGroupsIdsKeysArr = Array.from(
                        new Set(flattenedArray)
                      );

                      uniqueSubGroupsIdsKeysArr.forEach(
                        (subGroupId: string) => {
                          if (!_groupsMemberCount.membersIds[subGroupId]) {
                            _groupsMemberCount.membersIds[subGroupId] = [m.uid];
                          } else if (
                            !_groupsMemberCount.membersIds[subGroupId].includes(
                              m.uid
                            )
                          ) {
                            _groupsMemberCount.membersIds[subGroupId] = [
                              ..._groupsMemberCount.membersIds[subGroupId],
                              m.uid,
                            ];
                          }
                        }
                      );
                    }
                  }
                });
              }
            }
            if (m.children && m.children.length > 0) {
              m.children.forEach((c) => {
                if (c.subGroups && c.subGroups?.length > 0) {
                  c.subGroups.forEach((subGroupId: string) => {
                    const childId = String(c.childId);
                    if (!_groupsMemberCount.childrenIds[subGroupId]) {
                      _groupsMemberCount.childrenIds[subGroupId] = [childId];
                    } else if (
                      !_groupsMemberCount.childrenIds[subGroupId].includes(
                        childId
                      )
                    ) {
                      _groupsMemberCount.childrenIds[subGroupId] = [
                        ..._groupsMemberCount.childrenIds[subGroupId],
                        childId,
                      ];
                    }
                  });
                }
              });
            }
          });
        }

        setGroupMembersCount(_groupsMemberCount);
      }
    };

    fetchGroupMembersCounts();
  }, []);
};
