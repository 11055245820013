import React from "react";
import { Chip, SwipeableDrawer } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUser } from "../../../redux/user/userSlice";
import { GlobalState } from "../../../types/global";
import {
  BurgerMenuWrapper,
  StyledCloseBurgerMenu,
  StyledMenuListWrapper,
  StyledMenuItem,
  StyledLogoContainer,
  StyledLogoImage,
  StyledMenuItemWrapper,
  StyledBurgerMenuFlexContainer,
  PAPER_PROPS_STYLES,
  StyledLogoAndCloseIconContainer,
  StyledBottomButtonContainer,
} from "./AppBurgerMenu.styles";
import { useScreenWidth } from "../../../hooks/useScreenWidth/useScreenWidth";
import { getClientSelector } from "../../../redux/client/clientSlice";
import { CLIENT_LINKS, getYourZownLinks } from "./AppBurgerMenu.constant";
import { ArrowRightIcon } from "./icons/ArrowRightIcon";
import { MenuIcon } from "./icons/MenuIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import { useFirebaseLogoutUser } from "../../../hooks/firebase/useFirebaseLogoutUser";
import { YourZownIcon } from "../../../assets/icons/YourZownIcon";
import { APP_ROUTES } from "../../../constants/routes.constant";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useFirebaseStreamAppVersions } from "../../../hooks/useFirebaseStreamAppVersions/useFirebaseStreamAppVersions";
import { atom, useAtomValue } from "jotai";

export const BURGER_MENU_ATOMS = {
  version: atom<string>(""),
};

export const AppBurgerMenu = () => {
  const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState<boolean>(false);
  const screenWidth = useScreenWidth();
  const location = useLocation();
  const pageName = location?.pathname?.split("/").pop();

  const version = useAtomValue(BURGER_MENU_ATOMS.version);

  const navigate = useNavigate();

  useFirebaseStreamAppVersions();

  const handleNavigation = (url: string) => {
    navigate(url);
    setIsOpenBurgerMenu(false);
  };
  const logout = useFirebaseLogoutUser();
  const client = useSelector(getClientSelector);
  const userData = useSelector((state: GlobalState) => getUser(state));
  const yourzownPermissions = userData.yourzownPermissions;
  const clientAdmins = Object.entries(userData.isClientAdmin || {})?.filter(
    ([_clientId, isClientAdmin]) => isClientAdmin
  );
  let sidebarItems;
  if (yourzownPermissions) {
    sidebarItems = getYourZownLinks(yourzownPermissions);
  } else {
    sidebarItems = CLIENT_LINKS;
  }

  return (
    <div>
      {screenWidth < 1200 && (
        <div onClick={() => setIsOpenBurgerMenu(true)}>
          <MenuIcon />
        </div>
      )}
      <SwipeableDrawer
        anchor={screenWidth < 1200 ? "right" : "left"}
        open={screenWidth < 1200 ? isOpenBurgerMenu : true}
        onClose={() => setIsOpenBurgerMenu(false)}
        onOpen={() => setIsOpenBurgerMenu(true)}
        variant={screenWidth < 1200 ? undefined : "permanent"}
        PaperProps={
          screenWidth < 1200
            ? PAPER_PROPS_STYLES.mobile
            : PAPER_PROPS_STYLES.desktop
        }
        sx={{ height: "100%" }}
      >
        <BurgerMenuWrapper>
          <StyledLogoAndCloseIconContainer>
            <StyledLogoContainer>
              {!userData?.yourzownPermissions && client?.branding?.logoUrl ? (
                <StyledLogoImage src={client?.branding?.logoUrl} />
              ) : (
                <YourZownIcon width="70" height="70" />
              )}
            </StyledLogoContainer>

            <StyledCloseBurgerMenu onClick={() => setIsOpenBurgerMenu(false)}>
              X
            </StyledCloseBurgerMenu>
          </StyledLogoAndCloseIconContainer>

          <StyledMenuListWrapper>
            {sidebarItems.map((link, index) => {
              const isActive = Boolean(`/${pageName}` === link?.url);
              return (
                link && (
                  <StyledMenuItemWrapper $isActive={isActive} key={index}>
                    <StyledMenuItem
                      $isActive={isActive}
                      onClick={() => handleNavigation(link.url)}
                      key={link.url}
                    >
                      <StyledBurgerMenuFlexContainer
                        style={{ gap: 10, alignItems: "center" }}
                      >
                        {link.icon(isActive)}
                        <div>{link.label}</div>
                      </StyledBurgerMenuFlexContainer>
                      <ArrowRightIcon isActive={isActive} />
                    </StyledMenuItem>
                  </StyledMenuItemWrapper>
                )
              );
            })}
            <div style={{ marginTop: "auto" }}>
              {(window.location.origin.includes("localhost") ||
                window.location.origin.includes("yourzown-development")) &&
                (process.env.REACT_APP_PROJECT_ID === "yourzown-prod" ? (
                  <Chip
                    label="PRODUCTION"
                    style={{ backgroundColor: "red", color: "white" }}
                  />
                ) : (
                  <Chip label={`DEVELOPMENT`} style={{ color: "white" }} />
                ))}
              <Chip label={`${version}`} style={{ color: "white" }} />

              {clientAdmins.length > 1 && (
                <StyledBottomButtonContainer
                  onClick={() => navigate(APP_ROUTES.clientSelector)}
                >
                  <PeopleAltIcon fontSize="small" />
                  <div>Select Client</div>
                </StyledBottomButtonContainer>
              )}
              <StyledBottomButtonContainer onClick={logout}>
                <LogoutIcon fontSize="small" />
                <div data-testid="logout-button">Logout</div>
              </StyledBottomButtonContainer>
            </div>
          </StyledMenuListWrapper>
        </BurgerMenuWrapper>
      </SwipeableDrawer>
    </div>
  );
};
