export enum PAYMENT_FREQUENCY {
  monthly = 1,
  quarterly = 3,
  semiAnnually = 6,
  annually = 12,
}

export const FREQUENCY_TO_NAME = {
  [PAYMENT_FREQUENCY.monthly]: "Monthly",
  [PAYMENT_FREQUENCY.quarterly]: "Quarterly",
  [PAYMENT_FREQUENCY.semiAnnually]: "Semi Annually",
  [PAYMENT_FREQUENCY.annually]: "Annually",
};

export const DEV_FREQUENCIES = {
  [PAYMENT_FREQUENCY.monthly]: "Daily",
  [PAYMENT_FREQUENCY.quarterly]: "3 DAYS",
  [PAYMENT_FREQUENCY.semiAnnually]: "6 DAYS",
  [PAYMENT_FREQUENCY.annually]: "12 Days",
};

export interface PAYMENT_DATA {
  name: string;
  frequency: PAYMENT_FREQUENCY;
  price: number;
  paymentAccountId: string;
  clientId: string;
  updatedBy: string;
  isDefaultPayment?: boolean;

  productId: string;
  updatedAt: number;
}

export interface ADD_SUBSCRIPTION_PRODUCT_DTO {
  name: string;
  frequency: PAYMENT_FREQUENCY;
  price: number;
  paymentAccountId: string;
  clientId: string;
  updatedBy: string;
}

export interface SUBSCRIPTION_DATA {
  childId?: string;
  clientId: string;
  memberId: string;
  nextPaymentDate: number;
  productId: string;
  status: "active" | "inActive";
}

export interface DELETE_SUBSCRIPTION_PRODUCT_DTO {
  clientId: string;
  productId: string;
  currentSubscriptionsUsersIds?: SUBSCRIPTION_DATA[];
  newSubscriptionsUsersProductId?: string;
}

export interface UPDATE_DEFAULT_PAYMENT_DTO {
  productId?: string;
  clientId: string;
  checked: boolean;
}
