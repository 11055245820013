import React from "react";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";

export const PaymentsIcon = (props: { isActive: boolean }) => {
  const color = props.isActive
    ? APP_COMMON_COLORS.common.white
    : APP_COMMON_COLORS.dark[500];

  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H21C21.5304 16 22.0391 15.7893 22.4142 15.4142C22.7893 15.0391 23 14.5304 23 14V5.5C23 5.4337 22.9737 5.37011 22.9268 5.32322C22.8799 5.27634 22.8163 5.25 22.75 5.25H0.25C0.183696 5.25 0.120107 5.27634 0.0732233 5.32322C0.0263392 5.37011 0 5.4337 0 5.5V14ZM16.5 11.75H19.5C19.6989 11.75 19.8897 11.829 20.0303 11.9697C20.171 12.1103 20.25 12.3011 20.25 12.5C20.25 12.6989 20.171 12.8897 20.0303 13.0303C19.8897 13.171 19.6989 13.25 19.5 13.25H16.5C16.3011 13.25 16.1103 13.171 15.9697 13.0303C15.829 12.8897 15.75 12.6989 15.75 12.5C15.75 12.3011 15.829 12.1103 15.9697 11.9697C16.1103 11.829 16.3011 11.75 16.5 11.75ZM4 7.75H12C12.1989 7.75 12.3897 7.82902 12.5303 7.96967C12.671 8.11032 12.75 8.30109 12.75 8.5C12.75 8.69891 12.671 8.88968 12.5303 9.03033C12.3897 9.17098 12.1989 9.25 12 9.25H4C3.80109 9.25 3.61032 9.17098 3.46967 9.03033C3.32902 8.88968 3.25 8.69891 3.25 8.5C3.25 8.30109 3.32902 8.11032 3.46967 7.96967C3.61032 7.82902 3.80109 7.75 4 7.75ZM4 11.75H7C7.19891 11.75 7.38968 11.829 7.53033 11.9697C7.67098 12.1103 7.75 12.3011 7.75 12.5C7.75 12.6989 7.67098 12.8897 7.53033 13.0303C7.38968 13.171 7.19891 13.25 7 13.25H4C3.80109 13.25 3.61032 13.171 3.46967 13.0303C3.32902 12.8897 3.25 12.6989 3.25 12.5C3.25 12.3011 3.32902 12.1103 3.46967 11.9697C3.61032 11.829 3.80109 11.75 4 11.75Z"
        fill={color}
      />
      <path
        d="M23 2C23 1.46957 22.7893 0.960859 22.4142 0.585786C22.0391 0.210714 21.5304 0 21 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V3.5C0 3.5663 0.0263392 3.62989 0.0732233 3.67678C0.120107 3.72366 0.183696 3.75 0.25 3.75H22.75C22.8163 3.75 22.8799 3.72366 22.9268 3.67678C22.9737 3.62989 23 3.5663 23 3.5V2Z"
        fill={color}
      />
    </svg>
  );
};
