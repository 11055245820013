export const areAllPermissionsTogglesChecked = (permissionsObject: {
  [key: string]: { isChecked: boolean };
}) => {
  for (const key in permissionsObject) {
    if (
      permissionsObject.hasOwnProperty(key) &&
      !permissionsObject[key].isChecked
    ) {
      return false;
    }
  }
  return true;
};

export const filterCheckedPermissions = (permissions: any) => {
  const filteredPermissions: { [key: string]: boolean } = {};
  for (const key in permissions) {
    if (permissions[key].isChecked) {
      filteredPermissions[key] = true;
    }
  }
  return filteredPermissions;
};
