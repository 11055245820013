import React, { useEffect, useState } from "react";
import { StyledPageContainer } from "../../components/shared/StyledComponents/StyledPageContainer";
import { PageAddButton } from "../../components/shared/PageAddButton/PageAddButton";
import { StyledGroupsPageTools } from "./GroupsPage.styles";
import { AppTable } from "../../components/core/AppTable/AppTable";
import { useFirebaseStreamGroups } from "./useFirebaseStreamGroups";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../redux/client/clientSlice";
import { AppSpinner } from "../../components/core/AppSpinner/AppSpinner";
import { AppModal } from "../../components/core/AppModal/AppModal";
import { GROUP_MODAL_ATOMS } from "./GroupFormModal/GroupFormModal.Atoms";
import { useAtom } from "jotai";
import { GroupFormModal } from "./GroupFormModal/GroupFormModal";
import { GROUPS_PAGE_ATOMS } from "./GroupsPage.Atoms";
import { useGetGroupsMembersCounts } from "./useGetGroupsMembersCounts";

export const GroupsPage = () => {
  useGetGroupsMembersCounts();

  const [isLoading, setIsLoading] = useState(true);
  const client = useSelector(getClientSelector);
  const rows: any[] = useFirebaseStreamGroups(String(client?.clientId));

  const [isEditMode, setIsEditMode] = useAtom(GROUP_MODAL_ATOMS.isEditMode);
  const [isOpen, setIsOpen] = useAtom(GROUP_MODAL_ATOMS.isOpen);
  const [groupName, setGroupName] = useAtom(GROUP_MODAL_ATOMS.groupName);
  const [groupId, setGroupId] = useAtom(GROUP_MODAL_ATOMS.groupId);
  const [newGroupData, setNewGroupData] = useAtom(
    GROUP_MODAL_ATOMS.newGroupData
  );
  const [tree, setTree] = useAtom(GROUP_MODAL_ATOMS.tree);
  const [isPublished, setIsPublished] = useAtom(GROUP_MODAL_ATOMS.isPublished);
  const [groupMembersCount, setGroupMembersCount] = useAtom(
    GROUPS_PAGE_ATOMS.groupMembersCount
  );

  let columns = [
    {
      cell: <div>Group</div>,
    },

    {
      cell: <div>SUB GROUPS</div>,
    },
    {
      cell: <div>CHILDREN</div>,
    },
    {
      cell: <div>MEMBERS</div>,
    },

    {
      cell: (
        <div style={{ display: "flex", justifyContent: "end" }}>Actions</div>
      ),
    },
  ];

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    rows && setIsLoading(false);
  }, [rows]);

  useEffect(() => {
    if (!isOpen || isPublished) {
      setIsEditMode(false);
      setIsOpen(false);
      setGroupName("");
      setGroupId("");
      setNewGroupData(undefined);
      setTree([]);
      setIsPublished(false);
    }
  }, [isOpen, isPublished]);

  if (isLoading) {
    return (
      <StyledPageContainer>
        <AppSpinner />
      </StyledPageContainer>
    );
  }

  return (
    <StyledPageContainer>
      <StyledGroupsPageTools>
        <PageAddButton text={"Add group"} onClick={() => setIsOpen(true)} />
      </StyledGroupsPageTools>

      <AppModal
        isOpen={isOpen}
        closeModal={handleCloseModal}
        $customContentStyle={{ padding: 0 }}
        currentTab={0}
        setCurrentTab={() => {}}
      >
        <GroupFormModal />
      </AppModal>

      <AppTable
        columns={columns}
        rows={rows}
        showPagination={false}
        showTotalCount={false}
        customstyles={{
          tableTd: {
            width: "25%",
            padding: "20px 27px",
            "&:first-child": {
              paddingInlineStart: "20px",
            },
          },
        }}
      />
    </StyledPageContainer>
  );
};
