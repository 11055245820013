import React, { useState } from "react";
import styled from "styled-components";
import { RowProps } from "../types";
import { CSSObject } from "styled-components";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";
import { Collapse } from "@mui/material";

export const StyledTr = styled.tr<{
  customstyles?: CSSObject;
}>(({ customstyles }) => ({
  transition: "0.1s all linear",
  paddingInline: 20,
  textAlign: "start",
  overflow: "hidden",
  position: "relative",
  borderBottom: `1px dashed ${APP_COMMON_COLORS.dark[100]}`,

  "&::after": {
    content: "''",
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    width: "calc(100% - 40px)",
    height: "1px",
    backgroundSize: "8px 1px",
  },
  "&:last-child::after": {
    display: "none",
  },
  "@media screen and (max-width: 1200px)": {
    maxHeight: "2.875rem",
  },
  ...customstyles,
}));

export const StyledTd = styled.td<{
  customstyles?: CSSObject;
}>(({ customstyles }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: APP_COMMON_COLORS.dark[600],
  padding: 20,
  textAlign: "start",
  "&:hover": {
    color: "inherit",
  },
  "@media screen and (max-width: 1200px)": {
    lineHeight: "0.875rem",
    fontSize: "0.75rem",
    minWidth: "fit-content",
  },
  ...customstyles,
}));

export const TableData = (props: {
  rows: RowProps[];
  customstylesTableTr?: CSSObject;
  customstylesTableTd?: CSSObject;
}): React.ReactElement => {
  const RenderRow = ({ row, index }: { row: RowProps; index: number }) => {
    const [showMasterDetails, setShowMasterDetails] = useState(false);
    return (
      <React.Fragment>
        <StyledTr
          customstyles={{
            ...props.customstylesTableTr,
          }}
          onClick={
            row.masterDetails
              ? () => setShowMasterDetails(!showMasterDetails)
              : undefined
          }
        >
          {row.cells &&
            row.cells.map((cellContent: any, idx: number) => (
              <StyledTd
                key={`row${index}_data${idx}`}
                customstyles={{
                  ...props.customstylesTableTd,
                }}
              >
                {cellContent}
              </StyledTd>
            ))}
        </StyledTr>
        {row.masterDetails && (
          <StyledTr style={{ borderBottom: "none" }}>
            <StyledTd
              customstyles={{
                padding: "0px 20px",
              }}
              colSpan={row.cells.length}
            >
              <Collapse in={showMasterDetails} timeout="auto" unmountOnExit>
                {row.masterDetails}
              </Collapse>
            </StyledTd>
          </StyledTr>
        )}
      </React.Fragment>
    );
  };

  return (
    <tbody>
      {props.rows &&
        props.rows.map((row, index) => (
          <RenderRow key={index} index={index} row={row} />
        ))}
    </tbody>
  );
};
