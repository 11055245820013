import { SxProps } from "@mui/material";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";
import styled, { CSSObject } from "styled-components";

export const modalStyles: SxProps = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  left: "50%",
  transform: "translate(-50%, 0%)",
  backgroundColor: APP_COMMON_COLORS.common.white,
  zIndex: 2,
  overflow: "auto",
  maxHeight: "90%",
  outline: "none",
  borderRadius: "14px",
  boxShadow: 24,
  border: "10px solid #808393",
  "::-webkit-scrollbar": {
    width: "7px",
    height: "7px",
  },
  "::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#00000033",
    borderRadius: "7px",
  },
};

export const StyledModalClose = styled.div(({ theme }) => ({
  backgroundColor: "rgba(128, 131, 147, 0.3)",
  cursor: "pointer",
  color: theme.colorPrimary,
  position: "absolute",
  right: 20,
  top: 20,
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 100,
  marginLeft: "auto",
}));

export const StyledModalBackButton = styled.div(({ theme }) => ({
  cursor: "pointer",
  color: theme.colorPrimary,
  position: "absolute",
  left: 20,
  top: 20,
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 100,
  "@media only screen and (min-width: 1200px)": {
    display: "none",
  },
}));

export const StyledCustomModalContent = styled.div<{
  $customstyles?: CSSObject;
}>(({ $customstyles, theme }) => ({
  padding: 20,
  "@media only screen and (min-width: 1200px)": {
    padding: "78px 40px",
  },
  ...$customstyles,
}));

export const StyledModalHeaderContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "sticky",
  top: 0,
  padding: "16px 0",
  zIndex: 2,
});

export const StyledModalHeader = styled.div({
  color: APP_COMMON_COLORS.dark[700],
  fontWeight: 600,
  fontSize: 20,
});

export const APP_MODAL_HEIGHT = 680;
