import { useAtom } from "jotai";
import { AppModal } from "../../../../components/core/AppModal/AppModal";
import {
  StyleAppModalSingleStepContainer,
  StyledAppModalStepsContainer,
} from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppModalMobileSteps } from "../../../../components/shared/AppModalMobileSteps/AppModalMobileSteps";
import { AppModalDesktopSteps } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps";
import { StyledAppModalDesktopStepTitle } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { VIEW_CHILD_MODAL_ATOMS } from "./ViewChildModal.Atoms";
import { AppSpinner } from "../../../../components/core/AppSpinner/AppSpinner";
import { useEffect, useState } from "react";
import {
  StyledErrorChip,
  StyledSubGroupContainer,
  StyledViewClickable,
  StyledViewContainer,
  StyledViewRow,
  StyledViewTitle,
} from "../ViewModals.styles";
import { StyledPrimaryColorText } from "../../../../components/shared/StyledComponents/shared-styled-components";
import { getFirstValueFromFirebaseByColKeyVal } from "../../../../hooks/firebase/getFirstValueFromFirebaseByColKeyVal";
import { COLLECTIONS_TYPE } from "../../../../constants/firebase.constants";
import { ChildrenDTO } from "../../MembersPage.types";
import { tsToDayMonthYear } from "../../../../helpers/date-time";
import { MEMBERS } from "../../../../types/members/members.types";
import { Skeleton } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { VIEW_MEMBER_MODAL_ATOMS } from "../ViewMemberModal/ViewMemberModal.Atoms";
import { getNamePathFromGroups } from "../../Tabs/GroupsTab/GroupsTab.helper";
import { MEMBERS_ATOMS } from "../../MembersPage.Atoms";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  getNextPaymentDate,
  getPaymentStatus,
  mapValidProductId,
} from "../../MemberPage.helper";

export const ViewChildModal = () => {
  const [isViewChildModalOpen, setIsViewChildModalOpen] = useAtom(
    VIEW_CHILD_MODAL_ATOMS.isViewChildModalOpen
  );

  const [viewChildId, setViewChildId] = useAtom(
    VIEW_CHILD_MODAL_ATOMS.viewChildId
  );

  const [childData, setChildData] = useState<ChildrenDTO>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [memberData, setMemberData] = useState<MEMBERS>();
  const [viewMemberId, setViewMemberId] = useAtom(
    VIEW_MEMBER_MODAL_ATOMS.viewMemberId
  );
  const [isViewMemberModalOpen, setIsViewMemberModalOpen] = useAtom(
    VIEW_MEMBER_MODAL_ATOMS.isViewMemberModalOpen
  );
  const [clientGroupsAndSubGroups, setClientGroupsAndSubGroups] = useAtom(
    MEMBERS_ATOMS.clientGroupsAndSubGroups
  );

  const [memberSubscription, setMemberSubscription] = useAtom(
    MEMBERS_ATOMS.memberSubscription
  );

  const handleClose = () => {
    setIsViewChildModalOpen(false);
    setViewChildId(undefined);
  };

  const handleOpenMemberModal = (memberId: string) => {
    handleClose();
    setViewMemberId(memberId);
    setIsViewMemberModalOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    if (viewChildId) {
      const fetchMember = async () => {
        const _childData = (await getFirstValueFromFirebaseByColKeyVal(
          COLLECTIONS_TYPE.children,
          "childId",
          viewChildId
        )) as ChildrenDTO;
        setIsLoading(false);
        setChildData(_childData);
      };
      fetchMember();
    }
    console.log(memberSubscription);
  }, [viewChildId]);

  useEffect(() => {
    if (childData?.memberId) {
      const getMemberData = async () => {
        const _memberData = (await getFirstValueFromFirebaseByColKeyVal(
          COLLECTIONS_TYPE.members,
          "uid",
          String(childData?.memberId)
        )) as MEMBERS;
        setMemberData(_memberData);
      };

      getMemberData();
    }
  }, [childData?.memberId]);

  if (!isViewChildModalOpen) {
    return <></>;
  }

  return (
    <AppModal
      isOpen={isViewChildModalOpen}
      closeModal={() => handleClose()}
      $customContentStyle={{ padding: 0 }}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <StyledAppModalStepsContainer>
        <AppModalMobileSteps
          tabsLength={1}
          tabValue={0}
          titlesOfSteps={["Child profile"]}
          hideStepsTitle={true}
        />
        <AppModalDesktopSteps
          tabs={[]}
          tabsTitle={""}
          OnChangeTabChange={() => {}}
          tabValue={0}
        />
        <StyleAppModalSingleStepContainer style={{ paddingBottom: 0 }}>
          <StyledAppModalDesktopStepTitle>
            Child profile
          </StyledAppModalDesktopStepTitle>

          {isLoading && <AppSpinner />}

          {!isLoading && (
            <StyledViewContainer>
              <StyledViewRow>
                <StyledViewTitle>First name</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {childData?.firstName}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Last name</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {childData?.lastName}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Gender</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {childData?.gender || "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Date of birth</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {childData?.dateOfBirth
                    ? tsToDayMonthYear(childData?.dateOfBirth)
                    : "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Medical conditions</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {childData?.medicalConditions || "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Photo consent</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {childData?.photoConsent === false && "No"}
                  {childData?.photoConsent === true && "Yes"}
                  {!childData?.photoConsent &&
                    childData?.photoConsent !== false &&
                    "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Payment status</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {getPaymentStatus(
                    childData &&
                      memberSubscription &&
                      memberSubscription[String(childData.childId)]
                  )}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>
                  Payment date
                  {memberSubscription &&
                    mapValidProductId(
                      memberSubscription[String(childData?.childId)]?.productId
                    ) &&
                    getNextPaymentDate(
                      memberSubscription,
                      childData?.childId
                    ) !== 0 &&
                    getNextPaymentDate(memberSubscription, childData?.childId) <
                      Date.now() && <StyledErrorChip>overdue</StyledErrorChip>}
                </StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {(memberSubscription &&
                    mapValidProductId(
                      memberSubscription[String(childData?.childId)]?.productId
                    ) &&
                    tsToDayMonthYear(
                      getNextPaymentDate(memberSubscription, childData?.childId)
                    )) ||
                    "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Groups</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {childData?.subGroups &&
                    childData?.subGroups.map((subgroup) => {
                      return (
                        <StyledSubGroupContainer>
                          <ArrowForwardIcon />
                          <div>
                            {getNamePathFromGroups(
                              clientGroupsAndSubGroups,
                              subgroup
                            )}
                          </div>
                        </StyledSubGroupContainer>
                      );
                    })}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Guardian</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  <StyledViewClickable
                    onClick={() =>
                      handleOpenMemberModal(String(memberData?.uid))
                    }
                  >
                    {memberData?.firstName ? (
                      <>
                        <AccountCircleIcon />
                        <div>
                          {memberData?.firstName} {memberData?.lastName}
                        </div>
                      </>
                    ) : (
                      <Skeleton width={100} />
                    )}
                  </StyledViewClickable>
                </StyledPrimaryColorText>
              </StyledViewRow>
            </StyledViewContainer>
          )}
        </StyleAppModalSingleStepContainer>
      </StyledAppModalStepsContainer>
    </AppModal>
  );
};
