import { useEffect, useState } from "react";
import { TableData } from "./TableBody/TableBody";
import { TableHead } from "./TableHead/TableHead";
import { App_TABLE_CONTANTS, RowProps, TableProps } from "./types";
import { TableNoRecords } from "./shared-components/TableNoRecords/TableNoRecords";
import {
  StyledTableOptionsContainer,
  StyledTable,
  StyledAppTableTitle,
  StylesAppTableContainer,
  StylesTableContainer,
  StyledTableHeader,
  StyledPaginationContainer,
  StyledPaginationTotal,
  StyledUnderTitleActionButton,
  StyledSearchIconContainer,
  SEARCH_STYLES,
  StyledSearchIcon,
} from "./AppTable.styles";
import Tooltip from "@mui/material/Tooltip";
import { AppFormikField } from "../AppFormik/AppFormikField/AppFormikField";
import { Search } from "@mui/icons-material";

export const AppTable = ({
  showSearchInput = true,
  showPagination = true,
  showTotalCount = true,
  ...props
}: TableProps): JSX.Element => {
  const [rows, setRows] = useState<[]>(props.rows as []);
  const [displayedRows, setDisplayedRows] = useState<[]>(props.rows as []);
  const [searchedRows, setSearchedRows] = useState<[]>(props.rows as []);

  const [excelRows, setExcelRows] = useState<[]>(props.excelRows as []);

  const [searchInput, setSearchInput] = useState<string>("");
  const [isFilterMenuExpanded, setIsFilterMenuExpanded] = useState(false);

  const AppTablePaginationItemsPerPageLocalStorage = localStorage.getItem(
    "AppTablePaginationItemsPerPage"
  );
  const [paginationItemsPerPage, setPaginationItemsPerPage] = useState(
    showPagination
      ? Number(AppTablePaginationItemsPerPageLocalStorage) || 10
      : props?.rows?.length
  );

  const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
  const [paginationCount, setPaginationCount] = useState(
    Math.ceil(props?.rows?.length / paginationItemsPerPage)
  );

  const requestSearch = (value: string) => {
    setSearchInput(value);
    const _searchedRows = props.rows.filter((row: any) => {
      return (row.id + row.cells.join(" "))
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    const _searchedExcelRows =
      props.excelRows &&
      props.excelRows.filter((row: any) => {
        return (row.id + row.cells.join(" "))
          .toLowerCase()
          .includes(value.toLowerCase());
      });
    props.excelRows && setExcelRows(_searchedExcelRows as []);
    setSearchedRows(_searchedRows as []);
    updateVisibleRows(paginationCurrentPage);
  };

  const updateVisibleRows = (page: number) => {
    let _rows: [] | RowProps[] = searchInput ? searchedRows : props.rows;

    const startIndex = (page - 1) * paginationItemsPerPage;
    const endIndex = startIndex + paginationItemsPerPage;
    const visibleRows = _rows?.slice(startIndex, endIndex);
    setDisplayedRows(visibleRows as []);
  };

  const handlePageChange = (newPage: number) => {
    setPaginationCurrentPage(newPage);
    updateVisibleRows(newPage);
  };

  const handleOnChangePaginationItemPerPage = (value: number) => {
    setPaginationItemsPerPage(value);
    localStorage.setItem(
      App_TABLE_CONTANTS.AppTablePaginationItemsPerPage,
      String(value)
    );
  };

  useEffect(() => {
    setRows(props?.rows as []);
    setExcelRows(props?.excelRows as []);
    setPaginationCurrentPage(1);
    updateVisibleRows(1);
    setSearchInput("");
    setSearchedRows(props?.rows as []);
    setPaginationCount(Math.ceil(props?.rows?.length / paginationItemsPerPage));
    /** todo: remove after applying pagination */
    handleOnChangePaginationItemPerPage(99999);
  }, [props?.rows]);

  useEffect(() => {
    setPaginationCount(
      Math.ceil(searchedRows?.length / paginationItemsPerPage)
    );
    updateVisibleRows(1);
    setPaginationCurrentPage(1);
  }, [searchInput]);

  useEffect(() => {
    const rowsLength =
      searchedRows?.length != props?.rows?.length
        ? searchedRows?.length
        : props?.rows?.length;
    setPaginationCount(Math.ceil(rowsLength / paginationItemsPerPage));
    updateVisibleRows(1);
    setPaginationCurrentPage(1);
  }, [paginationItemsPerPage]);

  // useEffect(() => {
  //   scrollTop();
  // }, [paginationCurrentPage]);

  return (
    <StylesAppTableContainer $styles={props.tableContainerStyles}>
      <div>
        <StyledTableHeader style={props.customstyles?.tableTitle}>
          <StyledAppTableTitle>{props.title}</StyledAppTableTitle>

          {props.titleActionBtn}
        </StyledTableHeader>
        <StyledUnderTitleActionButton>
          {props.underTitleActionButton}
        </StyledUnderTitleActionButton>
      </div>

      {showSearchInput && (
        <StyledTableOptionsContainer>
          {/* Empty tag for flex design */}
          <div></div>
          <div style={{ display: "flex" }}>
            {showSearchInput && (
              <Tooltip title={props.serachTooltip} placement="top">
                <div>
                  <AppFormikField
                    type="text"
                    containerstyle={
                      props.searchContainerStyles || SEARCH_STYLES
                    }
                    value={searchInput}
                    label="Search..."
                    onChange={(e: any) => {
                      requestSearch(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <StyledSearchIconContainer>
                          <StyledSearchIcon />
                        </StyledSearchIconContainer>
                      ),
                    }}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </StyledTableOptionsContainer>
      )}

      {props.childrenFilterOptions && (
        <StyledTableOptionsContainer>
          {/* Empty tag for flex design */}
          <div></div>
          <div style={{ display: "flex" }}>{props.childrenFilterOptions}</div>
        </StyledTableOptionsContainer>
      )}
      <StylesTableContainer>
        <StyledTable style={props.customstyles?.table}>
          <TableHead
            columns={props.columns}
            customstylesTableHead={{ ...props.customstyles?.tableHead }}
          />
          <TableData
            rows={displayedRows}
            customstylesTableTr={{ ...props.customstyles?.tableTr }}
            customstylesTableTd={{ ...props.customstyles?.tableTd }}
          />
        </StyledTable>
        {displayedRows?.length === 0 && <TableNoRecords />}
      </StylesTableContainer>

      <StyledPaginationContainer>
        {showTotalCount && (
          <StyledPaginationTotal>
            Total
            {searchInput ? searchedRows?.length : props?.rows?.length}
          </StyledPaginationTotal>
        )}

        {/* {showPagination && (
          <>
            <AppSelect
              customSelectSX={{
                ...getStyledPaginationItemsPerPageStyles(screenWidth),
              }}
              value={paginationItemsPerPage}
              options={[...App_TABLE_CONTANTS.paginationSelectOptions]}
              onChange={(e: any) =>
                handleOnChangePaginationItemPerPage(e.value)
              }
            />

            <Pagination
              count={paginationCount}
              page={paginationCurrentPage}
              onChange={(e, pageNumber) => {
                handlePageChange(pageNumber);
              }}
              variant="outlined"
              shape="rounded"
              size={screenWidth < 1024 ? "medium" : "large"}
            />
          </>
        )} */}
      </StyledPaginationContainer>
    </StylesAppTableContainer>
  );
};
