import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

export const StyledAppSideInfoContainer = styled.div({
  marginBottom: 60,
  overflow: "auto",
  "@media only screen and (min-width: 1200px)": {
    margin: 0,
    background: APP_COMMON_COLORS.dark[200],
    width: "24%",
    padding: "80px 20px 20px 20px",
    height: "580px",
    marginBottom: 0,
  },
});
