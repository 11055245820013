import { CSSObject } from "styled-components";
import React from "react";

export interface TableProps {
  rows: RowProps[]; // table rows
  columns: ColumnProps[]; // table columns (head)
  backgroundColor?: string; // backgroundColor for table rows
  withHover?: boolean; // wheather to apply the highlighted rows on hover
  title?: string;
  titleActionBtn?: React.ReactNode;
  underTitleActionButton?: React.ReactNode;
  direction?: "ltr" | "rtl";
  canExportToExcel?: boolean;
  excelRows?: RowProps[];
  excelColumns?: any[];
  serachTooltip?: string;
  childrenFilterOptions?: React.ReactNode;
  childrenTableActionButtons?: React.ReactNode;
  showSearchInput?: boolean;
  searchContainerStyles?: CSSObject;
  showTotalCount?: boolean;
  tableContainerStyles?: CSSObject;
  customstyles?: {
    table?: CSSObject;
    tableHead?: CSSObject;
    tableTr?: CSSObject;
    tableTd?: CSSObject;
    tableTitle?: CSSObject;
  };
  showPagination?: boolean;
}

export type CellType = string | React.ReactElement | null;

export interface RowProps {
  id?: string; // row id to be used as key prop and for custom search
  cells: CellType[]; // table cell content
  data?: { timeStamp?: number } | any;
  masterDetails?: React.ReactNode;
}

export interface ColumnProps {
  id?: string; // column id to be used as key prop
  cell: CellType; // table head
}

export const App_TABLE_CONTANTS = {
  AppTablePaginationItemsPerPage: "appTablePaginationItemsPerPage",
  paginationSelectOptions: [
    { label: "10", value: 10 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "500", value: 500 },
  ],
};
