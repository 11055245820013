import { useState, useCallback } from 'react';

export const useModal: () => {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
} = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return { isOpen, openModal, closeModal };
};
