import React from "react";
import { AppBurgerMenu } from "../AppBurgerMenu/AppBurgerMenu";
import {
  StyledAppHeaderContainer,
  StyledAppBurgerMenuContainer,
  StyledAppHeaderWrapper,
  StyledLogoContainer,
  StyledLogoImg,
  StyledAvatarContainer,
  StyledGreetingMessageContainer,
} from "./AppHeader.styles";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../redux/client/clientSlice";
import { GreetingMessage } from "./GreetingMessage/GreetingMessage";
import { ProfileAvatar } from "../../shared/ProfileAvatar/ProfileAvatar";
import { getUser } from "../../../redux/user/userSlice";
import { YourZownIcon } from "../../../assets/icons/YourZownIcon";

export const AppHeader = () => {
  const client = useSelector(getClientSelector);
  const user = useSelector(getUser);

  return (
    <StyledAppHeaderWrapper>
      <StyledAppHeaderContainer>
        <StyledGreetingMessageContainer>
          <GreetingMessage />
        </StyledGreetingMessageContainer>
        <StyledLogoContainer>
          {client?.branding?.logoUrl ? (
            <StyledLogoImg src={client?.branding.logoUrl} alt="logo" />
          ) : (
            <YourZownIcon width="50" height="50" />
          )}
        </StyledLogoContainer>

        <StyledAppBurgerMenuContainer>
          <AppBurgerMenu />
        </StyledAppBurgerMenuContainer>

        <StyledAvatarContainer>{/* <ProfileAvatar /> */}</StyledAvatarContainer>
      </StyledAppHeaderContainer>
    </StyledAppHeaderWrapper>
  );
};
