import { useTheme } from "@mui/material";

export const CheckBoxIconNotChecked = (props: {
  width?: number;
  height?: number;
}) => {
  const theme = useTheme();
  return (
    <svg
      width={props.width || 29}
      height={props.height || 29}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="27"
        height="27"
        rx="3"
        fill="white"
        stroke={theme.palette.primary.main}
        strokeWidth="2"
      />
    </svg>
  );
};
