import { atom } from "jotai";
import { DISPLAY_STATES } from "./RolesPage.types";
import {
  PRE_DEFINED_ROLES_PERMISSIONS,
  PreDefinedRolesPermissions,
} from "./CreateRoleModal/RolesPermissionsStep/RolesPermissionsStep.types";
import { ADDED_FIELDS_TYPES } from "../../types/roles/roles.types";

export const ROLES_ATOMS = {
  modalCurrentStep: atom(0),
  roleName: atom<string>(""),
  customFieldSelectType: atom(""),
  customStepDisplayState: atom(DISPLAY_STATES.FIELDS_ROWS),
  selectedPermissions: atom<PreDefinedRolesPermissions>(
    PRE_DEFINED_ROLES_PERMISSIONS
  ),
  addedFields: atom<ADDED_FIELDS_TYPES[]>([]),
  isModalOpen: atom<boolean>(false),
  isEditMode: atom<boolean>(false),
  isPublished: atom<boolean>(false),
};
