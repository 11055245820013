import { useTheme } from "@mui/material";

export const CheckBoxIcon = (props: { width?: number; height?: number }) => {
  const theme = useTheme();
  return (
    <svg
      width={props.width || 29}
      height={props.height || 29}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="27"
        height="27"
        rx="3"
        fill="white"
        stroke={theme.palette.primary.main}
        strokeWidth="2"
      />
      <path
        d="M22.8987 9.16073L20.9205 7.10771C20.8875 7.07356 20.8485 7.04648 20.8054 7.028C20.7624 7.00951 20.7162 7 20.6696 7C20.623 7 20.5769 7.00951 20.5338 7.028C20.4908 7.04648 20.4517 7.07356 20.4188 7.10771L12.0636 15.7597C12.0307 15.7939 11.9916 15.821 11.9485 15.8394C11.9055 15.8579 11.8594 15.8674 11.8128 15.8674C11.7662 15.8674 11.72 15.8579 11.677 15.8394C11.6339 15.821 11.5948 15.7939 11.5619 15.7597L9.58365 13.7067C9.55074 13.6726 9.51165 13.6455 9.46861 13.627C9.42557 13.6085 9.37943 13.599 9.33283 13.599C9.28623 13.599 9.24008 13.6085 9.19704 13.627C9.154 13.6455 9.11491 13.6726 9.082 13.7067L7.10373 15.7597C7.03731 15.8285 7 15.9217 7 16.0189C7 16.1161 7.03731 16.2094 7.10373 16.2781L11.5619 20.8923C11.5948 20.9264 11.6339 20.9535 11.677 20.972C11.72 20.9905 11.7662 21 11.8128 21C11.8594 21 11.9055 20.9905 11.9485 20.972C11.9916 20.9535 12.0307 20.9264 12.0636 20.8923L22.8987 9.67399C22.9636 9.60546 23 9.51332 23 9.41736C23 9.3214 22.9636 9.22926 22.8987 9.16073Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
