import { Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import { StyledAppModalSubmitButtonContainer } from "../../../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppButton } from "../../../../../../components/core/AppButton/AppButton";
import { AppFormikField } from "../../../../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { useAtom, useSetAtom } from "jotai";
import { StyleForm } from "./RoleAttachment.styles";
import { ROLES_ATOMS } from "../../../../RolesPage.atoms";
import { DISPLAY_STATES } from "../../../../RolesPage.types";
import {
  StyledAddAndCancelContainer,
  StyledCancel,
} from "../../../CreateRoleModal.styles";
import {
  ATTACHMENT_FIELD,
  ROLE_FIELDS_TYPES,
} from "../../../../../../types/roles/roles.types";

interface RoleAttachmentLabelValue {
  label: string;
}

const schema = Yup.object({
  label: Yup.string().required("Required").max(255, "Maximum length is 255"),
});

export const RoleAttachmentInput = () => {
  const setDisplayState = useSetAtom(ROLES_ATOMS.customStepDisplayState);
  const setFieldSelectType = useSetAtom(ROLES_ATOMS.customFieldSelectType);
  const [addedFields, setAddedFields] = useAtom(ROLES_ATOMS.addedFields);

  const initialValues = {
    label: "",
  };

  const resetForm = () => {
    setFieldSelectType("");
    setDisplayState(DISPLAY_STATES.FIELDS_ROWS);
  };

  const handleOnSubmit = (values: RoleAttachmentLabelValue) => {
    const attachmentField: ATTACHMENT_FIELD = {
      type: ROLE_FIELDS_TYPES.attachment,
      label: values.label,
      required: false,
    };

    setAddedFields([...addedFields, attachmentField]);
    resetForm();
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values: RoleAttachmentLabelValue) => handleOnSubmit(values)}
    >
      {({
        errors,
        touched,
        values,
        submitCount,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => {
        const _touched: FormikTouched<RoleAttachmentLabelValue> = touched;
        const _errors: FormikErrors<RoleAttachmentLabelValue> = errors;

        return (
          <StyleForm>
            <AppFormikField
              containerstyle={{ marginBottom: 40 }}
              name="label"
              isRequired={true}
              value={values.label || ""}
              label="Attachment label"
              errorMessage={
                (_touched.label && _errors.label) ||
                (submitCount > 0 && errors.label)
                  ? String(errors.label)
                  : ""
              }
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
            />

            <StyledAddAndCancelContainer>
              <StyledAppModalSubmitButtonContainer style={{ margin: 0 }}>
                <AppButton onClick={handleSubmit}>Add</AppButton>
              </StyledAppModalSubmitButtonContainer>
              <StyledCancel onClick={resetForm}>CANCEL</StyledCancel>
            </StyledAddAndCancelContainer>
          </StyleForm>
        );
      }}
    </Formik>
  );
};
