import { Form } from "formik";
import styled from "styled-components";

export const StyledForm = styled(Form)({
  height: "58vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "@media only screen and (min-width: 1200px)": {
    height: "50vh",
  },
});

export const StyledFormInputs = styled.div({
  //   width: 600,
});
