import { useAtom } from "jotai";
import { AppModal } from "../../../../components/core/AppModal/AppModal";
import { MEMBERS_ATOMS } from "../../MembersPage.Atoms";
import {
  StyleAppModalSingleStepContainer,
  StyledAppModalStepsContainer,
} from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppModalMobileSteps } from "../../../../components/shared/AppModalMobileSteps/AppModalMobileSteps";
import { AppModalDesktopSteps } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps";
import { StyledAppModalDesktopStepTitle } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { VIEW_MEMBER_MODAL_ATOMS } from "./ViewMemberModal.Atoms";
import { useEffect, useState } from "react";
import { COLLECTIONS_TYPE } from "../../../../constants/firebase.constants";
import {
  StyledEmailIcon,
  StyledErrorChip,
  StyledIconValueContainer,
  StyledPhoneIphoneIcon,
  StyledSubGroupContainer,
  StyledTitleActionsContainer,
  StyledViewClickable,
  StyledViewContainer,
  StyledViewRow,
  StyledViewTitle,
} from "../ViewModals.styles";
import { MEMBERS } from "../../../../types/members/members.types";
import { getFirstValueFromFirebaseByColKeyVal } from "../../../../hooks/firebase/getFirstValueFromFirebaseByColKeyVal";
import { StyledPrimaryColorText } from "../../../../components/shared/StyledComponents/shared-styled-components";
import { tsToDayMonthYear } from "../../../../helpers/date-time";
import { AppSpinner } from "../../../../components/core/AppSpinner/AppSpinner";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { VIEW_CHILD_MODAL_ATOMS } from "../ViewChildModal/ViewChildModal.Atoms";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getNamePathFromGroups } from "../../Tabs/GroupsTab/GroupsTab.helper";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  getNextPaymentDate,
  getPaymentStatus,
  mapValidProductId,
} from "../../MemberPage.helper";
import { Chip } from "@mui/material";

const getRoles = (member: MEMBERS, clientId: string) => {
  const isAdmin = member?.isClientAdmin && member.isClientAdmin[clientId];

  let roles: any[] = isAdmin ? ["Admin"] : [];

  if (
    member?.roles &&
    member?.roles[clientId] &&
    Object.keys(member?.roles[clientId])?.length > 0
  ) {
    const rolesKeys = Object.keys(member?.roles[clientId]);
    if (rolesKeys && rolesKeys.length > 0) {
      rolesKeys.forEach((roleKey) => {
        if (Object.values(member.roles[clientId][roleKey])?.length > 0) {
          roles.push(roleKey);
        }
      });
    }
  }

  return `${roles?.join(", ")}`;
};

const getGroups = (member: MEMBERS, clientId: string) => {
  let subgroups: string[] = [];

  if (
    member?.roles &&
    member?.roles[clientId] &&
    Object.keys(member?.roles[clientId])?.length > 0
  ) {
    const rolesKeys = Object.keys(member?.roles[clientId]);
    if (rolesKeys && rolesKeys.length > 0) {
      rolesKeys.forEach((roleKey) => {
        if (Object.values(member.roles[clientId][roleKey])?.length > 0) {
          Object.keys(member.roles[clientId][roleKey]).map(
            (subgroup: string) => {
              subgroups.push(subgroup);
            }
          );
        }
      });
    }
  }

  return subgroups;
};

export const ViewMemberModal = () => {
  const [isViewMemberModalOpen, setIsViewMemberModalOpen] = useAtom(
    VIEW_MEMBER_MODAL_ATOMS.isViewMemberModalOpen
  );
  const [viewMemberId, setViewMemberId] = useAtom(
    VIEW_MEMBER_MODAL_ATOMS.viewMemberId
  );

  const [clientGroupsAndSubGroups, setClientGroupsAndSubGroups] = useAtom(
    MEMBERS_ATOMS.clientGroupsAndSubGroups
  );

  const [isLoading, setIsLoading] = useState<boolean>();

  const [memberData, setMemberData] = useState<MEMBERS>();

  const [isViewChildModalOpen, setIsViewChildModalOpen] = useAtom(
    VIEW_CHILD_MODAL_ATOMS.isViewChildModalOpen
  );

  const [viewChildId, setViewChildId] = useAtom(
    VIEW_CHILD_MODAL_ATOMS.viewChildId
  );

  const [memberSubscription, setMemberSubscription] = useAtom(
    MEMBERS_ATOMS.memberSubscription
  );

  const client = useSelector(getClientSelector);

  const fetchMember = async (_viewMemberId: string) => {
    const _memberData = (await getFirstValueFromFirebaseByColKeyVal(
      COLLECTIONS_TYPE.members,
      "uid",
      _viewMemberId
    )) as MEMBERS;
    setIsLoading(false);
    setMemberData(_memberData);
  };

  const handleClose = () => {
    setIsViewMemberModalOpen(false);
    setViewMemberId(undefined);
    setMemberData(undefined);
  };

  const handleOpenChildModal = (childId: string) => {
    handleClose();
    setViewChildId(childId);
    setIsViewChildModalOpen(true);
  };

  const filterChildrenByClient = () => {
    if (!memberData) {
      return [];
    }

    return (
      (memberData?.children &&
        memberData?.children?.filter(
          (child) => child?.clientId === client.clientId
        )) ||
      []
    );
  };

  useEffect(() => {
    setIsLoading(true);
    if (viewMemberId) {
      fetchMember(viewMemberId);
    }
  }, [viewMemberId]);

  if (!isViewMemberModalOpen) {
    return <></>;
  }

  return (
    <AppModal
      isOpen={isViewMemberModalOpen}
      closeModal={() => handleClose()}
      $customContentStyle={{ padding: 0 }}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <StyledAppModalStepsContainer>
        <AppModalMobileSteps
          tabsLength={1}
          tabValue={0}
          titlesOfSteps={[
            <StyledTitleActionsContainer>
              Member profile
            </StyledTitleActionsContainer>,
          ]}
          hideStepsTitle={true}
        />
        <AppModalDesktopSteps
          tabs={[]}
          tabsTitle={""}
          OnChangeTabChange={() => {}}
          tabValue={0}
        />
        <StyleAppModalSingleStepContainer style={{ paddingBottom: 0 }}>
          <StyledAppModalDesktopStepTitle>
            <StyledTitleActionsContainer style={{ justifyContent: "start" }}>
              Member profile
            </StyledTitleActionsContainer>
          </StyledAppModalDesktopStepTitle>

          {isLoading && <AppSpinner />}

          {!isLoading && memberData && (
            <StyledViewContainer>
              <StyledViewRow>
                <StyledViewTitle>First name</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {memberData?.firstName}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Middle name</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {memberData?.middleName || "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Last name</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {memberData?.lastName}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Gender</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {memberData?.gender || "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Date of birth</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {memberData?.dateOfBirth
                    ? tsToDayMonthYear(memberData?.dateOfBirth)
                    : "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Medical conditions</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {memberData?.medicalConditions || "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Photo consent</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {memberData?.photoConsent === false && "No"}
                  {memberData?.photoConsent === true && "Yes"}
                  {!memberData?.photoConsent &&
                    memberData?.photoConsent !== false &&
                    "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Payment status</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {getPaymentStatus(
                    memberData &&
                      memberSubscription &&
                      memberSubscription[memberData.uid]
                  )}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>
                  Payment date
                  {memberSubscription &&
                    mapValidProductId(
                      memberSubscription[String(memberData?.uid)]?.productId
                    ) &&
                    getNextPaymentDate(memberSubscription, memberData.uid) !==
                      0 &&
                    getNextPaymentDate(memberSubscription, memberData.uid) <
                      Date.now() && <StyledErrorChip>overdue</StyledErrorChip>}
                </StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {tsToDayMonthYear(
                    getNextPaymentDate(memberSubscription, memberData.uid)
                  ) || "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Roles</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {(memberSubscription &&
                    mapValidProductId(
                      memberSubscription[String(memberData?.uid)]?.productId
                    ) &&
                    getRoles(memberData as MEMBERS, String(client.clientId))) ||
                    "-"}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Groups</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  {getGroups(
                    memberData as MEMBERS,
                    String(client.clientId)
                  ).map((subgroup, index) => {
                    return (
                      <StyledSubGroupContainer key={index}>
                        <ArrowForwardIcon />
                        <div>
                          {getNamePathFromGroups(
                            clientGroupsAndSubGroups,
                            subgroup
                          )}
                        </div>
                      </StyledSubGroupContainer>
                    );
                  })}
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Contact</StyledViewTitle>
                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  <StyledIconValueContainer>
                    <StyledEmailIcon style={{ fontSize: 22 }} />
                    <span>{memberData?.email || "-"}</span>
                  </StyledIconValueContainer>
                </StyledPrimaryColorText>

                <StyledPrimaryColorText
                  style={{ fontSize: 18, fontWeight: 600 }}
                >
                  <StyledIconValueContainer>
                    <StyledPhoneIphoneIcon style={{ fontSize: 22 }} />
                    <span>{memberData?.phoneNumber || "-"}</span>
                  </StyledIconValueContainer>
                </StyledPrimaryColorText>
              </StyledViewRow>

              <StyledViewRow>
                <StyledViewTitle>Children</StyledViewTitle>
                {filterChildrenByClient().length > 0 ? (
                  <StyledPrimaryColorText
                    style={{ fontSize: 18, fontWeight: 600 }}
                  >
                    {filterChildrenByClient().map((child, index) => {
                      return (
                        <StyledViewClickable
                          key={index}
                          onClick={() =>
                            handleOpenChildModal(String(child.childId))
                          }
                        >
                          <AccountCircleIcon />
                          <span>
                            {child.firstName} {child.lastName}
                          </span>
                        </StyledViewClickable>
                      );
                    })}
                  </StyledPrimaryColorText>
                ) : (
                  <StyledPrimaryColorText
                    style={{ fontSize: 18, fontWeight: 600 }}
                  >
                    -
                  </StyledPrimaryColorText>
                )}
              </StyledViewRow>
            </StyledViewContainer>
          )}
        </StyleAppModalSingleStepContainer>
      </StyledAppModalStepsContainer>
    </AppModal>
  );
};
