import styled from "styled-components";
import BorderColorIcon from "@mui/icons-material/BorderColor";

export const StyledMainSubGroupName = styled.span(({ theme }) => ({
  color: theme.colorPrimary,
  display: "flex",
  fontWeight: 600,
  fontSize: 18,
}));

export const StyledEdit = styled(BorderColorIcon)(({ theme }) => ({
  color: theme.colorPrimary,
  cursor: "pointer",
  fontSize: "22px !important",
  fontWeight: 100,
}));

export const StyledOptions = styled.span(({ theme }) => ({
  fontSize: 12,
  color: theme.colorPrimary,
  marginTop: 3,
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));
