import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { _fs } from "../../services/firebase/config";

/** get data by key and value
 * note: you can use this function inside useEffect
 */
export const getFirebaseDataByColKeyVal = async (
  collection: COLLECTIONS_TYPE,
  fbKey: string,
  fbValue: string | boolean,
  orderBy?: { orderKey: string; direction: "desc" | "asc" }
) => {
  let _data: any;

  let ref = _fs.collection(collection).where(fbKey || "", "==", fbValue || "");

  if (orderBy?.direction && orderBy.orderKey) {
    ref = ref.orderBy(orderBy?.orderKey, orderBy?.direction);
  }

  return await ref
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc?.data()) {
          _data = {
            ..._data,
            [doc.id]: { ...doc.data(), docId: doc.id },
          };
        }
      });
      return _data;
    })
    .catch((error) => {
      return { status: "nok", error };
    });
};
