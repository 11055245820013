export const generateRandomString = (length: number, includeDate = false) => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    result += chars.charAt(randomIndex);
  }

  if (includeDate) {
    result = `${result}-${Date.now()}`;
  }

  return result;
};
