export const removeUndefinedValuesFromObject = (obj: any) => {
  Object.keys(obj).forEach(function (key) {
    const value = obj[key];
    const type = typeof value;
    if (type === "object") {
      removeUndefinedValuesFromObject(value);
      if (
        value === null ||
        (typeof value === "object" && !Object.keys(value).length)
      ) {
        delete obj[key];
      }
    } else if (type === "undefined") {
      delete obj[key];
    }
  });
};

export const capitalizeFirstChar = (str: string) => {
  if (!str) {
    return "";
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const addOrRemoveStringFromArray = (arr: any[], item: any) => {
  return arr.includes(item)
    ? [...arr.filter((i) => i !== item)]
    : [...arr, item];
};
