import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

export const StyledBody = styled.div({
  position: "relative",
});

export const StyledClearIconStyles = styled(ClearIcon)(({ theme }) => ({
  position: "absolute",
  right: 15,
  top: 15,
  cursor: "pointer",
  width: 32,
  height: 32,
  color: theme.colorPrimary,
  "&:hover": {
    fontSize: 28,
  },
}));

export const StyledUploadActionContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 15,
  justifyContent: "center",
  width: "100%",
  height: 133,
  border: `2px dashed ${theme.colorPrimary}`,
  overflow: "hidden",
  "&:hover": {
    "#browse": {
      textDecoration: "underline",
    },
  },
}));

export const StyledAppUploadImageWithPreviewContainer = styled.div({
  display: "flex",
  flexDirection: "column",
});

export const StyledDragDropMessage = styled.div({
  color: APP_COMMON_COLORS.dark[700],
  fontSize: 16,
  fontWeight: 300,
  marginRight: 5,
  textDecoration: "none !important",
});

export const StyledBrowseMessage = styled.div(({ theme }) => ({
  color: theme.colorPrimary,
  fontSize: 16,
  fontWeight: 500,
}));

export const StyledExtensionMessage = styled.div(({ theme }) => ({
  color: APP_COMMON_COLORS.dark[500],
  fontSize: 14,
  fontWeight: 300,
}));
