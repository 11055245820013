import styled, { CSSObject, useTheme } from "styled-components";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";
import { hexToRGBA } from "../../../../styles/styles.constants";
import { Select } from "@mui/material";

export const StyledSelectInputAndErrorContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 5,
});

export const StyledSelectField = styled(Select)<{
  $styledErrorProp?: boolean;
}>(({ theme, $styledErrorProp }) => ({
  width: "100%",
  "&.MuiOutlinedInput-root": {
    borderRadius: 50,
    height: 50,
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: `2px solid ${
      $styledErrorProp ? theme.error.main : theme.grey[600]
    }`,
  },
  "& .MuiInputBase-input": {
    color: theme.dark[700],
    paddingLeft: 25,
    fontWeight: 500,
  },
  ".MuiSvgIcon-root": {
    fill: $styledErrorProp ? APP_COMMON_COLORS.common.red : theme.colorPrimary,
    background: hexToRGBA(
      $styledErrorProp ? APP_COMMON_COLORS.common.red : theme.colorPrimary,
      0.1
    ),
    borderRadius: 50,
    marginRight: 8,
  },
}));
