import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";
import { YourZownIcon } from "../../../assets/icons/YourZownIcon";

interface AuthLayoutProps {
  title: string;
  subtitle: string;
  iconSubtitle: string;
  children: React.ReactNode;
}

const AuthLayoutContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "100vh",
});

const FirstContainer = styled.div({
  display: "none",
  "@media (min-width: 1200px)": {
    width: "50%",
    height: "100vh",
    background: APP_COMMON_COLORS.yourzown.colorPrimary,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
});

const LeftTitle = styled.div({
  fontSize: 20,
  fontWeight: 400,
});

const LeftSubtitle = styled.div({
  fontSize: 40,
  fontWeight: 700,
});

const TitleContainer = styled.div({
  color: APP_COMMON_COLORS.common.white,
  paddingInlineStart: 40,
  display: "flex",
  flexDirection: "column",
  gap: 20,
});

const SecondContainer = styled.div({
  width: "100%",
  padding: "80px 20px 0px 20px",
  background: APP_COMMON_COLORS.yourzown.colorPrimary,
  display: "flex",
  justifyContent: "end",
  flexDirection: "column",
  "@media (min-width: 1200px)": {
    width: "50%",
    height: "100vh",
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: APP_COMMON_COLORS.common.white,
  },
});

const SecondContainerContent = styled.div({
  border: `2px solid ${APP_COMMON_COLORS.common.lightBlue}`,
  borderBottom: "none",
  borderRadius: "5px 5px 0px 0px",
  background: APP_COMMON_COLORS.common.white,
  padding: "70px 20px 80px 20px",
  "@media (min-width: 1200px)": {
    padding: 0,
    height: "auto",
    border: "none",
  },
});

const YourZownIconContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  marginBottom: 30,
});

const StyledIconSubtitle = styled.div({
  display: "flex",
  justifyContent: "center",
  fontSize: 20,
  fontWeight: 600,
});

const SecondContainerWrapper = styled.div({
  overflow: "auto",
  "@media (min-width: 1200px)": {
    padding: 20,
  },
});

export const AuthLayout = (props: AuthLayoutProps) => {
  return (
    <AuthLayoutContainer>
      <FirstContainer>
        <TitleContainer>
          <LeftTitle>{props.title}</LeftTitle>
          <LeftSubtitle>{props.subtitle}</LeftSubtitle>
        </TitleContainer>
      </FirstContainer>
      <SecondContainer>
        <SecondContainerWrapper>
          <SecondContainerContent>
            <YourZownIconContainer>
              <YourZownIcon width="100" height="100" />
            </YourZownIconContainer>
            <StyledIconSubtitle>{props.iconSubtitle}</StyledIconSubtitle>
            {props.children}
          </SecondContainerContent>
        </SecondContainerWrapper>
      </SecondContainer>
    </AuthLayoutContainer>
  );
};
