import {
  StyledSectionSubtitle,
  StyledSectionTitle,
} from "../BrandingPage.styles";
import { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";
import { AppButton } from "../../../components/core/AppButton/AppButton";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../redux/client/clientSlice";
import {
  ColorInput,
  ColorPickerContainer,
  ColorPickerRow,
  StyledCancelLabel,
  StyledLabel,
  StyledPrimaryBrandColorContainer,
} from "./PrimaryBrandColor.styles";
import { PreSelectColorMessage } from "./PreSelectColorMessage/PreSelectColorMessage";

interface PrimaryBrandColorProps {
  setColorPrimary: (color: string) => void;
}

export const PrimaryBrandColor = (props: PrimaryBrandColorProps) => {
  const [color, setColor] = useState<string>("");
  const [isPickingColor, setIsPickingColor] = useState(false);

  const handleColorChange = (newColor: any) => {
    setColor(newColor);
  };

  const client = useSelector(getClientSelector);

  const handlePickColor = () => {
    props.setColorPrimary(color);
    setIsPickingColor(false);
  };

  const handleCancel = () => {
    const unchangedColor =
      client?.branding?.colorPrimary || APP_COMMON_COLORS.yourzown.colorPrimary;
    setColor(unchangedColor);
    props.setColorPrimary(unchangedColor);
    setIsPickingColor(false);
  };

  useEffect(() => {
    if (client?.branding?.colorPrimary) {
      setColor(client.branding.colorPrimary);
    } else {
      setColor(APP_COMMON_COLORS.yourzown.colorPrimary);
    }
  }, [client?.branding?.colorPrimary]);

  return (
    <StyledPrimaryBrandColorContainer>
      <StyledSectionTitle>Primary brand colour</StyledSectionTitle>
      <StyledSectionSubtitle>
        Displayed as accent colours in menus, buttons and interface elements.
      </StyledSectionSubtitle>
      {!isPickingColor && (
        <PreSelectColorMessage
          currentColor={color}
          onClick={() => setIsPickingColor(true)}
        />
      )}

      {isPickingColor && (
        <ColorPickerContainer>
          <HexColorPicker
            color={
              color === "undefined"
                ? APP_COMMON_COLORS.yourzown.colorPrimary
                : color
            }
            onChange={handleColorChange}
          />
          <ColorPickerRow>
            <StyledLabel>HEX</StyledLabel>
            <ColorInput
              type="text"
              value={
                color === "undefined"
                  ? APP_COMMON_COLORS.yourzown.colorPrimary
                  : color
              }
              onChange={(e) => handleColorChange(e.target.value)}
            />
          </ColorPickerRow>
          <AppButton onClick={handlePickColor}>Pick color</AppButton>
          <StyledCancelLabel onClick={handleCancel}>Cancel</StyledCancelLabel>
        </ColorPickerContainer>
      )}
    </StyledPrimaryBrandColorContainer>
  );
};
