import { useState } from "react";
import { AppModal } from "../../core/AppModal/AppModal";
import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";
import { AppSwitch } from "../../core/AppSwitch/AppSwitch";
import { AppButton } from "../../core/AppButton/AppButton";

interface DeleteModalProps {
  isOpen: boolean;
  title: string;
  deleteBtnLabel: string;
  onDeleteButton: () => void;
  onCloseDeleteModal: () => void;
  deleteContent: {
    title: string;
    subTitle: string;
    icon?: React.ReactNode;
  };
  isLoading?: boolean;
  children?: React.ReactNode;
  isProceedBtnDisabled?: boolean;
  hideProceedBtn?: boolean;
}

const StyledDeleteModalWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  "@media only screen and (min-width: 1200px)": {
    width: 600,
  },
});

const StyledDeleteModalContainer = styled.div({
  padding: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const StyledTitle1 = styled.div({
  fontWeight: 400,
  fontSize: 16,
  color: APP_COMMON_COLORS.dark[400],
  marginBottom: 20,
});

const StyledTitle2 = styled.div({
  fontWeight: 600,
  fontSize: 22,
  color: APP_COMMON_COLORS.dark[700],
  marginBottom: 80,
  "@media only screen and (min-width: 1200px)": {
    fontSize: 30,
  },
});

const StyledContent = styled.div({
  paddingBlock: 20,
  borderBlock: `1px dashed ${APP_COMMON_COLORS.dark[100]}`,
  width: 240,
  display: "flex",
  gap: 20,
  marginBottom: 20,
  "@media only screen and (min-width: 1200px)": {
    width: 600,
  },
});

const StyledIcon = styled.div({
  border: `1px solid ${APP_COMMON_COLORS.dark[100]}`,
  borderRadius: "50%",
  width: 40,
  height: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledDeleteContentTitle = styled.div({
  fontWeight: 400,
  fontSize: 14,
  color: APP_COMMON_COLORS.dark[400],
});

const StyledDeleteContentSubTitle = styled.div({
  fontWeight: 500,
  fontSize: 16,
  color: APP_COMMON_COLORS.dark[700],
});

const StyledContentTitles = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const StyledProceedContainer = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
});

const StyledProceedLabel = styled.div({});

const StyledDeleteBtnContainer = styled.div({
  marginTop: 150,
  "@media only screen and (min-width: 1200px)": {
    position: "absolute",
    marginTop: 0,
    bottom: 80,
  },
});

export const DeleteModal = (props: DeleteModalProps) => {
  const [isProceed, setIsProceed] = useState(false);
  return (
    <AppModal
      isOpen={props.isOpen}
      closeModal={props.onCloseDeleteModal}
      $customContentStyle={{ padding: 0 }}
    >
      <StyledDeleteModalContainer>
        <StyledDeleteModalWrapper>
          <StyledTitle1>ARE YOU SURE?</StyledTitle1>
          <StyledTitle2>
            {isProceed ? "This action can't be undone" : props.title}
          </StyledTitle2>

          <StyledContent>
            <StyledIcon>{props.deleteContent.icon}</StyledIcon>
            <StyledContentTitles>
              <StyledDeleteContentTitle>
                {props.deleteContent.title}
              </StyledDeleteContentTitle>
              <StyledDeleteContentSubTitle>
                {props.deleteContent.subTitle}
              </StyledDeleteContentSubTitle>
            </StyledContentTitles>
          </StyledContent>
          {props.children && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "start",
              }}
            >
              {props.children}
            </div>
          )}

          <StyledProceedContainer>
            {!props.hideProceedBtn && (
              <>
                <AppSwitch
                  onChange={(e) => setIsProceed(e)}
                  customColor={APP_COMMON_COLORS.common.red2}
                />

                <StyledProceedLabel>Proceed</StyledProceedLabel>
              </>
            )}
          </StyledProceedContainer>

          <StyledDeleteBtnContainer>
            <AppButton
              onClick={() => {
                props.onDeleteButton();
              }}
              isDisabled={!isProceed}
              containerCustomStyles={{
                minWidth: 200,
              }}
              isLoading={props.isLoading}
              customStyles={{
                background: isProceed ? APP_COMMON_COLORS.common.red2 : "",
                borderColor: isProceed ? APP_COMMON_COLORS.common.red2 : "",
                "&:hover": {
                  background: APP_COMMON_COLORS.common.red2,
                  border: `1px solid ${APP_COMMON_COLORS.common.red2}`,
                },
              }}
            >
              {props.deleteBtnLabel}
            </AppButton>
          </StyledDeleteBtnContainer>
        </StyledDeleteModalWrapper>
      </StyledDeleteModalContainer>
    </AppModal>
  );
};
