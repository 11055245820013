import React from "react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import { getClientSelector } from "../redux/client/clientSlice";
import { isUserLoggedInSelector } from "../redux/user/userSlice";

export const APP_COMMON_COLORS = {
  dark: {
    100: "#DDDDDD",
    200: "#F8F8F8",
    300: "#CCCCCC",
    400: "#666666",
    500: "#999999",
    600: "#444444",
    700: "#222222",
  },
  grey: {
    200: "#F7F7F7",
    300: "#E8E6E6",
    600: "#D2CECE",
  },
  red: {
    500: "#FF0000",
  },
  common: {
    white: "#ffffff",
    black: "#000000",
    lightGreen: "#00E676",
    darkGreen: "#11B76A",
    lightRed: "#F97066",
    lightBlue: "#E6E8F3",
    blue: "#031B87",
    red: "#EF6337",
    red2: "#DB0000",
  },
  error: {
    main: "#EF6337",
  },
  yourzown: {
    colorPrimary: "#213f9a",
    colorSecondary: "#213f9a",
  },
};

let theme = createTheme({
  palette: {
    secondary: {
      main: "#ba68c8",
      light: "#CCCCCC",
      contrastText: "#000000",
      200: "#F8F8F8",
      300: "#CCCCCC",
      400: "#666666",
      500: "#999999",
      600: "#444444",
      700: "#222222",
    },
    error: {
      main: "#EF6337",
    },
    common: {
      white: "#ffffff",
      black: "#000000",
    },
    grey: {
      "100": "#DDDDDD",
      300: "#E8E6E6",
      "600": "#D2CECE",
    },
    success: {
      main: "#00E651",
      light: "#00E676",
    },
  },
  typography: {
    fontFamily: '"Outfit", sans-serif',
    h4: {
      fontSize: "20px",
      "@media (min-width:1200px)": {
        fontSize: "40px",
      },
    },
    h5: {
      fontSize: "20px",
      "@media (min-width:1200px)": {
        fontSize: "30px",
      },
    },
  },
});

export const AppThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isLoggedIn = useSelector(isUserLoggedInSelector);

  const { branding } = useSelector(getClientSelector) || {};

  const colorPrimary =
    String(branding?.colorPrimary) === "undefined" ||
    String(branding?.colorPrimary).length === 0
      ? APP_COMMON_COLORS.yourzown.colorPrimary
      : branding?.colorPrimary;

  const colorSecondary =
    String(branding?.colorSecondary) === "undefined" ||
    String(branding?.colorSecondary).length === 0
      ? APP_COMMON_COLORS.yourzown.colorSecondary
      : branding?.colorSecondary;

  theme = {
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        ...theme.palette.primary,
        main: String(colorPrimary),
        light: String(colorSecondary),
      },
    },
  };

  return (
    <MuiThemeProvider theme={theme}>
      <StyledComponentsThemeProvider
        theme={{
          ...APP_COMMON_COLORS,
          colorPrimary: colorPrimary,
          colorSecondary: colorSecondary,
        }}
      >
        {children}
      </StyledComponentsThemeProvider>
    </MuiThemeProvider>
  );
};
