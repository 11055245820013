import { CSSObject, useTheme } from "styled-components";

export const ThreeDotsIcon = (props: { style?: CSSObject }) => {
  const theme = useTheme();

  return (
    <div style={{ ...props?.style }}>
      <svg
        width="26"
        height="6"
        viewBox="0 0 26 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="3" cy="3" r="3" fill={theme.colorPrimary} />
        <circle cx="13" cy="3" r="3" fill={theme.colorPrimary} />
        <circle cx="23" cy="3" r="3" fill={theme.colorPrimary} />
      </svg>
    </div>
  );
};
