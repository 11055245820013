import React from "react";
import { ColumnProps } from "../types";
import { CSSObject } from "styled-components";
import { StyledTh, TableHeadContainer } from "./TableHead.styles";

export const TableHead = (props: {
  columns: ColumnProps[];
  customstylesTableHead?: CSSObject;
}): React.ReactElement => {
  return (
    <TableHeadContainer>
      <tr>
        {props.columns &&
          props.columns.map((column, index) => (
            <StyledTh
              key={column.id || `head_${index}`}
              customstyles={{
                ...props.customstylesTableHead,
              }}
            >
              {column.cell}
            </StyledTh>
          ))}
      </tr>
    </TableHeadContainer>
  );
};
