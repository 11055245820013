import React, { useEffect, useState } from "react";
import { AppSpinner } from "../../components/core/AppSpinner/AppSpinner";
import { StyledPageContainer } from "../../components/shared/StyledComponents/StyledPageContainer";
import { useModal } from "../../components/core/AppModal/useModal";
import { PageAddButton } from "../../components/shared/PageAddButton/PageAddButton";
import { AppTable } from "../../components/core/AppTable/AppTable";
import { AppModal } from "../../components/core/AppModal/AppModal";
import { CreateRoleModal } from "./CreateRoleModal/CreateRoleModal";
import { useAtom, useSetAtom } from "jotai";
import { ROLES_ATOMS } from "./RolesPage.atoms";
import { DISPLAY_STATES } from "./RolesPage.types";
import { PRE_DEFINED_ROLES_PERMISSIONS } from "./CreateRoleModal/RolesPermissionsStep/RolesPermissionsStep.types";
import { useFirebaseStreamRoles } from "./useFirebaseStreamRoles";
import { getClientSelector } from "../../redux/client/clientSlice";
import { useSelector } from "react-redux";

const columns = [
  {
    cell: <div>Name</div>,
  },
  {
    cell: <div>Users</div>,
  },
  {
    cell: <div style={{ display: "flex", justifyContent: "end" }}>Actions</div>,
  },
];

export const RolesPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const setDisplayState = useSetAtom(ROLES_ATOMS.customStepDisplayState);
  const setFieldSelect = useSetAtom(ROLES_ATOMS.customFieldSelectType);
  const setAddedFields = useSetAtom(ROLES_ATOMS.addedFields);
  const setModalCurrentStep = useSetAtom(ROLES_ATOMS.modalCurrentStep);
  const setSelectedPermissions = useSetAtom(ROLES_ATOMS.selectedPermissions);
  const setRoleName = useSetAtom(ROLES_ATOMS.roleName);
  const setIsEditMode = useSetAtom(ROLES_ATOMS.isEditMode);
  const [isModalOpen, setIsModalOpen] = useAtom(ROLES_ATOMS.isModalOpen);
  const [isPublished, setIsPublished] = useAtom(ROLES_ATOMS.isPublished);
  const client = useSelector(getClientSelector);

  const rows = useFirebaseStreamRoles(String(client?.clientId));
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDisplayState(DISPLAY_STATES.FIELDS_ROWS);
    setFieldSelect("");
    setAddedFields([]);
    setModalCurrentStep(0);
    setSelectedPermissions(PRE_DEFINED_ROLES_PERMISSIONS);
    setIsModalOpen(false);
    setIsPublished(false);
    setRoleName("");
    setIsEditMode(false);
  };

  useEffect(() => {
    if (isPublished) {
      handleCloseModal();
    }
  }, [isPublished]);

  useEffect(() => {
    rows && setIsLoading(false);
  }, [rows]);

  if (isLoading) {
    return (
      <StyledPageContainer>
        <AppSpinner />
      </StyledPageContainer>
    );
  }

  return (
    <StyledPageContainer>
      <PageAddButton
        text={"Create role"}
        onClick={() => setIsModalOpen(true)}
      />

      <AppModal
        isOpen={isModalOpen}
        closeModal={handleCloseModal}
        $customContentStyle={{ padding: 0 }}
      >
        <CreateRoleModal />
      </AppModal>

      <AppTable
        columns={columns}
        rows={rows}
        showPagination={false}
        showTotalCount={false}
      ></AppTable>
    </StyledPageContainer>
  );
};
