export enum PermissionsValues {
  addEvent = "addEvent",
  editEvent = "editEvent",
  deleteEvent = "deleteEvent",
  addPost = "addPost",
  editPost = "editPost",
  deletePost = "deletePost",
  viewProfiles = "viewProfiles",
}

export type PreDefinedRolesPermissions = {
  [key in PermissionsValues]: {
    label: string;
    value: PermissionsValues;
    isChecked: boolean;
  };
};

export const PRE_DEFINED_ROLES_PERMISSIONS: PreDefinedRolesPermissions = {
  addEvent: {
    label: "Add event",
    value: PermissionsValues.addEvent,
    isChecked: false,
  },
  editEvent: {
    label: "Edit event",
    value: PermissionsValues.editEvent,
    isChecked: false,
  },
  deleteEvent: {
    label: "Delete event",
    value: PermissionsValues.deleteEvent,
    isChecked: false,
  },
  addPost: {
    label: "Add post",
    value: PermissionsValues.addPost,
    isChecked: false,
  },
  editPost: {
    label: "Edit post",
    value: PermissionsValues.editPost,
    isChecked: false,
  },
  deletePost: {
    label: "Delete post",
    value: PermissionsValues.deletePost,
    isChecked: false,
  },
  viewProfiles: {
    label: "View Profiles",
    value: PermissionsValues.viewProfiles,
    isChecked: false,
  },
};
