import { AppModalDesktopSteps } from "../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps";
import {
  StyleAppModalSingleStepContainer,
  StyledAppModalStepsContainer,
} from "../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppModalMobileSteps } from "../../../components/shared/AppModalMobileSteps/AppModalMobileSteps";
import { useAtom, useAtomValue } from "jotai";
import { LOCATION_MODAL_ATOMS } from "./LocationFormModal.Atoms";
import { StyledAppModalDesktopStepTitle } from "../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { HIDDEN_ELEMENT_STYLES } from "../../../styles/styles.constants";
import { LocationFormStepOne } from "./LocationFormStepOne/LocationFormStepOne";
import { LocationFormStepTwo } from "./LocationFormStepTwo/LocationFormStepTwo";

const StepsTitles = ["Title & address", "Location Details"];

export const LocationFormModal = () => {
  const isEditMode = useAtomValue(LOCATION_MODAL_ATOMS.isEditMode);
  const [currentStep, setCurrentStep] = useAtom(
    LOCATION_MODAL_ATOMS.currentStep
  );

  const getModalTitle = () => {
    return isEditMode ? "Edit location" : "Add location";
  };

  return (
    <StyledAppModalStepsContainer>
      <AppModalMobileSteps
        tabsLength={StepsTitles.length}
        tabValue={currentStep}
        titlesOfSteps={StepsTitles}
      />
      <AppModalDesktopSteps
        tabs={StepsTitles}
        tabsTitle={getModalTitle()}
        OnChangeTabChange={(value) => setCurrentStep(value)}
        tabValue={currentStep}
      />
      <StyleAppModalSingleStepContainer style={{ paddingBottom: 0 }}>
        <StyledAppModalDesktopStepTitle>
          {StepsTitles[currentStep]}
        </StyledAppModalDesktopStepTitle>

        <div style={{ ...(currentStep !== 0 && HIDDEN_ELEMENT_STYLES) }}>
          <LocationFormStepOne />
        </div>

        <div style={{ ...(currentStep !== 1 && HIDDEN_ELEMENT_STYLES) }}>
          <LocationFormStepTwo />
        </div>
      </StyleAppModalSingleStepContainer>
    </StyledAppModalStepsContainer>
  );
};
