import React, { useState } from "react";
import {
  InputAdornment,
  IconButton,
  Typography,
  useTheme as useMuiTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, Form, FormikTouched, FormikErrors } from "formik";
import * as Yup from "yup";
import styled, { useTheme } from "styled-components";
import { AppFormikField } from "../../components/core/AppFormik/AppFormikField/AppFormikField";
import { useAutoFill } from "../../hooks/useAutoFill/useAutoFill";
import { useNavigate } from "react-router-dom";
import { AppButton } from "../../components/core/AppButton/AppButton";
import { VisibilityOffIcon } from "../../assets/icons/VisibilityOffIcon";
import { VisibilityOnIcon } from "../../assets/icons/VisibilityOnIcon";
import { _fs } from "../../services/firebase/config";
import { AppPasswordStrengthBar } from "../../components/core/AppPasswordStrengthBar/AppPasswordStrengthBar";
import { APP_ROUTES } from "../../constants/routes.constant";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../styles/styles.constants";
import { APP_COMMON_COLORS } from "../../providers/AppThemeProvider";
import { firebaseHttpsCallable } from "../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../constants/firebase.constants";
import { YourZownIcon } from "../../assets/icons/YourZownIcon";

const schema = Yup.object({
  password: Yup.string()
    .min(8, "Must be 8 characters or more")
    .required("Required"),
});

const StyledSigningPagesContainer = styled.div<{ $isActive?: boolean }>(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    background: APP_COMMON_COLORS.yourzown.colorPrimary,
    justifyContent: "center",
    alignItems: "end",
    "@media only screen and (min-width: 1200px)": {
      alignItems: "normal",
    },
  })
);
export const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const { isAutoFill } = useAutoFill();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const isSmallScreen = useMediaQuery(useMuiTheme().breakpoints.down("lg"));

  const handleOnSubmit = async (values: { password: string }) => {
    setIsLoading(true);
    const params = new URLSearchParams(window.location.search);
    const createdAt = params.get("createdAt");
    const encryptedUID = params.get("c");

    const requestBody = {
      createdAt: createdAt,
      c: encryptedUID,
      p: values.password,
    };

    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.updatePassword)(requestBody)
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          toast(
            "Your password has been created. Please enter your credentials to login.",
            APP_TOASTIFY.SUCCESS
          );

          navigate(
            res?.data?.data?.isClientAdmin
              ? APP_ROUTES.signIn
              : `${APP_ROUTES.downloadMobileApps}?redirectedFrom=reset-password`
          );
          return;
        }
        if (res?.data?.result === "nok") {
          toast(res?.data?.message, APP_TOASTIFY.ERROR);
          return;
        }
      })
      .catch((err: any) => {
        toast("Something went wrong [8]", APP_TOASTIFY.ERROR);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <StyledSigningPagesContainer>
      {!isSmallScreen && (
        <Grid
          item
          display="flex"
          alignItems="center"
          md={6}
          sx={{
            width: "50%",
            height: "auto",
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            height={"100%"}
            justifyContent={"center"}
            paddingX={5}
            sx={{
              background: APP_COMMON_COLORS.yourzown.colorPrimary,
            }}
          >
            <Box
              display={"flex"}
              height={"100%"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Typography
                mb={2.5}
                fontWeight={400}
                variant="h6"
                color={theme.common.white}
              >
                WELCOME TO
              </Typography>
              <Typography
                mb={2.5}
                fontWeight={700}
                fontSize={"2rem"}
                color={theme.common.white}
              >
                YourZown Admin
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}
      <Grid
        item
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent={isSmallScreen ? "start" : "center"}
        borderRadius={isSmallScreen ? "10px 10px 0px 0px" : "0px"}
        md={6}
        xs={12}
        sx={{
          background: !isSmallScreen
            ? theme.common.white
            : theme.colorSecondary,
        }}
        paddingX={0.625}
        paddingTop={isSmallScreen ? 5 : 1}
        paddingBottom={!isSmallScreen ? 1 : 0}
        width={isSmallScreen ? "440px" : "50%"}
        height={"auto"}
      >
        {isSmallScreen && (
          <>
            <Typography
              marginBottom={1.375}
              color={theme.common.white}
              fontWeight={"300"}
              variant="caption"
            >
              Reset password
            </Typography>
          </>
        )}

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent={isSmallScreen ? "flex-start" : "center"}
          paddingTop={isSmallScreen ? 3.75 : 0}
          borderRadius={isSmallScreen ? "10px 10px 0px 0px" : "10px"}
          width={1}
          sx={{
            backgroundColor: theme.common.white,
            height: "660px",
          }}
        >
          <Formik
            enableReinitialize={true}
            initialValues={{ password: "" }}
            validationSchema={schema}
            onSubmit={(values: { password: string }) => handleOnSubmit(values)}
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => {
              const _touched: FormikTouched<{
                password: string;
              }> = touched;

              const _errors: FormikErrors<{ password: string }> = errors;

              return (
                <Form
                  style={{
                    width: isSmallScreen ? "100%" : 400,
                  }}
                >
                  <Box paddingX={2.5}>
                    <Box display="flex" justifyContent="center">
                      <YourZownIcon width="120" height="120" />
                    </Box>
                    {!isSmallScreen && (
                      <Typography
                        textAlign={"center"}
                        color={theme.common.black}
                        fontWeight={600}
                        variant="h6"
                        marginTop={1}
                        marginBottom={4.5}
                      >
                        Reset your password
                      </Typography>
                    )}
                    <AppFormikField
                      name="password"
                      value={values.password}
                      label="Choose password"
                      errorMessage={
                        _touched.password && _errors.password
                          ? String(errors.password)
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type={showPassword ? "text" : "password"}
                      InputLabelProps={{ shrink: isAutoFill || undefined }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                              sx={{
                                marginRight: "2px",
                              }}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityOnIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        marginTop: 2,
                      }}
                    />
                    <Box marginTop={1.25} marginBottom={2} marginLeft={2}>
                      <AppPasswordStrengthBar password={values.password} />
                    </Box>
                    <AppButton
                      customStyles={{
                        background: APP_COMMON_COLORS.yourzown.colorPrimary,
                        borderColor: APP_COMMON_COLORS.yourzown.colorPrimary,
                        "&:hover": {
                          background: APP_COMMON_COLORS.yourzown.colorPrimary,
                          border: `1px solid ${theme.white}`,
                        },
                      }}
                      isLoading={isLoading}
                      type="submit"
                    >
                      <Typography
                        color={theme.common.white}
                        fontWeight={600}
                        variant="body1"
                      >
                        Reset your password
                      </Typography>
                    </AppButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Grid>
    </StyledSigningPagesContainer>
  );
};
