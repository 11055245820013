import styled from "styled-components";
import { SelectColorIcon } from "../icons/SelectColorIcon";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";

const StyledPreSelectColorMessageContainer = styled.div({
  display: "flex",
  cursor: "pointer",
  width: "fit-content",
  alignItems: "center",
  "&:hover": {
    textDecoration: "underline",
  },
});
const StyledText = styled.div((props) => ({
  color: props.theme.colorPrimary,
  fontWeight: 500,
  fontSize: 14,
}));
export const PreSelectColorMessage = (props: {
  currentColor?: string;
  onClick?: () => void;
}) => {
  return (
    <StyledPreSelectColorMessageContainer onClick={props.onClick}>
      <SelectColorIcon containerStyles={{ height: 85, marginLeft: -25 }} />
      <StyledText>
        {props.currentColor === "undefined" ||
        props.currentColor === APP_COMMON_COLORS.yourzown.colorPrimary
          ? "Select primary colour"
          : `Hex: ${props.currentColor}`}
      </StyledText>
    </StyledPreSelectColorMessageContainer>
  );
};
