import { Add } from "@mui/icons-material";
import styled, { CSSObject } from "styled-components";
interface AddIconWithLabelProps {
  label?: string;
  onClick: () => void;
  $customstyles?: CSSObject;
}
const StyledAdd = styled(Add)(({ theme }) => ({
  color: theme.colorPrimary,
  cursor: "pointer",
}));

const StyledAddCustomFieldText = styled.div(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: theme.colorPrimary,
  "&:hover": {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const StyledAddCustomField = styled.div({
  display: "flex",
  alignItems: "center",
  marginBottom: 20,
  width: "fit-content",
});

export const AddIconWithLabel = (props: AddIconWithLabelProps) => {
  return (
    <StyledAddCustomField onClick={props.onClick} style={props.$customstyles}>
      <StyledAdd />
      {props.label && (
        <StyledAddCustomFieldText>{props.label}</StyledAddCustomFieldText>
      )}
    </StyledAddCustomField>
  );
};
