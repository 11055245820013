import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";

export const StyledViewContainer = styled.div({
  paddingBlock: 10,
  overflow: "scroll",
  "@media only screen and (min-width: 1200px)": {
    width: 600,
    paddingTop: 20,
    paddingBottom: 40,
    overflow: "auto",
  },
});

export const StyledViewRow = styled.div({
  gap: 20,
  marginBottom: 18,
});

export const StyledViewTitle = styled.div({
  color: APP_COMMON_COLORS.dark[500],
  fontSize: 14,
});

export const StyledViewClickable = styled.span({
  display: "flex",
  gap: 5,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
});

export const StyledSubGroupContainer = styled.div({
  display: "flex",
  alignItems: "center",
  marginBottom: 5,
});

export const StyledTitleActionsContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  gap: "1rem",
  alignItems: "center",
});

export const StyledArrowDropDownCircleIcon = styled(ArrowDropDownCircleIcon)(
  ({ theme }) => ({
    color: theme.colorPrimary,
    marginTop: 6,
    ":hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
    },
    "@media only screen and (min-width: 1200px)": {
      marginTop: 12,
    },
  })
);

export const StyledIconValueContainer = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 5,
  marginBottom: 8,
});

export const StyledEmailIcon = styled(EmailIcon)(({ theme }) => ({
  color: theme.colorPrimary,
}));

export const StyledPhoneIphoneIcon = styled(PhoneIphoneIcon)(({ theme }) => ({
  color: theme.colorPrimary,
}));

export const StyledErrorChip = styled.span({
  fontSize: 12,
  background: APP_COMMON_COLORS.common.red2,
  padding: 4,
  borderRadius: 10,
  color: APP_COMMON_COLORS.common.white,
  marginInline: 5,
  fontWeight: 700,
});
