import { useEffect, useState } from "react";
import { APP_COMMON_COLORS } from "../../../../../providers/AppThemeProvider";
import { EventAdditionalFieldsStepValuesKeys } from "../EventAdditionalFieldsStep";
import {
  StyledDot2,
  StyledDot1,
  StyledFlexDiv,
  StyledLabel1,
  StyledTitle,
  StyledLabel2,
  StyledLabel3,
  StyledDivider,
  StyledSubTitle,
} from "./EventSideInfo.styles";
import { useAtom } from "jotai";
import { TEMPLATES_ATOMS } from "../../AddEditTemplateModal.atoms";

const TEMPLATE_INCLUDED_FIELDS = [
  "Audience (Group & Roles)",
  "Date",
  "Description",
  "Event name",
  "External attendees (yes / no)",
  "Location",
  "Paid",
  "Start time",
  "End time",
];

interface EventSideInfoProps {
  addedFields: EventAdditionalFieldsStepValuesKeys[];
}

export const EventSideInfo: React.FC<EventSideInfoProps> = (props) => {
  const [addedFields, setAddedFields] = useState(props.addedFields);
  const [eventCustomFields, setEventCustomFields] = useAtom(
    TEMPLATES_ATOMS.eventCustomFields
  );

  useEffect(() => {
    setAddedFields(props.addedFields?.filter((field) => field.enabled));
  }, [props.addedFields]);

  return (
    <div>
      <StyledTitle>Fields in this event template</StyledTitle>

      <StyledSubTitle>
        <StyledFlexDiv>
          <StyledDot1 />
          <StyledLabel1>Mandatory</StyledLabel1>
        </StyledFlexDiv>
        <StyledFlexDiv>
          <StyledFlexDiv>
            <StyledDot1
              style={{
                background: APP_COMMON_COLORS.common.lightGreen,
              }}
            />
            <StyledLabel1>Optional</StyledLabel1>
          </StyledFlexDiv>
        </StyledFlexDiv>
      </StyledSubTitle>

      <StyledDivider />

      <StyledLabel2>Included for all event templates</StyledLabel2>

      {TEMPLATE_INCLUDED_FIELDS.map((name) => (
        <StyledFlexDiv style={{ marginBottom: 8 }} key={name}>
          <StyledDot2 />
          <StyledLabel3>{name}</StyledLabel3>
        </StyledFlexDiv>
      ))}

      {addedFields && addedFields.length > 0 && (
        <>
          <StyledDivider />
          <StyledLabel2>Additional fields</StyledLabel2>

          {addedFields.map((field) => {
            return (
              <StyledFlexDiv style={{ marginBottom: 8 }} key={field.value}>
                <StyledDot2
                  style={
                    field.required
                      ? { background: APP_COMMON_COLORS.common.lightRed }
                      : { background: APP_COMMON_COLORS.common.lightGreen }
                  }
                />
                <StyledLabel3>{field.label}</StyledLabel3>
              </StyledFlexDiv>
            );
          })}
        </>
      )}
      {eventCustomFields && eventCustomFields.length > 0 && (
        <>
          <StyledDivider />
          <StyledLabel2>Custom fields</StyledLabel2>

          {eventCustomFields.map((field) => {
            return (
              <StyledFlexDiv style={{ marginBottom: 8 }}>
                <StyledDot2
                  style={
                    field.required
                      ? { background: APP_COMMON_COLORS.common.lightRed }
                      : { background: APP_COMMON_COLORS.common.lightGreen }
                  }
                />
                <StyledLabel3>{field.label}</StyledLabel3>
              </StyledFlexDiv>
            );
          })}
        </>
      )}
    </div>
  );
};
