import styled, { CSSObject } from "styled-components";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";

export const StyledEventAdditionalFieldsContainer = styled.div({
  "@media only screen and (min-width: 1200px)": {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const StyledInputsContainer = styled.div({
  height: "48vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  "@media only screen and (min-width: 1200px)": {
    width: 600,
    height: "50vh",
  },
});

export const StyledAdditionalFieldsRowsContainer = styled.div({
  overflowY: "auto",
  height: 380,
  borderBottom: `1px solid ${APP_COMMON_COLORS.dark[300]}`,
  "@media only screen and (min-width: 1200px)": {
    height: 340,
  },
});

export const StyledAdditionalFieldRow = styled.div({
  display: "flex",
  borderBottom: `1px dashed ${APP_COMMON_COLORS.dark[100]}`,
  justifyContent: "space-between",
  "@media only screen and (min-width: 1200px)": {
    paddingRight: 20,
  },
});

export const StyleAppRadioButtonGroupsContainer = styled.div<{
  $isDisabled?: boolean;
}>(({ $isDisabled }) => ({
  display: "flex",
  width: 150,
  paddingBottom: 0,
  "@media only screen and (min-width: 1200px)": {
    width: 192,
  },
  ...($isDisabled && { opacity: 0.4, pointerEvents: "none" }),
}));

export const AppRadioCustomContainerStyles: CSSObject = {
  display: "flex",
  justifyContent: "space-around",
  flexDirection: "row",
  width: "100%",
};

export const CustomRadioAndLabelContainerStyles: CSSObject = {
  border: "none",
  paddingBottom: 0,
  display: "flex",
};

export const StyledAppButtonContainer = styled.div({
  "@media only screen and (min-width: 1200px)": {
    width: 200,
  },
});

export const StyledCloseIconAndLabel = styled.div({
  display: "flex",
  alignItems: "center",
  paddingInline: 5,
  gap: 22,
  color: APP_COMMON_COLORS.dark[700],
  fontSize: 16,
  fontWeight: 500,
  height: 62,
});

export const StyledVerticalDivider = styled.div({
  width: "1px",
  height: 25,
  borderRight: `1px solid ${APP_COMMON_COLORS.dark[300]}`,
});

export const StyledLabelSwitch = styled.div({
  minWidth: 160,
  maxWidth: 160,
  "@media only screen and (min-width: 1200px)": {
    minWidth: "none",
    maxWidth: "none",
  },
});
