import * as Yup from "yup";
import { MemberFormValues } from "./MembersPage.types";

export const AddChildrenYupSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
});

export const MEMBER_INITIAL_VALUES: MemberFormValues = {
  email: "",
  firstName: "",
  lastName: "",
  isClientAdmin: {},
  hasChildren: false,
  productId: "",
  children: [],
  nextPaymentDate: Date.now(),
};

export const ADD_MEMBER_STEPPER_TABS = [
  "Members Profile",
  "Children info",
  "Groups",
];

export const CHILD_INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  nextPaymentDate: Date.now(),
};
