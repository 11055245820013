import React from "react";
import { TabPanel } from "../../../components/shared/AppModalDesktopSteps/TabPanel/TabPanel";
import { StyledTabContainer } from "../ContactsPage.styles";
import { StyledAppModalDesktopStepTitle } from "../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import { Box, FormGroup, Typography, useTheme } from "@mui/material";
import { AppFormikField } from "../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { AppButton } from "../../../components/core/AppButton/AppButton";
import { ContactFormValues } from "./ContactFormModal";
import { Contact } from "../../../types/contacts/contacts.types";
import { StyledAppModalSubmitButtonContainer } from "../../../components/shared/StyledComponents/StyledAppModalComponents";

const contactProfileSchema = Yup.object({
  firstName: Yup.string()
    .required("Required")
    .min(1, "Minimum length is 1")
    .max(255, "Maximum length is 255"),
  lastName: Yup.string()
    .required("Required")
    .min(1, "Minimum length is 1")
    .max(255, "Maximum length is 255"),
  title: Yup.string()
    .required("Required")
    .min(1, "Minimum length is 1")
    .max(255, "Maximum length is 255"),
});

interface ContactProfileFormValues {
  firstName: string;
  lastName: string;
  title: string;
}

export const ProfileTab = ({
  outerTabValue,
  setCurrentTab,
  setContactFormData,
  contactFormData,
}: {
  outerTabValue: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
  setContactFormData: React.Dispatch<React.SetStateAction<ContactFormValues>>;
  contactFormData: Contact | null;
}) => {
  const theme = useTheme();

  return (
    <TabPanel value={outerTabValue} index={0}>
      <StyledTabContainer>
        <StyledAppModalDesktopStepTitle>
          Contact profile
        </StyledAppModalDesktopStepTitle>

        <Formik
          enableReinitialize={true}
          validateOnMount
          initialValues={{
            title: contactFormData?.title || "",
            firstName: contactFormData?.firstName || "",
            lastName: contactFormData?.lastName || "",
          }}
          validationSchema={contactProfileSchema}
          onSubmit={async (values: ContactProfileFormValues) => {
            setCurrentTab(1);
            setContactFormData((prev) => ({ ...prev, ...values }));
          }}
        >
          {({ errors, values, touched, handleChange, handleBlur }) => {
            const _touched: FormikTouched<ContactProfileFormValues> = touched;

            const _errors: FormikErrors<ContactProfileFormValues> = errors;

            return (
              <Form
                style={{
                  height: "100%",
                  display: "flex",
                  paddingBottom: "35px",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  height={"100%"}
                  flexGrow={1}
                  gap={2}
                >
                  <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
                    <AppFormikField
                      isRequired={true}
                      name="firstName"
                      value={values.firstName}
                      label="First Name"
                      errorMessage={
                        _touched.firstName && _errors.firstName
                          ? String(errors.firstName)
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                    />

                    <AppFormikField
                      isRequired={true}
                      name="lastName"
                      value={values.lastName}
                      label="Last Name"
                      errorMessage={
                        _touched.lastName && _errors.lastName
                          ? String(errors.lastName)
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                    />

                    <AppFormikField
                      isRequired={true}
                      name="title"
                      value={values.title}
                      label="Title"
                      errorMessage={
                        _touched.title && _errors.title
                          ? String(errors.title)
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                    />
                  </Box>

                  <FormGroup
                    sx={{ width: "100%", marginTop: "auto", marginBottom: 0 }}
                  >

                    <StyledAppModalSubmitButtonContainer>
                      <AppButton type="submit">Continue</AppButton>
                    </StyledAppModalSubmitButtonContainer>
                  </FormGroup>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </StyledTabContainer>
    </TabPanel>
  );
};
