import { useState } from "react";
import { BackgroundImage } from "./BackgroundImage/BackgroundImage";
import { BrandMark } from "./BrandMark/BrandMark";
import {
  StyledBrandingPageContainer,
  StyledInputs,
  StyledPreviewScreens,
  StyledSectionDivider,
} from "./BrandingPage.styles";
import { PrimaryBrandColor } from "./PrimaryBrandColor/PrimaryBrandColor";
import { UploadLogo } from "./UploadLogo/UploadLogo";
import { AppButton } from "../../components/core/AppButton/AppButton";
import {
  firebaseHttpsCallable,
  uploadImageToStorage,
} from "../../hooks/firebase/firebase.helper";
import {
  COLLECTIONS_TYPE,
  FIRESTORE_FUNCTIONS,
} from "../../constants/firebase.constants";
import { EDIT_CLIENT_BRANDING_DTO } from "../../types/client/client.types";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../redux/client/clientSlice";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../styles/styles.constants";
import { GENERAL_REPONSE } from "../../types/firbase.types";
import { APP_COMMON_COLORS } from "../../providers/AppThemeProvider";
import { StyledSubmitButtonContainer } from "../GroupsPage/GroupsPage.styles";

export const BrandingPage = () => {
  const [logoFile, setLogoFile] = useState<File | undefined>();
  const [logoFileDeleted, setLogoFileDeleted] = useState<boolean>(false);

  const [brandMarkFile, setBrandMarkFile] = useState<File | undefined>();
  const [brandMarkFileDeleted, setBrandMarkFileDeleted] =
    useState<boolean>(false);

  const [backgroundImageFile, setBackgroundImageFile] = useState<
    File | undefined
  >();
  const [backgroundImageFileDeleted, setBackgroundImageFileDeleted] =
    useState<boolean>(false);

  const [colorPrimary, setColorPrimary] = useState<string>("");
  const client = useSelector(getClientSelector);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePublish = async () => {
    setIsSubmitting(true);
    const storagePath = `${COLLECTIONS_TYPE.clients}/${client.clientId}/branding`;
    const payload: EDIT_CLIENT_BRANDING_DTO = {
      clientId: String(client.clientId),
      logoUrl: client.branding?.logoUrl,
      brandMarkUrl: client.branding?.brandMarkUrl,
      backgroundImageUrl: client.branding?.backgroundImageUrl,
      colorPrimary: colorPrimary || client.branding?.colorPrimary,
    };

    if (payload.colorPrimary === "undefined" || !payload.colorPrimary) {
      payload.colorPrimary = APP_COMMON_COLORS.yourzown.colorPrimary;
    }

    if (logoFileDeleted) {
      payload.logoUrl = null;
    } else if (logoFile) {
      try {
        await Promise.all([
          uploadImageToStorage(logoFile, storagePath, `logo-${Date.now()}`)
            .then((res) => {
              payload.logoUrl = res as string;
            })
            .catch((error) => {
              toast("Error uploading image", APP_TOASTIFY.ERROR);
              console.error("Error uploading image:", error);
              return;
            }),
        ]);
      } catch (error) {
        toast("Error uploading image", APP_TOASTIFY.ERROR);
        console.error("Error in Promise.all:", error);
        return;
      }
    }

    if (brandMarkFileDeleted) {
      payload.brandMarkUrl = null;
    }
    if (backgroundImageFileDeleted) {
      payload.backgroundImageUrl = null;
    }

    if (brandMarkFile) {
      try {
        await Promise.all([
          uploadImageToStorage(
            brandMarkFile,
            storagePath,
            `brandMark-${Date.now()}`
          )
            .then((res) => {
              payload.brandMarkUrl = res as string;
            })
            .catch((error) => {
              toast("Error uploading image", APP_TOASTIFY.ERROR);
              console.error("Error uploading image:", error);
              return;
            }),
        ]);
      } catch (error) {
        toast("Error uploading image", APP_TOASTIFY.ERROR);
        console.error("Error in Promise.all:", error);
        return;
      }
    }

    if (backgroundImageFile) {
      try {
        await Promise.all([
          uploadImageToStorage(
            backgroundImageFile,
            storagePath,
            `backgroundImage-${Date.now()}`
          )
            .then((res) => {
              payload.backgroundImageUrl = res as string;
            })
            .catch((error) => {
              toast("Error uploading image", APP_TOASTIFY.ERROR);
              console.error("Error uploading image:", error);
              return;
            }),
        ]);
      } catch (error) {
        toast("Error uploading image", APP_TOASTIFY.ERROR);
        console.error("Error in Promise.all:", error);
        return;
      }
    }

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.editClientBranding)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_REPONSE;
        if (data.result === "ok") {
          window.location.reload();
        } else {
          toast("Error while updating branding [1]", APP_TOASTIFY.ERROR);
        }
      })
      .catch((e) =>
        toast("Error while updating branding [2]", APP_TOASTIFY.ERROR)
      )
      .finally(() => setIsSubmitting(false));
    return;
  };

  return (
    <StyledBrandingPageContainer>
      <StyledInputs>
        <UploadLogo
          setLogoFile={setLogoFile}
          setLogoFileDeleted={(e) => setLogoFileDeleted(e)}
        />
        {/* <StyledSectionDivider />
        <BrandMark
          setBrandMarkFile={setBrandMarkFile}
          setBrandMarkFileDeleted={setBrandMarkFileDeleted}
        />
        <StyledSectionDivider />
        <BackgroundImage
          setBackgroundImageFile={setBackgroundImageFile}
          setBackgroundImageFileDeleted={setBackgroundImageFileDeleted}
        />
        <StyledSectionDivider /> */}
        <StyledSectionDivider />
        <PrimaryBrandColor setColorPrimary={setColorPrimary} />
        <StyledSectionDivider />
        <StyledSubmitButtonContainer>
          <AppButton onClick={() => handlePublish()} isLoading={isSubmitting}>
            Publish
          </AppButton>
        </StyledSubmitButtonContainer>
      </StyledInputs>
    </StyledBrandingPageContainer>
  );
};
