import { APP_ROUTES } from "../../../constants/routes.constant";
import { DashboardIcon } from "./icons/DashboardIcon";
import { MembersIcon } from "./icons/MembersIcon";
import { TemplatesIcon } from "./icons/TemplatesIcon";
import { BrandingIcon } from "./icons/BrandingIcon";
import { GroupsIcon } from "./icons/GroupsIcon";
import { SidebarItems } from "../../../types/sidebar.enums";
import { YOURZOWN_ADMIN_PERMISSIONS } from "../../../types/user/user.types";
import { RolesIcon } from "./icons/RolesIcon";
import { ContactsIcon } from "./icons/ContactsIcon";
import { PaymentsIcon } from "./icons/PaymentsIcon";
import { LocationsIcon } from "./icons/LocationsIcon";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

export const CLIENT_LINKS = [
  {
    key: SidebarItems.dashboard,
    url: APP_ROUTES.dashboard,
    label: "Dashboard",
    icon: ($isActive: boolean) => <DashboardIcon isActive={$isActive} />,
  },
  {
    key: SidebarItems.groups,
    url: APP_ROUTES.groups,
    label: "Groups",
    icon: ($isActive: boolean) => <GroupsIcon isActive={$isActive} />,
  },
  {
    key: SidebarItems.members,
    url: APP_ROUTES.members,
    label: "Members",
    icon: ($isActive: boolean) => <MembersIcon isActive={$isActive} />,
  },
  {
    key: SidebarItems.roles,
    url: APP_ROUTES.roles,
    label: "Roles",
    icon: ($isActive: boolean) => <RolesIcon isActive={$isActive} />,
  },
  {
    key: SidebarItems.templates,
    url: APP_ROUTES.templates,
    label: "Templates",
    icon: ($isActive: boolean) => <TemplatesIcon isActive={$isActive} />,
  },
  {
    key: SidebarItems.branding,
    url: APP_ROUTES.branding,
    label: "Branding",
    icon: ($isActive: boolean) => <BrandingIcon isActive={$isActive} />,
  },
  {
    key: SidebarItems.contacts,
    url: APP_ROUTES.contacts,
    label: "Contacts",
    icon: ($isActive: boolean) => <ContactsIcon isActive={$isActive} />,
  },
  {
    key: SidebarItems.payments,
    url: APP_ROUTES.payments,
    label: "Payments",
    icon: ($isActive: boolean) => <PaymentsIcon isActive={$isActive} />,
  },
  {
    key: SidebarItems.clubDocs,
    url: APP_ROUTES.clubDocs,
    label: "Club Docs",
    icon: ($isActive: boolean) => (
      <LocalPoliceIcon
        style={{
          color: $isActive
            ? APP_COMMON_COLORS.common.white
            : APP_COMMON_COLORS.dark[500],
          fontSize: "20px",
        }}
      />
    ),
  },
  {
    key: SidebarItems.locations,
    url: APP_ROUTES.locations,
    label: "Locations",
    icon: ($isActive: boolean) => <LocationsIcon isActive={$isActive} />,
  },
];

export const getYourZownLinks = (permissions: YOURZOWN_ADMIN_PERMISSIONS) => {
  let yourZownLinks = [
    {
      key: SidebarItems.dashboard,
      url: APP_ROUTES.dashboard,
      label: "Dashboard",
      icon: ($isActive: boolean) => <DashboardIcon isActive={$isActive} />,
    },
    permissions.clients
      ? {
          key: SidebarItems.clients,
          url: APP_ROUTES.clients,
          label: "Clients",
          icon: ($isActive: boolean) => <BrandingIcon isActive={$isActive} />,
        }
      : null,
  ];

  return yourZownLinks.filter((item) => item !== null);
};
