export enum SidebarItems {
  dashboard = "dashboard",
  members = "members",
  roles = "roles",
  templates = "templates",
  branding = "branding",
  clients = "clients",
  groups = "groups",
  contacts = "contacts",
  payments = "payments",
  clubDocs = "clubDocs",
  locations = "locations",
}
