import { CSSObject } from "styled-components";
import { APP_COMMON_COLORS } from "../providers/AppThemeProvider";

export const APP_TOASTIFY = {
  SUCCESS: {
    hideProgressBar: true,
    type: "success" as any,
    icon: false,
    style: {
      background: APP_COMMON_COLORS.common.darkGreen,
      color: "white",
    },
  },
  ERROR: {
    hideProgressBar: true,
    type: "error" as any,
    icon: false,
    style: {
      background: APP_COMMON_COLORS.error.main,
      color: "white",
    },
  },
};

export const HIDDEN_ELEMENT_STYLES: CSSObject = {
  display: "none",
};

export const DISABLED_ELEMENT_STYLES: CSSObject = {
  opacity: 0.5,
  pointerEvents: "none",
};

export const hexToRGBA = (hex = "", alpha = 0.5) => {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
