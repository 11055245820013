import React from "react";
import Radio from "@mui/material/Radio";
import RadioButtonGroup from "@mui/material/RadioGroup";
import styled, { CSSObject } from "styled-components";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";
import { StyledAppFormikFieldError } from "../AppFormik.styles";

export interface RadioGroupOptions {
  label: string;
  value: any;
}

export interface RadioGroupProps {
  options: RadioGroupOptions[];
  value: any;
  onChangeValue: (value: any) => void;
  customContainerStyles?: CSSObject;
  customRadioAndLabelContainerStyles?: CSSObject;
  customErrorStyles?: CSSObject;
  errorMessage?: string;
  rowDirection?: boolean;
  isDisabled?: boolean;
}

const StyledRadioButtonGroupContainer = styled(RadioButtonGroup)<{
  $customstyles?: CSSObject;
}>(({ $customstyles }) => ({
  width: "100%",
  gap: 10,
  display: "flex",
  flexDirection: "column",
  "@media only screen and (min-width: 1200px)": {
    gap: 0,
  },
  ...$customstyles,
}));

const StyledLabel = styled.div({
  color: APP_COMMON_COLORS.dark[700],
  fontWeight: 500,
  fontSize: 16,
  cursor: "pointer",
});

const StyledRadioAndLabelContainer = styled.div<{
  $customstyles?: CSSObject;
}>(({ $customstyles }) => ({
  display: "flex",
  gap: 0,
  alignItems: "center",
  borderBottom: `1px dashed ${APP_COMMON_COLORS.dark[100]}`,
  paddingBottom: 5,
  "@media only screen and (min-width: 1200px)": {
    padding: 5,
  },
  ...$customstyles,
}));

export const AppRadioButtonGroup: React.FC<RadioGroupProps> = ({
  ...props
}) => {
  return (
    <StyledRadioButtonGroupContainer
      row={props.rowDirection}
      $customstyles={{
        ...props.customContainerStyles,
        ...(props.isDisabled && { opacity: 0.4, pointerEvents: "none" }),
      }}
    >
      {props.options?.map((option: RadioGroupOptions, index: number) => (
        <StyledRadioAndLabelContainer
          key={index}
          onClick={() => {
            if (props.isDisabled) {
              return;
            }
            props.onChangeValue(option.value);
          }}
          $customstyles={{ ...props.customRadioAndLabelContainerStyles }}
        >
          <Radio size="medium" checked={option.value === props.value}></Radio>
          <StyledLabel>{option?.label}</StyledLabel>
        </StyledRadioAndLabelContainer>
      ))}
      <StyledAppFormikFieldError style={{ ...props.customErrorStyles }}>
        {props.errorMessage}
      </StyledAppFormikFieldError>
    </StyledRadioButtonGroupContainer>
  );
};
