import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { _fs } from "../../services/firebase/config";

/** stream COLLECTION data
 * note: you can use this function inside useEffect
 */
export const streamFirebaseDataCollection = (
  collection: COLLECTIONS_TYPE,
  onData: (data: any) => void,
  onError: (error: any) => void
) => {
  const unsubscribe = _fs.collection(collection).onSnapshot(
    (querySnapshot) => {
      const data: any = {};
      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          data[doc.id] = { ...doc.data(), docId: doc.id };
        }
      });
      onData(data);
    },
    (error) => {
      onError({ status: "nok", error });
    }
  );

  // Return the unsubscribe function
  return unsubscribe;
};
