import { createSlice } from "@reduxjs/toolkit";
import { GlobalState } from "../../types/global";
import { USER_STATE } from "../../types/user/user.types";

const initialState: USER_STATE = {};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: { payload: USER_STATE }) => {
      state.email = action.payload.email;
      state.uid = action.payload.uid;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.clientsIds = action.payload.clientsIds;
      state.isClientAdmin = action.payload.isClientAdmin;
      state.roles = action.payload.roles;
      state.createdAt = action.payload.createdAt;
      state.profilePictureUrl = action.payload.profilePictureUrl;
      state.yourzownPermissions = action.payload.yourzownPermissions;
    },
    setUserIsLoggedIn: (
      state,
      action: { payload: { isLoggedIn: boolean } }
    ) => {
      state.isLoggedIn = action.payload.isLoggedIn;
    },
    logoutAndRemoveUserData: (state) => {
      Object.assign(state, initialState);
      state.isLoggedIn = false;
    },
  },
});

export const { setUserData, setUserIsLoggedIn, logoutAndRemoveUserData } =
  userSlice.actions;

export const getUser = (state: GlobalState) => state.user || {};

export const isUserLoggedInSelector = (state: GlobalState) =>
  state.user.isLoggedIn;
