import { compareAlphanumeric } from "../../../helpers/compareAlphanumeric";
import { SUB_GROUPS } from "../../../types/groups/groups.types";

export const sortTreesRowsByName = (a: SUB_GROUPS, b: SUB_GROUPS) => {
  let groupNameA = "";
  let groupNameB = "";

  Object.values(a || {}).forEach((groupsA) => {
    if (groupsA.subGroupId === groupsA.parentId) {
      groupNameA = groupsA.subGroupName;
    }
  });

  Object.values(b || {}).forEach((groupsB) => {
    if (groupsB.subGroupId === groupsB.parentId) {
      groupNameB = groupsB.subGroupName;
    }
  });

  return compareAlphanumeric(groupNameA, groupNameB);
};
