import { useEffect, useState } from "react";
import { AppModal } from "../../../components/core/AppModal/AppModal";
import { StyledAppModalStepsContainer } from "../../../components/shared/StyledComponents/StyledAppModalComponents";
import { TempStyledAppSideContainer } from "../ContactsPage.styles";
import { AppModalMobileSteps } from "../../../components/shared/AppModalMobileSteps/AppModalMobileSteps";
import { AppModalDesktopSteps } from "../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps";
import { ProfileTab } from "./ProfileTab";
import * as Yup from "yup";
import { DetailsTab } from "./DetailsTab";
import { firebaseHttpsCallable } from "../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../constants/firebase.constants";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../redux/client/clientSlice";
import { GENERAL_REPONSE } from "../../../types/firbase.types";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../../styles/styles.constants";
import { Contact } from "../../../types/contacts/contacts.types";

const contactsFormTabs = ["Profile", "Details"];

export interface ContactFormValues {
  email: string;
  phoneNumber: string;
  description: string;
  includeInMemberApp: boolean;
  firstName: string;
  lastName: string;
  title: string;
}

export const ContactFormModal = ({
  handleCloseModal,
  isModalOpen,
  isEditMode,
  contactData,
}: {
  handleCloseModal: () => void;
  isModalOpen: boolean;
  isEditMode: boolean;
  contactData: Contact | null;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { clientId } = useSelector(getClientSelector);
  const [currentTab, setCurrentTab] = useState(0);
  const [contactFormData, setContactFormData] = useState<
    ContactFormValues | {} | null
  >(contactData);

  const handleSubmit = async (values: ContactFormValues) => {
    if (!isLoading) {
      setIsLoading(true);
      await firebaseHttpsCallable(
        isEditMode
          ? FIRESTORE_FUNCTIONS.updateContact
          : FIRESTORE_FUNCTIONS.addContact
      )({
        ...values,
        clientId,
      })
        .then((res) => {
          const data = res.data as GENERAL_REPONSE;
          if (data.result === "ok") {
            toast(
              isEditMode
                ? "Contact Updated Successfully"
                : "Contact Added Successfully",
              APP_TOASTIFY.SUCCESS
            );
            handleCloseModal();
            setCurrentTab(0);
            setContactFormData({});
          } else {
            toast("Something Went Wrong", APP_TOASTIFY.ERROR);
          }
        })
        .catch((e) => toast("Something Went Wrong", APP_TOASTIFY.ERROR))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    setContactFormData(contactData);
  }, [contactData]);

  return (
    <AppModal
      closeModal={() => {
        handleCloseModal();
        setCurrentTab(0);
      }}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      isOpen={isModalOpen}
      showBackArrow
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      $customContentStyle={{ padding: 0 }}
    >
      <StyledAppModalStepsContainer>
        <TempStyledAppSideContainer>
          <AppModalMobileSteps
            tabsLength={2}
            tabValue={currentTab}
            titlesOfSteps={contactsFormTabs}
          />
          <AppModalDesktopSteps
            tabs={contactsFormTabs}
            tabsTitle={isEditMode ? "Edit contact" : "Add contact"}
            OnChangeTabChange={setCurrentTab}
            tabValue={currentTab}
          />
        </TempStyledAppSideContainer>

        <ProfileTab
          outerTabValue={currentTab}
          setCurrentTab={setCurrentTab}
          setContactFormData={setContactFormData}
          contactFormData={contactFormData as Contact}
        />
        <DetailsTab
          outerTabValue={currentTab}
          setContactFormData={setContactFormData}
          onSubmit={handleSubmit}
          contactFormData={contactFormData as Contact}
          isLoading={isLoading}
          isEditMode={isEditMode}
        />
      </StyledAppModalStepsContainer>
    </AppModal>
  );
};
