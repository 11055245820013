import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CSSObject } from "@emotion/react";
import { Divider } from "@mui/material";

interface MobSelectButtonProps {
  title: React.ReactElement | string;
  buttonsList: (string | React.ReactNode)[];
  buttonActions: ((event: React.MouseEvent<HTMLElement>) => void)[];
  menuStyles?: CSSObject;
  dropdownStyles?: CSSObject;
  containerStyle?: React.CSSProperties;
}

export default function AppSelectButton(props: MobSelectButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div style={{ display: "flex", ...(props.containerStyle || {}) }}>
      <div onClick={handleClick} style={{ cursor: "pointer" }}>
        {props.title}
      </div>
      <Menu
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 2,
            textWrap: "nowrap",
            ...props.dropdownStyles,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {props.buttonsList.map(
          (buttonName: string | React.ReactNode, index: number) => (
            <div key={index}>
              <MenuItem
                onClick={(event) => {
                  props.buttonActions[index](event);
                  handleClose(event);
                }}
              >
                {buttonName}
              </MenuItem>
              {props.buttonsList.length > 1 &&
                index !== props.buttonsList.length - 1 && <Divider />}
            </div>
          )
        )}
      </Menu>
    </div>
  );
}
