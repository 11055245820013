import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import { AppFormikField } from "../../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { AppGooglePlacesAutoComplete } from "../../../../components/core/AppGooglePlacesAutoComplete/AppGooglePlacesAutoComplete";
import { StyledAppModalSubmitButtonContainer } from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppButton } from "../../../../components/core/AppButton/AppButton";
import { useAtom, useSetAtom } from "jotai";
import { LOCATION_MODAL_ATOMS } from "../LocationFormModal.Atoms";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";

interface LOCATION_STEP_ONE_VALUES {
  title: string;
  addressLineOne: string;
}

const extractPostalCode = (place: any) => {
  if (!place || !place.address_components) return "";
  const postalCodeComponent = place.address_components.find((component: any) =>
    component.types.includes("postal_code")
  );
  return postalCodeComponent ? postalCodeComponent.short_name : "";
};

export const LOCATION_STEP_ONE_INITIAL_VALUES: LOCATION_STEP_ONE_VALUES = {
  title: "",
  addressLineOne: "",
};

const schema = Yup.object().shape({
  title: Yup.string().required("Required"),
  addressLineOne: Yup.string().required("Required"),
});

export const LocationFormStepOne = () => {
  const setCurrentStep = useSetAtom(LOCATION_MODAL_ATOMS.currentStep);
  const [locationFormData, setLocationFormData] = useAtom(
    LOCATION_MODAL_ATOMS.locationFormData
  );

  const [mapCenter, setMapCenter] = useState({
    lat: 51.502,
    lng: -0.1124,
  });

  const handleOnMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      setLocationFormData((prev) => ({
        ...prev,
        lat: event.latLng?.lat() || 0,
        lng: event.latLng?.lng() || 0,
      }));
    }
  };

  const handleOnSubmit = (values: LOCATION_STEP_ONE_VALUES) => {
    setLocationFormData((prev) => ({ ...prev, ...values }));
    setCurrentStep(1);
  };

  useEffect(() => {
    if (locationFormData.lat && locationFormData.lng) {
      setMapCenter({
        lat: locationFormData.lat,
        lng: locationFormData.lng,
      });
    }
  }, [locationFormData.lat, locationFormData.lng]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...LOCATION_STEP_ONE_INITIAL_VALUES,
        ...locationFormData,
      }}
      validationSchema={schema}
      onSubmit={(values: LOCATION_STEP_ONE_VALUES) => {
        handleOnSubmit(values);
      }}
    >
      {({
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
        handleBlur,
      }) => {
        const _touched: FormikTouched<LOCATION_STEP_ONE_VALUES> = touched;
        const _errors: FormikErrors<LOCATION_STEP_ONE_VALUES> = errors;

        const handleOnPlacesSelect = (e: any) => {
          const _lat = e?.geometry?.location.lat();
          const _lng = e?.geometry?.location.lng();
          console.log(e);

          if (_lat && _lng) {
            setLocationFormData((prev) => ({
              ...prev,
              lat: _lat,
              lng: _lng,
              addressLineOne: e?.formatted_address,
            }));
            setMapCenter({
              lat: _lat,
              lng: _lng,
            });
            console.log("e", e);
            setFieldValue("addressLineOne", e?.formatted_address);
          }
          const extractedPostCode = extractPostalCode(e);
          if (extractedPostCode) {
            setLocationFormData((prev) => ({
              ...prev,
              postCode: extractedPostCode,
            }));
          }
        };

        return (
          <Form>
            <div>
              <AppFormikField
                isRequired={true}
                name="title"
                value={values.title}
                label="Title"
                errorMessage={
                  _touched.title && _errors.title
                    ? String(errors.title)
                    : undefined
                }
                onBlur={handleBlur}
                onChange={(e) => {
                  setLocationFormData((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }));

                  setFieldValue("title", e.target.value);
                }}
                type="text"
                containerstyle={{ marginBottom: 10 }}
              />
              <LoadScript
                googleMapsApiKey={String(
                  process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                )}
                libraries={["places"]}
              >
                <AppGooglePlacesAutoComplete
                  isRequired={true}
                  name="addressLineOne"
                  label={"Post Code/ Address"}
                  onBlur={handleBlur}
                  errorMessage={
                    _touched.addressLineOne && _errors.addressLineOne
                      ? String(errors.addressLineOne)
                      : undefined
                  }
                  onPlaceSelect={(e) => handleOnPlacesSelect(e)}
                  value={values.addressLineOne}
                  containerstyle={{ marginBottom: 5 }}
                />
                <GoogleMap
                  mapContainerStyle={{
                    width: "100%",
                    height: "260px",
                    marginBottom: 15,
                  }}
                  center={mapCenter}
                  zoom={13}
                  options={{
                    mapTypeControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                  }}
                  onClick={(event: google.maps.MapMouseEvent) =>
                    handleOnMapClick(event)
                  }
                >
                  {locationFormData.lat && locationFormData.lng && (
                    <Marker
                      position={{
                        lat: locationFormData.lat,
                        lng: locationFormData.lng,
                      }}
                    />
                  )}
                </GoogleMap>
              </LoadScript>
            </div>

            <StyledAppModalSubmitButtonContainer>
              <AppButton onClick={handleSubmit}>Continue</AppButton>
            </StyledAppModalSubmitButtonContainer>
          </Form>
        );
      }}
    </Formik>
  );
};
