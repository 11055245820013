import React, { useState } from "react";
import {
  Typography,
  useTheme as useMuiTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, Form, FormikTouched, FormikErrors } from "formik";
import * as Yup from "yup";
import styled, { useTheme } from "styled-components";
import { AppFormikField } from "../../components/core/AppFormik/AppFormikField/AppFormikField";
import { useAutoFill } from "../../hooks/useAutoFill/useAutoFill";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppButton } from "../../components/core/AppButton/AppButton";
import { _fs } from "../../services/firebase/config";
// import { YourZownIcon } from "../../assets/icons/YourZownIcon";
import { APP_COMMON_COLORS } from "../../providers/AppThemeProvider";
import { emailRegex } from "../../helpers/emailRegex";
import { firebaseHttpsCallable } from "../../hooks/firebase/firebase.helper";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../styles/styles.constants";
import { GENERAL_REPONSE } from "../../types/firbase.types";
import { APP_ROUTES } from "../../constants/routes.constant";
import { FIRESTORE_FUNCTIONS } from "../../constants/firebase.constants";
import { YourZownIcon } from "../../assets/icons/YourZownIcon";

const schema = Yup.object({
  email: Yup.string()
    .matches(emailRegex, "Invalid email address")
    .required("Required")
    .min(5, "Minimum length is 5")
    .max(255, "Maximum length is 255"),
});

const StyledSigningPagesContainer = styled.div<{ $isActive?: boolean }>(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    background: APP_COMMON_COLORS.yourzown.colorPrimary,
    justifyContent: "center",
    alignItems: "end",
    "@media only screen and (min-width: 1200px)": {
      alignItems: "normal",
    },
  })
);
export const ForgetPasswordPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const { isAutoFill } = useAutoFill();
  const isSmallScreen = useMediaQuery(useMuiTheme().breakpoints.down("lg"));
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isMobileApp = query.get("mobile-app") === "true";

  const handleOnSubmit = async (values: { email: string }) => {
    setIsLoading(true);

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.sendResetPasswordEmail)({
      email: values.email,
      baseUrl: window.location.origin,
    })
      .then((res) => {
        const data = res.data as GENERAL_REPONSE;
        console.log(res);
        if (data.result === "ok") {
          if (isMobileApp) {
            toast(
              "Reset password link has been sent to your email",
              APP_TOASTIFY.SUCCESS
            );
            if ((window as any).FlutterChannel) {
              // Send a message to Flutter
              (window as any).FlutterChannel.postMessage(
                "reset-password-email-sent"
              );
            }
          } else {
            navigate(APP_ROUTES.signIn);
          }
          return;
        }
        if (data.result === "nok") {
          toast(data.message, APP_TOASTIFY.ERROR);
        }
      })
      .catch((e) =>
        toast("Error occured while reseting password [2]", APP_TOASTIFY.ERROR)
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <StyledSigningPagesContainer>
      {!isSmallScreen && (
        <Grid
          item
          display="flex"
          alignItems="center"
          md={6}
          sx={{
            width: "50%",
            height: "auto",
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            height={"100%"}
            justifyContent={"center"}
            paddingX={5}
            sx={{
              background: APP_COMMON_COLORS.yourzown.colorPrimary,
            }}
          >
            <Box
              display={"flex"}
              height={"100%"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Typography
                mb={2.5}
                fontWeight={400}
                variant="h6"
                color={theme.common.white}
              >
                WELCOME TO
              </Typography>
              <Typography
                mb={2.5}
                fontWeight={700}
                fontSize={"2rem"}
                color={theme.common.white}
              >
                YourZown Admin
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}
      <Grid
        item
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent={isSmallScreen ? "start" : "center"}
        borderRadius={isSmallScreen ? "10px 10px 0px 0px" : "0px"}
        md={6}
        xs={12}
        sx={{
          background: !isSmallScreen
            ? theme.common.white
            : theme.colorSecondary,
        }}
        paddingX={0.625}
        paddingTop={isSmallScreen ? 5 : 1}
        paddingBottom={!isSmallScreen ? 1 : 0}
        width={isSmallScreen ? "440px" : "50%"}
        height={"auto"}
      >
        {isSmallScreen && (
          <>
            <Typography
              marginBottom={1.375}
              color={theme.common.white}
              fontWeight={"300"}
              variant="caption"
            >
              Forgot password
            </Typography>
          </>
        )}

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent={isSmallScreen ? "flex-start" : "center"}
          paddingTop={isSmallScreen ? 3.75 : 0}
          borderRadius={isSmallScreen ? "10px 10px 0px 0px" : "10px"}
          width={1}
          sx={{
            backgroundColor: theme.common.white,
            maxHeight: "660px",
            height: "80vh",
          }}
        >
          <Formik
            enableReinitialize={true}
            initialValues={{ email: "" }}
            validationSchema={schema}
            onSubmit={(values: { email: string }) => handleOnSubmit(values)}
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => {
              const _touched: FormikTouched<{
                email: string;
              }> = touched;

              const _errors: FormikErrors<{ email: string }> = errors;

              return (
                <Form
                  style={{
                    width: isSmallScreen ? "100%" : 400,
                  }}
                >
                  <Box paddingX={2.5}>
                    <Box display="flex" justifyContent="center">
                      <YourZownIcon width="120" height="120" />
                    </Box>
                    {!isSmallScreen && (
                      <Typography
                        textAlign={"center"}
                        color={theme.common.black}
                        fontWeight={600}
                        variant="h6"
                        marginTop={1}
                        marginBottom={4.5}
                      >
                        Forgot password
                      </Typography>
                    )}
                    <AppFormikField
                      name="email"
                      value={values.email}
                      label="EMAIL ADDRESS"
                      errorMessage={
                        _touched.email && _errors.email
                          ? String(errors.email)
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: isAutoFill || undefined }}
                      containerstyle={{
                        marginBlock: 20,
                      }}
                    />
                    <AppButton
                      isLoading={isLoading}
                      type="submit"
                      customStyles={{
                        background: APP_COMMON_COLORS.yourzown.colorPrimary,
                        borderColor: APP_COMMON_COLORS.yourzown.colorPrimary,
                        "&:hover": {
                          background: APP_COMMON_COLORS.yourzown.colorPrimary,
                          border: `1px solid ${theme.white}`,
                        },
                      }}
                    >
                      <Typography
                        color={theme.common.white}
                        fontWeight={600}
                        variant="body1"
                      >
                        SEND RECOVERY LINK
                      </Typography>
                    </AppButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Grid>
    </StyledSigningPagesContainer>
  );
};
