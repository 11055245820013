export const PlayStoreIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3476_11381"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="15"
        y="7"
        width="8"
        height="7"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.4003 8.50051L19.5303 7.62051C19.3457 7.53608 19.1284 7.57164 18.9803 7.71051L16.1503 10.3605C16.0493 10.455 15.992 10.5872 15.992 10.7255C15.992 10.8638 16.0493 10.996 16.1503 11.0905L19.0203 13.7705C19.1684 13.9094 19.3857 13.9449 19.5703 13.8605L21.4003 13.0205C22.2799 12.6095 22.8421 11.7264 22.8421 10.7555C22.8421 9.78459 22.2799 8.90148 21.4003 8.49051V8.50051Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3476_11381)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.992 2.5752H27.8421V18.9058H10.992L10.992 2.5752Z"
          fill="white"
        />
      </g>
      <mask
        id="mask1_3476_11381"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="22"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.01 21.54L13.13 11.11C13.231 11.0155 13.2883 10.8833 13.2883 10.745C13.2883 10.6067 13.231 10.4745 13.13 10.38L1.9 0C1.63875 0.05937 1.38884 0.16069 1.16 0.3C0.436375 0.7572 -0.00169507 1.55404 4.92983e-06 2.41V19.11C-0.00169507 19.966 0.436375 20.7628 1.16 21.22C1.42122 21.3781 1.70939 21.4866 2.01 21.54Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_3476_11381)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5 -5H18.2883V26.54H-5L-5 -5Z"
          fill="white"
        />
      </g>
      <mask
        id="mask2_3476_11381"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="0"
        width="13"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.19 7.29953C17.3121 7.18615 17.3696 7.01942 17.3436 6.85488C17.3175 6.69033 17.2112 6.54959 17.06 6.47953L5 0.769531L14.28 9.34953Here's the continuation of the React component code for the SVG:

```jsx
        C14.4718 9.52739 14.7682 9.52739 14.96 9.34953L17.19 7.29953Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_3476_11381)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 -4.23047H22.3497V14.4829H0L0 -4.23047Z"
          fill="white"
        />
      </g>
      <mask
        id="mask3_3476_11381"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="11"
        width="13"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.97 12.0807C14.7782 11.9028 14.4818 11.9028 14.29 12.0807L5.2 20.6107L17.13 15.0107C17.2812 14.9406 17.3875 14.7999 17.4136 14.6353C17.4397 14.4708 17.3821 14.304 17.26 14.1907L14.97 12.0807Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_3476_11381)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.200001 6.94727H22.4197V25.6107H0.200001L0.200001 6.94727Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
