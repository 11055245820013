import { Form } from "formik";
import styled from "styled-components";

export const StyleForm = styled(Form)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "45vh",
  "@media only screen and (min-width: 1200px)": {
    height: "38vh",
  },
});
