import styled from "styled-components";

const TableNoRecordsContainer = styled.div`
  height: 30px;
  font-size: 18px;
  padding: 20px;
  font-weight: bold;
  background: white;
  color: "black";
`;

export function TableNoRecords(): React.ReactElement {
  return <TableNoRecordsContainer>No Records</TableNoRecordsContainer>;
}
