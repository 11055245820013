import { Switch, styled as MUIStyled } from "@mui/material";
import styled from "styled-components";

export const StyledAppSwitchContainer = styled.div({
  display: "flex",
  padding: "10px 0",
  alignItems: "center",
  gap: 5,
  "@media only screen and (min-width: 1200px)": {
    gap: 10,
  },
});

export const StyledLabel = styled.label({
  fontSize: 14,
  fontWeight: 500,
  "@media only screen and (min-width: 1200px)": {
    fontSize: 16,
  },
});

export const StyledSwitch = MUIStyled(Switch)<{ $customColor?: string }>(
  ({ theme, $customColor }) => ({
    padding: 8,
    width: "66px",
    height: "41px",
    "& .Mui-checked": {
      transform: "translateX(24px) !important",
      color: "white !important",
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: $customColor || theme.palette.primary.main,
      opacity: "1 !important",
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#E8E6E6",
      borderRadius: 25 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 19,
        height: 19,
      },
    },
    "& .MuiSwitch-thumb": {
      opacity: 1,
      boxShadow: "none",
      width: 19,
      height: 19,
      margin: "2px 2px",
    },
  })
);
