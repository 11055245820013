import { EVENT_FIELD } from "../../../../types/templates/templates.types";
import {
  EventAdditionalFieldsStepValues,
  EventAdditionalFieldsStepValuesKeys,
} from "./EventAdditionalFieldsStep";

export const PRE_DEFINED_ADDITIONAL_FIELDS: EventAdditionalFieldsStepValues = {
  attachments: {
    label: "Attachments",
    value: "attachments",
    enabled: false,
    required: false,
  },
  comments: {
    label: "Comments",
    value: "comments",
    enabled: false,
    required: false,
  },
  countDown: {
    label: "Count Down",
    value: "countDown",
    enabled: false,
    required: false,
  },
  coverImage: {
    label: "Cover Image",
    value: "coverImage",
    enabled: false,
    required: false,
  },
  meetUpTime: {
    label: "Meet Up Time",
    value: "meetUpTime",
    enabled: false,
    required: false,
  },
  freeTextField: {
    label: "Free Text Field",
    value: "freeTextField",
    enabled: false,
    required: false,
  },
  imageGallery: {
    label: "Image Gallery",
    value: "imageGallery",
    enabled: false,
    required: false,
  },
  postEventSummary: {
    label: "Post Event Summary",
    value: "postEventSummary",
    enabled: false,
    required: false,
  },
  score: { label: "Score", value: "score", enabled: false, required: false },
  capacity: {
    label: "Capacity",
    value: "capacity",
    enabled: false,
    required: false,
  },
};

/** return only enabled: true */
export const getEnabledFieldsArray = (
  values: EventAdditionalFieldsStepValues
): EventAdditionalFieldsStepValuesKeys[] => {
  const enabledFields = Object.values(values)
    .filter((field) => field.enabled)
    .map(({ enabled, ...rest }) => rest);

  return [...Object.values(enabledFields)];
};

/** revert back getEnabledFieldsArray */
export const mapEnabledArrayFieldsToObj = (
  arr: EVENT_FIELD[]
): EventAdditionalFieldsStepValues => {
  return arr.reduce((acc, field) => {
    acc[field.value] = { ...field, enabled: true };
    return acc;
  }, {} as EventAdditionalFieldsStepValues);
};
