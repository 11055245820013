import { useEffect, useState } from "react";
import { APP_COMMON_COLORS } from "../../../../../providers/AppThemeProvider";
import {
  StyledDot2,
  StyledDot1,
  StyledFlexDiv,
  StyledLabel1,
  StyledTitle,
  StyledLabel2,
  StyledLabel3,
  StyledDivider,
  StyledSubTitle,
} from "./RolesSideInfoFieldsInTemplate.styles";
import { ROLES_ATOMS } from "../../../RolesPage.atoms";
import { useAtom } from "jotai";

const MEMBER_INCLUDED_FIELDS = [
  { label: "First name", required: true },
  { label: "Last name", required: true },
  { label: "Date of birth", required: true },
  { label: "Email address", required: true },
  { label: "Mobile number", required: true },
  { label: "Photo consent", required: true },
  { label: "Medical conditions", required: false },
  { label: "Middle name", required: false },
  { label: "Gender", required: false },
  { label: "Ethnicity", required: false },
];

const CHILD_INCLUDED_FIELDS = [
  { label: "First name", required: true },
  { label: "Last name", required: true },
  { label: "Date of birth", required: true },
  { label: "Photo consent", required: true },
  { label: "Medical conditions", required: true },
  { label: "Gender", required: false },
  { label: "Email address", required: false },
];

export const RolesSideInfoFieldsInTemplate = () => {
  const [addedFields, setAddedFields] = useAtom(ROLES_ATOMS.addedFields);

  return (
    <div>
      <StyledTitle>Fields in this template</StyledTitle>

      <StyledSubTitle>
        <StyledFlexDiv>
          <StyledDot1 />
          <StyledLabel1>Mandatory</StyledLabel1>
        </StyledFlexDiv>
        <StyledFlexDiv>
          <StyledFlexDiv>
            <StyledDot1
              style={{
                background: APP_COMMON_COLORS.common.lightGreen,
              }}
            />
            <StyledLabel1>Optional</StyledLabel1>
          </StyledFlexDiv>
        </StyledFlexDiv>
      </StyledSubTitle>

      <StyledDivider />

      <StyledLabel2>Included for all member roles</StyledLabel2>

      {MEMBER_INCLUDED_FIELDS.map((name) => (
        <StyledFlexDiv style={{ marginBottom: 8 }} key={name.label}>
          <StyledDot2
            style={
              name.required
                ? { background: APP_COMMON_COLORS.common.lightRed }
                : { background: APP_COMMON_COLORS.common.lightGreen }
            }
          />
          <StyledLabel3>{name.label}</StyledLabel3>
        </StyledFlexDiv>
      ))}

      <StyledDivider />

      <StyledLabel2>Included for all child roles</StyledLabel2>

      {CHILD_INCLUDED_FIELDS.map((name) => (
        <StyledFlexDiv style={{ marginBottom: 8 }} key={name.label}>
          <StyledDot2
            style={
              name.required
                ? { background: APP_COMMON_COLORS.common.lightRed }
                : { background: APP_COMMON_COLORS.common.lightGreen }
            }
          />
          <StyledLabel3>{name.label}</StyledLabel3>
        </StyledFlexDiv>
      ))}

      {addedFields && addedFields.length > 0 && (
        <>
          <StyledDivider />
          <StyledLabel2>Custom fields</StyledLabel2>

          {addedFields.map((field) => {
            return (
              <StyledFlexDiv style={{ marginBottom: 8 }}>
                <StyledDot2
                  style={
                    field.required
                      ? { background: APP_COMMON_COLORS.common.lightRed }
                      : { background: APP_COMMON_COLORS.common.lightGreen }
                  }
                />
                <StyledLabel3>{field.label}</StyledLabel3>
              </StyledFlexDiv>
            );
          })}
        </>
      )}
    </div>
  );
};
