import { Typography, useTheme } from "@mui/material";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";
import { StyledAppModalMobileStepsContainer } from "./AppModalMobileSteps.styles";

interface AppModalMobileStepsProps {
  tabsLength?: number;
  tabValue: number;
  titlesOfSteps?: string[] | React.ReactNode[];
  customSmallTitle?: string;
  hideStepsTitle?: boolean;
}

export const AppModalMobileSteps: React.FC<AppModalMobileStepsProps> = ({
  ...props
}) => {
  const stepsTitle = `STEP ${props.tabValue + 1} OF ${props.tabsLength || 1}`;

  return (
    <StyledAppModalMobileStepsContainer>
      {!props.hideStepsTitle && (
        <Typography
          color={APP_COMMON_COLORS.common.black}
          fontWeight={400}
          variant="caption"
          textAlign={"center"}
        >
          {stepsTitle}
        </Typography>
      )}
      {props.customSmallTitle && (
        <Typography
          color={APP_COMMON_COLORS.common.black}
          fontWeight={400}
          variant="caption"
          textAlign={"center"}
        >
          {props.customSmallTitle}
        </Typography>
      )}

      <Typography
        color={APP_COMMON_COLORS.common.black}
        fontWeight={600}
        variant="h4"
        textAlign={"center"}
      >
        {props.titlesOfSteps && props.titlesOfSteps[props.tabValue]}
      </Typography>
    </StyledAppModalMobileStepsContainer>
  );
};
