import styled from "styled-components";
import { HIDDEN_ELEMENT_STYLES } from "../../../../../styles/styles.constants";
import { AppTopTags } from "../../../../../components/core/AppTopTags/AppTopTags";
import {
  AppRadioCustomContainerStyles,
  CustomRadioAndLabelContainerStyles,
  StyledFieldRow,
  StyledFieldsRowsContainer,
  StyledLabelContainer,
} from "./FieldsRows.styles";
import React from "react";
import { AppRadioButtonGroup } from "../../../../../components/core/AppFormik/AppFormikField/AppRadioButton";
import { ROLES_ATOMS } from "../../../RolesPage.atoms";
import { useAtom } from "jotai";
import { StyledAppModalFooterButtonContainer } from "../../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppButton } from "../../../../../components/core/AppButton/AppButton";
import { AddIconWithLabel } from "../../../../../components/shared/AddIconWithLabel/AddIconWithLabel";
import { StyledClose } from "../../../../../components/shared/StyledComponents/StyledClose";

interface RolesAddedFieldsRowsProps {
  onClick: () => void;
  hideSection: boolean;
}

export const StyledAddedFieldsListContainer = styled.div({});

export const FieldsRows = (props: RolesAddedFieldsRowsProps) => {
  const [addedFields, setAddedFields] = useAtom(ROLES_ATOMS.addedFields);
  const [modalCurrentStep, setModalCurrentStep] = useAtom(
    ROLES_ATOMS.modalCurrentStep
  );

  const handleRemoveField = (index: number) => {
    const newArray = [...addedFields];
    newArray.splice(index, 1);
    setAddedFields(newArray);
  };

  const handleRequiredOptionClicked = (e: boolean, index: number) => {
    const newArray = [...addedFields];
    newArray[index].required = e;
    setAddedFields(newArray);
  };

  const handleNext = () => {
    setModalCurrentStep(2);
  };

  return (
    <StyledAddedFieldsListContainer
      style={{ ...(props.hideSection && HIDDEN_ELEMENT_STYLES) }}
    >
      {addedFields.length < 1 ? (
        <AddIconWithLabel label="ADD CUSTOM FIELD" onClick={props.onClick} />
      ) : (
        <>
          <AppTopTags tags={["MANDATORY", "OPTIONAL"]} />
          <StyledFieldsRowsContainer>
            {Object.values(addedFields).map((additionalField, index) => {
              return (
                <React.Fragment key={index}>
                  <StyledFieldRow>
                    <StyledLabelContainer>
                      <StyledClose onClick={() => handleRemoveField(index)} />
                      {additionalField.label}
                    </StyledLabelContainer>

                    <div style={{ display: "flex" }}>
                      <AppRadioButtonGroup
                        options={[
                          { label: "", value: true },
                          { label: "", value: false },
                        ]}
                        value={additionalField.required}
                        onChangeValue={(e) =>
                          handleRequiredOptionClicked(e, index)
                        }
                        customContainerStyles={AppRadioCustomContainerStyles}
                        customRadioAndLabelContainerStyles={{
                          ...CustomRadioAndLabelContainerStyles,
                        }}
                        customErrorStyles={{ display: "none" }}
                        rowDirection={true}
                      />
                    </div>
                  </StyledFieldRow>
                </React.Fragment>
              );
            })}
          </StyledFieldsRowsContainer>

          <AddIconWithLabel label="ADD CUSTOM FIELD" onClick={props.onClick} />
        </>
      )}
      <StyledAppModalFooterButtonContainer>
        <AppButton onClick={handleNext}>NEXT</AppButton>
      </StyledAppModalFooterButtonContainer>
    </StyledAddedFieldsListContainer>
  );
};
