import styled, { CSSObject } from "styled-components";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";

export const TableHeadContainer = styled.thead({
  background: APP_COMMON_COLORS.grey[200],
  height: 40,
});

export const StyledTh = styled.th<{ customstyles: CSSObject }>(
  ({ customstyles }) => ({
    textTransform: "uppercase",
    lineHeight: "30px",
    fontWeight: 500,
    fontSize: 12,
    color: APP_COMMON_COLORS.dark[700],
    paddingInline: 20,
    textAlign: "start",
    ...customstyles,
  })
);
