import styled from "styled-components";
import {
  MEMBERS,
  MEMBER_STATUS,
  RESEND_INVITE_MEMBER_EMAIL,
} from "../../../../types/members/members.types";
import { useState } from "react";
import { firebaseHttpsCallable } from "../../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../../constants/firebase.constants";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { GENERAL_REPONSE } from "../../../../types/firbase.types";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../../../styles/styles.constants";

interface MemberStatusCellProps {
  status: MEMBER_STATUS;
  email: string;
  isDeactivated?: { [key: string]: boolean };
  clientId?: string;
}

const StyledContainer = styled.div({
  minWidth: 150,
  "@media only screen and (min-width: 1200px)": {
    minWidth: 250,
  },
});

const StyledResendInvite = styled.span(({ theme }) => ({
  color: theme.colorPrimary,
  cursor: "pointer",
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

export const MemberStatusCell = (props: MemberStatusCellProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const client = useSelector(getClientSelector);

  const handleOnClickResendInvite = () => {
    setIsLoading(true);
    const payload: RESEND_INVITE_MEMBER_EMAIL = {
      email: props.email,
      client: client,
      baseUrl: window.location.origin,
    };

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.resendInviteMemberEmail)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_REPONSE;
        if (data.result === "ok") {
          toast("Invitation sent", APP_TOASTIFY.SUCCESS);
        } else {
          toast("Error while sending invite email [1]", APP_TOASTIFY.ERROR);
        }
      })
      .catch((e) => toast("Error sending invite email [2]", APP_TOASTIFY.ERROR))
      .finally(() => setIsLoading(false));
  };

  if (
    props.isDeactivated &&
    props.clientId &&
    props.isDeactivated[props.clientId]
  ) {
    return (
      <StyledContainer>
        <div>Deactivated</div>
      </StyledContainer>
    );
  }

  if (props.status === MEMBER_STATUS.active) {
    return <StyledContainer>Active</StyledContainer>;
  }

  return (
    <StyledContainer>
      <div>Not signed in:</div>
      {isLoading ? (
        "Sending..."
      ) : (
        <StyledResendInvite onClick={handleOnClickResendInvite}>
          Resend invite
        </StyledResendInvite>
      )}
    </StyledContainer>
  );
};
