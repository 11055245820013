import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { _fs } from "../../services/firebase/config";

/** get COLLECTION
 * note: you can use this function inside useEffect
 */
export const getFirebaseDataCollection = async (
  collection: COLLECTIONS_TYPE
) => {
  let _data: any;

  return await _fs
    .collection(collection)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc?.data()) {
          _data = {
            ..._data,
            [doc.id]: { ...doc.data(), docId: doc.id },
          };
        }
      });
      return _data;
    })
    .catch((error) => {
      return { status: "nok", error };
    });
};
