import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../providers/AppThemeProvider";

export const StyledBrandingPageContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  padding: "40px 40px 85px 40px",
  justifyContent: "space-between",
  "@media only screen and (min-width: 1200px)": {
    flexDirection: "row",
    padding: "40px",
  },
});

export const StyledInputs = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  "@media only screen and (min-width: 1200px)": {
    width: "65%",
  },
});

export const StyledPreviewScreens = styled.div({
  "@media only screen and (min-width: 1200px)": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 15,
    width: 260,
    height: "fit-content",
    padding: "20px 30px",
    border: `1px solid ${APP_COMMON_COLORS.dark[100]}`,
    borderRadius: 8,
  },
});

export const StyledSectionTitle = styled.div({
  fontWeight: 500,
  fontSize: 20,
  marginBottom: 8,
  color: APP_COMMON_COLORS.dark[600],
});

export const StyledSectionSubtitle = styled.div({
  fontWeight: 300,
  fontSize: 16,
  marginBottom: 30,
  color: APP_COMMON_COLORS.dark[600],
});

export const StyledSectionDivider = styled.div({
  height: 1,
  borderBottom: `1.5px dashed ${APP_COMMON_COLORS.dark[100]}`,
  marginBlock: 30,
});
