import { FORM_FIELD } from "../../pages/TemplatesPage/AddEditTemplateModal/FormPostFieldsV2/FormPostFields.types";

export interface EVENT_FIELD {
  label: string;
  value: string; // type value of the field
  required: boolean;
}

export enum EVENT_CUSTOM_FIELDS_TYPE {
  textInput = "textInput",
  attachment = "attachment",
  redirection = "redirection",
}

export interface EVENT_CUSTOM_FIELD {
  label: string;
  value: EVENT_CUSTOM_FIELDS_TYPE;
  required: boolean;
}

export enum TEMPLATE_TYPE {
  event = "event",
  performancePost = "performancePost",
  quickPost = "generalPost",
  formPost = "accidentPost",
}

export interface ADD_EVENT_TEMPLATE_DTO {
  clientId: string;
  templateName: string;
  templateType: TEMPLATE_TYPE.event;
  additionalFields?: EVENT_FIELD[] | null;
  customFields?: EVENT_CUSTOM_FIELD[] | null;
  updatedBy: string;
}

export interface EVENT_TEMPLATE extends ADD_EVENT_TEMPLATE_DTO {
  templateId: string;
  updatedAt: number;
}

export interface GET_EVENTS_TEMPLATES_BY_CLIENT_ID_DTO {
  clientId: string;
}

export interface ADD_QUICK_POST_TEMPLATE_DTO {
  clientId: string;
  templateName: string;
  templateType: TEMPLATE_TYPE.quickPost;
  updatedBy: string;
}

export interface QUICK_POST_TEMPLATE extends ADD_QUICK_POST_TEMPLATE_DTO {
  templateId: string;
  updatedAt?: number;
}

export interface PERFORMANCE_POST_FIELDS {
  metricTitle: string;
  starRating: string;
}
export interface ADD_PERFORMANCE_POST_TEMPLATE_DTO {
  clientId: string;
  templateName: string;
  templateType: TEMPLATE_TYPE.performancePost;
  additionalFields: PERFORMANCE_POST_FIELDS[];
  updatedBy: string;
}

export interface PERFORMANCE_POST_TEMPLATE
  extends ADD_PERFORMANCE_POST_TEMPLATE_DTO {
  templateId: string;
  updatedAt?: number;
}

export interface DELETE_TEMPLATE_DTO {
  clientId: string;
  templateId: string;
}

export interface ADD_FORM_POST_TEMPLATE_DTO {
  clientId: string;
  templateName: string;
  templateType: TEMPLATE_TYPE.formPost;
  additionalFields: FORM_FIELD[];
  updatedBy: string;
}

export interface FORM_POST_TEMPLATE extends ADD_FORM_POST_TEMPLATE_DTO {
  templateId: string;
  updatedAt: number;
}

export type TEMPLATE_DATA =
  | EVENT_TEMPLATE
  | QUICK_POST_TEMPLATE
  | PERFORMANCE_POST_TEMPLATE
  | FORM_POST_TEMPLATE;
