import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../../../providers/AppThemeProvider";
import { Close } from "@mui/icons-material";

export const StyledTitle = styled.div({
  display: "none",
  "@media only screen and (min-width: 1200px)": {
    display: "block",
    color: APP_COMMON_COLORS.dark[700],
    fontWeight: 400,
    fontSize: 16,
    marginBottom: 12,
  },
});

export const StyledDataContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const StyledSubTitle = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 8,
  "@media only screen and (min-width: 1200px)": {
    justifyContent: "start",
  },
});

export const StyledFlexDiv = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 8,
});

export const StyledLabel1 = styled.div({
  fontWeight: 400,
  fontSize: 12,
  color: APP_COMMON_COLORS.dark[400],
});

export const StyledLabel2 = styled.div({
  fontWeight: 300,
  fontSize: 12,
  color: APP_COMMON_COLORS.dark[400],
  marginBottom: 12,
});

export const StyledLabel3 = styled.div({
  fontWeight: 400,
  fontSize: 14,
  color: APP_COMMON_COLORS.dark[600],
});

export const StyledDivider = styled.div({
  height: 1,
  borderBottom: `1px dashed ${APP_COMMON_COLORS.dark[100]}`,
  marginBlock: 18,
});

export const StyledClose = styled(Close)(({ theme }) => ({
  color: theme.colorPrimary,
  cursor: "pointer",
  "&:hover": {
    color: APP_COMMON_COLORS.error.main,
  },
}));
