import { useSelector } from "react-redux";
import { GlobalState } from "../../types/global";
import { getUser, isUserLoggedInSelector } from "../../redux/user/userSlice";

export const useUserIdentity = () => {
  const user = useSelector((state: GlobalState) => getUser(state));
  const isLoggedIn = useSelector((state: GlobalState) =>
    isUserLoggedInSelector(state)
  );

  return {
    user,
    isLoggedIn,
  };
};
