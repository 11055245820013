import styled from "styled-components";
import { BurgerMenuWidth } from "./components/core/AppBurgerMenu/AppBurgerMenu.styles";

export const StyledAppLoggedInContainer = styled.div((props: any) => ({
  "@media only screen and (min-width: 1200px)": {
    width: props.isFullWidthRoute
      ? "100%"
      : `calc(100% - (${BurgerMenuWidth}))`,
    marginLeft: props.isFullWidthRoute ? 0 : BurgerMenuWidth,
  },
})) as any;
