import { useEffect, useState } from "react";
import { _fs } from "../../services/firebase/config";
import { FB_QUERYSNAPSHOT } from "../../types/firbase.types";

export const useFirebaseStreamDataByColKeyVal = (input: {
  collection: any;
  fbKey: string;
  fbValue: string | boolean;
  orderBy?: { orderKey: string; direction: "desc" | "asc" };
  limit?: number;
}) => {
  const { collection, fbKey, fbValue, orderBy, limit } = input;
  const [data, setData] = useState<any>();
  const [fetched, setFetched] = useState<boolean>(false);

  let ref = _fs.collection(collection).where(fbKey || "", "==", fbValue || "");

  if (orderBy?.orderKey && orderBy?.direction) {
    ref = ref.orderBy(orderBy.orderKey, orderBy.direction);
  }

  if (limit) {
    ref = ref.limit(limit);
  }

  useEffect(() => {
    if (collection) {
      const unsubscribe = ref.onSnapshot((querySnapshot: FB_QUERYSNAPSHOT) => {
        let data: any = [];
        querySnapshot.forEach((doc: any) => {
          data = [...data, { ...doc?.data(), docId: doc.id }];
        });
        setData(data);
        setFetched(true);
      });
      return () => unsubscribe();
    }
  }, [collection, fbKey, fbValue]);

  return fetched ? data : [];
};
