import { useEffect, useState } from "react";
import { _fs } from "../../services/firebase/config";
import { FB_QUERYSNAPSHOT } from "../../types/firbase.types";
import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { RowProps } from "../../components/core/AppTable/types";
import { ThreeDotsIcon } from "../../assets/icons/ThreeDotsIcon";
import AppSelectButton from "../../components/core/AppSelectButton/AppSelectButton";
import styled from "styled-components";
import { Divider } from "@mui/material";
import { RecycleBinIcon } from "../../assets/icons/RecycleBinIcon";
import { CLUB_DOC } from "../../types/clubDocs/clubDocs.types";

const StyledActions = styled.div({
  display: "flex",
  justifyContent: "end",
  gap: 15,
});

export const useFirebaseStreamClubDocs = (
  clientId: string,
  onEditStart: (rowData: CLUB_DOC) => void,
  onDeleteStart: (rowData: CLUB_DOC) => void
) => {
  const [rows, setRows] = useState<any>([]);
  const [fetched, setFetched] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = _fs
      .collection(COLLECTIONS_TYPE.clubDocs)
      .doc(clientId)
      .collection(COLLECTIONS_TYPE.clientClubDocs)
      .onSnapshot(async (querySnapshot: FB_QUERYSNAPSHOT) => {
        let _rows: RowProps[] = [];
        let _row: RowProps = {} as RowProps;

        querySnapshot.forEach((doc) => {
          const { clubDocId, docText, docTitle } = doc?.data() as CLUB_DOC;

          _row = {
            id: [clubDocId, docText, docTitle].join(" "),
            cells: [
              docTitle,
              <StyledActions>
                <RecycleBinIcon
                  onClick={() => onDeleteStart(doc.data() as CLUB_DOC)}
                />
                <Divider orientation="vertical" style={{ height: 22 }} />
                <AppSelectButton
                  containerStyle={{ justifyContent: "end" }}
                  title={<ThreeDotsIcon />}
                  buttonsList={["Edit Doc"]}
                  buttonActions={[
                    () => {
                      onEditStart(doc?.data() as CLUB_DOC);
                    },
                  ]}
                />
              </StyledActions>,
            ],
          };
          _rows.push(_row);
        });
        setRows(_rows);
        setFetched(true);
      });
    return () => unsubscribe();
  }, [fetched]);

  return fetched ? rows : null;
};
