import React from "react";
import { TabPanel } from "../../../components/shared/AppModalDesktopSteps/TabPanel/TabPanel";
import { StyledTabContainer } from "../ContactsPage.styles";
import { StyledAppModalDesktopStepTitle } from "../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import { Box, FormGroup, Typography, useTheme } from "@mui/material";
import { AppFormikField } from "../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { AppButton } from "../../../components/core/AppButton/AppButton";
import { AppPhoneNumberSelect } from "../../../components/core/AppFormik/AppPhoneNumberSelect/AppPhoneNumberSelect";
import { ContactFormValues } from "./ContactFormModal";
import { Contact } from "../../../types/contacts/contacts.types";
import { StyledAppModalSubmitButtonContainer } from "../../../components/shared/StyledComponents/StyledAppModalComponents";
import styled from "styled-components";

const contactDetailsSchema = Yup.object({
  email: Yup.string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
    .required("Required")
    .min(1, "Minimum length is 1")
    .max(255, "Maximum length is 255"),
  phoneNumber: Yup.string().required("Required"),
  phoneNumberCode: Yup.string(),
  description: Yup.string().max(255, "Maximum length is 255"),
  includeInMemberApp: Yup.boolean().required("Required"),
});

interface ContactDetailsFormValues {
  email: string;
  phoneNumber: string;
  description: string;
  includeInMemberApp: boolean;
  phoneNumberCode?: string;
}

const StyledFields = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 20,
  marginBottom: 100,
  "@media only screen and (min-width: 1200px)": {
    marginBottom: 0,
  },
});

export const DetailsTab = ({
  outerTabValue,
  setContactFormData,
  contactFormData,
  onSubmit,
  isLoading,
  isEditMode,
}: {
  outerTabValue: number;
  setContactFormData: React.Dispatch<React.SetStateAction<ContactFormValues>>;
  contactFormData: Contact | null;
  onSubmit: (values: ContactFormValues) => void;
  isLoading: boolean;
  isEditMode: boolean;
}) => {
  const theme = useTheme();

  return (
    <TabPanel value={outerTabValue} index={1}>
      <StyledTabContainer>
        <StyledAppModalDesktopStepTitle>Details</StyledAppModalDesktopStepTitle>

        <Formik
          enableReinitialize={true}
          validateOnMount
          initialValues={{
            phoneNumberCode:
              contactFormData?.phoneNumber?.split?.("-")?.[0] || "+44",
            phoneNumber: contactFormData?.phoneNumber?.split?.("-")?.[1] || "",
            email: contactFormData?.email || "",
            description: contactFormData?.description || "",
            includeInMemberApp: contactFormData?.includeInMemberApp || false,
          }}
          validationSchema={contactDetailsSchema}
          onSubmit={async (values: ContactDetailsFormValues) => {
            setContactFormData((prev) => ({
              ...prev,
              ...values,
              phoneNumber: `${
                values.phoneNumberCode
              }-${values.phoneNumber.replace(/^0+/, "")}`,
            }));
            onSubmit({
              ...contactFormData,
              ...values,
              phoneNumber: `${
                values.phoneNumberCode
              }-${values.phoneNumber.replace(/^0+/, "")}`,
            } as ContactFormValues);
          }}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => {
            const _touched: FormikTouched<ContactDetailsFormValues> = touched;

            const _errors: FormikErrors<ContactDetailsFormValues> = errors;

            return (
              <Form
                style={{
                  height: "100%",
                  display: "flex",
                  paddingBottom: "35px",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  height={"100%"}
                  flexGrow={1}
                  gap={2}
                >
                  <StyledFields>
                    <AppFormikField
                      isRequired={true}
                      name="phoneNumber"
                      value={values.phoneNumber}
                      label="Mobile Number"
                      errorMessage={
                        _touched.phoneNumber && _errors.phoneNumber
                          ? String(errors.phoneNumber)
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={(e) =>
                        setFieldValue(
                          "phoneNumber",
                          e.target?.value?.replace?.(/[^0-9]/gi, "")
                        )
                      }
                      type="text"
                      InputProps={{
                        startAdornment: (
                          <AppPhoneNumberSelect
                            name="phoneNumberCode"
                            value={values.phoneNumberCode}
                            onChange={handleChange}
                          />
                        ),
                      }}
                    />

                    <AppFormikField
                      isRequired={true}
                      name="email"
                      value={values.email}
                      label="Email Address"
                      errorMessage={
                        _touched.email && _errors.email
                          ? String(errors.email)
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                    />

                    <AppFormikField
                      name="description"
                      value={values.description}
                      label="Description"
                      tooltip="This field provides brief descriptions of each contact's role, helping members know who to reach for specific needs."
                      errorMessage={
                        _touched.description && _errors.description
                          ? String(errors.description)
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                    />
                  </StyledFields>
                  <FormGroup
                    sx={{ width: "100%", marginTop: "auto", marginBottom: 0 }}
                  >
                    <StyledAppModalSubmitButtonContainer>
                      <AppButton
                        type="submit"
                        containerCustomStyles={{ marginTop: "auto" }}
                        isLoading={isLoading}
                      >
                        {isEditMode ? "Edit contact" : "Add contact"}
                      </AppButton>
                    </StyledAppModalSubmitButtonContainer>
                  </FormGroup>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </StyledTabContainer>
    </TabPanel>
  );
};
