export const APP_ROUTES = {
  home: "/",
  dashboard: "/dashboard",
  groups: "/groups",
  signIn: "/sign-in",
  signUp: "/sign-up",
  forgetPassword: "/forget-password",
  resetPassword: "/reset-password",
  members: "/members",
  roles: "/roles",
  templates: "/templates",
  branding: "/branding",
  clients: "/clients",
  contacts: "/contacts",
  downloadMobileApps: "/download-mobile-app",
  payments: "/payments",
  clubDocs: "/club-docs",
  locations: "/locations",
  attendEvent: "/attend-event",
  clientSelector: "/client-selector",
  pageNotFound: "/*",
};

export const EXTERNAL_URLS = {
  yourzownLogo:
    "https://firebasestorage.googleapis.com/v0/b/yourzown-dev-40a7c.appspot.com/o/yourzownAssets%2FyourzownLogo.png?alt=media&token=d7d4b6c4-f737-4fc1-859f-4d5bcabed715",
};
