export const compareAlphanumeric = (nameA: string, nameB: string) => {
  const numA = nameA?.toLowerCase()?.match(/\d+/);
  const numB = nameB?.toLowerCase()?.match(/\d+/);

  if (numA && numB) {
    const diff = parseInt(numA[0]) - parseInt(numB[0]);
    if (diff !== 0) {
      return diff; // Sort by numeric part if they exist
    }
  }

  // Fallback to standard string comparison if no numeric difference
  return nameA.localeCompare(nameB);
};
