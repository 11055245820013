import styled, { CSSObject } from "styled-components";

export const StyledAppFormikFieldError = styled.div<{
  $customstyles?: CSSObject;
}>(({ $customstyles, theme }) => ({
  color: theme.error.main,
  fontSize: 12,
  minHeight: 15,
  marginLeft: "26px",
  ...$customstyles,
}));

export const StyledAppFormikFieldTooltip = styled.div<{
  $customstyles?: CSSObject;
}>(({ $customstyles, theme }) => ({
  color: theme.dark[600],
  fontSize: 12,
  marginLeft: "26px",
  ...$customstyles,
}));
