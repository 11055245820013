import { CSSObject, useTheme } from "styled-components";

export const AppUploadIcon = (props: { containerStyles?: CSSObject }) => {
  const theme = useTheme();
  return (
    <div style={{ ...props.containerStyles }}>
      <svg
        width="98"
        height="98"
        viewBox="0 0 98 98"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_1204_2161)">
          <rect
            x="24"
            y="19.0039"
            width="50"
            height="50"
            rx="25"
            fill={theme.colorPrimary}
          />
          <rect
            x="22"
            y="17.0039"
            width="54"
            height="54"
            rx="27"
            stroke="white"
            strokeOpacity="0.5"
            strokeWidth="4"
          />
        </g>
        <mask
          id="mask0_1204_2161"
          maskUnits="userSpaceOnUse"
          x="41"
          y="32"
          width="16"
          height="19"
        >
          <path
            fillRule="evenodd"
            d="M45.6161 48.9887C45.6166 49.7915 46.2673 50.4422 47.0701 50.4427H50.4621C51.2645 50.4416 51.9145 49.7911 51.9151 48.9887V40.7607C51.9151 40.6226 52.027 40.5107 52.1651 40.5107H55.0651C55.5564 40.5488 56.0164 40.2664 56.2047 39.811C56.393 39.3556 56.2668 38.8307 55.8921 38.5107L49.7941 32.4157C49.2187 31.8666 48.3134 31.8666 47.7381 32.4157L41.6401 38.5157C41.2639 38.8352 41.1368 39.3608 41.3254 39.8168C41.5139 40.2729 41.9752 40.5552 42.4671 40.5157H45.3671C45.4335 40.5154 45.4972 40.5417 45.5441 40.5886C45.5911 40.6356 45.6173 40.6993 45.6171 40.7657L45.6161 48.9887Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_1204_2161)">
          <path
            fillRule="evenodd"
            d="M36.239 27.0039H61.2911V55.4427H36.239V27.0039Z"
            fill="white"
          />
        </g>
        <mask
          id="mask1_1204_2161"
          maskUnits="userSpaceOnUse"
          x="37"
          y="48"
          width="24"
          height="8"
        >
          <path
            fillRule="evenodd"
            d="M37 51.3912C37.0022 53.6188 38.8074 55.424 41.035 55.4262H56.965C59.1926 55.424 60.9978 53.6188 61 51.3912V49.5142C60.9811 48.8516 60.4384 48.3242 59.7755 48.3242C59.1126 48.3242 58.5699 48.8516 58.551 49.5142V51.3882C58.5499 52.2637 57.8405 52.9731 56.965 52.9742H41.035C40.1595 52.9731 39.4501 52.2637 39.449 51.3882V49.5142C39.4301 48.8516 38.8874 48.3242 38.2245 48.3242C37.5616 48.3242 37.0189 48.8516 37 49.5142V51.3912Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_1204_2161)">
          <path
            fillRule="evenodd"
            d="M32 43.3242H66V60.4262H32V43.3242Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1204_2161"
            x="0"
            y="0.00390625"
            width="98"
            height="98"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="5" />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1204_2161"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1204_2161"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
