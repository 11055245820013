import { useEffect, useState } from "react";
import {
  StyledAppSwitchContainer,
  StyledLabel,
  StyledSwitch,
} from "./AppSwitch.styles";
import { CSSObject } from "styled-components";
import { DISABLED_ELEMENT_STYLES } from "../../../styles/styles.constants";

interface AppSwitchProps {
  label?: string;
  id?: string;
  checked?: boolean;
  isDisabled?: boolean;
  customColor?: string;
  onChange: (checked: boolean) => void;
  containerStyles?: CSSObject;
  labelStyles?: CSSObject;
}

export const AppSwitch = (props: AppSwitchProps) => {
  const [checked, setChecked] = useState(Boolean(props.checked));
  const handleOnChange = (e: any) => {
    const _checked = e.target.checked;
    setChecked(_checked);
    props.onChange(_checked);
  };

  useEffect(() => {
    setChecked(Boolean(props.checked));
  }, [props.checked]);

  return (
    <StyledAppSwitchContainer
      style={{
        ...props.containerStyles,
        ...(props.isDisabled ? DISABLED_ELEMENT_STYLES : {}),
      }}
    >
      <StyledSwitch
        id={props.id || props.label}
        checked={checked}
        onChange={props.isDisabled ? () => {} : handleOnChange}
        $customColor={checked ? props.customColor : ""}
      />

      {props.label && (
        <StyledLabel htmlFor={props.label} style={props.labelStyles}>
          {props.label}
        </StyledLabel>
      )}
    </StyledAppSwitchContainer>
  );
};
